import {
    LOAD_TRANSFER,
    SET_INPUT_TRANSFER,
    CLEAR_STATE_TRANSFER,
    SET_PRODUCT_TRANSFER,
} from './types'

const initialState = {
    creatingTransfer:false,
    types: [],
    idType: 0,
    drivers: [],
    idDriver: 0,
    userDrivers: [],
    idUser: 0,
    note: '',
    other: '',
    site: '',
    productsTransfer: [],
    idProduct: 0,
    productName: '',
    gallons: '',
    productName: '',
    deliveryProducts: [],
    states: [],
    idState: 0
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case SET_INPUT_TRANSFER:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }

        case LOAD_TRANSFER:
            return {
                ...state,
                types: payload.types_transfer,
                drivers: payload.drivers,
                productsTransfer: payload.products,
                userDrivers:payload.userDrivers,
                states: payload.states
            }

        case SET_PRODUCT_TRANSFER:
            return {
                ...state,
                deliveryProducts: payload.deliveryProducts,
                productName: '',
                gallons: '',
                idProduct: 0
            }
        case CLEAR_STATE_TRANSFER:
            return {
                ...initialState
            }

        default:
            return state
    }
}
