import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Email from '@material-ui/icons/EmailOutlined';
import Lock from '@material-ui/icons/LockOpenOutlined';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Link from '@material-ui/core/Link';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import 'react-notifications/lib/notifications.css';


import { loginStyles } from '../../../modules/resources/styles'
import { mainTheme } from '../../../modules/resources/themes'
import { clearStateLogin, setInput, login, recoverPassword } from '../../../modules/core/Login/actions'
import { colors } from '../../../modules/resources/colors'
import { string_login } from '../../../modules/resources/strings'
import { img_truck, logo, img_footer } from '../../../modules/resources/images'
import Loading from '../../components/Loading'
import Button from '../../components/Button'
import Modal from '../../components/Modal'

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '90%',
    height: '100vh',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      width: '30%',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class Login extends React.Component {

  state = {
    showPassword: false,
    showModalResetPassword: false,
    butonsDisabled: false,
    showLoadingRecoverPass: false
  }

  componentDidMount() {
    this.props.clearStateLogin()
  }

  render() {
    const { classes } = this.props;

    if (this.props.isAuthenticated) {
      return <Redirect to='/' />
    }

    return (
      this.props.loading ?
        <Loading label={'Loading...'} />
        :
        <div style={loginStyles.container}>
          <ThemeProvider theme={mainTheme}>
            <div className={classes.sectionResponsive}>
              <img src={logo} style={{ width: '40%', textAlign: 'center', marginBottom: 20, marginTop: 10 }} />

              <div style={loginStyles.containerCard}>
                <div style={loginStyles.containerTitle}>
                  <label style={loginStyles.title}>{string_login.title}</label>
                </div>

                <div style={{ padding: 20 }}>

                  <TextField
                    id="email"
                    label="Email"
                    placeholder="Enter your email"
                    margin="normal"
                    variant="filled"
                    style={loginStyles.textField}
                    className={classes.margin}
                    value={this.props.email}
                    onChange={event => this.props.setInput({ prop: 'email', value: event.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  <TextField
                    id="password"
                    label="Password"
                    placeholder="Enter your password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    margin="normal"
                    variant="filled"
                    style={loginStyles.textField}
                    className={classes.margin}
                    value={this.props.password}
                    onChange={event => this.props.setInput({ prop: 'password', value: event.target.value })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />


                  <div style={{
                    width: '100%',
                    textAlign: 'center'
                  }}>
                    <Button
                      label={string_login.button}
                      onPress={() => this.props.login({
                        email: this.props.email,
                        password: this.props.password,
                        history: this.props.history
                      })}
                      width={'80%'}
                      fontSize={15} />
                  </div>

                  <div style={{ textAlign: 'center', marginTop: 15 }}>
                    <Link
                      color="inherit"
                      className={classes.margin}
                      onClick={() => this.setState({
                        showModalResetPassword: true,
                        butonsDisabled: false,
                        showLoadingRecoverPass: false
                      })}>
                      {string_login.reset_password}
                    </Link>
                  </div>

                </div>
              </div>

              <img src={img_truck} style={{ marginLeft: -100, width: '80%' }} />

              <img src={img_footer} style={{ textAlign: 'center', width: '30%', marginTop: 20 }} />
            </div>
            <Modal
              butonsDisabled={this.state.butonsDisabled}
              buttonTitle='Reset'
              maxWidth='sm'
              open={this.state.showModalResetPassword}
              close={() => {
                this.props.setInput({ prop: 'email', value: '' })
                this.setState({ showModalResetPassword: false })
              }
              }
              title='Reset password'
              onPressAction={() => {
                this.props.recoverPassword({
                  email: this.props.email,
                  loading: () => this.setState({ butonsDisabled: true, showLoadingRecoverPass: true }),
                  callBack: () => {
                    this.props.setInput({ prop: 'email', value: '' })
                    this.setState({ showModalResetPassword: false })
                  }
                })
              }}
            >
              {
                this.state.showLoadingRecoverPass ?
                  <Loading label={'Resetting password...'} maxHeight={180} />
                  : <TextField
                    id="email"
                    label="Email"
                    placeholder="Enter your email"
                    margin="normal"
                    variant="filled"
                    style={loginStyles.textField}
                    className={classes.margin}
                    value={this.props.email}
                    onChange={event => this.props.setInput({ prop: 'email', value: event.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />
              }

            </Modal>
          </ThemeProvider>
        </div>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateProps = (state) => {
  const { loading, email, password } = state.login
  const { isAuthenticated } = state.user
  return { isAuthenticated, loading, email, password }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, { clearStateLogin, setInput, login, recoverPassword })(Login)))

