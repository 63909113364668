import React from "react";
import _ from 'lodash'

import {
    LOAD_CUSTOMER,
    SET_INPUT_CUSTOMER,
    CLEAR_STATE_CUSTOMER,
    GOT_CUSTOMER,
    LOADING_OPTIONAL_DATA_CUSTOMER,
    LOAD_OPTIONAL_DATA_CUSTOMER,
    GETTING_CUSTOMER,
    SET_CUSTOMER_DRIVERS_SELECTED,
    ERROR_GETTING_CUSTOMER
} from './types'

import {
    sw_get_customers,
    sw_get_drivers,
    sw_get_states,
    sw_get_subcontractors,
    sw_get_truck_type,
    sw_get_events,
    sw_create_customer,
    sw_get_customer,
    sw_update_customer
} from '../../services/CustomerApi'

import { SET_ITEM_USER } from '../User/types'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { validateEmail, showAlert, isEmpty } from '../../resources/Util'

import firebase from '../../services/Firebase'

export const clearState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE_CUSTOMER
        })
    }
}

export const createCustomer = (info) => {

    const { token, customerName, contactName, email, phone,
        color, address, zipCode, city,
        fk_idState, drivers, history } = info

    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(customerName) || isEmpty(contactName) || isEmpty(email) || isEmpty(cleanPhone) || cleanPhone.length !== 10 ||
            isEmpty(color) || isEmpty(address) || isEmpty(zipCode) || isEmpty(city) ||
            isEmpty(fk_idState) || fk_idState === -1) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (zipCode === "00000" || zipCode === "00000-" || (zipCode.length < 5 || (zipCode.length > 5 && zipCode.length < 10))) {
            showAlert({ msj: string_general.errorMsj.formatZipCode, type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'creatingCustomer', value: true }
        })

        let driversCustomer = []
        drivers.forEach(e => driversCustomer.push(e.id))

        sw_create_customer({
            token, customerName, phone: cleanPhone, contactName, color,
            email, address, zipCode, city,
            fk_idState, drivers: driversCustomer
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_CUSTOMER,
                    payload: { prop: 'creatingCustomer', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_CUSTOMER,
                        payload: { prop: 'creatingCustomer', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Customer created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_CUSTOMER
                })
                history.push("/customers")
            } else {
                dispatch({
                    type: SET_INPUT_CUSTOMER,
                    payload: { prop: 'creatingCustomer', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const updateCustomer = (info) => {

    const {
        token,
        id,
        customerName,
        contactName,
        email,
        phone,
        idStatus,
        color,
        address,
        zipCode,
        city,
        fk_idState,
        drivers,
        history } = info

    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(id) || isEmpty(customerName) || isEmpty(contactName) || isEmpty(email) || isEmpty(cleanPhone) || cleanPhone.length !== 10 ||
            isEmpty(idStatus) || isEmpty(color) || isEmpty(address) || isEmpty(zipCode) || isEmpty(city) ||
            isEmpty(fk_idState) || fk_idState === -1) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (zipCode === "00000" || zipCode === "00000-" || (zipCode.length < 5 || (zipCode.length > 5 && zipCode.length < 10))) {
            showAlert({ msj: string_general.errorMsj.formatZipCode, type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        } else if (isNaN(id)) {
            showAlert({ msj: 'Customer invalid to update', type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'updatingCustomer', value: true }
        })

        let driversCustomer = []
        drivers.forEach(e => driversCustomer.push(e.id))

        sw_update_customer({
            token,
            id,
            customerName,
            contactName,
            email,
            phone: cleanPhone,
            idStatus,
            color,
            address,
            zipCode,
            city,
            fk_idState,
            drivers: driversCustomer
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_CUSTOMER,
                    payload: { prop: 'updatingCustomer', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_CUSTOMER,
                        payload: { prop: 'updatingCustomer', value: false }
                    })
                    return false
                }

                if (idStatus === 2) {
                    firebase.database().ref(`tracking`).remove()
                }

                showAlert({ msj: 'Customer updated successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_CUSTOMER
                })
                history.push("/customers")
            } else {
                dispatch({
                    type: SET_INPUT_CUSTOMER,
                    payload: { prop: 'updatingCustomer', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const getCustomer = (info) => {
    const { token, id } = info
    return (dispatch) => {

        sw_get_customer({ token, id }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_CUSTOMER
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_CUSTOMER
                    })
                    return false
                }

                const customer = data.data

                if (customer === null) {
                    showAlert({ msj: 'Customer not found', type: 2 })
                    dispatch({
                        type: ERROR_GETTING_CUSTOMER
                    })
                    return false
                }

                const columns = [
                    {
                        Header: 'Events',
                        accessor: 'event',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]
                let items = []
                customer.historial.forEach(event => {
                    items.push({
                        event: event.event.eventName
                    })
                })

                customer.drivers.forEach(e => {
                    const driver = e.user
                    dispatch({
                        type: SET_CUSTOMER_DRIVERS_SELECTED,
                        payload: {
                            id: driver.id,
                            description: `${driver.firstName} ${driver.lastName}`,
                            status: driver.status === null ? 0 : driver.status.id,
                            truck: driver.trucks === null ? 0 : driver.trucks.fk_idType,
                            event: driver.event === null ? 0 : driver.event.fk_idEvent,
                            company: driver.sub_contractor_company === null ? 0 : driver.sub_contractor_company[0].fk_idCompany,
                            isSelect: true
                        }
                    })

                })

                dispatch({
                    type: GOT_CUSTOMER,
                    payload: {
                        customerName: customer.customerName,
                        contactName: customer.contactName,
                        email: customer.email,
                        phone: customer.phone.phone,
                        color: customer.color,
                        address: customer.address,
                        status: customer.status.id,
                        city: customer.city,
                        zipCode: customer.zipCode,
                        stateCustomer: customer.state.id,
                        columns,
                        items
                    }
                })
            } else {
                showAlert({ msj: 'Customer not found', type: 2 })
                dispatch({
                    type: ERROR_GETTING_CUSTOMER
                })
            }
        })
    }
}

export const getCustomers = (info) => {
    const { token, success, showError, history } = info
    return (dispatch) => {
        sw_get_customers({ token }).then(data => {

            const columns = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 100,
                    sortable: false,
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Customer Name',
                    accessor: 'customerName',
                    headerStyle: tableStyles.header,
                    Cell: props => <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => props.value.onClick()}>{props.value.label}</a>, // Custom cell components!
                    sortMethod: (a, b) => { return a.label.localeCompare(b.label) },
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Color',
                    accessor: 'color',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Phone Number',
                    accessor: 'phone',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'City',
                    accessor: 'city',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'State',
                    accessor: 'state',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            let items = []

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            } else {
                if (data.status === 200) {
                    const tempCustomers = data.data
                    
                    if (!data.data.success && data.data.success !== undefined) {
                        showAlert({ msj: data.data.message, type: 3 })
                        showError()
                    } else {
                        if (tempCustomers !== undefined) {
                            tempCustomers.forEach(customer => {
                                items.push(
                                    {
                                        manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/edit_customer/${customer.id}`)}>Edit</a>,
                                        name: customer.customerName.toLowerCase(),
                                        customerName: {
                                            onClick: () => {
                                                const itemsCustomers = [{
                                                    id: 1,
                                                    label: 'Sites',
                                                    icon: 'tim-icons icon-square-pin',
                                                    link: `/customer/${customer.id}/sites`
                                                }]
                                                dispatch({
                                                    type: SET_ITEM_USER,
                                                    payload: { prop: 'itemsSubmenu', value: itemsCustomers }
                                                })
                                                dispatch({
                                                    type: SET_ITEM_USER,
                                                    payload: { prop: 'infoLabel', value: customer.customerName }
                                                })
                                                history.push(`/customer/${customer.id}/sites`)
                                            },
                                            label: customer.customerName
                                        },
                                        color: <i className='tim-icons icon-tag' style={{ color: customer.color }} />,
                                        email: customer.email,
                                        phone: customer.phone.phone,
                                        city: customer.city,
                                        state: customer.state.description,
                                        status: customer.status.status,
                                        updated_at: customer.updated_at
                                    }
                                )
                            });
                        }
                        success()
                    }
                } else {
                    showError()
                }
            }
            items = _.orderBy(items, 'name', 'asc')
            dispatch({
                type: LOAD_CUSTOMER,
                payload: { columns, items, listItemsFilter: items.filter(e => e.status === 'Active') }
            })
        })
    }
}

export const setInputCustomer = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop, value }
        })
    }
}

export const filterByStatsCustomer = (info) => {
    const { listItems, status } = info
    return (dispatch) => {
        let filter = (status !== 'All') ? listItems.filter(item => item.status === status) : listItems
        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}

export const filterDrivers = (info) => {
    const {
        list,
        filterByCompany,
        filterByStatus,
        filterByTruck,
        filterByEvent } = info

    return (dispatch) => {

        let filter = []
        list.forEach(e => {
            // ALL
            if (filterByCompany === 0 && filterByStatus === 0 && filterByTruck === 0 && filterByEvent === 0) {
                filter.push(e)
            } else

                // FILTER BY COMPANY - STATUS - TRUCK - EVENT
                if (e.company === filterByCompany && e.status === filterByStatus && e.truck === filterByTruck && e.event === filterByEvent) {
                    filter.push(e)
                } else

                    // FILTER BY COMPANY - STATUS -  TRUCK
                    if (e.company === filterByCompany && e.status === filterByStatus && e.truck === filterByTruck && filterByEvent === 0) {
                        filter.push(e)
                    } else

                        // FILTER BY COMPANY - STATUS
                        if (e.company === filterByCompany && e.status === filterByStatus && filterByTruck === 0 && filterByEvent === 0) {
                            filter.push(e)
                        } else

                            // FILTER BY COMPANY
                            if (e.company === filterByCompany && filterByStatus === 0 && filterByTruck === 0 && filterByEvent === 0) {
                                filter.push(e)
                            } else

                                // FILTER BY COMPANY - TRUCK - EVENT
                                if (e.company === filterByCompany && filterByStatus === 0 && e.truck === filterByTruck && e.event === filterByEvent) {
                                    filter.push(e)
                                } else

                                    // FILTER BY COMPANY - EVENT
                                    if (e.company === filterByCompany && filterByStatus === 0 && filterByTruck === 0 && e.event === filterByEvent) {
                                        filter.push(e)
                                    } else

                                        // FILTER BY COMPANY - TRUCK
                                        if (e.company === filterByCompany && filterByStatus === 0 && e.truck === filterByTruck && filterByEvent === 0) {
                                            filter.push(e)
                                        } else

                                            // FILTER BY COMPANY - STATUS  - EVENT
                                            if (e.company === filterByCompany && e.status === filterByStatus && filterByTruck === 0 && e.event === filterByEvent) {
                                                filter.push(e)
                                            } else

                                                // FILTER BY STATUS - TRUCK - EVENT
                                                if (filterByCompany === 0 && e.status === filterByStatus && e.truck === filterByTruck && e.event === filterByEvent) {
                                                    filter.push(e)
                                                } else

                                                    // FILTER BY  TRUCK - EVENT
                                                    if (filterByCompany === 0 && filterByStatus === 0 && e.truck === filterByTruck && e.event === filterByEvent) {
                                                        filter.push(e)
                                                    } else

                                                        // FILTER BY EVENT
                                                        if (filterByCompany === 0 && filterByStatus === 0 && filterByTruck === 0 && e.event === filterByEvent) {
                                                            filter.push(e)
                                                        } else

                                                            // FILTER BY STATUS  - EVENT
                                                            if (filterByCompany === 0 && e.status === filterByStatus && filterByTruck === 0 && e.event === filterByEvent) {
                                                                filter.push(e)
                                                            } else

                                                                // FILTER BY STATUS - TRUCK
                                                                if (filterByCompany === 0 && e.status === filterByStatus && e.truck === filterByTruck && filterByEvent === 0) {
                                                                    filter.push(e)
                                                                } else

                                                                    // FILTER  STATUS
                                                                    if (filterByCompany === 0 && e.status === filterByStatus && filterByTruck === 0 && filterByEvent === 0) {
                                                                        filter.push(e)
                                                                    } else

                                                                        // FILTER BY TRUCK
                                                                        if (filterByCompany === 0 && filterByStatus === 0 && e.truck === filterByTruck && filterByEvent === 0) {
                                                                            filter.push(e)
                                                                        }
        })

        filter = filter.length === 0 ? null : filter

        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'driversFilter', value: filter }
        })
    }
}

export const selectDriversToCreate = (info) => {
    const { drivers, array, item } = info
    return (dispatch) => {
        let newArray = [...array.filter(e => e.id !== item.id), { ...item, isSelect: !item.isSelect }]
        let driversSelect = [...drivers.filter(e => e.id !== item.id), { ...item, isSelect: !item.isSelect }]
        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'driversFilter', value: _.orderBy(newArray, 'id', 'asc') }
        })

        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'drivers', value: _.orderBy(driversSelect, 'id', 'asc') }
        })
    }
}

export const selectDrivers = (info) => {
    const { drivers } = info
    return (dispatch) => {
        let newArray = drivers.filter(e => e.isSelect)
        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'driversSelect', value: _.orderBy(newArray, 'id', 'asc') }
        })
    }
}

export const deleteDrivers = (info) => {
    const { drivers, driversFilter, driversSelect, item } = info
    return (dispatch) => {
        let newArray = driversSelect.filter(e => e.id !== item.id)

        let changeDriversFilter = [...driversFilter.filter(e => e.id !== item.id), { ...item, isSelect: false }]
        let changeDrivers = [...drivers.filter(e => e.id !== item.id), { ...item, isSelect: false }]

        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'driversSelect', value: _.orderBy(newArray, 'id', 'asc') }
        })

        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'driversFilter', value: _.orderBy(changeDriversFilter, 'id', 'asc') }
        })

        dispatch({
            type: SET_INPUT_CUSTOMER,
            payload: { prop: 'drivers', value: _.orderBy(changeDrivers, 'id', 'asc') }
        })
    }
}

export const getInfo = (info) => {
    const { token, idCustomer, getCustomer } = info
    return (dispatch) => {

        if (getCustomer !== undefined) {
            dispatch({
                type: GETTING_CUSTOMER
            })
        }

        dispatch({
            type: LOADING_OPTIONAL_DATA_CUSTOMER
        })

        const requests = [
            sw_get_states({ token }),
            sw_get_drivers({ token }),
            sw_get_subcontractors({ token }),
            sw_get_truck_type({ token }),
            sw_get_events({ token })
        ]

        Promise.all(requests).then(function (values) {

            const tempStates = values[0].data
            let states = []
            tempStates.forEach(e => states.push({ value: e.id, label: e.description }))

            const tempDrivers = values[1].data
            let drivers = []
            tempDrivers.forEach(e => drivers.push({
                id: e.id,
                description: `${e.firstName} ${e.lastName}`,
                status: e.status === null ? 0 : e.status.id,
                truck: e.trucks === null ? 0 : e.trucks.fk_idType,
                event: e.event === null ? 0 : e.event.fk_idEvent,
                company: e.sub_contractor_company === null ? 0 : e.sub_contractor_company[0].fk_idCompany,
                isSelect: false
            }))

            const tempCompanies = values[2].data
            let companies = [{ value: 0, description: 'All' }]
            tempCompanies.forEach(e => companies.push({ value: e.id, description: e.companyName }))


            let status = [
                { value: 1, description: 'Active' },
                { value: 2, description: 'Inactive' }
            ]

            const tempTypes = values[3].data
            let types = [{ value: 0, description: 'All' }]
            tempTypes.forEach(e => types.push({ value: e.id, description: e.description }))

            const tempEvents = values[4].data
            let events = [{ value: 0, description: 'All' }]
            tempEvents.forEach(e => events.push({ value: e.id, description: e.eventName }))

            dispatch({
                type: LOAD_OPTIONAL_DATA_CUSTOMER,
                payload: {
                    labelStates: 'Select state',
                    arrayStates: _.orderBy(states, 'id', 'asc'),
                    drivers: _.orderBy(drivers, 'id', 'asc'),
                    driversFilter: _.orderBy(drivers, 'id', 'asc'),
                    driversSelect: [],
                    arrayCompanies: _.orderBy(companies, 'id', 'asc'),
                    arrayStatus: _.orderBy(status, 'id', 'asc'),
                    arrayTypes: _.orderBy(types, 'id', 'asc'),
                    arrayEvents: _.orderBy(events, 'id', 'asc')
                }
            })

            if (getCustomer !== undefined) {
                getCustomer()
            }
        })
    }
}