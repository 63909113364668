import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';

export default function DatePicker({label, disableFuture, selectedDate, onChange, hasTime }) {


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label={label}
          style={{width: hasTime ? '50%' : '100%'}}
          format="MM/dd/yyyy"
          disableFuture={disableFuture}
          value={selectedDate}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        {
          hasTime && (
            <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="Delivered Time"
            value={selectedDate}
            onChange={onChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
          )
        }
      
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
