import React from 'react'
import { Ball } from "react-loading-io";
import 'react-notifications/lib/notifications.css';


import { loadingStyles } from '../../modules/resources/styles'
import { colors } from '../../modules/resources/colors'


export default function Loading(props) {
    return (
        <div style={{ ...loadingStyles.container, height: props.maxHeight !== undefined ? props.maxHeight : '100vh' }}>
            <Ball
                size={150}
                thickness={2}
                color={colors.blue_sfs} />
            <label>{props.label}</label>
        </div>
    )
}

