import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import Email from '@material-ui/icons/EmailOutlined';
import User from '@material-ui/icons/PermIdentityOutlined';
import Phone from '@material-ui/icons/Phone';

//MY COMPONENTS
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'

// ACTIONS
import { clearState, setInputSubcontractorAdmin, createSubcontractorAdmin } from '../../../modules/core/SubcontractorAdmin/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { subcontractorAdminStyles } from '../../../modules/resources/styles'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_subcontractor } from '../../../modules/resources/strings'
import { isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  sectionResponsive: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      width: '40%',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50
    },
  }
});

class CreateSubcontractorAdmin extends React.Component {

  state = {
    showPassword: false,
    showConfirmPassword: false
  }

  componentDidMount() {
    this.configMenuItems()
  }

  configMenuItems() {
    const idCompany = this.props.match.params.company
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [
        {
          id: 1,
          label: 'Subcontractor admins',
          icon: 'tim-icons icon-book-bookmark',
          link: `/subcontractor/${idCompany}/admins`
        },
        {
          id: 2,
          label: 'Drivers',
          icon: 'tim-icons icon-badge',
          link: `/subcontractor/${idCompany}/drivers`
        },
        {
          id: 3,
          label: 'Helpers',
          icon: 'tim-icons icon-single-02',
          link: `/subcontractor/${idCompany}/helpers`
        },
        {
          id: 4,
          label: 'Trucks',
          icon: 'tim-icons icon-delivery-fast',
          link: `/subcontractor/${idCompany}/trucks`
        },
      ]
    })

    this.props.setInputUser({ prop: 'value', value: 2 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/subcontractor_companies`,
          label: 'Subcontractor'
        },
        {
          link: `/subcontractor_companies`,
          label: this.props.infoLabel
        },
        {
          link: `/subcontractor/${idCompany}/admins`,
          label: 'Admins'
        },
        {
          link: `/subcontractor/${idCompany}/create_admin`,
          label: 'Create Admin'
        }]
    })
  }

  componentWillUnmount() {
    if (!isEmpty(this.props.firstName) || !isEmpty(this.props.lastName) ||
      !isEmpty(this.props.email) || !isEmpty(this.props.phone)) {
      if (!window.confirm(string_general.leaveMsjCreate)) {
        this.props.history.push(`/subcontractor/${this.props.match.params.company}/create_admin`)
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      this.props.creatingSubcontractorAdmin ?
        <Loading label={string_subcontractor.creating_admin} />
        :
        <div style={{ ...subcontractorAdminStyles.container, alignItems: 'center' }}>

          {/* TITLE */}
          <span style={subcontractorAdminStyles.title}>{string_subcontractor.title_create_admin}</span>

          <div className={classes.sectionResponsive}>
            <ThemeProvider theme={mainTheme}>

              {/* FIRSTNAME */}
              <Input
                id="Firstname"
                label="First Name"
                placeholder="Enter admin first name"
                isRequired
                type
                className={classes.margin}
                value={this.props.firstName}
                onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'firstName', value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <User className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input
                }}
              />

              {/* LASTNAME */}
              <Input
                isRequired
                id="Lastname"
                label="Last Name"
                placeholder="Enter admin last name"
                type
                className={classes.margin}
                value={this.props.lastName}
                onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'lastName', value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <User className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input
                }}
              />

              {/* EMAIL */}
              <Input
                isRequired
                id="Email"
                label="Email"
                placeholder="Enter admin email"
                type
                className={classes.margin}
                value={this.props.email}
                onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'email', value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input
                }}
              />

              {/* PHONE */}
              <Input
                isRequired
                id="Phone"
                label="Number Phone"
                placeholder="Enter admin phone"
                phone
                className={classes.margin}
                value={this.props.phone}
                onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'phone', value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input
                }}
              />

            </ThemeProvider>
            <div style={subcontractorAdminStyles.containerButton}>
              <span>{string_general.required_fields}</span>
              <Button
                label={string_general.button_create}
                onPress={() =>
                  this.props.createSubcontractorAdmin({
                    token: this.props.user.token,
                    company: this.props.match.params.company,
                    firstName: this.props.firstName,
                    lastName: this.props.lastName,
                    email: this.props.email,
                    phone: this.props.phone,
                    history: this.props.history
                  })}
                width={'100%'}
                fontSize={15} />
            </div>
          </div>
        </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel } = state.user
  const { creatingSubcontractorAdmin, firstName, lastName, email, phone } = state.subcontractorAdmin
  return { isAuthenticated, user, infoLabel, creatingSubcontractorAdmin, firstName, lastName, email, phone }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  setInputSubcontractorAdmin,
  createSubcontractorAdmin,
  setInputUser
})(CreateSubcontractorAdmin)))

