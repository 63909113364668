import {
    GOT_SUBCONTRACTOR_COMPANY,
    LOAD_SUBCONTRACTOR_COMPANIES,
    SET_INPUT_SUBCONTRACTOR_COMPANY,
    CLEAR_STATE_SUBCONTRACTOR_COMPANY,
    GETTING_COMPANY,
    LOAD_DATA_COMPANY,
    SET_COMPANY_EVENTS_SELECT,
    ERROR_GETTING_SUBCONTRACTOR_COMPANY
} from './types'

const initialState = {
    creatingSubcontractorCompany: false,
    updatingSubcontractorCompany: false,
    gettingSubcontractorCompany: false,
    columns: [],
    listItems: [],
    listItemsFilter: [],
    labelStates: 'Loading states...',
    companyName: '',
    phone: '',
    email: '',
    status: '',
    address: '',
    city: '',
    zipcode: '',
    stateCompany: undefined,
    arrayStates: [],
    arrayEventsSelect: [],
    arrayStatus: [{ value: -1, description: 'Loading status...' }],
    companyFound: true
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_SUBCONTRACTOR_COMPANY:
            return {
                ...initialState
            }
        case LOAD_SUBCONTRACTOR_COMPANIES:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter
            }
        case SET_INPUT_SUBCONTRACTOR_COMPANY:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GOT_SUBCONTRACTOR_COMPANY:
            return {
                ...state,
                companyFound: true,
                updatingSubcontractorCompany: false,
                gettingSubcontractorCompany: false,
                companyName: payload.companyName,
                phone: payload.phone,
                email: payload.email,
                status: payload.status,
                address: payload.address,
                city: payload.city,
                zipcode: payload.zipcode,
                notes: payload.notes,
                stateCompany: payload.stateCompany
            }
        case LOAD_DATA_COMPANY:
            return {
                ...state,
                arrayEventsSelect: payload.arrayEventsSelect,
                labelStates: payload.labelStates,
                arrayStates: payload.arrayStates,
                arrayStatus: payload.arrayStatus
            }
        case GETTING_COMPANY:
            return {
                ...state,
                updatingSubcontractorCompany: true,
                gettingSubcontractorCompany: true
            }
        case SET_COMPANY_EVENTS_SELECT:
            return {
                ...state,
                arrayEventsSelect: [...state.arrayEventsSelect.filter(e => e.id !== payload.id), payload]
            }
        case ERROR_GETTING_SUBCONTRACTOR_COMPANY:
            return {
                ...state,
                companyFound: false,
                creatingSubcontractorCompany: false,
                updatingSubcontractorCompany: false,
                gettingSubcontractorCompany: false,
            }
        default:
            return state
    }
}
