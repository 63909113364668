import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_get_delivery = (params) => {
    const { token, idDelivery, isDelivery } = params
    
    return axios.get(`${string_general.base_URL}auth/show/deliveries/transfers/${idDelivery}?isDelivery=${isDelivery}`, {

        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_delivery = (params) => {
    const { token, idDelivery } = params
    return axios.put(`${string_general.base_URL}auth/update/deliveries/transfers/${idDelivery}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_delivery_status = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/dispatch_statuses`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_delivery_types = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/types/dispatch/transfer `, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}



export const sw_get_deliveries = (params) => {
    const { token, idEvent } = params
    return axios.get(`${string_general.base_URL}auth/deliveries?idEvent=${idEvent}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}


export const sw_report_gallons = (params) =>{
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/deliveries`,{
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    },{ params })
    .then( res => {
        
        return res;
    })
    .catch( (error)=>{
        
        return error.response;
    })

}