import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';

import { colors } from '../../modules/resources/colors'
import { checkBox } from '../../modules/resources/themes'
import Loading from '../components/Loading'
import NotFound from '../components/ResourceNotFound'


export default function ListSelect(props) {
    const [value, setValue] = React.useState();

    const useStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            width: '100%',
            height: props.maxHeight,
            border: `5px solid ${colors.bg_input}`,
            borderRadius: 10,
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'scroll',
        },
        contentList: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: "100%",
        },
        containerLoading: {
            display: 'flex',
            height: props.maxHeight,
            width: '100%',
            justifyContent: 'center',
            justifyItems: 'center'
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                props.items === null ?
                    <div style={{ width: '50%', height: '50%', marginBottom: 30 }}>
                        <NotFound
                            isList
                            titleInfo={props.titleNotfound}
                            titleButton={props.titleButtonNotfound}
                            goTo={() => props.goToNotFound()}
                        />
                    </div>
                    : props.items.length === 0 ?
                        <div className={classes.containerLoading}>
                            <Loading label={props.titleLoading} maxHeight={props.maxHeight} />
                        </div>
                        :
                        <div className={classes.contentList}>
                            <ThemeProvider theme={checkBox}>
                                {
                                    props.items.map(item => {
                                        return (
                                            <div style={{ marginBottom: 5 }}>
                                                <ListItem
                                                    key={item.id}
                                                    selected={item.id === value}
                                                    button={props.selectItem}
                                                >
                                                    <div
                                                        style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                                                        onClick={() => {
                                                            if (item.isSelect && props.selectItem) {
                                                                if (item.id === value) {
                                                                    setValue(null)
                                                                    props.customerSelected({})
                                                                } else {
                                                                    setValue(item.id)
                                                                    props.customerSelected(item)
                                                                }
                                                            }
                                                        }}>
                                                        <ListItemAvatar>
                                                            {props.icon}
                                                        </ListItemAvatar>
                                                        <ListItemText primary={item.description} />
                                                    </div>
                                                    <ListItemAvatar>
                                                        <Checkbox
                                                            edge="end"
                                                            checked={item.isSelect}
                                                            onChange={() => {
                                                                if (item.isSelect && props.selectItem && item.id === value) {
                                                                    setValue(null)
                                                                    props.customerSelected({})
                                                                }
                                                                props.onChange(item)
                                                            }}
                                                            inputProps={{ 'aria-labelledby': `checkbox-list-secondary-label-${0}` }}
                                                        />
                                                    </ListItemAvatar>
                                                </ListItem>
                                            </div>
                                        )
                                    })
                                }
                            </ThemeProvider>
                        </div>
            }
        </div>
    );

}




