import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";


// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import IconButton from '@material-ui/core/IconButton';
import Email from '@material-ui/icons/EmailOutlined';
import User from '@material-ui/icons/PermIdentityOutlined';
import Lock from '@material-ui/icons/LockOutlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import NotFound from '../../components/ResourceNotFound'

// ACTIONS
import { clearState, setInputAdmin, getUserAdmin, updateUserAdmin } from '../../../modules/core/Admin/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { superAdminStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_admins } from '../../../modules/resources/strings'
import { isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '90%',
    height: '100vh',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      width: '40%',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class EditAdmin extends React.Component {

  state = {
    showPassword: false,
    showConfirmPassword: false
  }

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getUserAdmin({
        token: this.props.user.token,
        id: this.props.match.params.id
      })
    }

    this.configMenuItems()

  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    if (this.props.userType === 2) {
      this.props.setInputUser({
        prop: 'itemsSubmenu', value: [
          {
            id: 1,
            label: 'SFS Admins',
            icon: 'tim-icons icon-book-bookmark',
            link: '/admins'
          }
        ]
      })
      this.props.setInputUser({ prop: 'itemSelect', value: 1 })
    } else {
      this.props.setInputUser({
        prop: 'itemsSubmenu', value: [
          {
            id: 1,
            label: 'Super SFS Admins',
            icon: 'tim-icons icon-book-bookmark',
            link: '/super_admins'
          },
          {
            id: 2,
            label: 'SFS Admins',
            icon: 'tim-icons icon-book-bookmark',
            link: '/admins'
          }
        ]
      })
      this.props.setInputUser({ prop: 'itemSelect', value: 2 })
    }

    this.props.setInputUser({ prop: 'value', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [{
        link: `/admins`,
        label: 'Users'
      },
      {
        link: `/admins`,
        label: 'SFS Admins'
      },
      {
        link: `/edit_admin/${this.props.match.params.id}`,
        label: 'Update SFS Admins'
      }]
    })
  }

  componentWillUnmount() {

    if (!isEmpty(this.props.firstName) || !isEmpty(this.props.lastName) ||
      !isEmpty(this.props.email) || !isEmpty(this.props.password) || !isEmpty(this.props.confirmPassword)) {
      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push(`/edit_admin/${this.props.match.params.id}`)
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      this.props.updatingAdmin ?
        <Loading label={this.props.gettingAdmin ? string_admins.getting_admin : string_admins.updating_admin} />
        : this.props.adminFound ?
          <div style={{ ...superAdminStyles.container, alignItems: 'center' }}>
            {/* TITLE */}
            <span style={superAdminStyles.title}>{string_admins.title_update_admin}</span>

            <div className={classes.sectionResponsive}>
              <ThemeProvider theme={mainTheme}>

                {/* FIRSTNAME */}
                <Input
                  id="Firstname"
                  label="First Name"
                  placeholder="Enter admin first name"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.firstName}
                  onChange={value => this.props.setInputAdmin({ prop: 'firstName', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* LASTNAME */}
                <Input
                  isRequired
                  id="Lastname"
                  label="Last Name"
                  placeholder="Enter admin last name"
                  className={classes.margin}
                  value={this.props.lastName}
                  type
                  onChange={value => this.props.setInputAdmin({ prop: 'lastName', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* EMAIL */}
                <Input
                  isRequired
                  id="Email"
                  label="Email"
                  placeholder="Enter admin email"
                  type
                  className={classes.margin}
                  value={this.props.email}
                  onChange={value => this.props.setInputAdmin({ prop: 'email', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />
                {/* STATUS */}
                <div style={superAdminStyles.containerDropdown}>
                  <Dropdown
                    placeholder={string_general.label_dropdown_status}
                    selectItem={this.props.status}
                    onChange={(value, description) => this.props.setInputAdmin({ prop: 'status', value })}
                    options={this.props.listStatus}
                  />
                  <div style={superAdminStyles.required}> * </div>
                </div>


                {/* PASSWORD */}
                <Input
                  id="password"
                  label="Password"
                  placeholder="Enter admin password"
                  info
                  type={this.state.showPassword}
                  className={classes.margin}
                  value={this.props.password}
                  onChange={value => this.props.setInputAdmin({ prop: 'password', value })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />
                {/* CONFIRM PASSWORD */}
                <Input
                  id="ConfirmPassword"
                  label="Confirm password"
                  placeholder="Enter admin confirm password"
                  info
                  type={this.state.showConfirmPassword}
                  className={classes.margin}
                  value={this.props.confirmPassword}
                  onChange={value => this.props.setInputAdmin({ prop: 'confirmPassword', value })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })}>
                          {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />
              </ThemeProvider>
              <div style={superAdminStyles.containerButton}>
                <span>{string_general.required_fields}</span>
                <Button
                  label={string_general.button_update}
                  onPress={() =>
                    this.props.updateUserAdmin({
                      token: this.props.user.token,
                      id: this.props.match.params.id,
                      firstname: this.props.firstName,
                      lastname: this.props.lastName,
                      email: this.props.email,
                      status: this.props.status,
                      password: this.props.password,
                      confirmPassword: this.props.confirmPassword,
                      history: this.props.history
                    })}
                  width={'100%'}
                  fontSize={15} />
              </div>
            </div>
          </div>
          : <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <NotFound
              titleInfo='Admin not found'
              titleButton="Go to list admin"
              goTo={() => this.props.history.push('/admins')}
            />
          </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, isEdited } = state.user
  const { updatingAdmin, gettingAdmin, adminFound, columns,
    listItems, listItemsFilter, firstName,
    lastName, email, status, password, confirmPassword, listStatus } = state.admin
  return {
    isAuthenticated, user, userType: user.user.type.id, isEdited, updatingAdmin,
    gettingAdmin, adminFound, columns, listItems, listItemsFilter,
    firstName, lastName, email, status, password,
    confirmPassword, listStatus
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  setInputAdmin,
  getUserAdmin,
  updateUserAdmin,
  setInputUser
})(EditAdmin)))

