import {
    CLEAR_STATE_TRACKING,
    SET_INPUT_TRACKING,
    LOAD_DRIVERS,
    RESET_DRIVERS,
    ADD_DRIVER
} from './types'

const initialState = {
    drivers: [],
    labelCustomers: '',
    customers: [],
    sites: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_TRACKING:
            return {
                ...initialState
            }
        case SET_INPUT_TRACKING:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case LOAD_DRIVERS:
            return {
                ...state,
                labelCustomers: payload.labelCustomers,
                customers: payload.customers,
                sites: payload.sites,
                columnsWithoutSortable: payload.columnsWithoutSortable
            }
        case RESET_DRIVERS:
            return {
                ...state,
                drivers: []
            }
        case ADD_DRIVER:
            if(state.drivers.length > 0) {
                let wasUpdated = false;
                state.drivers.forEach((driver, index) => {
                    if(driver.id === payload.id) {
                        state.drivers[index] = payload;
                        wasUpdated = true;
                    }
                });

                if(!wasUpdated) {
                    return {
                        ...state,
                        drivers: [...state.drivers, payload]
                    }
                } else {
                    return {
                        ...state,
                        drivers: state.drivers
                    }
                }
            }
            return {
                ...state,
                drivers: [...state.drivers, payload]
            }
        default:
            return state
    }
}