import React from "react";
import _ from 'lodash'

import {
    CLEAR_STATE_SUBCONTRACTOR_TRUCK,
    LOAD_SUBCONTRACTOR_TRUCKS,
    SET_INPUT_SUBCONTRACTOR_TRUCK,
    GETTING_SUBCONTRACTOR_TRUCK,
    GOT_SUBCONTRACTOR_TRUCK,
    LOAD_DATA_CREATE_SUBCONTRACTOR_TRUCK,
    LOADING_DATA_CREATE_SUBCONTRACTOR_TRUCK,
    ERROR_GETTING_SUBCONTRACTOR_HELPER,
    UPDATE_LIST_DRIVERS_EDIT_TRUCK,
    UPDATE_LIST_ASSISTANT_EDIT_TRUCK,

    UPDATE_LIST_DRIVERS_HELPERS_CREATE_TRUCK

} from './types'

import {
    sw_get_subcontractor_trucks,
    sw_create_subcontractor_truck,
    sw_get_subcontractor_truck,
    sw_update_subcontractor_truck,
    sw_get_truck_types,
    sw_get_events,
    sw_get_free_drivers,
    sw_get_free_assistants,

    //  WHEN SHOW THE TRUCKS FROM EVENT
    sw_get_subcontractor_trucks_from_event,
    sw_get_subcontractor_companies
    //  WHEN SHOW THE TRUCKS FROM EVENT
} from '../../services/SubcontractorTruckApi'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { showAlert, isEmpty } from '../../resources/Util'

export const clearState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE_SUBCONTRACTOR_TRUCK,
        })
    }
}

export const createSubcontractorTruck = (info) => {

    const {
        token,
        company,
        truckNumber,
        comparmentsNumber,
        pumpsNumber,
        totalCapacity,
        licenseNumber,
        make,
        fk_idTruckType,
        color,
        idEvent,
        idDriver,
        idAssistant,
        isFromEvent,
        userType,
        history } = info

    return (dispatch) => {

        let params = {}

        if (isEmpty(fk_idTruckType) || isEmpty(truckNumber) || isEmpty(comparmentsNumber) ||
            isEmpty(pumpsNumber) || isEmpty(totalCapacity) || isEmpty(make)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (isEmpty(company) || isNaN(company) || company === null) {
            showAlert({ msj: 'Subcontractor not found', type: 2 })
            return false
        } else if (isNaN(comparmentsNumber)) {
            showAlert({ msj: string_general.errorMsj.compartments, type: 2 })
            return false
        } else if (isNaN(pumpsNumber)) {
            showAlert({ msj: string_general.errorMsj.pumps, type: 2 })
            return false
        } else if (isNaN(totalCapacity)) {
            showAlert({ msj: string_general.errorMsj.totalCapacity, type: 2 })
            return false
        }

        params = {
            ...params,
            token,
            "idCompany": company,
            fk_idTruckType,
            truckNumber,
            comparmentsNumber,
            pumpsNumber,
            totalCapacity,
            make,
            idEvent: idEvent === 0 ? null : idEvent,
            idDriver: idDriver === 0 ? null : idDriver,
            idAssistant: idAssistant === 0 ? null : idAssistant
        }

        if (!isEmpty(licenseNumber)) {
            params = { ...params, licenseNumber }
        }

        if (!isEmpty(color)) {
            params = { ...params, color }
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: 'creatingSubcontractorTruck', value: true }
        })

        sw_create_subcontractor_truck(params).then(data => {
            console.log("TRUCK: ", data)
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_TRUCK,
                    payload: { prop: 'creatingSubcontractorTruck', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_SUBCONTRACTOR_TRUCK,
                        payload: { prop: 'creatingSubcontractorTruck', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Truck created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_TRUCK
                })
                if (isFromEvent === undefined) {
                    if (userType !== 3) {
                        history.push(`/subcontractor/${company}/trucks`)
                    } else {
                        history.push(`/subcontractor/trucks`)
                    }
                } else {
                    history.push(`/event/${idEvent}/trucks`)
                }

            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_TRUCK,
                    payload: { prop: 'creatingSubcontractorTruck', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const updateSubcontractorTruck = (info) => {

    const {
        token,
        company,
        id,
        truckNumber,
        comparmentsNumber,
        pumpsNumber,
        totalCapacity,
        licenseNumber,
        make,
        fk_idTruckType,
        color,
        fk_idStatus,
        idEvent,
        idDriver,
        idAssistant,
        isFromEvent,
        userType,
        history } = info
    return (dispatch) => {

        let params = {}

        if (isEmpty(fk_idTruckType) || isEmpty(truckNumber) || isEmpty(comparmentsNumber) ||
            isEmpty(pumpsNumber) || isEmpty(totalCapacity) || isEmpty(make) || isEmpty(fk_idStatus)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (isEmpty(company) || isNaN(company)) {
            showAlert({ msj: 'Company not found', type: 2 })
            return false
        } else if (isNaN(comparmentsNumber)) {
            showAlert({ msj: string_general.errorMsj.compartments, type: 2 })
            return false
        } else if (isNaN(pumpsNumber)) {
            showAlert({ msj: string_general.errorMsj.pumps, type: 2 })
            return false
        } else if (isNaN(totalCapacity)) {
            showAlert({ msj: string_general.errorMsj.totalCapacity, type: 2 })
            return false
        } else if (isNaN(id)) {
            showAlert({ msj: 'Truck not exist', type: 2 })
            return false
        }

        params = {
            ...params,
            token,
            id,
            "idCompany": company,
            fk_idTruckType,
            truckNumber,
            comparmentsNumber,
            pumpsNumber,
            totalCapacity,
            make,
            fk_idStatus,
            idEvent: idEvent === 0 ? null : idEvent,
            idDriver: idDriver === 0 ? null : idDriver,
            idAssistant: idAssistant === 0 ? null : idAssistant
        }

        if (!isEmpty(licenseNumber)) {
            params = { ...params, licenseNumber }
        }

        if (!isEmpty(color)) {
            params = { ...params, color }
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: 'updatingSubcontractorTruck', value: true }
        })

        sw_update_subcontractor_truck(params).then(data => {
            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_TRUCK,
                payload: { prop: 'updatingSubcontractorTruck', value: false }
            })
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }

                showAlert({ msj: 'Truck updated successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_TRUCK,
                })
                if (isFromEvent === undefined) {
                    if (userType !== 3) {
                        history.push(`/subcontractor/${company}/trucks`)
                    } else {
                        history.push(`/subcontractor/trucks`)
                    }
                } else {
                    history.push(`/event/${idEvent}/trucks`)
                }
            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_TRUCK,
                    payload: { prop: 'updatingSubcontractorTruck', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })
    }
}

export const getSubcontractorTruck = (info) => {
    const { token, id, gotTruck } = info
    return (dispatch) => {
        dispatch({
            type: GETTING_SUBCONTRACTOR_TRUCK
        })

        sw_get_subcontractor_truck({ token, id }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_HELPER
                })
                return false
            }

            if (data.status === 200) {
                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_SUBCONTRACTOR_HELPER
                    })
                    return false
                }
                const truck = data.data

                const event = truck.event
                let eventSelect = 0
                if (event) {
                    eventSelect = event.fk_idEvent
                }

                const driver = truck.driver
                let driverSelect = 0
                if (driver !== null) {
                    driverSelect = driver.id
                    dispatch({
                        type: UPDATE_LIST_DRIVERS_EDIT_TRUCK,
                        payload: {
                            value: driver.id,
                            description: `${driver.firstName} ${driver.lastName}`,
                            event: eventSelect
                        }
                    })

                    dispatch({
                        type: UPDATE_LIST_ASSISTANT_EDIT_TRUCK,
                        payload: {
                            value: driver.id,
                            description: `${driver.firstName} ${driver.lastName}`,
                            event: eventSelect
                        }
                    })
                }

                const assistant = truck.assistant
                let assistantSelect = 0
                if (assistant !== null) {
                    assistantSelect = assistant.id
                    dispatch({
                        type: UPDATE_LIST_ASSISTANT_EDIT_TRUCK,
                        payload: {
                            value: assistant.id,
                            description: `${assistant.firstName} ${assistant.lastName}`,
                            event: eventSelect
                        }
                    })
                }

                const typeTruckSelect = truck.type.id
                const color = truck.color === null ? '#000' : truck.color

                const columns = [
                    {
                        Header: 'Events',
                        accessor: 'event',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        width: 90,
                        Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Drivers',
                        accessor: 'driver',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Drivers 2',
                        accessor: 'assistant',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]
                let items = []

                if (truck.historial !== undefined) {
                    truck.historial.forEach(element => items.push({
                        event: element.event === null ? '-' : element.event.eventName,
                        driver: element.driver === null ? '-' : `${element.driver.firstName} ${element.driver.lastName}`,
                        assistant: element.helper === null ? '-' : `${element.helper.firstName} ${element.helper.lastName}`
                    }));
                }

                gotTruck(eventSelect, typeTruckSelect, driverSelect, assistantSelect, color)

                dispatch({
                    type: GOT_SUBCONTRACTOR_TRUCK,
                    payload: {
                        truckNumber: truck.truckNumber,
                        comparmentsNumber: truck.comparmentsNumber,
                        pumpsNumber: truck.pumpsNumber,
                        totalCapacity: truck.totalCapacity,
                        make: truck.make,
                        licenseNumber: truck.licenseNumber,
                        status: truck.status.id,
                        columns,
                        listItems: items,
                        event: eventSelect,
                        idDriver: driverSelect,
                        idAssitant: assistantSelect
                    }
                })

            } else {
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_HELPER
                })
                showAlert({ msj: 'Driver not found', type: 2 })
            }
        })
    }
}

export const getSubcontractoTrucks = (info) => {
    const { token, company, userType, success, showError, history } = info
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: "items", value: [] }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: "listItemsFilter", value: [] }
        })

        const requests = [
            sw_get_truck_types({ token }),
            sw_get_events({ token, company }),
            sw_get_subcontractor_trucks({ token, company })
        ]

        Promise.all(requests).then(function (values) {

            let typesTruck = []
            const typesTemp = values[0].data
            if (typesTemp !== undefined) {
                typesTemp.forEach(e => typesTruck.push({
                    value: e.id,
                    description: e.description,
                    id: e.id,
                    label: e.description,
                    disabled: false
                }))
            }

            if (userType === 3) {
                typesTruck.push({
                    value: 0,
                    description: 'All'
                })
            }

            console.log("typesTruck: ", typesTruck)

            let events = [{
                value: 0,
                description: userType === 3 ? 'All' : 'Select an event'
            }]
            const eventsTemp = values[1].data
            if (eventsTemp !== undefined) {
                eventsTemp.forEach(e => events.push({
                    value: e.id,
                    description: e.eventName
                }))
            }

            const data = values[2]
            const columns = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 80,
                    sortable: false,
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Truck Type',
                    accessor: 'truckType',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Truck Number',
                    accessor: 'truckNumber',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Compartments Number',
                    accessor: 'compartments',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Pumps Number',
                    accessor: 'pumps',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Total Capacity',
                    accessor: 'totalCapacity',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Make',
                    accessor: 'make',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'License Number',
                    accessor: 'license',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Event',
                    accessor: 'event',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            let items = []

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
                return false
            }

            if (data.status === 200) {
                const tempTrucks = data.data

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    showError()
                } else {
                    tempTrucks.forEach(truck => {
                        items.push(
                            {
                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => {
                                    userType !== 3 ? history.push(`/subcontractor/${company}/edit_truck/${truck.id}`) : history.push(`/subcontractor/edit_truck/${truck.id}`)
                                }}>Edit</a>,
                                truckType: truck.type.description,
                                type: truck.type.id,
                                truckNumber: truck.truckNumber,
                                compartments: truck.comparmentsNumber,
                                pumps: truck.pumpsNumber,
                                totalCapacity: truck.totalCapacity,
                                make: truck.make,
                                license: truck.licenseNumber,
                                status: truck.status.status,
                                event: truck.event === null ? '-' : truck.event.event.eventName,
                                eventId: truck.event === null ? -1 : truck.event.event.id,
                                updated_at: truck.updated_at
                            }
                        )
                    })
                    success()
                }
            } else {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            }

            dispatch({
                type: LOAD_SUBCONTRACTOR_TRUCKS,
                payload: {
                    columns, items,
                    listItemsFilter: items.filter(e => e.status === 'Active'),
                    events: _.orderBy(events, 'value', 'asc'),
                    typesTruck: _.orderBy(typesTruck, 'value', 'asc')
                }
            })
        })
    }
}

export const getOptionalDataTruck = (info) => {
    const { token, company, event, getTruck } = info
    return (dispatch) => {

        if (getTruck !== undefined) dispatch({
            type: GETTING_SUBCONTRACTOR_TRUCK
        })

        dispatch({
            type: LOADING_DATA_CREATE_SUBCONTRACTOR_TRUCK,
            payload: {
                labelEvents: 'Loading events...',
                labelDrivers: 'Loading drivers...',
                labelAssistants: 'Loading drivers/helpers...',
                labelCompanies: 'Loading subcontractors...'
            }
        })

        const requests = [
            sw_get_truck_types({ token }),
            sw_get_events({ token, company }),
            sw_get_free_drivers({ token, company }),
            sw_get_free_assistants({ token, company }),
            sw_get_subcontractor_companies({ token, event })
        ]

        Promise.all(requests).then(function (values) {

            const typesTemp = values[0].data
            let typesTruck = []
            if (typesTemp !== undefined) {
                typesTemp.forEach(e => typesTruck.push({
                    id: e.id,
                    label: e.description,
                    disabled: false
                }))
            }

            let events = []
            if (event === undefined) {
                const eventsTemp = values[1].data
                if (eventsTemp !== undefined) {
                    eventsTemp.forEach(e => events.push({
                        value: e.id,
                        description: e.eventName
                    }))
                }
            }

            events.length === 0 ? events.push({
                value: 0,
                description: 'Events not found'
            }) : events.push({
                value: 0,
                description: 'Select an event'
            })

            const driversTemp = values[2].data
            let drivers = []
            if (driversTemp !== undefined) {
                driversTemp.forEach(e => drivers.push({
                    value: e.id,
                    description: `${e.firstName} ${e.lastName}`,
                    event: e.fk_idEvent
                }))
            }

            drivers.push({
                value: 0,
                description: 'Select a driver',
                event: 0
            })

            const assistantTemp = values[3].data

            let assistants = []
            if (assistantTemp !== undefined) {
                assistantTemp.forEach(e => assistants.push({
                    value: e.id,
                    description: `${e.firstName} ${e.lastName}`,
                    event: e.fk_idEvent
                }))
            }

            assistants.push({
                value: 0,
                description: 'Select Helper or Driver #2',
                event: 0
            })

            const companiesTemp = values[4].data
            let companies = []
            companiesTemp.forEach(e => {
                companies.push({
                    value: e.subcontractor.id,
                    description: e.subcontractor.companyName
                })
            })

            companies.push({
                value: 0,
                description: 'Select a subcontractor company'
            })

            dispatch({
                type: LOAD_DATA_CREATE_SUBCONTRACTOR_TRUCK,
                payload: {
                    typesTruck,
                    labelEvents: events.length !== 0 ? 'Select event' : 'Events not found',
                    events: _.orderBy(events, 'value', 'asc'),

                    labelDrivers: drivers ? 'Select driver' : 'Drivers not found',
                    drivers: _.orderBy(drivers, 'value', 'asc'),
                    driversFilter: drivers.filter(e => e.value === 0),

                    labelAssistants: assistants ? 'Select Helper or Driver #2' : 'Driver/Helper not found',
                    assistants,
                    assistants: _.orderBy(assistants, 'value', 'asc'),
                    assistantsFilter: assistants.filter(e => e.value === 0),

                    labelCompanies: companies.length !== 0 ? 'Select subcontractor' : 'Subcontractors not found',
                    companies
                }
            })

            if (getTruck !== undefined) getTruck()
        })

    }
}

export const setInputSubcontractorTruck = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop, value }
        })
    }
}

export const filterSubcontractorTruck = (info) => {
    const { listItems, status, labelStatus, company, type, event } = info
    return (dispatch) => {
        //  WHEN SHOW THE TRUCKS FROM EVENT
        let filter = []
        console.log("info: ", info)
        if (status === 0 && company === 0 && type === 0 && event === 0) {
            filter = listItems
        } else if (status !== 0 && company === 0 && type === 0 && event === 0) {
            filter = listItems.filter(item => item.status === labelStatus)
        } else if (status !== 0 && company !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.company === company)
        } else if (status === 0 && company !== 0) {
            filter = listItems.filter(item => item.company === company)
        } else if (status === 0 && type === 0 && event !== 0) {
            filter = listItems.filter(item => item.eventId === event)
        } else if (status !== 0 && type === 0 && event !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.eventId === event)
        } else if (status === 0 && type !== 0 && event !== 0) {
            filter = listItems.filter(item => item.type === type && item.eventId === event)
        } else if (status !== 0 && type !== 0 && event !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.type === type && item.eventId === event)
        } else if (status !== 0 && type !== 0 && event === 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.type === type)
        } else if (status === 0 && type !== 0 && event === 0) {
            filter = listItems.filter(item => item.type === type)
        }
        //  WHEN SHOW THE TRUCKS FROM EVENT
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}

export const filterDrivers_Assistant = (info) => {
    const { listItems, id, event, prop } = info
    return (dispatch) => {

        let itemsFilter = []
        listItems.forEach(e => {
            if ((e.event == event && e.value !== id) || e.value === 0) {
                itemsFilter.push(e)
            }
        })

        console.log("itemsFilter: ", itemsFilter)

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop, value: itemsFilter }
        })
    }
}

export const filterByEvent = (info) => {
    const { event, drivers, assistants } = info
    return (dispatch) => {

        let driversFilter = []
        drivers.forEach(e => {
            if (e.event === event || e.value === 0) {
                driversFilter.push(e)
            }
        })

        let assistantsFilter = []
        assistants.forEach(e => {
            if (e.event === event || e.value === 0) {
                assistantsFilter.push(e)
            }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: 'driversFilter', value: driversFilter }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: 'assistantsFilter', value: assistantsFilter }
        })
    }
}

//  WHEN SHOW THE TRUCKS FROM EVENT
export const getSubcontractoTrucksFromEvent = (info) => {
    const { token, company, customer, event, success, showError, userType, history } = info
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: "items", value: [] }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_TRUCK,
            payload: { prop: "listItemsFilter", value: [] }
        })

        const requests = [
            sw_get_truck_types({ token }),
            sw_get_subcontractor_companies({ token, event }),
            sw_get_subcontractor_trucks_from_event({ token, idEvent: event })
        ]

        Promise.all(requests).then(function (values) {

            const typesTemp = values[0].data
            let typesTruck = []
            if (typesTemp !== undefined) {
                typesTemp.forEach(e => typesTruck.push({
                    id: e.id,
                    label: e.description,
                    disabled: false,

                    value: e.id,
                    description: e.description,
                }))
            }

            if (userType === 3) {
                typesTruck.push({
                    value: 0,
                    description: 'All'
                })
            }

            const companiesTemp = values[1].data

            let companies = [{ value: 0, description: 'All' }]
            companiesTemp.forEach(e => {
                companies.push({
                    value: e.subcontractor.id,
                    description: e.subcontractor.companyName
                })
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_TRUCK,
                payload: { prop: 'companies', value: companies }
            })

            const data = values[2]
            console.log("data trucks: ", data)
            const columns = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 80,
                    sortable: false,
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Truck Type',
                    accessor: 'truckType',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Truck Number',
                    accessor: 'truckNumber',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Compartments Number',
                    accessor: 'compartments',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Pumps Number',
                    accessor: 'pumps',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Total Capacity',
                    accessor: 'totalCapacity',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Make',
                    accessor: 'make',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'License Number',
                    accessor: 'license',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            let items = []

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
                return false
            }

            if (data.status === 200) {
                const tempTrucks = data.data

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    showError()
                } else {
                    tempTrucks.forEach(truck => {
                        items.push(
                            {
                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/event/${event}/company/${truck.idCompany}/edit_truck/${truck.id}`)}>Edit</a>,
                                truckType: truck.type.description,
                                type: truck.type.id,
                                truckNumber: truck.truckNumber,
                                compartments: truck.comparmentsNumber,
                                pumps: truck.pumpsNumber,
                                totalCapacity: truck.totalCapacity,
                                make: truck.make,
                                license: truck.licenseNumber,
                                status: truck.status.status,
                                updated_at: truck.updated_at,
                                company: truck.idCompany
                            }
                        )
                    })

                    success()
                }
            } else {
                showAlert({ msj: 'Check your connection to internet', type: 3 })
                showError()
            }
            if (userType === 3) {
                items = items.filter(e => e.company === company)
            }

            dispatch({
                type: LOAD_SUBCONTRACTOR_TRUCKS,
                payload: {
                    columns,
                    items,
                    events: [],
                    typesTruck: _.orderBy(typesTruck, "value", 'asc'),
                    listItemsFilter: items.filter(e => e.status === 'Active')
                }
            })
        })
    }
}

export const updateListDriversAndHelpers = (info) => {
    const { token, company, event } = info
    return (dispatch) => {

        dispatch({
            type: UPDATE_LIST_DRIVERS_HELPERS_CREATE_TRUCK,
            payload: {
                drivers: [],
                labelDrivers: 'Loading drivers...',
                assistants: [],
                labelAssistants: 'Loading driver 2...'
            }
        })

        const requests = [
            sw_get_free_drivers({ token, company }),
            sw_get_free_assistants({ token, company })
        ]

        Promise.all(requests).then(function (values) {

            const driversTemp = values[0].data
            let drivers = []
            if (driversTemp !== undefined) {
                driversTemp.forEach(e => {
                    if (e.fk_idEvent == event) {
                        drivers.push({
                            value: e.id,
                            description: `${e.firstName} ${e.lastName}`,
                            event: e.fk_idEvent
                        })
                    }
                })
            }

            drivers.push({
                value: 0,
                description: 'Select a driver',
                event: 0
            })

            const assistantTemp = values[1].data
            let assistants = []
            if (assistantTemp !== undefined) {
                assistantTemp.forEach(e => {
                    if (e.fk_idEvent == event) {
                        assistants.push({
                            value: e.id,
                            description: `${e.firstName} ${e.lastName}`,
                            event: e.fk_idEvent
                        })
                    }
                })
            }

            assistants.push({
                value: 0,
                description: 'Select a driver 2',
                event: 0
            })

            dispatch({
                type: UPDATE_LIST_DRIVERS_HELPERS_CREATE_TRUCK,
                payload: {
                    labelDrivers: drivers ? 'Select a driver' : 'Drivers not found',
                    drivers,
                    labelAssistants: assistants ? 'Select a driver 2' : 'Driver 2 not found',
                    assistants
                }
            })
        })

    }
}
//  WHEN SHOW THE TRUCKS FROM EVENT