import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ScrollDialog(props) {
    const [scroll, setScroll] = React.useState('paper');

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={props.maxWidth}
                open={props.open}
                onClose={() => { props.close() }}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">{props.title} </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.close()} color="primary" disabled={props.butonsDisabled}>
                        Cancel
                    </Button>
                    <Button onClick={() => props.onPressAction()} color="primary" disabled={props.butonsDisabled}>
                        {props.buttonTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
