import {
    LOAD_SUBCONTRACTOR_ADMINS,
    SET_INPUT_SUBCONTRACTOR_ADMIN,
    CLEAR_STATE_SUBCONTRACTOR_ADMIN,
    GETTING_SUBCONTRACTOR_ADMIN,
    GOT_SUBCONTRACTOR_ADMIN,
    ERROR_GETTING_SUBCONTRACTOR_ADMIN
} from './types'

const initialState = {
    creatingSubcontractorAdmin: false,
    updatingSubcontractorAdmin: false,
    gettingSubcontractorAdmin: false,
    subcontractorAdminFound: true,
    columns: [],
    listItems: [],
    listItemsFilter: [],
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    phone: '',
    password: '',
    confirmPassword: ''
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_SUBCONTRACTOR_ADMIN:
            return {
                ...initialState
            }
        case LOAD_SUBCONTRACTOR_ADMINS:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter
            }
        case SET_INPUT_SUBCONTRACTOR_ADMIN:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GETTING_SUBCONTRACTOR_ADMIN:
            return {
                ...state,
                updatingSubcontractorAdmin: true,
                gettingSubcontractorAdmin: true,
            }
        case GOT_SUBCONTRACTOR_ADMIN:
            return {
                ...state,
                subcontractorAdminFound: true,
                updatingSubcontractorAdmin: false,
                gettingSubcontractorAdmin: false,
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                status: payload.status,
                phone: payload.phone,
                password: '',
                confirmPassword: ''
            }
        case ERROR_GETTING_SUBCONTRACTOR_ADMIN:
            return {
                ...state,
                subcontractorAdminFound: false,
                updatingSubcontractorAdmin: false,
                gettingSubcontractorAdmin: false,
                firstName: '',
                lastName: '',
                email: '',
                status: '',
                phone: '',
                password: '',
                confirmPassword: ''
            }
        default:
            return state
    }
}
