import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_create_site = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/sites`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_sites = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/sites?idCustomer=${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_states = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/states`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_site = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/sites/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_site = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/sites/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_import_sites = (params) => {
    const { token } = params
    const { data } = params
    return axios.post(`${string_general.base_URL}auth/import/sites`, data, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_location_by_address = (params) => {
    const { address } = params
    return axios.get(`${string_general.geocoding_URL}address=${address}&key=${string_general.api_key}`)
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}