/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import { ThemeProvider } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';

// ICONS
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// SCREENS
import ChangePassword from '../screens/ChangePassword';

import Home from '../screens/Home'

import ListSuperAdmins from '../screens/SuperAdmin/ListSuperAdmins'
import CreateSuperAdmin from '../screens/SuperAdmin/CreateSuperAdmin'
import EditSuperAdmin from '../screens/SuperAdmin/EditSuperAdmin'

import ListAdmins from '../screens/Admin/ListAdmins'
import CreateAdmin from '../screens/Admin/CreateAdmin'
import EditAdmin from '../screens/Admin/EditAdmin'

import ListSubcontractorCompanies from '../screens/SubcontractorCompany/ListSubcontractorCompany'
import CreateSubcontractorCompany from '../screens/SubcontractorCompany/CreateSubcontractorCompany'
import EditSubcontractorCompany from '../screens/SubcontractorCompany/EditSubcontractorCompany'

import ListSubcontractorAdmins from '../screens/SubcontractorAdmin/ListSubcontractorAdmins'
import CreateSubcontractorAdmin from '../screens/SubcontractorAdmin/CreateSubcontractorAdmin'
import EditSubcontractorAdmin from '../screens/SubcontractorAdmin/EditSubcontractorAdmin'

import ListSubcontractorDrivers from '../screens/SubcontractorDriver/ListSubcontractorDrivers'
import CreateSubcontractorDriver from '../screens/SubcontractorDriver/CreateSubcontractorDriver'
import EditSubcontractorDriver from '../screens/SubcontractorDriver/EditSubcontractorDriver'

import ListSubcontractorHelpers from '../screens/SubcontractorHelper/ListSubcontractorHelpers'
import CreateSubcontractorHelper from '../screens/SubcontractorHelper/CreateSubcontractorHelper'
import EditSubcontractorHelper from '../screens/SubcontractorHelper/EditSubcontractorHelper'

import ListSubcontractorTrucks from '../screens/SubcontractorTruck/ListSubcontractorTrucks'
import CreateSubcontractorTruck from '../screens/SubcontractorTruck/CreateSubcontractorTruck'
import EditSubcontractorTruck from '../screens/SubcontractorTruck/EditSubcontractorTruck'


import ListCustomers from '../screens/Customer/ListCustomers'
import CreateCustomer from '../screens/Customer/CreateCustomer'
import EditCustomer from '../screens/Customer/EditCustomer'

import ListSites from '../screens/Site/ListSites'
import CreateSite from '../screens/Site/CreateSite'
import EditSite from '../screens/Site/EditSite'

import ListEvents from '../screens/Event/ListEvents'
import CreateEvent from '../screens/Event/CreateEvent'
import EditEvent from '../screens/Event/EditEvent'

import Reports from '../screens/EventReport/Reports'
import ReportHome from '../screens/EventReport/ReportHome'

import ListDispatches from '../screens/Dispatch/ListDispatches'
import CreateDispatch from '../screens/Dispatch/CreateDispatch'
import EditDispatch from '../screens/Dispatch/EditDispatch';
import ModifyDispatch from '../screens/Dispatch/ModifyDispatch';

import CreateTransfer from '../screens/Transfer/CreateTransfer';

import ListDeliveries from '../screens/Delivery/ListDeliveries'
import EditDelivery from '../screens/Delivery/EditDelivery'

import Tracking from '../screens/Tracking/Tracking'

import ListProducts from '../screens/Product/ListProducts'


//STYLE
import { tabarTheme } from '../../modules/resources/themes'
import { colors } from '../../modules/resources/colors'
import { tabsMenuStyles } from '../../modules/resources/styles'

// IMAGES
import { logo } from '../../modules/resources/images'

// ACTIONS
import { setInputUser } from '../../modules/core/User/actions'
import { logout } from '../../modules/core/Login/actions'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: colors.blue_light
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    justifyItems: 'center',
    backgroundColor: 'white',
    height: 65
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'white',
    paddingTop: theme.spacing(5),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentNav: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
    height: 10,
    ...theme.mixins.toolbar,
    backgroundColor: colors.blue_sfs
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  listItem: {
    color: 'white',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
    width: '100%',
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    width: '100%'
  },
  containerLogout: {
    display: 'none',
    position: "absolute",
    right: 0,
    marginRight: 60,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

function MainPage(props) {

  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  function handleChangeMenu({ id, label, link, items }) {
    props.setInputUser({ prop: 'value', value: id })
    props.setInputUser({ prop: 'labelValue', value: `${label}` })
    props.setInputUser({ prop: 'infoLabel', value: '' })
    props.setInputUser({ prop: 'idCustomerSelected', value: 0 })

    handleMobileMenuClose()

    props.history.push(`${link}`)
    props.setInputUser({ prop: 'itemsSubmenu', value: items })
  }

  function handleChangeSubmenu({ id, link }) {
    props.setInputUser({ prop: 'itemSelect', value: id })
    props.history.push(`${link}`)
  }

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      {
        props.itemsMenu.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => handleChangeMenu(item)}>
            <p>{item.label}</p>
          </MenuItem>
        ))
      }
      <MenuItem
        key={-1}
        onClick={() => props.logout({
          token: props.token,
          history: props.history
        })}>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    !props.changePassword ? <ChangePassword /> :
      <div className={classes.root}>
        <CssBaseline />
        <ThemeProvider theme={tabarTheme} >
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Tabs
              variant="scrollable"
              scrollButtons="auto">
              <div style={{
                display: 'flex',
                justifyItems: 'center',
                marginLeft: 20,
                height: 65
              }}>

                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={() => setOpen(true)}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>

                <div className={classes.sectionDesktop}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {props.itemsMenu.map(item => (
                      <div
                        key={item.id}
                        style={{
                          ...tabsMenuStyles.container,
                          backgroundColor: props.value === item.id ? colors.tabMenu : 'transparent',
                          color: props.value === item.id ? 'white' : 'black'
                        }}
                        onClick={() => handleChangeMenu(item)}
                      ><a>{item.label}</a></div>
                    ))}
                  </div>
                </div>

                {/* LOGOUT */}
                <div className={classes.containerLogout}>
                  <div><span>{`Hi, ${props.user.firstName}`}</span></div>
                  <span style={{ marginLeft: 5, marginRight: 5 }}> | </span>
                  <div
                    style={{
                      border: `1px solid ${colors.blue_sfs}`,
                      borderRadius: 10,
                      backgroundColor: colors.blue_sfs
                    }}
                    onClick={() => props.logout({
                      token: props.token,
                      history: props.history
                    })}><a style={{ padding: 10, color: 'white' }}>Logout</a></div>
                </div>
              </div>
              <div className={classes.sectionMobile}>
                <div style={{
                  display: 'flex',
                  width: '50%',
                  alignItems: 'center'
                }}>
                  <span>{props.labelValue}</span>
                </div>

                <div style={{
                  display: 'flex',
                  width: '50%',
                  justifyItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                  <IconButton
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />

                  </IconButton>
                </div>
              </div>

            </Tabs>
          </AppBar>
          {renderMobileMenu}
        </ThemeProvider>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.drawerHeader}>
            <img
              onClick={() => props.history.push('/')}
              src={logo}
              style={{ width: '80%', height: '100%', textAlign: 'center' }}
            />
            <div style={{
              display: 'flex',
              width: '15%',
              justifyContent: 'center',
              justifyItems: 'center'
            }}>
              <IconButton
                onClick={() => setOpen(false)}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
          </div>
          { props.value !== 4 ? null :
            <span style={{
              height: 39,
              textAlign: 'center',
              padding: 9, color: 'yellow',
              fontSize: 17,
              fontWeight: 'bold',
              backgroundColor: colors.blue_sfs,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>

              {props.infoLabel === '' ? null :
              
              <span>Event: {props.infoLabel}</span>
            }
              </span>
          }
          <Divider />
          <List>
            {props.itemsSubmenu.map(item => (
              <ListItem button
                key={item.id}
                selected={item.id === props.itemSelect}
                classes={classes.listItem}
                onClick={() => handleChangeSubmenu(item)}>
                <ListItemIcon className={classes.listItem}><i className={item.icon} /></ListItemIcon>
                <ListItemText primary={item.label} className={classes.listItem} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Drawer>

        <main className={clsx(classes.content, { [classes.contentShift]: open })}>

          <div className={classes.contentNav}>
            {
              props.siteNavigations !== undefined ? props.siteNavigations.map(nav => {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                return <a
                  style={{ color: 'white', fontSize: 12, marginTop: 20 }}
                  onClick={() => props.history.push(`${nav.link}`)}>{`${nav.label} /`}&nbsp;</a>
              }) : null
            }
          </div>

          <Route exact path="/" component={Home} />

          {props.userType === 1 ? <div>
            <Route exact path="/super_admins" component={ListSuperAdmins} />
            <Route exact path="/create_super_admin" component={CreateSuperAdmin} />
            <Route exact path="/edit_super_admin/:id" component={EditSuperAdmin} />

          </div> : null}

          {props.userType !== 3 ? <div>
            <Route exact path="/admins" component={ListAdmins} />
            <Route exact path="/create_admin" component={CreateAdmin} />
            <Route exact path="/edit_admin/:id" component={EditAdmin} />

            <Route exact path="/subcontractor_companies" component={ListSubcontractorCompanies} />
            <Route exact path="/create_subcontractor_company" component={CreateSubcontractorCompany} />
            <Route exact path="/edit_subcontractor_company/:id" component={EditSubcontractorCompany} />

            <Route exact path="/subcontractor/:company/admins" component={ListSubcontractorAdmins} />
            <Route exact path="/subcontractor/:company/create_admin" component={CreateSubcontractorAdmin} />
            <Route exact path="/subcontractor/:company/edit_admin/:id" component={EditSubcontractorAdmin} />

            <Route exact path="/subcontractor/:company/drivers" component={ListSubcontractorDrivers} />
            <Route exact path="/subcontractor/:company/create_driver" component={CreateSubcontractorDriver} />
            <Route exact path="/subcontractor/:company/edit_driver/:id" component={EditSubcontractorDriver} />

            <Route exact path="/subcontractor/:company/helpers" component={ListSubcontractorHelpers} />
            <Route exact path="/subcontractor/:company/create_helper" component={CreateSubcontractorHelper} />
            <Route exact path="/subcontractor/:company/edit_helper/:id" component={EditSubcontractorHelper} />

            <Route exact path="/subcontractor/:company/trucks" component={ListSubcontractorTrucks} />
            <Route exact path="/subcontractor/:company/create_truck" component={CreateSubcontractorTruck} />
            <Route exact path="/subcontractor/:company/edit_truck/:id" component={EditSubcontractorTruck} />

            <Route exact path="/customers" component={ListCustomers} />
            <Route exact path="/create_customer" component={CreateCustomer} />
            <Route exact path="/edit_customer/:id" component={EditCustomer} />

            <Route exact path="/customer/:customer/sites" component={ListSites} />
            <Route exact path="/customer/:customer/create_site" component={CreateSite} />
            <Route exact path="/customer/:customer/edit_site/:id" component={EditSite} />

            <Route exact path="/create_event" component={CreateEvent} />
            <Route exact path="/edit_event/:id" component={EditEvent} />

            <Route exact path="/event/:event/inventoryReport" component={Reports} />
            <Route exact path="/event/:event/reports" component={ReportHome} />


            <Route exact path="/event/:event/dispatches" component={ListDispatches} />
            <Route exact path="/event/:event/create_dispatch" component={CreateDispatch} />
            <Route exact path="/event/:event/edit_dispatch/:id" component={EditDispatch} />
            <Route exact path="/event/:event/modify_dispatch/:id/:idDriver" component={ModifyDispatch} />

            <Route exact path="/event/:event/create_transfer" component={CreateTransfer} />


            <Route exact path="/event/:event/deliveries" component={ListDeliveries} />
            <Route exact path="/event/:event/deliveries/edit_delivery/:id/:isDelivery" component={EditDelivery} />

            <Route exact path="/event/:event/tracking" component={Tracking} />

            <Route exact path="/products" component={ListProducts} />

          </div> : null}

          <Route exact path="/events" component={ListEvents} />

          <Route exact path="/event/:event/trucks" component={ListSubcontractorTrucks} />
          <Route exact path="/event/:event/create_truck" component={CreateSubcontractorTruck} />
          <Route exact path="/event/:event/company/:company/edit_truck/:id" component={EditSubcontractorTruck} />

          <Route exact path="/event/:event/drivers" component={ListSubcontractorDrivers} />
          <Route exact path="/event/:event/create_driver" component={CreateSubcontractorDriver} />
          <Route exact path="/event/:event/edit_driver/:id" component={EditSubcontractorDriver} />

          <Route exact path="/event/:event/helpers" component={ListSubcontractorHelpers} />
          <Route exact path="/event/:event/create_helper" component={CreateSubcontractorHelper} />
          <Route exact path="/event/:event/edit_helper/:id" component={EditSubcontractorHelper} />

          {props.userType === 3 ? <div>
            <Route exact path="/subcontractor/drivers" component={ListSubcontractorDrivers} />
            <Route exact path="/subcontractor/create_driver" component={CreateSubcontractorDriver} />
            <Route exact path="/subcontractor/edit_driver/:id" component={EditSubcontractorDriver} />

            <Route exact path="/subcontractor/helpers" component={ListSubcontractorHelpers} />
            <Route exact path="/subcontractor/create_helper" component={CreateSubcontractorHelper} />
            <Route exact path="/subcontractor/edit_helper/:id" component={EditSubcontractorHelper} />

            <Route exact path="/subcontractor/trucks" component={ListSubcontractorTrucks} />
            <Route exact path="/subcontractor/create_truck" component={CreateSubcontractorTruck} />
            <Route exact path="/subcontractor/edit_truck/:id" component={EditSubcontractorTruck} />
          </div> : null}
          {/* <img src={img_footer_page} style={{ width: '25%', height: "3%"}} /> */}
        </main>
      </div>
  );
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, itemsMenu, itemsSubmenu, siteNavigations, value, itemSelect, labelValue, changePassword, infoLabel } = state.user

  return {
    isAuthenticated,
    user: user.user, token: user.token, userType: user.user.type.id, siteNavigations,
    itemsMenu, itemsSubmenu, value, itemSelect, labelValue,
    changePassword, infoLabel
  }
}

export default withRouter(connect(mapStateProps, { setInputUser, logout })(MainPage))

