import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_create_dispatches = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/dispatch`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_dispatch = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/dispatch/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_customers_from_event = (params) => {
    const { token, event } = params
    return axios.get(`${string_general.base_URL}auth/customers/list/events/${event}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_dispatch_types = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/dispatch_types`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_sites_by_dispatch = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/dispatch/sites/customer`, {

        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_sites_by_dispatch_list = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/dispatch/sites/pending/customer`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_drivers_event = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/drivers/event`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_groups_event = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/dispatch/sites/pending/customer/group`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_groups_event_create_dispatch = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/sites/group/event`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_dispatch = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/dispatch/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_dispatches = (params) => {
    const { token, event } = params
    return axios.get(`${string_general.base_URL}auth/dispatch?idEvent=${event}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_status = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/dispatch_statuses`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_priority_dispatch = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/priority/dispatch`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_import_sites = (params) => {
    const { token } = params
    const { data } = params
    return axios.post(`${string_general.base_URL}auth/import/sites`, data, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

