import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';

import { setInputUser } from '../../modules/core/User/actions'
import { logout } from '../../modules/core/Login/actions'

import { labelTitleTheme } from '../../modules/resources/themes'
import { homeStyles } from '../../modules/resources/styles'
import { colors } from '../../modules/resources/colors'
import { users, companies, customers, events, products, trucks, ico_logout } from '../../modules/resources/images'

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class Home extends React.Component {

  state = {
    users: ''
  }

  componentDidMount() {

    this.setState({
      users: '../../modules/resources/images/users.svg'
    })

    this.props.setInputUser({
      prop: 'itemsSubmenu',
      value: []
    })

    this.props.setInputUser({
      prop: 'value',
      value: 0
    })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: []
    })

    this.props.setInputUser({
      prop: 'infoLabel',
      value: ''
    })
  }

  render() {
    const { classes } = this.props;
    return (
      this.props.userType !== 3 ? <div style={homeStyles.container}>
        <ThemeProvider theme={labelTitleTheme}>
          <div className={classes.sectionResponsive}>
            <a style={homeStyles.containerButtons}
              onClick={() => {
                if (this.props.userType !== 2) {
                  this.props.history.push('/super_admins')
                } else {
                  this.props.history.push('/admins')
                }
              }}>
              {/* <img src={users} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Users</span>
            </a>

            <a style={homeStyles.containerButtons}
              onClick={() => {
                this.props.history.push('/subcontractor_companies')
              }}>
              {/* <img src={companies} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Subcontractors</span>
            </a>

            <a style={homeStyles.containerButtons}
              onClick={() => {
                this.props.history.push('/customers')
              }}>
              {/* <img src={customers} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Customers</span>
            </a>
          </div>

          <div className={classes.sectionResponsive}>
            <a style={homeStyles.containerButtons}
              onClick={() => {
                this.props.history.push('/events')
              }}>
              {/* <img src={events} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Events</span>
            </a>

            <a style={homeStyles.containerButtons}
              onClick={() => {
                this.props.history.push('/products')
              }}>
              {/* <img src={products} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Products</span>
            </a>

            <a style={homeStyles.containerButtons}
              onClick={() => this.props.logout({ token: this.props.user.token, history: this.props.history })}>
              {/* <img src={ico_logout} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Logout</span>
            </a>
          </div>
        </ThemeProvider>
      </div> : <ThemeProvider theme={labelTitleTheme}>
          <div className={classes.sectionResponsive}>
            <a style={homeStyles.containerButtons}
              onClick={() => {
                this.props.history.push('/events')
              }}>
              {/* <img src={events} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Events</span>
            </a>

            <a style={homeStyles.containerButtons}
              onClick={() => {
                this.props.history.push('/subcontractor/drivers')
              }}>
              {/* <img src={users} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Drivers</span>
            </a>
          </div>


          <div className={classes.sectionResponsive}>

            <a style={homeStyles.containerButtons}
              onClick={() => {
                this.props.history.push('/subcontractor/helpers')
              }}>
              {/* <img src={users} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Helpers</span>
            </a>
            <a style={homeStyles.containerButtons}
              onClick={() => {
                this.props.history.push('/subcontractor/trucks')
              }}>
              {/* <img src={trucks} style={homeStyles.img} /> */}
              <span style={homeStyles.label}>Trucks</span>
            </a>
          </div>
        </ThemeProvider>
    )
  }
}

const mapStateProps = (state) => {
  const { user, infoLabel } = state.user
  return { user, userType: user.user.type.id, infoLabel }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, { setInputUser, logout })(Home)))

