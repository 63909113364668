import {
    CLEAR_STATE_LOGIN,
    SET_INPUT,
    LOADING_LOGIN,
    FAIL_LOGIN,
    SUCCESS_LOGIN,
} from './types'

const initialState = {
    loading: false,
    email: '',
    password: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_STATE_LOGIN:
            return {
                ...initialState
            }
        case SET_INPUT:
            const { prop, value } = action.payload
            return {
                ...state,
                [prop]: value
            }
        case LOADING_LOGIN:
            return {
                ...state,
                loading: true,
            }
        case FAIL_LOGIN:
            return {
                ...state,
                loading: false,
            }
        case SUCCESS_LOGIN:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}
