import React from 'react'
import { connect } from 'react-redux'
import {
    HashRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom'

import Login from '../screens/Auth/Login'
import MainPage from '../components/MainPage'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        rest.isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />
    )} />
)

const Routers = (props) => {
    return (
        <Router>
            <Switch>
                <Route exact path="/login" component={Login} />
                <PrivateRoute isAuthenticated={props.isAuthenticated} component={MainPage} />
            </Switch>
        </Router>
    )
}

const mapStateProps = (state) => {
    const { isAuthenticated } = state.user
    return { isAuthenticated }
}

export default connect(mapStateProps, {})(Routers)