import React from "react";
import ReactTable from "react-table";

import Pagination from "./Pagination";

import "react-table/react-table.css";
import "../../modules/resources/css/Table.css";

import { tableStyles } from '../../modules/resources/styles'

export default function DatatablePage(props) {
    const pages = Math.ceil(props.items.length / props.numberPages)
    return (
        <ReactTable
            PaginationComponent={Pagination}
            showPaginationTop={!props.hidePaginationTop}
            showPaginationBottom={props.hidePaginationBottom}
            previousText='<'
            nextText=">"
            resizable
            noDataText={props.noDataText ? props.noDataText : 'No records'}
            defaultPageSize={props.numberPages}
            minRows={props.numberPages}
            pageSize={props.numberPages}
            pages={pages === 0 ? 1 : pages}
            data={props.items}
            columns={props.columns}
            style={tableStyles.table}
            loading={props.loading}
        />
    )
}
