import {
    LOAD_PRODUCTS,
    LOADING_PRODUCT,
    CLEAR_STATE_PRODUCT,
    SET_INPUT_PRODUCT,
    LOAD_PRODUCTS_STATUS,
    LOAD_PRODUCTS_STATUS_UPDATE
} from './types'

const initialState = {
    creatingProduct: false,
    loadingProduct: false,
    loadingProduct: false,
    loadingProductText: '',
    columns: [],
    listItems: [],
    listStatus: [],
    listItemsFilter: [],
    listStatusUpdate: [],
    productName: '',
    idProduct: '',
    idStatus: 1,
    showAddProductModal: false,
    showEditProductModal: false,
    status: {},
    idStatusUpdate: 0,
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_PRODUCT:
            return {
                ...initialState
            }
        case LOAD_PRODUCTS:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter
            }

        case LOAD_PRODUCTS_STATUS:
            return {
                ...state,
                listStatus: payload.items,

            }
        case LOAD_PRODUCTS_STATUS_UPDATE:
            return {
                ...state,
                listStatusUpdate: payload.itemsUpdate,

            }
        case SET_INPUT_PRODUCT:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case LOADING_PRODUCT:
            return {
                ...state,
                loadingProduct: true,
            }
        default:
            return state
    }
}
