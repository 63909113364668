import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function RadioButton(props) {

    const [value, setValue] = React.useState(`${props.itemSelect}`);

    if (`${props.itemSelect}` !== value) {
        setValue(`${props.itemSelect}`)
    }

    function handleChange(event) {
        const val = event.target.value
        props.onChange(val)
        setValue(val);
    }

    return (

        <div style={{ marginTop: 20 }}>
            <FormControl component="fieldset">
                <FormLabel component="legend" style={{ fontSize: 13 }}>{props.title}</FormLabel>
                <RadioGroup aria-label="position" name="position" value={value} onChange={handleChange} row>
                    {
                        props.radios.map(e => (
                            <FormControlLabel
                                disabled={props.disabled}
                                value={`${e.id}`}
                                control={<Radio color="primary" />}
                                label={e.label}
                                labelPlacement="end"
                            />
                        ))
                    }
                </RadioGroup>
            </FormControl>
        </div>

    );
}
