import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { colors } from "../colors"

export const classes = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(10),
  }
}))

export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#4dabf5',
      main: '#2196f3',
      dark: '#000',
      contrastText: '#fff'
    },
    secondary: {
      light: '#FFF',
      main: '#696969',
      dark: '#FFF',
      contrastText: '#fff'
    }
  }
})


export const datePickerTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#4dabf5',
      main: '#2e85d2',
      dark: '#000',
      contrastText: '#fff'
    },
    secondary: {
      light: '#FFF',
      main: '#696969',
      dark: '#FFF',
      contrastText: '#fff'
    }
  }
})

export const tabarTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#fff',
      dark: '#000',
      contrastText: '#000'
    },
    secondary: {
      light: '#FFF',
      main: '#000',
      dark: '#FFF',
      contrastText: '#fff'
    }
  }
})

export const labelTitleTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#FFF',
      main: '#FFF',
      dark: '#FFF',
      contrastText: '#fff'
    },
  },
})

export const dropdownTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#fff',
      dark: 'transparent',
      contrastText: '#000'
    },
    secondary: {
      light: colors.blue_light,
      main: colors.blue_sfs,
      dark: colors.blue_sfs,
      contrastText: '#fff'
    },
  }
})

export const checkBox = createMuiTheme({
  palette: {
    secondary: {
      light: colors.blue_light,
      main: colors.blue_sfs,
      dark: colors.blue_sfs,
      contrastText: '#fff'
    }
  }
})