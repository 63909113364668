import React from "react";
import Moment from "moment";
import _ from 'lodash'

import {
    SET_INPUT_EVENT_REPORT,
    LOAD_REPORT_EVENT,
    UPDATE_START_DATE,
    UPDATE_END_DATE,
    LOADING_FILTER_DATA_REPORT,
    LOAD_FILTER_DATA_REPORT,
    CLEAR_STATE_REPORT
} from './types'

import { sw_get_customers_by_event, sw_get_active_products, sw_get_inventory_report } from '../../services/EventReportApi'
import { string_general, string_event_report } from '../../../modules/resources/strings'
import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { showAlert } from '../../resources/Util'


export const clearState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_STATE_REPORT })
    }
}


export const getFilterData = (info) => {
    const { token, idEvent, loadReport, idCustomer } = info
    return (dispatch) => {
        dispatch({
            type: LOADING_FILTER_DATA_REPORT,
            payload: {
                labelCustomers: string_general.placeholder_select_loading_customers,
                labelProducts: string_general.placeholder_select_loading_products,
            }
        })
        const requests = [
            sw_get_customers_by_event({ token, idEvent }),
            sw_get_active_products({ token }),
        ]

        Promise.all(requests).then(function (values) {
            // CUSTOMERS
            let customers = [{ value: 0, description: 'All' }]
            if (values[0].status == 200) {
                const tempCustomers = values[0].data
                if (tempCustomers !== undefined) {
                    tempCustomers.forEach(customer => {
                        customers.push(
                            {
                                value: customer.idCustomer,
                                description: customer.customer.customerName
                            }
                        )
                    });
                }
            }
            let products = [{ value: 0, description: 'All' }]
            if (values[1].status == 200) {
                const tempProducts = values[1].data

                if (tempProducts !== undefined) {
                    tempProducts.forEach(product => {
                        products.push(
                            {
                                value: product.id,
                                description: product.productName
                            }
                        )
                    });
                }
            }


            dispatch({
                type: LOAD_FILTER_DATA_REPORT,
                payload: {
                    idCustomer,
                    customers, products,
                    labelCustomers: string_general.placeholder_select,
                    labelProducts: string_general.placeholder_select,
                }
            })
            loadReport()


        })
    }
}


export const getInventoryReport = (info) => {
    const { token, idEvent, idCustomer, idProduct, startDate, endDate, hideLoading } = info

    return (dispatch) => {
        sw_get_inventory_report({ token, idEvent, idCustomer, idProduct, startDate, endDate }).then(data => {
            hideLoading()
            if (data === undefined) {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })
                return false
            }
            if (data.status === 200) {
                const tempReport = data.data.products
                let reportList = []
                tempReport.forEach(report => {
                    reportList.push(
                        {
                            product: report.product,
                            site: report.site,
                            notes: report.notes,
                            images: report.images && JSON.parse(report.images),
                            gallons: report.gallons,
                            deliveriedDate: report.deliveriedDate,
                            otherSupplier: (report.OtherSupplier === null || report.OtherSupplier === undefined) ? '-' : report.OtherSupplier,
                            customer: report.customerName === null ? '-' : report.customerName,
                            supplier: (report.supplierFullName === null || report.supplierFullName === undefined) ? '-' : report.supplierFullName,
                            type: report.type,
                            driverFullName: report.driverFullName,
                            state: !report.state ? '-' : report.state,
                            //Just for detailed report export
                            siteAddress: !report.siteAddress ? '-' : report.siteAddress,
                            siteCity: !report.siteCity ? '-' : report.siteCity,
                            siteState: !report.siteState ? '-' : report.siteState,
                            siteZipCode: !report.siteZipCode ? '-' : report.siteZipCode,
                        }
                    )
                });

                reportList = _.orderBy(reportList, "deliveriedDate", 'asc')
                const columns = [
                    {
                        Header: 'Type',
                        accessor: 'type',
                        headerStyle: tableStyles.header,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: string_event_report.site_label,
                        accessor: 'site',
                        headerStyle: tableStyles.header,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: string_event_report.product_label,
                        accessor: 'product',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: string_event_report.gallons_label,
                        accessor: 'gallons',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: string_event_report.delivery_date_label,
                        accessor: 'deliveriedDate',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]
                const gallonColumns = [
                    {
                        Header: string_event_report.total_gallons_label,
                        accessor: 'totalGallons',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: string_event_report.start_date_label,
                        accessor: 'minDate',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },

                    {
                        Header: string_event_report.end_date_label,
                        accessor: 'maxDate',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },

                ]

                let gallonReport = []

                if (data.data.total.total_gallons != null) {
                    gallonReport.push({ totalGallons: data.data.total.total_gallons, minDate: data.data.total.min_date, maxDate: data.data.total.max_date })
                }
                dispatch({
                    type: LOAD_REPORT_EVENT,
                    payload: { reportList, columns, gallonReport, gallonColumns }
                })

            } else {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })
                return false
            }
        })
    }
}

export const setInputEventReport = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_EVENT_REPORT,
            payload: { prop, value }
        })
    }
}


export const validateDates = (info) => {

    const { startDate, endDate, isStartDate, loadReport } = info
    return (dispatch) => {

        let startDateFormat = Moment(startDate).format('YYYY-MM-DD')
        let endDateFormat = Moment(endDate).format('YYYY-MM-DD')
        if (isStartDate) {
            if (startDate > endDate) {
                showAlert({ msj: string_event_report.invalid_start_date_message, type: 2 })
                return false
            } else {
                dispatch({
                    type: UPDATE_START_DATE,
                    payload: { startDate, startDateFormat }
                })
                loadReport(startDateFormat, endDateFormat)
            }
        } else {
            if (endDate < startDate) {
                showAlert({ msj: string_event_report.invalid_end_date_message, type: 2 })
                return false
            } else {
                dispatch({
                    type: UPDATE_END_DATE,
                    payload: { endDate, endDateFormat }
                })
                loadReport(startDateFormat, endDateFormat)
            }
        }
    }
}


