import React from 'react'
import InputMask from 'react-input-mask'
import ReactTooltip from 'react-tooltip'

// MATERIAL
import TextField from '@material-ui/core/TextField';
import 'react-notifications/lib/notifications.css';

// STYLES
import { inputStyles } from '../../modules/resources/styles'

import { string_general } from '../../modules/resources/strings'
import { format_password } from '../../modules/resources/images'

export default function Input(props) {
    return (
        <div style={inputStyles.containerInput}>
            <ReactTooltip id='pass' place="right" type="dark" effect="float" html={true}>
                {string_general.formatPassword}
            </ReactTooltip>
            <div style={{ width: '90%'}}>
                {!props.phone ?
                    <TextField
                        id={props.id}
                        label={props.label}
                        placeholder={props.placeholder}
                        margin="normal"
                        variant="filled"
                        type={props.type ? 'text' : 'password'}
                        style={inputStyles.input}
                        className={props.className}
                        value={props.value}
                        onChange={event => props.onChange(event.target.value)}
                        InputProps={props.InputProps}
                    />
                    :
                    <InputMask
                        mask={'999-999-9999'}
                        value={props.value}
                        onChange={event => props.onChange(event.target.value)}
                    >
                        {inputProps =>
                            <TextField
                                id={props.id}
                                label={props.label}
                                placeholder={props.placeholder}
                                margin="normal"
                                variant="filled"
                                type="text"
                                style={inputStyles.input}
                                className={props.className}
                                InputProps={props.InputProps}
                            />
                        }
                    </InputMask>
                }
            </div>
            <div style={{
                width: '10%', display: 'flex',
                alignItems: 'center',
            }}>
                {props.isRequired ? <div style={inputStyles.required}> * </div> : null}

                <div style={inputStyles.info} data-tip data-for='pass'>
                    {props.info ? <img src={format_password} style={{ width: 20, height: 20, marginLeft: 5 }} /> : null}
                </div>
            </div>
        </div>
    )
}