import React from "react";
import _ from 'lodash'

import {
    CLEAR_STATE_SUBCONTRACTOR_ADMIN,
    LOAD_SUBCONTRACTOR_ADMINS,
    SET_INPUT_SUBCONTRACTOR_ADMIN,
    GETTING_SUBCONTRACTOR_ADMIN,
    GOT_SUBCONTRACTOR_ADMIN,
    ERROR_GETTING_SUBCONTRACTOR_ADMIN
} from './types'

import { sw_get_subcontractor_admins, sw_create_subcontractor_admin, sw_get_subcontractor_admin, sw_update_subcontractor_admin } from '../../services/SubcontractorAdminApi'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { validateEmail, validatePassword, showAlert, isEmpty } from '../../resources/Util'

export const clearState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE_SUBCONTRACTOR_ADMIN
        })
    }
}

export const createSubcontractorAdmin = (info) => {
    const {
        token, company,
        firstName, lastName, email, phone,
        history } = info
    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(firstName) || isEmpty(lastName) || isEmpty(email) || isEmpty(cleanPhone) || cleanPhone.length !== 10) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (isEmpty(company) || isNaN(company)) {
            showAlert({ msj: 'Company not found', type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_ADMIN,
            payload: { prop: 'creatingSubcontractorAdmin', value: true }
        })

        sw_create_subcontractor_admin({
            token,
            firstName,
            lastName,
            email,
            phone: cleanPhone,
            "idCompany": company,
            "idType": 3
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_ADMIN,
                    payload: { prop: 'creatingSubcontractorAdmin', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_SUBCONTRACTOR_ADMIN,
                        payload: { prop: 'creatingSubcontractorAdmin', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Subcontractor Admin created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_ADMIN
                })
                history.push(`/subcontractor/${company}/admins`)
            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_ADMIN,
                    payload: { prop: 'creatingSubcontractorAdmin', value: false }
                })
                console.log("data.data: ", data)
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const updateSubcontractorAdmin = (info) => {
    const { token, id, company,
        firstName,
        lastName,
        email,
        status,
        password,
        phone,
        confirmPassword,
        history } = info
    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(firstName) || isEmpty(lastName) ||
            isEmpty(email) || isEmpty(cleanPhone) || cleanPhone.length !== 10 ||
            isEmpty(status)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (!isEmpty(password) || !isEmpty(confirmPassword)) {
            if (password !== confirmPassword) {
                showAlert({ msj: string_general.errorMsj.differentPasswords, type: 2 })
                return false
            } else if (!validatePassword(password)) {
                showAlert({
                    msj: `${string_general.errorMsj.password} ${string_general.formatPassword_msj}`,
                    type: 2,
                    duration: 5000
                })
                return false
            }
        } else if (isEmpty(company) || isNaN(company)) {
            showAlert({ msj: 'Company not found', type: 2 })
            return false
        } else if (isEmpty(id) || isNaN(id)) {
            showAlert({ msj: 'User not found', type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_ADMIN,
            payload: { prop: 'updatingSubcontractorAdmin', value: true }
        })

        sw_update_subcontractor_admin({
            token,
            id,
            email,
            password,
            firstName,
            lastName,
            phone: cleanPhone,
            "idStatus": status
        }).then(data => {
            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_ADMIN,
                payload: { prop: 'updatingSubcontractorAdmin', value: false }
            })

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }

                showAlert({ msj: 'Subcontractor Admin updated successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_ADMIN
                })
                history.push(`/subcontractor/${company}/admins`)
            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_ADMIN,
                    payload: { prop: 'updatingAdmin', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })
    }
}

export const getSubcontractorAdmin = (info) => {
    const { token, id } = info
    return (dispatch) => {
        dispatch({
            type: GETTING_SUBCONTRACTOR_ADMIN
        })
        sw_get_subcontractor_admin({ token, id }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_ADMIN
                })
                return false
            }

            if (data.status === 200) {
                const admin = data.data

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_SUBCONTRACTOR_ADMIN
                    })
                    return false
                }

                dispatch({
                    type: GOT_SUBCONTRACTOR_ADMIN,
                    payload: {
                        firstName: admin.firstName,
                        lastName: admin.lastName,
                        email: admin.email,
                        status: admin.status.id,
                        phone: admin.phone.phone
                    }
                })
            } else {
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_ADMIN
                })
                showAlert({ msj: 'Admin not found', type: 2 })
            }
        })
    }
}

export const getSubcontractoAdmins = (info) => {
    const { token, company, success, showError, history } = info
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_ADMIN,
            payload: { prop: "items", value: [] }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_ADMIN,
            payload: { prop: "listItemsFilter", value: [] }
        })

        sw_get_subcontractor_admins({ token, company }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
                return false
            }

            if (data.status === 200) {
                const tempAdmins = data.data

                const columns = [
                    {
                        Header: 'Edit',
                        accessor: 'manage',
                        headerStyle: tableStyles.header,
                        width: 100,
                        sortable: false,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'First Name',
                        accessor: 'firstName',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'lastName',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phone',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Date Modified',
                        accessor: 'updated_at',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]

                let items = []
                tempAdmins.forEach(admin => {
                    items.push(
                        {
                            manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/subcontractor/${company}/edit_admin/${admin.id}`)}>Edit</a>,
                            firstName: admin.firstName,
                            lastName: admin.lastName,
                            email: admin.email,
                            phone: admin.phone.phone,
                            status: admin.status.status,
                            updated_at: admin.updated_at
                        }
                    )
                });
                success()
                items = _.orderBy(items, 'lastName', 'asc')
                dispatch({
                    type: LOAD_SUBCONTRACTOR_ADMINS,
                    payload: { columns, items, listItemsFilter: items.filter(e => e.status === 'Active') }
                })
            } else {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
                return false
            }
        })
    }
}

export const setInputSubcontractorAdmin = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_ADMIN,
            payload: { prop, value }
        })
    }
}

export const filterByStatusSubcontractorAdmin = (info) => {
    const { listItems, status } = info
    return (dispatch) => {
        let filter = (status !== 'All') ? listItems.filter(item => item.status === status) : listItems
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_ADMIN,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}