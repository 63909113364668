import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";


// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import IconButton from '@material-ui/core/IconButton';
import Email from '@material-ui/icons/EmailOutlined';
import User from '@material-ui/icons/PermIdentityOutlined';
import Phone from '@material-ui/icons/Phone';
import Lock from '@material-ui/icons/LockOutlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import NotFound from '../../components/ResourceNotFound'

// ACTIONS
import { clearState, setInputSubcontractorAdmin, getSubcontractorAdmin, updateSubcontractorAdmin } from '../../../modules/core/SubcontractorAdmin/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { subcontractorAdminStyles } from '../../../modules/resources/styles'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_subcontractor } from '../../../modules/resources/strings'
import { isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  sectionResponsive: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      width: '40%',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50
    },
  }
});

class EditSubcontractorAdmin extends React.Component {

  state = {
    showPassword: false,
    showConfirmPassword: false
  }

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getSubcontractorAdmin({
        token: this.props.user.token,
        id: this.props.match.params.id
      })
    }

    this.configMenuItems()
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    const idCompany = this.props.match.params.company
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [
        {
          id: 1,
          label: 'Subcontractor admins',
          icon: 'tim-icons icon-book-bookmark',
          link: `/subcontractor/${idCompany}/admins`
        },
        {
          id: 2,
          label: 'Drivers',
          icon: 'tim-icons icon-badge',
          link: `/subcontractor/${idCompany}/drivers`
        },
        {
          id: 3,
          label: 'Helpers',
          icon: 'tim-icons icon-single-02',
          link: `/subcontractor/${idCompany}/helpers`
        },
        {
          id: 4,
          label: 'Trucks',
          icon: 'tim-icons icon-delivery-fast',
          link: `/subcontractor/${idCompany}/trucks`
        },
      ]
    })

    this.props.setInputUser({ prop: 'value', value: 2 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/subcontractor_companies`,
          label: 'Subcontractor'
        },
        {
          link: `/subcontractor_companies`,
          label: this.props.infoLabel
        },
        {
          link: `/subcontractor/${idCompany}/admins`,
          label: 'Admins'
        },
        {
          link: `/subcontractor/${idCompany}/edit_admin/${this.props.match.params.id}`,
          label: 'Update Admin'
        }]
    })
  }

  componentWillUnmount() {
    if (!isEmpty(this.props.firstName) || !isEmpty(this.props.lastName) ||
      !isEmpty(this.props.email) || !isEmpty(this.props.phone) ||
      !isEmpty(this.props.password) || !isEmpty(this.props.confirmPassword)) {
      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push(`/subcontractor/${this.props.match.params.company}/edit_admin/${this.props.match.params.id}`)
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      this.props.updatingSubcontractorAdmin ?
        <Loading label={this.props.gettingSubcontractorAdmin ? string_subcontractor.getting_subcontractor_admin : string_subcontractor.updating_subcontractor_admin} />
        : this.props.subcontractorAdminFound ?
          <div style={{ ...subcontractorAdminStyles.container, alignItems: 'center' }}>

            {/* TITLE */}
            <span style={subcontractorAdminStyles.title}>{string_subcontractor.title_update_admin}</span>

            <div className={classes.sectionResponsive}>
              <ThemeProvider theme={mainTheme}>

                {/* FIRSTNAME */}
                <Input
                  id="Firstname"
                  label="First Name"
                  placeholder="Enter admin first name"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.firstName}
                  onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'firstName', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* LASTNAME */}
                <Input
                  isRequired
                  id="Lastname"
                  label="Last Name"
                  placeholder="Enter admin last name"
                  type
                  className={classes.margin}
                  value={this.props.lastName}
                  onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'lastName', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* EMAIL */}
                <Input
                  isRequired
                  id="Email"
                  label="Email"
                  placeholder="Enter admin email"
                  type
                  className={classes.margin}
                  value={this.props.email}
                  onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'email', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* PHONE */}
                <Input
                  isRequired
                  id="Phone"
                  label="Number Phone"
                  placeholder="Enter admin phone"
                  phone
                  className={classes.margin}
                  value={this.props.phone}
                  onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'phone', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* STATUS */}
                <div style={subcontractorAdminStyles.containerDropdown}>
                  <Dropdown
                    placeholder={string_general.label_dropdown_status}
                    selectItem={this.props.status}
                    onChange={(value, description) => this.props.setInputSubcontractorAdmin({ prop: 'status', value })}
                    options={[{ value: 1, description: 'Active' }, { value: 2, description: 'Inactive' }]}
                  />
                  <div style={subcontractorAdminStyles.required}> * </div>
                </div>


                {/* PASSWORD */}
                <Input
                  id="password"
                  label="Password"
                  placeholder="Enter admin password"
                  info
                  type={this.state.showPassword}
                  className={classes.margin}
                  value={this.props.password}
                  onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'password', value })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />
                {/* CONFIRM PASSWORD */}
                <Input
                  id="ConfirmPassword"
                  label="Confirm password"
                  placeholder="Enter admin confirm password"
                  info
                  type={this.state.showConfirmPassword}
                  className={classes.margin}
                  value={this.props.confirmPassword}
                  onChange={value => this.props.setInputSubcontractorAdmin({ prop: 'confirmPassword', value })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })}>
                          {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />
              </ThemeProvider>
              <div style={subcontractorAdminStyles.containerButton}>
                <span>{string_general.required_fields}</span>
                <Button
                  label={string_general.button_update}
                  onPress={() =>
                    this.props.updateSubcontractorAdmin({
                      token: this.props.user.token,
                      company: this.props.match.params.company,
                      id: this.props.match.params.id,
                      firstName: this.props.firstName,
                      lastName: this.props.lastName,
                      email: this.props.email,
                      status: this.props.status,
                      phone: this.props.phone,
                      password: this.props.password,
                      confirmPassword: this.props.confirmPassword,
                      history: this.props.history
                    })}
                  width={'100%'}
                  fontSize={15} />
              </div>
            </div>
          </div>
          : <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <NotFound
              titleInfo='Subcontractor Admin not found'
              titleButton="Go to list Subcontractor admin"
              goTo={() => this.props.history.push(`/subcontractor/${this.props.match.params.company}/admins`)}
            />
          </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel, isEdited } = state.user
  const {
    updatingSubcontractorAdmin, gettingSubcontractorAdmin, subcontractorAdminFound,
    firstName, lastName, email, phone, status, password, confirmPassword
  } = state.subcontractorAdmin
  return {
    isAuthenticated, user, infoLabel, isEdited, updatingSubcontractorAdmin, gettingSubcontractorAdmin, subcontractorAdminFound,
    firstName, lastName, email, phone, status, password, confirmPassword
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  setInputSubcontractorAdmin,
  getSubcontractorAdmin,
  updateSubcontractorAdmin,
  setInputUser
})
  (EditSubcontractorAdmin)))

