import axios from 'axios'
import { string_general } from '../resources/strings'


export const sw_get_events = async (params) => {
    const { token, company, userType } = params
    let path = `${string_general.base_URL}auth/`

    if (userType !== 3) {
        path += `events`
    } else {
        path += `event/subcontractor/${company}`
    }
    console.log("path: ", path)
    return axios.get(path, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}

export const sw_create_event = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/events`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_event = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/events/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_event = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/events/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_status = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/event_statuses`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_cities = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/cities`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_types = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/event_types`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_states = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/states`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_customers = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/customers`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontactors = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/subcontractor_company`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}