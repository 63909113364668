import axios from 'axios'
import { string_general } from '../resources/strings'


export const sw_get_products = (params) => {
    const { token, status } = params
    return axios.get(`${string_general.base_URL}auth/products?status=${status}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_products_status = (params) => {
    const { token } = params

    return axios.get(`${string_general.base_URL}auth/product_statuses`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}



export const sw_create_product = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/products`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_product = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/products/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

