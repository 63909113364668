// ALERTS
import { NotificationManager } from 'react-notifications';
import Geocode from "react-geocode";

export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePassword = (password) => {
    if (password.length < 6) {
        return false
    }

    let is_capital_letters = false
    let is_number = false
    let is_special_character = false

    const capital_letters = "ABCDEFGHYJKLMNÑOPQRSTUVWXYZ";
    const numbers = '0123456789'

    for (let i = 0; i < password.length; i++) {
        if (capital_letters.indexOf(password.charAt(i), 0) != -1) {
            is_capital_letters = true
        }

        if (numbers.indexOf(password.charAt(i), 0) != -1) {
            is_number = true
        }

        if (password.charAt(i).match(/^[^a-zA-Z0-9]+$/)) {
            is_special_character = true
        }
    }

    return (is_capital_letters && is_number && is_special_character) ? true : false
}

export function showAlert({ msj, type, duration = 3000 }) {
    switch (type) {
        case 1:
            return NotificationManager.success(msj, '', duration)
        case 2:
            return NotificationManager.warning(msj, 'Warning message', duration)
        case 3:
            return NotificationManager.error(msj, 'Error message', duration)
    }
}
export function convertCoordstoAddress(lat, lng) {
    Geocode.setApiKey("AIzaSyBxALnoXq5IJucf3UvdzeMLLSDNiHHO5KY");
    Geocode.fromLatLng(`${lat}`, `${lng}`).then(
        response => {
            const address = response.results[0].formatted_address;
            console.log(address);
            return address;
        },
        error => {
            console.error(error);
        })
}

export function isEmpty(str) {
    return (!str || 0 === str.length);
}

export function getIconTruck(color, truckType) {
    let icon = ''
    // truck
    // icon = `data:image/svg+xml;utf8,
    //         <svg 
    //         id="Layer_1" 
    //         enable-background="new 0 0 512 512" 
    //         height="30"
    //         viewBox="0 0 512 512" width="512" 
    //         xmlns="http://www.w3.org/2000/svg">
    //         <path fill="${hex2rgb(color)}" d="m480 221h-23.316l-61.708-109.916c-1.416-2.522-4.083-4.084-6.976-4.084h-153c-3.885 0-7.208 2.791-7.879 6.618l-18.84 107.382c-40.612 0-159.39 0-200.281 0-4.418 0-8 3.582-8 8v112c0 4.418 3.582 8 8 8h72.518c3.951 31.525 30.903 56 63.482 56s59.531-24.475 63.482-56h97.036c3.951 31.525 30.903 56 63.482 56s59.531-24.475 63.482-56h48.518c17.645 0 32-14.355 32-32v-64c0-17.645-14.355-32-32-32zm-336 168c-26.467 0-48-21.533-48-48s21.533-48 48-48 48 21.533 48 48-21.533 48-48 48zm224 0c-26.468 0-48-21.533-48-48s21.532-48 48-48 48 21.533 48 48-21.532 48-48 48zm-83.855-168 8.854-66h108.283l37.053 66zm211.855 72h-16c-4.411 0-8-3.589-8-8s3.589-8 8-8h16z"/><path fill="${hex2rgb(color)}" d="m144 325c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"/><path d="m368 325c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"/></svg>`

    // circle 
    icon = `data:image/svg+xml;utf8,
    <svg 
    version="1.1" 
    id="Capa_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    height="30"
    width="30px"
    viewBox="0 0 438.533 438.533" 
    style="enable-background:new 0 0 438.533 438.533;"
    xml:space="preserve">
   <path fill="${hex2rgb(color)}" d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062C438.533,179.485,428.732,142.795,409.133,109.203z M353.742,297.208c-13.894,23.791-32.736,42.633-56.527,56.534c-23.791,13.894-49.771,20.834-77.945,20.834c-28.167,0-54.149-6.94-77.943-20.834c-23.791-13.901-42.633-32.743-56.527-56.534c-13.897-23.791-20.843-49.772-20.843-77.941c0-28.171,6.949-54.152,20.843-77.943c13.891-23.791,32.738-42.637,56.527-56.53c23.791-13.895,49.772-20.84,77.943-20.84c28.173,0,54.154,6.945,77.945,20.84c23.791,13.894,42.634,32.739,56.527,56.53c13.895,23.791,20.838,49.772,20.838,77.943C374.58,247.436,367.637,273.417,353.742,297.208z"/>
</svg>`;

    const triangle = `data:image/svg+xml;utf8,
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
    height="30"
    width="30px"
         viewBox="0 0 185 185" style="enable-background:new 0 0 185 185;" xml:space="preserve">
    <style type="text/css">
        .st0{fill:${hex2rgb(color)};}
    </style>
    <g>
        <path class="st0" d="M184.88,172.5H0.12l92.38-160L184.88,172.5z M34.76,152.5h115.47L92.5,52.5L34.76,152.5z"/>
    </g>
    </svg>`;
    const square = `data:image/svg+xml;utf8,
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    height="30"
    width="30px"
         viewBox="0 0 185 185" style="enable-background:new 0 0 185 185;" xml:space="preserve">
    <style type="text/css">
        .st0{fill:${hex2rgb(color)};}
    </style>
    <g>
        <path class="st0" d="M174.7,174.7H10.3V10.3H174.7V174.7z M30.3,154.7H154.7V30.3H30.3V154.7z"/>
    </g>
    </svg>
    `;

    if (truckType == 'Tanker') {
        return icon;
    } else if (truckType == 'Bob Truck') {
        return triangle;
    } else if (truckType == 'Pick up') {
        return square;
    }
}


export function getIconDispatch(color) {
    let icon = `data:image/svg+xml;utf8,
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
   <path d="M256,0C167.641,0,96,71.625,96,160c0,24.75,5.625,48.219,15.672,69.125C112.234,230.313,256,512,256,512l142.594-279.375
       C409.719,210.844,416,186.156,416,160C416,71.625,344.375,0,256,0z M256,256c-53.016,0-96-43-96-96s42.984-96,96-96
       c53,0,96,43,96,96S309,256,256,256z" fill="${hex2rgb(color)}"/>
</g>
</svg>`
    return icon;
}

function hex2rgb(hex, opacity) {
    hex = hex.trim();
    hex = hex[0] === '#' ? hex.substr(1) : hex;
    var bigint = parseInt(hex, 16), h = [];
    if (hex.length === 3) {
        h.push((bigint >> 4) & 255);
        h.push((bigint >> 2) & 255);
    } else {
        h.push((bigint >> 16) & 255);
        h.push((bigint >> 8) & 255);
    }
    h.push(bigint & 255);
    if (arguments.length === 2) {
        h.push(opacity);
        return 'rgba(' + h.join() + ')';
    } else {
        return 'rgb(' + h.join() + ')';
    }
}

export function validateZipCode(value) {
    if (value.length <= 10) {

        if (value.length > 5) {

            if (value.charAt(5) !== '-') {
                return false
            }

            if (value === "00000-0000") {
                return false
            }

            const code = value.split("-")

            if (code.length > 2) {
                return false
            }

            const codeA = code[0];
            let codeB = code.length > 1 ? code[1] : null

            if (codeB === null) {
                if (!isNaN(codeA) && codeA.length <= 5) {
                    return true
                }
            } else {
                if (!isNaN(codeA) && codeA.length <= 5 && !isNaN(codeB) && codeB.length <= 4) {
                    return true
                }
            }

        } else {
            if (!isNaN(value) && value.length <= 5) {
                return true
            }
        }


        return false
    }
}

export function validateLicense(value) {

    if (value.length === 0) return true

    //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
    var regex = /^[A-Za-z0-9 ]+$/

    //Validate TextBox value against the Regex.
    var isValid = regex.test(value);
    if (!isValid) {
        return false;
    } else {
        if (/\s/.test(value)) {
            return false;
        }
    }
    return true
}


export const siteFailedCols = [
    {
        Header: 'Import Site Name',
        accessor: 'colSiteName',
    },
    {
        Header: 'Import Site Address',
        accessor: 'colSiteAddress',
    },
    {
        Header: 'Existing Site Name',
        accessor: 'colCurrentSiteName',
    },
    {
        Header: 'Existing Site Address',
        accessor: 'colCurrentSiteAddress',
    },
]


export const sitesToCheckCoordinatesCols = [
    {
        Header: 'Site Name',
        accessor: 'colSiteName',
    },
    {
        Header: 'Site Address',
        accessor: 'colSiteAddress',
    },
]
