import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

// ICONS
import User from '@material-ui/icons/PermIdentityOutlined';
import Address from '@material-ui/icons/AddLocationOutlined';
import City from '@material-ui/icons/LocationCityOutlined';
import ZipCode from '@material-ui/icons/RoomOutlined';
import Location from '@material-ui/icons/EditLocationOutlined';
import GroupNumber from '@material-ui/icons/Filter1Outlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import DropdownSearch from '../../components/DropdownSearch'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import NotFound from '../../components/ResourceNotFound'

// ACTIONS
import { clearState, getDataSite, getSite, setInputSite, updateSite, recalculateGpsData } from '../../../modules/core/Site/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { sitesStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_customer } from '../../../modules/resources/strings'
import { validateZipCode, isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class EditSite extends React.Component {

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getDataSite({
        token: this.props.user.token,
        getSite: () => this.props.getSite({
          token: this.props.user.token,
          id: this.props.match.params.id
        })
      })
    }

    this.configMenuItems()
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    const idCustomer = this.props.match.params.customer
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [{
        id: 1,
        label: 'Sites',
        icon: 'tim-icons icon-square-pin',
        link: `/customer/${idCustomer}/sites`
      }]
    })
    this.props.setInputUser({ prop: 'value', value: 3 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/customers`,
          label: 'Customers'
        },
        {
          link: `/customers`,
          label: this.props.infoLabel
        },
        {
          link: `/customer/${this.props.match.params.customer}/sites`,
          label: 'Sites'
        },
        {
          link: `/customer/${this.props.match.params.customer}/edit_site/${this.props.match.params.id}`,
          label: 'Update Site'
        }]
    })
  }

  componentWillUnmount() {
    if (!isEmpty(this.props.siteName) || !isEmpty(this.props.address) ||
      !isEmpty(this.props.city) || !isEmpty(this.props.notes) ||
      !isEmpty(this.props.zipCode) || !isEmpty(this.props.latitude) ||
      !isEmpty(this.props.longitude) || !isEmpty(this.props.groupNumber) ||
      !isEmpty(this.props.stateSite)) {
      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push(`/customer/${this.props.match.params.customer}/edit_site/${this.props.match.params.id}`)
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (

      this.props.updatingSite ?
        <Loading label={this.props.gettingSite ? string_customer.getting_site : string_customer.updating_site} />
        : this.props.siteFound ?
          <div style={{ ...sitesStyles.container, alignItems: 'center' }}>

            {/* TITLE */}
            <span style={sitesStyles.title}>{string_customer.title_update_site}</span>
            <ThemeProvider theme={mainTheme}>
              <div className={classes.sectionResponsive}>

                {/* SECTION #1 */}
                <div style={sitesStyles.containerDivResponsive}>

                  {/* SITE NAME */}
                  <Input
                    id="SiteName"
                    label="Site Name"
                    placeholder="Enter site name"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.siteName}
                    onChange={value => this.props.setInputSite({ prop: 'siteName', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <User className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* STATUS */}
                  <div style={sitesStyles.containerDropdown}>
                    <Dropdown
                      selectItem={this.props.status}
                      placeholder={string_general.label_dropdown_status}
                      options={[{ value: 1, description: 'Active' }, { value: 2, description: 'Inactive' }]}
                      onChange={(value, description) => this.props.setInputSite({ prop: 'status', value })}
                    />
                    <div style={sitesStyles.required}> * </div>
                  </div>

                  {/* ADDRESS */}
                  <Input
                    id="Address"
                    label="Address"
                    placeholder="Enter site address"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.address}
                    onChange={value => this.props.setInputSite({ prop: 'address', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Address className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* CITY */}
                  <Input
                    isRequired
                    id="City"
                    label="City"
                    placeholder="Enter site city"
                    type
                    className={classes.margin}
                    value={this.props.city}
                    onChange={value => this.props.setInputSite({ prop: 'city', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <City className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* STATE */}
                  <div style={sitesStyles.containerDropdownStates}>
                    <DropdownSearch
                      label='States'
                      selectItem={this.props.stateSite}
                      placeholder={this.props.labelStates}
                      options={this.props.arrayStates}
                      onChange={(value, description) => this.props.setInputSite({ prop: 'stateSite', value })}
                    />
                    <div style={sitesStyles.required}> * </div>
                  </div>

                  {/* ZIP CODE */}
                  <Input
                    isRequired
                    id="ZipCode"
                    label="Zip Code"
                    placeholder="Enter site zip code"
                    type
                    className={classes.margin}
                    value={this.props.zipCode}
                    onChange={value => {
                      if (validateZipCode(value)) {
                        this.props.setInputSite({ prop: 'zipCode', value })
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ZipCode className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                </div>

                {/* SECTION #2 */}
                <div style={sitesStyles.containerDivResponsive}>
                  
                  <div style={{flexDirection:'row', width:'100%',display:'flex',alignItems:'center'}}>
                  <span>GPS DATA</span>
                  <Button
                        label={string_general.recalculateGpsData}
                        onPress={() =>
                          this.props.recalculateGpsData({
                            customer: this.props.match.params.customer,
                            id: this.props.match.params.id,
                            siteName: this.props.siteName,
                            address: this.props.address,
                            city: this.props.city,
                            fk_idState: this.props.stateSite,
                            zipCode: this.props.zipCode,
                            latitude: this.props.latitude,
                            longitude: this.props.longitude,
                            fk_idStatus: this.props.status,
                          })}
                        width={'100%'}
                        fontSize={12} />
                  </div>
                  
                  

                  {/* LATITUDE */}
                  <Input
                    id="Latitude"
                    label="Latitude"
                    placeholder="Enter site latitude"
                    type
                    className={classes.margin}
                    value={this.props.latitude}
                    onChange={value => this.props.setInputSite({ prop: 'latitude', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Location className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* Longitude */}
                  <Input
                    id="Longitude"
                    label="Longitude"
                    placeholder="Enter site longitude"
                    type
                    className={classes.margin}
                    value={this.props.longitude}
                    onChange={value => this.props.setInputSite({ prop: 'longitude', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Location className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* GROUP NUMBER */}
                  <Input
                    id="GroupNumber"
                    label="Group Number"
                    placeholder="Enter site group number"
                    type
                    className={classes.margin}
                    value={this.props.groupNumber}
                    onChange={value => this.props.setInputSite({ prop: 'groupNumber', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <GroupNumber className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* SITE NOTES */}
                  <TextareaAutosize
                    style={{ height: 150, width: '90%' }}
                    aria-label="maximum height"
                    placeholder="Site notes"
                    defaultValue={this.props.notes}
                    onChange={event => this.props.setInputSite({ prop: 'notes', value: event.target.value })}
                  />

                  {/* BUTTON */}
                  <div style={sitesStyles.containerButton}>
                    <span>{string_general.required_fields}</span>
                    <Button
                      label={string_general.button_update}
                      onPress={() =>
                        this.props.updateSite({
                          token: this.props.user.token,
                          customer: this.props.match.params.customer,
                          id: this.props.match.params.id,
                          siteName: this.props.siteName,
                          address: this.props.address,
                          city: this.props.city,
                          fk_idState: this.props.stateSite,
                          zipCode: this.props.zipCode,
                          latitude: this.props.latitude,
                          longitude: this.props.longitude,
                          groupNumber: this.props.groupNumber,
                          notes: this.props.notes,
                          fk_idStatus: this.props.status,
                          history: this.props.history
                        })}
                      width={'100%'}
                      fontSize={15} />
                  </div>

                </div>

              </div>
            </ThemeProvider>
          </div>
          : <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <NotFound
              titleInfo='Site not found'
              titleButton="Go to list sites"
              goTo={() => this.props.history.push(`/customer/${this.props.match.params.customer}/sites`)}
            />
          </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel, isEdited } = state.user
  const {
    updatingSite, gettingSite, siteFound,

    siteName,
    address,
    city,
    notes,
    zipCode,
    latitude,
    longitude,
    groupNumber,
    stateSite,
    status,

    labelStates,
    arrayStates

  } = state.site

  return {
    isAuthenticated, user, infoLabel, isEdited,

    updatingSite, gettingSite, siteFound,

    siteName,
    address,
    city,
    notes,
    zipCode,
    latitude,
    longitude,
    groupNumber,
    stateSite,
    status,

    labelStates,
    arrayStates

  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getDataSite,
  getSite,
  setInputSite,
  updateSite,
  setInputUser,
  recalculateGpsData
})(EditSite)))

