import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import HttpsRedirect from 'react-https-redirect';

import configureStore from "./app/store";
import App from './app'
//import './modules/resources/css/nucleo-icons.css'

const { store, persistor } = configureStore();

render(


  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
