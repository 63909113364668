import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_get_customers_from_event = (params) => {
    const { token, event } = params
    return axios.get(`${string_general.base_URL}auth/customers/list/events/${event}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            // console.log("from tracking api", res);
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_pending_sites = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/dispatch/sites/pending/customer`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_dispatches = (params) => {
    const { token, event } = params
    return axios.get(`${string_general.base_URL}auth/dispatch?idEvent=${event}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

