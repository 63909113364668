import {
    LOAD_SUBCONTRACTOR_HELPERS,
    SET_INPUT_SUBCONTRACTOR_HELPER,
    CLEAR_STATE_SUBCONTRACTOR_HELPER,
    GETTING_SUBCONTRACTOR_HELPER,
    GOT_SUBCONTRACTOR_HELPER,
    LOADING_DATA_CREATE_SUBCONTRACTOR_HELPER,
    LOAD_DATA_CREATE_SUBCONTRACTOR_HELPER,
    ERROR_GETTING_SUBCONTRACTOR_HELPER,
    UPDATE_LIST_TRUCK_EDIT_HELPER
} from './types'

const initialState = {
    creatingSubcontractorHelper: false,
    updatingSubcontractorHelpers: false,
    gettingSubcontractorHelper: false,
    helperFound: true,
    labelEvents: '',
    labelTrucks: '',
    events: [],
    trucks: [],
    trucksFilter: [],

    columns: [],
    listItems: [],
    listItemsFilter: [],
    columnsHistorical: [],
    listItemsHistorical: [],
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    phone: '',
    password: '',
    confirmPassword: '',

    labelCompanies: '',
    companies: [],

    eventSelect: 0,
    truckSelect: 0,
    companySelected: 0,

    lastnames: [],
    listStatus: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_SUBCONTRACTOR_HELPER:
            return {
                ...initialState
            }
        case LOAD_SUBCONTRACTOR_HELPERS:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter,
                events: payload.events,
                lastnames: payload.lastnames,
                listStatus: payload.listStatus
            }
        case SET_INPUT_SUBCONTRACTOR_HELPER:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GETTING_SUBCONTRACTOR_HELPER:
            return {
                ...state,
                updatingSubcontractorHelpers: true,
                gettingSubcontractorHelper: true,
            }
        case UPDATE_LIST_TRUCK_EDIT_HELPER:
            return {
                ...state,
                trucks: [...state.trucks.filter(e => e.value !== payload.value), payload]
            }
        case GOT_SUBCONTRACTOR_HELPER:

            let trucksFilter = []
            state.trucks.forEach(e => {
                if ((e.event === payload.event) || e.value === 0) {
                    trucksFilter.push(e)
                }
            })

            return {
                ...state,
                helperFound: true,
                updatingSubcontractorHelpers: false,
                gettingSubcontractorHelper: false,
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                status: payload.status,
                phone: payload.phone,
                password: '',
                confirmPassword: '',
                columnsHistorical: payload.columns,
                listItemsHistorical: payload.listItems,
                trucksFilter
            }
        case ERROR_GETTING_SUBCONTRACTOR_HELPER:
            return {
                ...state,
                helperFound: false,
                updatingSubcontractorHelpers: false,
                gettingSubcontractorHelper: false,
                firstName: '',
                lastName: '',
                email: '',
                status: '',
                phone: '',
                password: '',
                confirmPassword: ''
            }
        case LOADING_DATA_CREATE_SUBCONTRACTOR_HELPER:
            return {
                ...state,
                labelEvents: payload.labelEvents,
                labelTrucks: payload.labelTrucks,
            }
        case LOAD_DATA_CREATE_SUBCONTRACTOR_HELPER:
            return {
                ...state,
                events: payload.events,
                trucks: payload.trucks,
                trucksFilter: payload.trucksFilter,
                labelEvents: payload.labelEvents,
                labelTrucks: payload.labelTrucks,
                labelCompanies: payload.labelCompanies,
                companies: payload.companies,
                listStatus: payload.listStatus
            }
        default:
            return state
    }
}
