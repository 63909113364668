import React from "react";
import _ from 'lodash'

import {
    LOAD_SUBCONTRACTOR_COMPANIES,
    SET_INPUT_SUBCONTRACTOR_COMPANY,
    CLEAR_STATE_SUBCONTRACTOR_COMPANY,
    GOT_SUBCONTRACTOR_COMPANY,
    LOAD_DATA_COMPANY,
    GETTING_COMPANY,
    SET_COMPANY_EVENTS_SELECT,
    ERROR_GETTING_SUBCONTRACTOR_COMPANY
} from './types'

import {
    sw_get_subcontractor_companies,
    sw_get_events,
    sw_get_states,
    sw_get_status,
    sw_create_subcontractor_company,
    sw_get_subcontractor_company,
    sw_update_subcontractor_company
} from '../../services/SubcontratorCompanyApi'

import { SET_ITEM_USER } from '../User/types'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { validateEmail, showAlert, isEmpty } from '../../resources/Util'

import firebase from '../../services/Firebase'

export const clearState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE_SUBCONTRACTOR_COMPANY
        })
    }
}

export const createSubcontractorCompany = (info) => {

    let { token, companyName, phone,
        email, address, zipcode, city,
        state, events, history, notes} = info
    console.log("info: ", info)
    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(companyName) || isEmpty(cleanPhone) || cleanPhone.length !== 10 || isEmpty(email) || isEmpty(address) ||
            isEmpty(zipcode) || isEmpty(city) || isEmpty(state) || state === -1) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (zipcode === "00000" || zipcode === "00000-" || (zipcode.length < 5 || (zipcode.length > 5 && zipcode.length < 10))) {
            showAlert({ msj: string_general.errorMsj.formatZipCode, type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_COMPANY,
            payload: { prop: 'creatingSubcontractorCompany', value: true }
        })

        let eventsSelect = []
        if (events !== null) {
            events.forEach(e => e.isSelect ? eventsSelect.push(e.id) : null)
        }
        zipcode = zipcode.replace(/-/g, "")
        sw_create_subcontractor_company({
            token,
            companyName,
            phone: cleanPhone,
            email,
            address,
            zipcode,
            notes,
            city,
            fk_idState: state,
            events: eventsSelect,
            fk_idStatusCompany: 1
        }).then(data => {
            console.log("data: ", data)
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_COMPANY,
                    payload: { prop: 'creatingSubcontractorCompany', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_SUBCONTRACTOR_COMPANY,
                        payload: { prop: 'creatingSubcontractorCompany', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Subcontractor company created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_COMPANY
                })
                history.push("/subcontractor_companies")
            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_COMPANY,
                    payload: { prop: 'creatingSubcontractorCompany', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const updateSubcontractorCompany = (info) => {

    let { token, id, companyName, phone, status,
        email, address, zipcode, city,
        state, events, history, notes } = info

    console.log("info: ", info)

    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(companyName) || isEmpty(cleanPhone) || cleanPhone.length !== 10 || isEmpty(email) || isEmpty(address) ||
            isEmpty(zipcode) || isEmpty(city) || isEmpty(state) || state === -1) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (zipcode === "00000" || zipcode === "00000-" || (zipcode.length < 5 || (zipcode.length > 5 && zipcode.length < 10))) {
            showAlert({ msj: string_general.errorMsj.formatZipCode, type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_COMPANY,
            payload: { prop: 'updatingSubcontractorCompany', value: true }
        })

        let eventsSelect = []
        events.forEach(e => e.isSelect ? eventsSelect.push(e.id) : null)
        zipcode = zipcode.replace(/-/g, "")
        console.log("zipcode: ", zipcode)
        sw_update_subcontractor_company({
            token,
            id,
            companyName,
            phone: cleanPhone,
            email,
            address,
            zipcode,
            notes,
            city,
            fk_idState: state,
            events: eventsSelect,
            fk_idStatusCompany: status
        }).then(data => {

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_COMPANY,
                payload: { prop: 'updatingSubcontractorCompany', value: false }
            })

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }

                if (status === 2) {
                    firebase.database().ref(`tracking`).remove()
                }

                showAlert({ msj: 'Subcontractor company updated successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_COMPANY
                })
                history.push("/subcontractor_companies")
            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_COMPANY,
                    payload: { prop: 'updatingSubcontractorCompany', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const getSubcontractorCompany = (info) => {
    const { token, id } = info
    return (dispatch) => {

        sw_get_subcontractor_company({ token, id }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_COMPANY
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_SUBCONTRACTOR_COMPANY
                    })
                    return false
                }

                const company = data.data
                console.log("company: ", company)
                if (company === null) {
                    showAlert({ msj: 'Company not found', type: 2 })
                    dispatch({
                        type: ERROR_GETTING_SUBCONTRACTOR_COMPANY
                    })
                    return false
                }

                if (company.events !== null) {
                    company.events.forEach(e => {
                        if (e.event !== null) {
                            dispatch({
                                type: SET_COMPANY_EVENTS_SELECT,
                                payload: {
                                    id: e.event.id,
                                    description: e.event.eventName,
                                    isSelect: true
                                }
                            })
                        }
                    }
                    )
                }
                const tempCode = `${company.zipcode}`
                const zipcode = tempCode.length === 5 ? tempCode : `${tempCode.substring(0, 5)}-${tempCode.substring(5, 9)}`
                dispatch({
                    type: GOT_SUBCONTRACTOR_COMPANY,
                    payload: {
                        companyName: company.companyName,
                        phone: company.phone.phone,
                        email: company.email,
                        status: company.status.id,
                        address: company.address,
                        city: company.city,
                        notes: company.notes,
                        zipcode,
                        stateCompany: company.state.id
                    }
                })
            } else {
                showAlert({ msj: 'Company not found', type: 2 })
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_COMPANY
                })
            }
        })
    }
}

export const getSubcontractorCompanies = (info) => {
    const { token, success, showError, history } = info
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_COMPANY,
            payload: { prop: "items", value: [] }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_COMPANY,
            payload: { prop: "listItemsFilter", value: [] }
        })

        sw_get_subcontractor_companies({ token }).then(data => {

            const columns = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 100,
                    sortable: false,
                    getProps: (state, rowInfo, column) => {

                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Company Name',
                    accessor: 'companyName',
                    headerStyle: tableStyles.header,
                    Cell: props => <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => props.value.onClick()}>{props.value.label}</a>, // Custom cell components!
                    sortMethod: (a, b) => { return a.label.localeCompare(b.label) },
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Phone Number',
                    accessor: 'phoneNumber',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'City',
                    accessor: 'city',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'State',
                    accessor: 'state',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            let items = []
            console.log("DATA : ", data)
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            } else {
                if (data.status === 200) {
                    const tempCompanies = data.data

                    if (!data.data.success && data.data.success !== undefined) {
                        showAlert({ msj: data.data.message, type: 3 })
                        showError()
                    } else {
                        console.log("tempCompanies: ", _.orderBy(tempCompanies, 'id', 'asc'))
                        tempCompanies.forEach(company => {
                            items.push(
                                {
                                    manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/edit_subcontractor_company/${company.id}`)}>Edit</a>,
                                    name: company.companyName.toLowerCase(), //this is for order bay name
                                    companyName: {
                                        onClick: () => {
                                            const itemsSubcontractor = [
                                                {
                                                    id: 1,
                                                    label: 'Subcontractor admins',
                                                    icon: 'tim-icons icon-book-bookmark',
                                                    link: `/subcontractor/${company.id}/admins`
                                                },
                                                {
                                                    id: 2,
                                                    label: 'Drivers',
                                                    icon: 'tim-icons icon-badge',
                                                    link: `/subcontractor/${company.id}/drivers`
                                                },
                                                {
                                                    id: 3,
                                                    label: 'Helpers',
                                                    icon: 'tim-icons icon-single-02',
                                                    link: `/subcontractor/${company.id}/helpers`
                                                },
                                                {
                                                    id: 4,
                                                    label: 'Trucks',
                                                    icon: 'tim-icons icon-delivery-fast',
                                                    link: `/subcontractor/${company.id}/trucks`
                                                },
                                            ]
                                            dispatch({
                                                type: SET_ITEM_USER,
                                                payload: { prop: 'itemsSubmenu', value: itemsSubcontractor }
                                            })
                                            dispatch({
                                                type: SET_ITEM_USER,
                                                payload: { prop: 'infoLabel', value: company.companyName }
                                            })
                                            history.push(`/subcontractor/${company.id}/admins`)
                                        }, label: company.companyName
                                    },
                                    phoneNumber: company.phone.phone,
                                    email: company.email,
                                    city: company.city,
                                    state: company.state.description,
                                    status: company.status.description,
                                    updated_at: company.updated_at
                                }
                            )
                        });
                        success()
                    }
                } else {
                    showError()
                }
            }
            items = _.orderBy(items, 'name', 'asc')
            dispatch({
                type: LOAD_SUBCONTRACTOR_COMPANIES,
                payload: { columns, items, listItemsFilter: items.filter(e => e.status === 'Active') }
            })
        })
    }
}

export const setInputSubcontractorCompany = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_COMPANY,
            payload: { prop, value }
        })
    }
}

export const filterByStatsSubcontractorCompany = (info) => {
    const { listItems, status } = info
    return (dispatch) => {
        let filter = (status !== 'All') ? listItems.filter(item => item.status === status) : listItems
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_COMPANY,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}

export const selectDriversToCreate = (info) => {
    const { array, item } = info
    return (dispatch) => {
        let newArray = [...array.filter(e => e.id !== item.id), { ...item, isSelect: !item.isSelect }]
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_COMPANY,
            payload: { prop: 'arrayEventsSelect', value: _.orderBy(newArray, 'id', 'asc') }
        })
    }
}

export const getDataCompany = (info) => {
    const { token, getCompany } = info
    return (dispatch) => {

        if (getCompany !== undefined) dispatch({
            type: GETTING_COMPANY
        })

        const requests = [
            sw_get_events({ token }),
            sw_get_states({ token }),
            sw_get_status({ token })]

        Promise.all(requests).then(function (values) {

            const tempEvents = values[0].data
            let events = []
            tempEvents.forEach(e => events.push({
                id: e.id,
                description: e.eventName,
                isSelect: false
            }))

            const tempStates = values[1].data
            let states = []
            tempStates.forEach(e => states.push({ value: e.id, label: e.description }))

            const statusTemp = values[2].data
            let status = []
            statusTemp.forEach(e => status.push({ value: e.id, description: e.description }))

            dispatch({
                type: LOAD_DATA_COMPANY,
                payload: {
                    arrayEventsSelect: events.length === 0 ? null : _.orderBy(events, 'description', 'asc'),
                    labelStates: 'Select state',
                    arrayStates: _.orderBy(states, 'id', 'asc'),
                    arrayStatus: _.orderBy(status, 'id', 'asc')
                }
            })

            if (getCompany !== undefined) getCompany()
        })

    }
}