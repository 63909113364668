import {
    CLEAR_STATE_LOGIN,
    SET_INPUT,
    LOADING_LOGIN,
    FAIL_LOGIN,
    SUCCESS_LOGIN,
} from './types'

import { SET_AUTHENTICATION, LOGOUT, SET_ITEM_USER, CHANGE_PASSWORD_SUCCESS } from '../User/types'

import { validateEmail, showAlert, isEmpty, validatePassword } from '../../resources/Util'
import { sw_login, sw_recovery_password, sw_logout, sw_change_password } from '../../services/AuthApi'
import { string_general } from '../../resources/strings'

export const clearStateLogin = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_STATE_LOGIN })
    }
}

export const setInput = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT,
            payload: { prop, value }
        })
    }
}

export const login = (info) => {
    const { email, password, history } = info
    return (dispatch) => {

        if (isEmpty(email) || isEmpty(password)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        }

        dispatch({
            type: LOADING_LOGIN
        })

        sw_login({
            email,
            password,
            from: 0
        }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: FAIL_LOGIN
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: FAIL_LOGIN
                    })
                    return false
                }

                const user = data.data.user
                console.log("USER: ", user)

                dispatch({
                    type: SET_ITEM_USER,
                    payload: { prop: 'changePassword', value: user.changePassword }
                })

                let isUserWeb = true
                let itemsMenu = []
                let itemsSubmenu = []

                switch (user.type.id) {
                    case 1:
                        itemsMenu = [
                            {
                                id: 1,
                                label: 'Users',
                                link: '/super_admins',
                                items: [
                                    {
                                        id: 1,
                                        label: 'Super SFS Admins',
                                        icon: 'tim-icons icon-book-bookmark',
                                        link: '/super_admins'
                                    },
                                    {
                                        id: 2,
                                        label: 'SFS Admins',
                                        icon: 'tim-icons icon-book-bookmark',
                                        link: '/admins'
                                    }
                                ]
                            },
                            { id: 2, label: 'Subcontractors', link: '/subcontractor_companies', items: [] },
                            { id: 3, label: 'Customers', link: '/customers', items: [] },
                            { id: 4, label: 'Events', link: '/events', items: [] },
                            { id: 5, label: 'Products', link: '/products', items: [] }
                        ]
                        break
                    case 2:
                        itemsMenu = [
                            {
                                id: 1,
                                label: 'Users',
                                link: '/admins',
                                items: [
                                    {
                                        id: 1,
                                        label: 'SFS Admins',
                                        icon: 'tim-icons icon-book-bookmark',
                                        link: '/admins'
                                    }
                                ]
                            },
                            { id: 2, label: 'Subcontractors', link: '/subcontractor_companies', items: [] },
                            { id: 3, label: 'Customers', link: '/customers', items: [] },
                            { id: 4, label: 'Events', link: '/events', items: [] },
                            { id: 5, label: 'Products', link: '/products', items: [] }
                        ]
                        break
                    case 3:
                        const company = user.subcontractor.company.id
                        dispatch({
                            type: SET_ITEM_USER,
                            payload: { prop: 'infoLabel', value: user.subcontractor.company.companyName }
                        })

                        itemsMenu = [
                            {
                                id: 1,
                                label: 'Events',
                                link: '/events',
                                items: []
                            },
                            { id: 2, label: 'Drivers', link: `/subcontractor/drivers`, items: [] },
                            { id: 3, label: 'Helpers', link: `/subcontractor/helpers`, items: [] },
                            { id: 4, label: 'Trucks', link: `/subcontractor/trucks`, items: [] },
                        ]
                        break
                    default:
                        isUserWeb = false
                        break

                }

                console.log("USER: ", data.data.user.type.id)
                if (isUserWeb) {
                    dispatch({
                        type: SUCCESS_LOGIN,
                        payload: true
                    })
                    dispatch({
                        type: SET_AUTHENTICATION,
                        payload: { user: data.data, itemsMenu, itemsSubmenu }
                    })
                    showAlert({ msj: `Welcome ${data.data.user.firstName}`, type: 1 })
                    history.push("/")
                } else {
                    dispatch({
                        type: FAIL_LOGIN
                    })
                    showAlert({ msj: string_general.errorMsj.credencials, type: 3 })
                }
            } else {
                dispatch({
                    type: FAIL_LOGIN
                })
                showAlert({ msj: string_general.errorMsj.credencials, type: 3 })
            }
        })
    }
}

export const logout = (info) => {
    const { token, history } = info
    return (dispatch) => {
        sw_logout({
            token
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }

                dispatch({
                    type: LOGOUT
                })
                history.push("/login")
            } else {
                showAlert({ msj: 'Fail logout', type: 3 })
            }
        })
    }
}

export const change_password = (info) => {
    const { token, password, confirmPassword, history } = info
    return (dispatch) => {

        if (isEmpty(password) || isEmpty(confirmPassword)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (password !== confirmPassword) {
            showAlert({ msj: string_general.errorMsj.differentPasswords, type: 2 })
            return false
        } else if (!validatePassword(password)) {
            showAlert({
                msj: `${string_general.errorMsj.formatEmail} ${string_general.formatPassword}`,
                type: 2,
                duration: 5000
            })
            return false
        }

        dispatch({
            type: SET_ITEM_USER,
            payload: { prop: 'changing_password', value: true }
        })
        sw_change_password({
            token,
            password
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_ITEM_USER,
                    payload: { prop: 'changing_password', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_ITEM_USER,
                        payload: { prop: 'changing_password', value: false }
                    })
                    return false
                }

                dispatch({
                    type: CHANGE_PASSWORD_SUCCESS
                })

                history.push("/")
            } else {
                dispatch({
                    type: SET_ITEM_USER,
                    payload: { prop: 'changing_password', value: false }
                })
                showAlert({ msj: 'Fail change password', type: 3 })
            }
        })
    }
}

export const recoverPassword = (info) => {
    const { email, loading, callBack } = info
    return (dispatch) => {

        if (isEmpty(email)) {
            showAlert({ msj: string_general.errorMsj.credencials, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        }

        loading()

        sw_recovery_password({
            email
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                callBack();
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    callBack();
                    return false
                }
                callBack();
                showAlert({ msj: "Password sent to email", type: 1 })

                dispatch({
                    type: SET_ITEM_USER,
                    payload: { prop: 'email', value: '' }
                })

            } else {
                callBack();
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
            }
        })
    }
}

export const validateCredencials = (username, password) => {
    return (dispatch) => {

        username === '' ? dispatch() : dispatch()

        password === '' ? dispatch() : dispatch()

    }
}
