import React from "react";
import _ from 'lodash'

import {
    CLEAR_STATE_EVENT,
    LOAD_EVENTS,
    SET_INPUT_EVENT,
    LOAD_DATA_EVENT,
    ADD_NEW_CITY_TO_EVENT,
    GETTING_EVENT,
    ERROR_GETTING_EVENT,
    GOT_EVENT,

    SET_STATES_EVENT,
    SET_CITIES_EVENT,
    SET_CUSTOMERS_EVENT,
    SET_SUBCONTRACTORS_EVENT
} from './types'

import {
    sw_get_events,
    sw_get_states,
    sw_get_status,
    sw_get_cities,
    sw_get_types,
    sw_get_customers,
    sw_get_subcontactors,
    sw_create_event,
    sw_get_event,
    sw_update_event
} from '../../services/EventApi'

import { SET_ITEM_USER } from '../User/types'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { showAlert, isEmpty } from '../../resources/Util'
import firebase from '../../services/Firebase'

export const clearState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE_EVENT
        })
    }
}

export const getEvents = (info) => {
    const { token, success, showSubList, showError, company, userType, history } = info
    return (dispatch) => {
        sw_get_events({ token, company, userType }).then(data => {

            let columns = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 100,
                    sortable: false,
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Event Name',
                    accessor: 'eventName',
                    headerStyle: tableStyles.header,
                    Cell: props => <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => props.value.onClick()}>{props.value.label}</a>, // Custom cell components!
                    sortMethod: (a, b) => { return a.label.localeCompare(b.label) },
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Event Type',
                    accessor: 'eventType',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'City',
                    accessor: 'city',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'State',
                    accessor: 'state',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            let items = []
            
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            } else {
                if (data.status === 200) {
                    const tempEvents = data.data

                    if (!data.data.success && data.data.success !== undefined) {
                        showAlert({ msj: data.data.message, type: 3 })
                        showError()
                    } else {
                        tempEvents.forEach(event => {
                            const idEvent = event.id
                            items.push(
                                {
                                    manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/edit_event/${idEvent}`)}>Edit</a>,
                                    eventName: {
                                        onClick: () => {
                                            const itemsEvents = [{
                                                id: 1,
                                                label: 'Trucks',
                                                icon: 'tim-icons icon-delivery-fast',
                                                link: `/event/${idEvent}/trucks`
                                            },
                                            {
                                                id: 2,
                                                label: 'Drivers',
                                                icon: 'tim-icons icon-badge',
                                                link: `/event/${idEvent}/drivers`
                                            },
                                            {
                                                id: 3,
                                                label: 'Helpers',
                                                icon: 'tim-icons icon-single-02',
                                                link: `/event/${idEvent}/helpers`
                                            },
                                            {
                                                id: 4,
                                                label: 'Dispatch',
                                                icon: 'tim-icons icon-vector',
                                                link: `/event/${idEvent}/dispatches`
                                            },
                                            {
                                                id: 5,
                                                label: 'Deliveries',
                                                icon: 'tim-icons icon-tag',
                                                link: `/event/${idEvent}/deliveries`

                                            },
                                            {
                                                id: 6,
                                                label: 'Report',
                                                icon: 'tim-icons icon-notes',
                                                link: `/event/${idEvent}/reports`
                                            },
                                            {
                                                id: 7,
                                                label: 'Tracking',
                                                icon: 'tim-icons icon-world',
                                                link: `/event/${idEvent}/tracking`
                                            }]

                                            dispatch({
                                                type: SET_ITEM_USER,
                                                payload: { prop: 'itemsSubmenu', value: itemsEvents }
                                            })
                                            dispatch({
                                                type: SET_ITEM_USER,
                                                payload: { prop: 'infoLabel', value: event.eventName }
                                            })
                                            dispatch({
                                                type: SET_ITEM_USER,
                                                payload: { prop: 'idCustomerSelected', value: 0 }
                                            })

                                            history.push(`/event/${idEvent}/trucks`)
                                        },
                                        label: event.eventName
                                    },
                                    eventType: event.type.description,
                                    city: <a onClick={() => {
                                        let cities = []
                                        event.cities.forEach(e => cities.push(e.city.description))
                                        showSubList(`${event.eventName} Cities`, cities)
                                    }}>View cities</a>,
                                    state: <a onClick={() => {
                                        let states = []
                                        event.states.forEach(e => states.push(e.state.description))
                                        showSubList(`${event.eventName} States`, states)
                                    }}>View states</a>,
                                    status: event.status.status,
                                    updated_at: event.updated_at
                                }
                            )
                        });
                        success()
                    }
                } else {
                    showError()
                }
            }

            if (userType === 3) {
                columns = columns.filter(e => e.accessor !== 'manage')
            }

            dispatch({
                type: LOAD_EVENTS,
                payload: { columns, items, listItemsFilter: items.filter(e => e.status === 'Active') }
            })
        })
    }
}

export const createEvent = (info) => {

    const { token,
        eventName,
        type,
        cities,
        states,
        customers,
        subcontractors,
        customerSelect,
        history } = info

    return (dispatch) => {

        let statesSelect = []
        states.forEach(e => e.isSelect ? statesSelect.push(e.id) : null)

        if (isEmpty(eventName) || isEmpty(type) || statesSelect.length === 0) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        }

        dispatch({
            type: SET_INPUT_EVENT,
            payload: { prop: 'creatingEvent', value: true }
        })

        let sendCities = []
        cities.forEach(e => e.id === null ? sendCities.push(e.description) : sendCities.push(e.id))

        let customersSelect = []
        customers.forEach(e => e.isSelect ? customersSelect.push(e.id) : null)

        let subcontractorsSelect = []
        subcontractors.forEach(e => e.isSelect ? subcontractorsSelect.push(e.id) : null)

        sw_create_event({
            token,
            eventName,
            type,
            cities: sendCities,
            states: statesSelect,
            customers: customersSelect,
            subcontractors: subcontractorsSelect
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_EVENT,
                    payload: { prop: 'creatingEvent', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_EVENT,
                        payload: { prop: 'creatingEvent', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Event created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_EVENT
                })

                const idEvent = data.data.id
                const itemsEvents = [{
                    id: 1,
                    label: 'Trucks',
                    icon: 'tim-icons icon-delivery-fast',
                    link: `/event/${idEvent}/trucks`
                },
                {
                    id: 2,
                    label: 'Drivers',
                    icon: 'tim-icons icon-badge',
                    link: `/event/${idEvent}/drivers`
                },
                {
                    id: 3,
                    label: 'Helpers',
                    icon: 'tim-icons icon-single-02',
                    link: `/event/${idEvent}/helpers`
                },
                {
                    id: 4,
                    label: 'Dispatch',
                    icon: 'tim-icons icon-vector',
                    link: `/event/${idEvent}/dispatches`
                },
                {
                    id: 5,
                    label: 'Deliveries',
                    icon: 'tim-icons icon-tag',
                    link: `/event/${idEvent}/deliveries`

                },
                {
                    id: 6,
                    label: 'Report',
                    icon: 'tim-icons icon-notes',
                    link: `/event/${idEvent}/reports`
                },
                {
                    id: 7,
                    label: 'Tracking',
                    icon: 'tim-icons icon-world',
                    link: `/event/${idEvent}/tracking`
                }]

                let titleSubmenu = data.data.eventName

                if (customerSelect !== undefined) {

                    titleSubmenu = `${titleSubmenu} | ${customerSelect.description}`

                    dispatch({
                        type: SET_ITEM_USER,
                        payload: { prop: 'idCustomerSelected', value: customerSelect.id }
                    })
                }

                dispatch({
                    type: SET_ITEM_USER,
                    payload: { prop: 'infoLabel', value: titleSubmenu }
                })

                dispatch({
                    type: SET_ITEM_USER,
                    payload: { prop: 'itemsSubmenu', value: itemsEvents }
                })

                history.push(`/event/${idEvent}/trucks`)
            } else {
                dispatch({
                    type: SET_INPUT_EVENT,
                    payload: { prop: 'creatingEvent', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })
    }
}

export const updateEvent = (info) => {

    const { token,
        id,
        eventName,
        type,
        cities,
        status,
        states,
        customers,
        subcontractors,
        customerSelect,
        history } = info

    return (dispatch) => {

        let statesSelect = []
        states.forEach(e => e.isSelect ? statesSelect.push(e.id) : null)

        if (isEmpty(eventName) || isEmpty(type) || statesSelect.length === 0 || isEmpty(status)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        }

        dispatch({
            type: SET_INPUT_EVENT,
            payload: { prop: 'updatingEvent', value: true }
        })

        let sendCities = []
        cities.forEach(e => e.id === null ? sendCities.push(e.description) : sendCities.push(e.id))

        let customersSelect = []
        customers.forEach(e => e.isSelect ? customersSelect.push(e.id) : null)

        let subcontractorsSelect = []
        subcontractors.forEach(e => e.isSelect ? subcontractorsSelect.push(e.id) : null)

        sw_update_event({
            token,
            id,
            eventName,
            type,
            status,
            cities: sendCities,
            states: statesSelect,
            customers: customersSelect,
            subcontractors: subcontractorsSelect
        }).then(data => {
            console.log("DATA: ", data)
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_EVENT,
                    payload: { prop: 'updatingEvent', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_EVENT,
                        payload: { prop: 'updatingEvent', value: false }
                    })
                    return false
                }

                if (status === 2) {
                    firebase.database().ref(`tracking/${id} - Event`).remove()
                }

                showAlert({ msj: 'Event updated successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_EVENT
                })

                const idEvent = data.data.id
                const itemsEvents = [{
                    id: 1,
                    label: 'Trucks',
                    icon: 'tim-icons icon-delivery-fast',
                    link: `/event/${idEvent}/trucks`
                },
                {
                    id: 2,
                    label: 'Drivers',
                    icon: 'tim-icons icon-badge',
                    link: `/event/${idEvent}/drivers`
                },
                {
                    id: 3,
                    label: 'Helpers',
                    icon: 'tim-icons icon-single-02',
                    link: `/event/${idEvent}/helpers`
                },
                {
                    id: 4,
                    label: 'Dispatch',
                    icon: 'tim-icons icon-vector',
                    link: `/event/${idEvent}/dispatches`
                },
                {
                    id: 5,
                    label: 'Deliveries',
                    icon: 'tim-icons icon-tag',
                    link: `/event/${idEvent}/deliveries`
                },
                {
                    id: 6,
                    label: 'Report',
                    icon: 'tim-icons icon-notes',
                    link: `/event/${idEvent}/reports`
                },
                {
                    id: 7,
                    label: 'Tracking',
                    icon: 'tim-icons icon-world',
                    link: `/event/${idEvent}/tracking`
                }]

                let titleSubmenu = data.data.eventName

                if (customerSelect !== undefined) {

                    titleSubmenu = `${titleSubmenu} | ${customerSelect.description}`

                    dispatch({
                        type: SET_ITEM_USER,
                        payload: { prop: 'idCustomerSelected', value: customerSelect.id }
                    })
                }

                dispatch({
                    type: SET_ITEM_USER,
                    payload: { prop: 'infoLabel', value: titleSubmenu }
                })

                dispatch({
                    type: SET_ITEM_USER,
                    payload: { prop: 'itemsSubmenu', value: itemsEvents }
                })

                history.push(`/event/${idEvent}/trucks`)
            } else {
                dispatch({
                    type: SET_INPUT_EVENT,
                    payload: { prop: 'updatingEvent', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })
    }
}

export const getEvent = (info) => {
    const { token, id } = info
    return (dispatch) => {

        sw_get_event({ token, id }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_EVENT
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_EVENT
                    })
                    return false
                }

                const event = data.data
                if (event === null) {
                    showAlert({ msj: 'Event not found', type: 2 })
                    dispatch({
                        type: ERROR_GETTING_EVENT
                    })
                    return false
                }

                if (event.states !== null) {
                    event.states.forEach(e => {
                        dispatch({
                            type: SET_STATES_EVENT,
                            payload: {
                                id: e.state.id,
                                description: e.state.description,
                                isSelect: true
                            }
                        })
                    })
                }

                if (event.cities !== null) {
                    event.cities.forEach(e => {
                        dispatch({
                            type: SET_CITIES_EVENT,
                            payload: {
                                id: e.city.id,
                                description: e.city.description
                            }
                        })
                    })
                }

                if (event.customers !== null) {
                    event.customers.forEach(e => {
                        dispatch({
                            type: SET_CUSTOMERS_EVENT,
                            payload: {
                                id: e.customer.id,
                                description: e.customer.customerName,
                                isSelect: true
                            }
                        })
                    })
                }

                if (event.subcontractors !== null) {
                    event.subcontractors.forEach(e => {
                        dispatch({
                            type: SET_SUBCONTRACTORS_EVENT,
                            payload: {
                                id: e.subcontractor.id,
                                description: e.subcontractor.companyName,
                                isSelect: true
                            }
                        })
                    })
                }

                dispatch({
                    type: GOT_EVENT,
                    payload: {
                        eventName: event.eventName,
                        typeEvent: event.type.id,
                        status: event.status.id
                    }
                })
            } else {
                showAlert({ msj: 'Event not found', type: 2 })
                dispatch({
                    type: ERROR_GETTING_EVENT
                })
            }
        })
    }
}

export const setInputEvent = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_EVENT,
            payload: { prop, value }
        })
    }
}

export const filterByStatus = (info) => {
    const { listItems, status } = info
    return (dispatch) => {
        let filter = (status !== 'All') ? listItems.filter(item => item.status === status) : listItems
        dispatch({
            type: SET_INPUT_EVENT,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}

export const getDataEvent = (info) => {
    const { token, getEvent } = info
    return (dispatch) => {

        if (getEvent !== undefined) dispatch({
            type: GETTING_EVENT
        })

        const requests = [
            sw_get_status({ token }),
            sw_get_types({ token }),
            sw_get_states({ token }),
            sw_get_customers({ token }),
            sw_get_subcontactors({ token }),
            sw_get_cities({ token })
        ]

        Promise.all(requests).then(function (values) {

            const statusTemp = values[0].data
            let status = []
            if (statusTemp !== undefined) {
                statusTemp.forEach(e => status.push({ value: e.id, description: e.description }))
            }


            const tempTypes = values[1].data
            let types = [{ value: 0, description: 'Select a type' }]
            if (tempTypes !== undefined) {
                tempTypes.forEach(e => types.push({ value: e.id, description: e.description }))
            }

            const tempStates = values[2].data
            let states = []
            if (tempStates !== undefined) {
                tempStates.forEach(e => states.push({ id: e.id, description: e.description }))
            }

            const tempCustomers = values[3].data
            let customers = []
            if (tempCustomers !== undefined) {
                tempCustomers.forEach(e => {
                    if (e.fk_idStatus == 1) {
                        customers.push({ id: e.id, description: e.customerName })
                    }
                })
            }


            const tempSubcontractors = values[4].data
            let subcontractors = []
            if (tempSubcontractors !== undefined) {
                tempSubcontractors.forEach(e => subcontractors.push({ id: e.id, description: e.companyName }))
            }


            const tempCities = values[5].data
            let cities = []
            if (tempCities !== undefined) {
                tempCities.forEach(e => cities.push({ id: e.id, description: e.description }))
            }

            dispatch({
                type: LOAD_DATA_EVENT,
                payload: {
                    labelTypes: 'Select type',
                    types: _.orderBy(types, 'id', 'asc'),
                    states: states.length === 0 ? null : states,
                    customers: customers.length === 0 ? null : customers,
                    subcontractors: subcontractors.length === 0 ? null : subcontractors,
                    cities: cities.length === 0 ? null : cities,
                }
            })

            if (getEvent !== undefined) getEvent()
        })

    }
}

export const addCities = (info) => {
    const { city, cities } = info
    return (dispatch) => {

        let newCity = { id: null, description: city }
        const cityTemp = cities.filter(e => e.description === city)[0]

        if (cityTemp !== undefined) {
            newCity = cityTemp
        }

        dispatch({
            type: ADD_NEW_CITY_TO_EVENT,
            payload: newCity
        })
    }
}

export const deleteCities = (info) => {
    const { cities, item } = info
    return (dispatch) => {
        let filter = cities.filter(e => e !== item)
        dispatch({
            type: SET_INPUT_EVENT,
            payload: { prop: 'citiesSelect', value: filter }
        })
    }
}

export const selectStatesToEvent = (info) => {
    const { array, item } = info
    return (dispatch) => {
        let newArray = [...array.filter(e => e.id !== item.id), { ...item, isSelect: !item.isSelect }]
        dispatch({
            type: SET_INPUT_EVENT,
            payload: { prop: 'states', value: _.orderBy(newArray, 'id', 'asc') }
        })
    }
}

export const selectCustomersToEvent = (info) => {
    const { array, item } = info
    return (dispatch) => {
        let newArray = [...array.filter(e => e.id !== item.id), { ...item, isSelect: !item.isSelect }]
        dispatch({
            type: SET_INPUT_EVENT,
            payload: { prop: 'customers', value: _.orderBy(newArray, 'id', 'asc') }
        })
    }
}

export const selectSubcontractorToEvent = (info) => {
    const { array, item } = info
    return (dispatch) => {
        let newArray = [...array.filter(e => e.id !== item.id), { ...item, isSelect: !item.isSelect }]
        dispatch({
            type: SET_INPUT_EVENT,
            payload: { prop: 'subcontractors', value: _.orderBy(newArray, 'id', 'asc') }
        })
    }
}




