import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash'

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import Email from '@material-ui/icons/EmailOutlined';
import User from '@material-ui/icons/PermIdentityOutlined';
import Phone from '@material-ui/icons/Phone';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import RadioButton from '../../components/RadioButton'
import ChooseColor from '../../components/ChooseColor'

// ACTIONS
import {
  clearState,
  setInputSubcontractorDriver,
  getOptionalDataDriver,
  getTrucksByCompany,
  createSubcontractorDriver,
  filterByEvent
} from '../../../modules/core/SubcontractorDriver/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { subcontractorDriverStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_subcontractor } from '../../../modules/resources/strings'
import { isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class CreateSubcontractorDriver extends React.Component {

  state = {
    radios: [{ id: 1, label: 'Driver', disabled: false }, { id: 2, label: 'Driver 2', disabled: false }],
  }

  componentDidMount() {
    if (this.props.idCustomerSelected !== 0) {
      this.props.setInputSubcontractorDriver({ prop: 'customerSelected', value: this.props.idCustomerSelected })
    }

    const company = this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id
    if (!this.props.isEdited) {
      this.props.getOptionalDataDriver({
        token: this.props.user.token,
        company,
        event: this.props.match.params.event,
      })
    }

    this.confiSiteNavigations()
    this.configMenuItems()
  }

  confiSiteNavigations() {

    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor_companies`,
              label: 'Subcontractor'
            },
            {
              link: `/subcontractor_companies`,
              label: this.props.infoLabel
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/drivers`,
              label: 'Drivers'
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/create_driver`,
              label: 'Create Driver'
            }]
        })
      } else {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor/drivers`,
              label: 'Drivers'
            },
            {
              link: `/subcontractor/create_driver`,
              label: 'Create Driver'
            },
          ]
        })
      }
    } else {
      this.props.setInputUser({
        prop: 'siteNavigations',
        value: [
          {
            link: `/events`,
            label: 'Events'
          },
          {
            link: `/events`,
            label: this.props.infoLabel
          },
          {
            link: `/event/${this.props.match.params.event}/drivers`,
            label: 'Drivers'
          },
          {
            link: `/event/${this.props.match.params.event}/create_driver`,
            label: 'Create Driver'
          }]
      })
    }
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        const idCompany = this.props.match.params.company
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [
            {
              id: 1,
              label: 'Subcontractor admins',
              icon: 'tim-icons icon-book-bookmark',
              link: `/subcontractor/${idCompany}/admins`
            },
            {
              id: 2,
              label: 'Drivers',
              icon: 'tim-icons icon-badge',
              link: `/subcontractor/${idCompany}/drivers`
            },
            {
              id: 3,
              label: 'Helpers',
              icon: 'tim-icons icon-single-02',
              link: `/subcontractor/${idCompany}/helpers`
            },
            {
              id: 4,
              label: 'Trucks',
              icon: 'tim-icons icon-delivery-fast',
              link: `/subcontractor/${idCompany}/trucks`
            },
          ]
        })
        this.props.setInputUser({ prop: 'value', value: 2 })
        this.props.setInputUser({ prop: 'itemSelect', value: 2 })
      } else {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: []
        })
        this.props.setInputUser({ prop: 'value', value: 2 })
        this.props.setInputUser({ prop: 'itemSelect', value: 1 })
      }

    } else {
      const idEvent = this.props.match.params.event
      this.props.setInputSubcontractorDriver({ prop: 'eventSelect', value: idEvent })
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          },
          {
            id: 4,
            label: 'Dispatch',
            icon: 'tim-icons icon-vector',
            link: `/event/${idEvent}/dispatches`
          },
          {
            id: 5,
            label: 'Deliveries',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/deliveries`

          },
          {
            id: 6,
            label: 'Transfers',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/create_transfer`
    
          },
        
          {
            id: 7,
            label: 'Tracking',
            icon: 'tim-icons icon-world',
            link: `/event/${idEvent}/tracking`
          },
          {
            id: 8,
            label: 'Report',
            icon: 'tim-icons icon-notes',
            link: `/event/${idEvent}/reports`
          }
         ]
        })
        this.props.setInputUser({ prop: 'value', value: 4 })
      } else {
        this.props.setInputSubcontractorDriver({ prop: 'companySelected', value: this.props.user.user.subcontractor.company.id })
        this.props.getTrucksByCompany({
          token: this.props.user.token,
          company: this.props.user.user.subcontractor.company.id,
          event: this.props.match.params.event
        })
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          }]
        })
        this.props.setInputUser({ prop: 'value', value: 1 })
      }
      this.props.setInputUser({ prop: 'itemSelect', value: 2 })
    }
  }

  componentWillUnmount() {
    if (!isEmpty(this.props.firstName) || !isEmpty(this.props.lastName) ||
      !isEmpty(this.props.email) || !isEmpty(this.props.phone) ||
      (this.props.eventSelect !== 0 && this.props.match.params.event === undefined) || this.props.truckSelect !== 0 ||
      this.props.idCustomerSelected !== 0 ||
      (this.props.match.params.event !== undefined && this.props.companySelected !== 0 && this.props.userType !== 3)) {
      if (!window.confirm(string_general.leaveMsjCreate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        if (this.props.match.params.event === undefined) {
          if (this.props.userType !== 3) {
            this.props.history.push(`/subcontractor/${this.props.match.params.company}/create_driver`)
          } else {
            this.props.history.push(`/subcontractor/create_driver`)
          }
        } else {
          this.props.history.push(`/event/${this.props.match.params.event}/create_driver`)
        }
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      this.props.creatingSubcontractorDriver ?
        <Loading label={string_subcontractor.creating_driver} />
        :
        <div style={{ ...subcontractorDriverStyles.container, alignItems: 'center' }}>

          {/* TITLE */}
          <span style={subcontractorDriverStyles.title}>{string_subcontractor.title_create_driver}</span>
          <ThemeProvider theme={mainTheme}>

            <div className={classes.sectionResponsive}>

              <div style={subcontractorDriverStyles.containerDivResponsive}>

                {/* FIRSTNAME */}
                <Input
                  id="Firstname"
                  label="First Name"
                  placeholder="Enter driver first name"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.firstName}
                  onChange={value => this.props.setInputSubcontractorDriver({ prop: 'firstName', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* LASTNAME */}
                <Input
                  isRequired
                  id="Lastname"
                  label="Last Name"
                  placeholder="Enter driver last name"
                  type
                  className={classes.margin}
                  value={this.props.lastName}
                  onChange={value => this.props.setInputSubcontractorDriver({ prop: 'lastName', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* EMAIL */}
                <Input
                  isRequired
                  id="Email"
                  label="Email"
                  placeholder="Enter driver email"
                  type
                  className={classes.margin}
                  value={this.props.email}
                  onChange={value => this.props.setInputSubcontractorDriver({ prop: 'email', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* PHONE */}
                <Input
                  isRequired
                  id="Phone"
                  label="Number Phone"
                  placeholder="Enter driver phone"
                  phone
                  className={classes.margin}
                  value={this.props.phone}
                  onChange={value => this.props.setInputSubcontractorDriver({ prop: 'phone', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />
              </div>

              <div style={subcontractorDriverStyles.containerDivResponsive}>

                {/* COLOR */}
                {
                  this.props.userType !== 3 ?
                    <ChooseColor
                      icon='tim-icons icon-single-02'
                      color={this.props.color}
                      isRequired
                      isDriver
                      selectColor={(color) => this.props.setInputSubcontractorDriver({ prop: 'color', value: color })}
                    /> :
                    null}


                {/* SUBCONTRACTOR */}
                {
                  (this.props.match.params.event !== undefined && this.props.userType !== 3) ?
                    <div style={{ ...subcontractorDriverStyles.containerDropdown, zIndex: 2, }}>
                      <Dropdown
                        selectItem={this.props.companySelected}
                        placeholder={this.props.labelCompanies}
                        options={this.props.companies}
                        onChange={(value) => {
                          this.props.setInputSubcontractorDriver({ prop: 'companySelected', value })
                          this.props.getTrucksByCompany({
                            token: this.props.user.token,
                            company: value,
                            event: this.props.match.params.event
                          })
                        }
                        } />
                      <div style={subcontractorDriverStyles.required}> * </div>
                    </div> : null
                }

                {/* EVENT */}
                {
                  this.props.match.params.event === undefined ?
                    <div style={{ ...subcontractorDriverStyles.containerDropdown, zIndex: 2, }}>
                      <Dropdown
                        selectItem={this.props.eventSelect}
                        placeholder={this.props.labelEvents}
                        options={this.props.events}
                        onChange={(value) => {
                          this.props.setInputSubcontractorDriver({ prop: 'eventSelect', value })
                          this.props.setInputSubcontractorDriver({ prop: 'customerSelected', value: 0 })
                          this.props.setInputSubcontractorDriver({ prop: 'truckSelect', value: 0 })
                          this.props.filterByEvent({
                            event: value,
                            customers: this.props.customers,
                            trucks: this.props.trucks
                          })
                        }
                        }
                      />
                    </div> : null
                }

                {/* CUSTOMER */}
                {
                  this.props.idCustomerSelected === 0 && this.props.userType !== 3 ?
                    <div style={{ ...subcontractorDriverStyles.containerDropdown, zIndex: 3 }}>
                      <Dropdown
                        selectItem={this.props.customerSelected}
                        placeholder={this.props.labelCustomers}
                        options={this.props.customersFilter}
                        onChange={(value) => this.props.setInputSubcontractorDriver({ prop: 'customerSelected', value })}
                      />
                    </div> : null
                }

                {/* TRUCKS */}
                <div style={{ ...subcontractorDriverStyles.containerDropdown, zIndex: 1, }}>
                  <Dropdown
                    selectItem={this.props.truckSelect}
                    placeholder={this.props.labelTrucks}
                    options={this.props.trucksFilter}
                    onChange={(value) => {
                      const truck = this.props.trucks.filter(e => e.value === value)[0]
                      this.props.setInputSubcontractorDriver({ prop: 'truckSelect', value })
                      //code
                      // 1 el truck tiene driver
                      // 2 el truck tiene asisstant
                      // 3 el truck no tiene nada
                      switch (truck.code) {
                        case 1:
                          this.props.setInputSubcontractorDriver({ prop: 'radioDisabled', value: true })
                          this.props.setInputSubcontractorDriver({ prop: 'typeDriverSelect', value: 2 })
                          break
                        case 2:
                          this.props.setInputSubcontractorDriver({ prop: 'radioDisabled', value: true })
                          this.props.setInputSubcontractorDriver({ prop: 'typeDriverSelect', value: 1 })
                          break
                        default:
                          this.props.setInputSubcontractorDriver({ prop: 'radioDisabled', value: false })
                          this.props.setInputSubcontractorDriver({ prop: 'typeDriverSelect', value: 1 })
                      }
                    }}
                  />
                </div>

                <RadioButton
                  title='Select role'
                  radios={this.state.radios}
                  disabled={this.props.radioDisabled}
                  itemSelect={this.props.typeDriverSelect}
                  onChange={(value) => this.props.setInputSubcontractorDriver({ prop: 'typeDriverSelect', value })}
                />

                <div style={subcontractorDriverStyles.containerButton}>
                  <span>{string_general.required_fields}</span>
                  <Button
                    label={string_general.button_create}
                    onPress={() =>
                      this.props.createSubcontractorDriver({
                        token: this.props.user.token,
                        company: this.props.match.params.event === undefined ? (this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id) : this.props.companySelected,
                        firstName: this.props.firstName,
                        lastName: this.props.lastName,
                        email: this.props.email,
                        phone: this.props.phone,
                        color: this.props.color,
                        idCustomer: this.props.customerSelected,
                        idEvent: this.props.match.params.event === undefined ? this.props.eventSelect : this.props.match.params.event,
                        idTruck: this.props.truckSelect,
                        idTruckUserType: this.props.typeDriverSelect,
                        isFromEvent: this.props.match.params.event,
                        userType: this.props.userType,
                        history: this.props.history
                      })}
                    width={'100%'}
                    fontSize={15} />
                </div>

              </div>

            </div>
          </ThemeProvider>
        </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, idCustomerSelected, infoLabel, isEdited } = state.user
  const { creatingSubcontractorDriver,
    labelCustomers, customers, customersFilter,
    labelEvents, events,
    labelTrucks, trucks, trucksFilter,
    labelCompanies, companies,
    firstName, lastName, email, phone,
    radioDisabled,
    eventSelect,
    truckSelect,
    customerSelected,
    typeDriverSelect,
    companySelected,
    color } = state.subcontractorDriver
  return {
    isAuthenticated, user, idCustomerSelected, infoLabel, userType: user.user.type.id, isEdited,
    creatingSubcontractorDriver,

    labelCustomers, customers, customersFilter: _.orderBy(customersFilter, 'value', 'asc'),
    labelEvents, events,
    labelTrucks, trucks, trucksFilter: _.orderBy(trucksFilter, 'value', 'asc'),
    labelCompanies, companies,
    firstName, lastName, email, phone,
    radioDisabled,
    eventSelect,
    truckSelect,
    customerSelected,
    typeDriverSelect,
    companySelected,
    color,
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps,
  {
    clearState,
    setInputSubcontractorDriver,
    getOptionalDataDriver,
    getTrucksByCompany,
    createSubcontractorDriver,
    filterByEvent,
    setInputUser
  })(CreateSubcontractorDriver)))

