import React from 'react'
import Routers from './router'
import { NotificationContainer } from 'react-notifications';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { string_general } from '../modules/resources/strings'

class App extends React.Component {

  componentDidMount() {
    document.title = string_general.appName
    window.scrollTo(0, 0);
  }

  render() {
    return (<div>
      <Routers />
      <NotificationContainer />
    </div>)
  }
}

export default App