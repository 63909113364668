import {
    SET_INPUT_DELIVERY,
    LOAD_DELIVERIES,
    LOADING_FILTER_DATA_DELIVERY,
    CLEAR_STATE_DELIVERY,
    GETTING_DELIVERY,
    SET_DELIVERY,
    SET_PRODUCT,
    SET_PRODUCT_UPDATE,
} from './types'
const initialState = {
    customerList: [],
    idCustomer: 0,
    deliveryColumns: [],
    transferColumns: [],
    deliveryList: [],
    transferList: [],
    typeList: [],
    idType: 0,
    statusList: [],
    idStatus: 0,
    isTransfer: false,
    labelCustomers: '',
    labelTypes: '',
    labelStatus: '',
    itemsFilter: [],
    updatingDelivery: false,
    gettingDelivery: false,
    productList: [],
    images: [],
    idProduct: 0,
    productName: '',
    driverName: '',
    driverNameIn: '',
    driverNameOut: '',
    gallons: '',
    deliveryDate: '',
    notes: '',
    delivery: {},
    deliveryProducts: [],
    showModalUpdateProduct: false,
    productNameUpdate: '',
    gallonsUpdate: '',
    idProductUpdate: 0,
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case SET_INPUT_DELIVERY:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }

        case LOAD_DELIVERIES:
            return {
                ...state,
                customerList: payload.customers,
                idCustomer: payload.idCustomer,
                typeList: payload.types,
                idType: payload.types[0].value,
                statusList: payload.status,
                idStatus: payload.status[0].value,
                labelCustomers: payload.labelCustomers,
                labelTypes: payload.labelTypes,
                labelStatus: payload.labelStatus,
                deliveryList: payload.deliveries,
                transferList: payload.transfers,
                itemsFilter: payload.deliveries,
                deliveryColumns: payload.deliveryColumns,
                transferColumns: payload.transferColumns
            }

        case LOADING_FILTER_DATA_DELIVERY:
            return {
                ...state,
                labelCustomers: payload.labelCustomers,
                labelTypes: payload.labelTypes,
                labelStatus: payload.labelStatus
            }

        case GETTING_DELIVERY:
            return {
                ...state,
                updatingDelivery: true,
                gettingDelivery: true,
            }



        case SET_PRODUCT:
            return {
                ...state,
                deliveryProducts: payload.deliveryProducts,
                productName: '',
                gallons: '',
                idProduct: 0
            }

        case SET_PRODUCT_UPDATE:
            return {
                ...state,
                deliveryProducts: payload.deliveryProducts,
                productNameUpdate: '',
                gallonsUpdate: '',
                idProductUpdate: 0,
                showModalUpdateProduct: false
            }

        case SET_DELIVERY:
            return {
                ...state,
                updatingDelivery: false,
                gettingDelivery: false,
                productList: payload.products,
                images: payload.delivery.images,
                statusList: payload.status,
                driverName: payload.delivery.driver,
                idStatus: payload.delivery.idStatus,
                notes: payload.delivery.notes,
                deliveryDate: payload.delivery.deliveryDate,
                deliveryProducts: payload.delivery.products,
                driverNameIn: payload.delivery.driverIn,
                driverNameOut: payload.delivery.driverOut,

            }

        case CLEAR_STATE_DELIVERY:
            return {
                ...initialState
            }

        default:
            return state
    }
}
