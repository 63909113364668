import React, { Children } from "react";
import { render } from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from 'lodash'

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

import Pagination from "./Pagination";
import { tableStyles } from '../../modules/resources/styles'

class DragTrComponent extends React.Component {
    render() {
        const { children = null, rowInfo } = this.props;
        if (rowInfo) {
            const { original, index } = rowInfo;
            const { id } = original;
            return (
                <Draggable
                    key={id}
                    index={index}
                    draggableId={id}>
                    {
                        (draggableProvided, draggableSnapshot) => (
                            <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                            >
                                <ReactTable.defaultProps.TrComponent style={{ width: '100%' }}>
                                    {children}
                                </ReactTable.defaultProps.TrComponent>
                            </div>
                        )
                    }
                </Draggable>
            );
        } else
            return (
                <ReactTable.defaultProps.TrComponent>
                    {children}
                </ReactTable.defaultProps.TrComponent>
            );
    }
}

class DropTbodyComponent extends React.Component {
    render() {
        const { children = null } = this.props;
        return (
            <Droppable droppableId="droppable">
                {(droppableProvided, droppableSnapshot) => (
                    <div ref={droppableProvided.innerRef}>
                        <ReactTable.defaultProps.TbodyComponent>
                            {children}
                        </ReactTable.defaultProps.TbodyComponent>
                    </div>
                )}
            </Droppable>
        );
    }
}

class TableDndApp extends React.Component {

    constructor() {
        super();
        this.state = { data: [] };
    }

    handleDragEnd = result => {

        if (!result.destination) {
            return;
        }

        const data = reorder(
            this.props.items,
            result.source.index,
            result.destination.index
        );
        this.props.reorder(data)
    };

    getTrProps = (state, rowInfo) => {
        return { rowInfo };
    };

    render() {
        const pages = Math.ceil(this.props.items.length / this.props.numberPages)
        return (
            <DragDropContext
                onDragEnd={this.handleDragEnd}>
                <ReactTable
                    TrComponent={DragTrComponent}
                    TbodyComponent={DropTbodyComponent}
                    getTrProps={this.getTrProps}
                    PaginationComponent={Pagination}
                    showPaginationTop={!this.props.hidePaginationTop}
                    previousText='<'
                    nextText=">"
                    resizable
                    defaultPageSize={this.props.numberPages}
                    minRows={this.props.numberPages}
                    pageSize={this.props.numberPages}
                    pages={pages === 0 ? 1 : pages}
                    data={this.props.items}
                    columns={this.props.columns}
                    style={tableStyles.table}
                    loading={this.props.loading}
                />
            </DragDropContext>
        );
    }
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default TableDndApp
