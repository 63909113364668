import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import ReactTooltip from 'react-tooltip'

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import IconButton from '@material-ui/core/IconButton';
import Lock from '@material-ui/icons/LockOutlined';

//MY COMPONENTS
import Button from '../components/Button'
import Loading from '../components/Loading'
import Input from '../components/Input'

// ACTIONS
import { change_password } from '../../modules/core/Login/actions'

// STYLES
import { changePasswordStyles } from '../../modules/resources/styles'
import { colors } from '../../modules/resources/colors'
import { mainTheme } from '../../modules/resources/themes'
import { string_general, string_change_password } from '../../modules/resources/strings'

const styles = theme => ({
    root: {
        display: 'flex',
        background: "black"
    },
    input: {
        color: "black",
        background: colors.bg_input
    },
    sectionResponsive: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
            width: '40%',
            marginLeft: 10,
            marginRight: 10,
            marginTop: 50,
        },
    }
});
class ChangePassword extends React.Component {

    state = {
        showPassword: false,
        showConfirmPassword: false,
        password: '',
        confirmPassword: ''
    }

    render() {
        const { classes } = this.props;
        return (
            this.props.changing_password ?
                <Loading label={string_change_password.changing_password} />
                :
                <div style={{ ...changePasswordStyles.container, alignItems: 'center' }}>
                    <div className={classes.sectionResponsive}>
                        {/* TITLE */}
                        <span style={changePasswordStyles.title}>{string_change_password.title}</span>


                        <ThemeProvider theme={mainTheme}>
                          
                                {/* NEW PASSWORD */}
                                <Input
                                    isRequired
                                    id="password"
                                    label="New Password"
                                    placeholder="Enter admin new password"
                                    info
                                    type={this.state.showPassword}
                                    className={classes.margin}
                                    value={this.props.password}
                                    onChange={value => this.setState({ password: value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock className={classes.margin} color="secondary" />
                                            </InputAdornment>
                                        ),
                                        className: classes.input
                                    }}
                                />
                                {/* CONFIRM  NEW PASSWORD */}
                                <Input
                                    isRequired
                                    id="ConfirmPassword"
                                    label="Confirm New Password"
                                    placeholder="Enter admin new confirm password"
                                    info
                                    type={this.state.showConfirmPassword}
                                    className={classes.margin}
                                    value={this.props.confirmPassword}
                                    onChange={value => this.setState({ confirmPassword: value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })}>
                                                    {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock className={classes.margin} color="secondary" />
                                            </InputAdornment>
                                        ),
                                        className: classes.input
                                    }}
                                />
                        </ThemeProvider>
                        <div style={changePasswordStyles.containerButton}>
                            <span>{string_general.required_fields}</span>
                            <Button
                                label={string_general.button_update}
                                onPress={() =>
                                    this.props.change_password({
                                        token: this.props.user.token,
                                        password: this.state.password,
                                        confirmPassword: this.state.confirmPassword,
                                        history: this.props.history
                                    })}
                                width={'100%'}
                                fontSize={15} />
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateProps = (state) => {
    const { isAuthenticated, user, changing_password } = state.user
    return { isAuthenticated, user, changing_password }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, { change_password })(ChangePassword)))

