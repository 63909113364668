import React from "react";
import _ from 'lodash'

import {
    CLEAR_STATE_TRACKING,
    SET_INPUT_TRACKING,
    LOAD_DRIVERS,
    RESET_DRIVERS,
    ADD_DRIVER
} from './types'

import { sw_get_customers_from_event, sw_get_dispatches, sw_get_pending_sites } from '../../services/TrackingApi'
import firebase from '../../services/Firebase'
import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import Geocode from "react-geocode";
import moment from 'moment';
import { string_general } from "../../resources/strings";

const starCountRef = firebase.database().ref(`tracking/`);
Geocode.setApiKey(string_general.api_key);
// Geocode.setApiKey("AIzaSyBxALnoXq5IJucf3UvdzeMLLSDNiHHO5KY"); OLD

export const clearState = () => {

    return (dispatch) => {
        //firebase.database().ref(`tracking/2 - Event/`).remove()
        // firebase.database().ref('tracking/1 - Event/4').set(
        //     {
        //         id: 4,
        //         driverFullName: 'Keyler Navarro',
        //         email: "knavarro@outcoding.com",
        //         phone: '12345678',
        //         customer: {
        //             idCustomer: 4,
        //             customerName: "Customer B",
        //             color: '#0C9BE9',
        //         },
        //         truck: {
        //             idTruck: 1,
        //             truckNumber: "1234",
        //             truckType: "1234",
        //         },
        //         helper: {
        //             id: 15,
        //             helperFullName: 'Enrique Sandoval',
        //             email: "esandoval@outcoding.com",
        //             phone: '12345678',
        //         },
        //         position: {
        //             lat: 42.448795,
        //             lng: -75.34609
        //         }
        //     }
        // );
        dispatch({ type: CLEAR_STATE_TRACKING })
    }
}

export const getData = (info) => {
    const { token, event, customer, history, loadDriver } = info

    return (dispatch) => {
        dispatch({
            type: SET_INPUT_TRACKING,
            payload: { prop: 'labelCustomers', value: 'Loading customers...' }
        })
        const requests = [
            sw_get_customers_from_event({ token, event }),
            sw_get_pending_sites({ token, idEvent: event }),
            sw_get_dispatches({ token, event }),
        ]
        if(loadDriver) {
            starCountRef.once('value', function (snapshot) {    
                const data = snapshot.val();
                for (const prop in data) {
                    if (!!data[prop]) {
                        if (prop === `${event} - Event`) {
                            const driversData = data[prop];
                            for (const driver in driversData) {
                                Geocode.fromLatLng(`${driversData[driver].position.lat}`, `${driversData[driver].position.lng}`).then(
                                    response => {
                                        console.debug("successfuly");
                                        const address = response.results[0].formatted_address;
                                        driversData[driver].address = address;
                                        dispatch({
                                            type: ADD_DRIVER, 
                                            payload: driversData[driver]
                                        })
                                    },
                                    error => {
                                        console.error("Error responde Geocode API", error);
                                    });
                            }
                        }
                    }
                }
            });
        }

        Promise.all(requests).then(function (values) {

            // CUSTOMERS
            const customerTemp = values[0].data
            const dispatches = values[2].data;
            let customers = [{ value: 0, description: 'All' }]
            if (customerTemp !== undefined) {
                customerTemp.forEach(e => customers.push({
                    value: e.customer.id,
                    description: e.customer.customerName
                }))
            }


            const sitesTemp = values[1].data
            let sites = []

            if (sitesTemp !== undefined) {
                sitesTemp.forEach(e => {
                    sites.push({
                        id: `site_${e.id}`,
                        idCustomer: e.customer.id,
                        site: e.siteName,
                        address: e.address,
                        city: e.city,
                        state: e.state.description,
                        zipcode: e.zipCode,
                        color: e.customer.color,
                        setColor: e.setColor,
                        driverColor: e.driverColor,
                        lastSeen: e.updated_at,
                        position: {
                            lat: e.latitude,
                            lng: e.longitude
                        },
                        driversId: e.driversId,
                        priorityOrder: e.priorityOrder,
                        dispatches: dispatches.filter((dispatch) => {
                            if (dispatch.idSite === e.id) {
                                dispatch.urlEdit = goToEditDispatch(history, `/event/${dispatch.event.id}/edit_dispatch/${dispatch.id}`)
                                return dispatch;
                            }
                        })
                    })
                })
            }
            sites = _.uniqWith(sites, _.isEqual)

            // COLUMNS
            const columnsSortable = [
                {
                    Header: 'Driver',
                    accessor: 'driverFullName',
                    headerStyle: tableStyles.header,
                    sortable: true,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },

                {
                    Header: 'Customer',
                    accessor: 'customer',
                    headerStyle: tableStyles.header,
                    sortable: true,
                    Cell: props => <span>{props.value['customerName']}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Seen',
                    accessor: 'lastSeen',
                    headerStyle: tableStyles.header,
                    sortable: true,
                    Cell: props => <span>{moment(`${props.value}`).format('LLL')}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Location',
                    accessor: 'address',
                    headerStyle: tableStyles.header,
                    sortable: true,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },


            ]
            const columnsWithoutSortable = columnsSortable.map(e => ({ ...e, sortable: false }))


            dispatch({
                type: LOAD_DRIVERS,
                payload: {
                    labelCustomers: 'Select',
                    customers,
                    sites,
                    columnsWithoutSortable
                }
            })
        })
    }
}

const goToEditDispatch = (history, url) => {
    return (
        <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(url)}>Edit</a>
    )
}
