import {
    LOAD_SITES,
    SET_INPUT_SITE,
    CLEAR_STATE_SITE,
    GETTING_SITE,
    GOT_SITE,
    LOAD_DATA_SITE,
    ERROR_GETTING_SITE,
    UPDATE_LIST_SITES,
    UPDATE_LISTFILTER_SITES
} from './types'

const initialState = {
    creatingSite: false,
    updatingSite: false,
    gettingSite: false,
    siteFound: true,
    columns: [],
    listItems: [],
    listItemsFilter: [],
    siteName: '',
    address: '',
    city: '',
    notes: '',
    zipCode: '',
    latitude: null,
    longitude: null,
    groupNumber: null,
    status: '',
    stateSite: undefined,

    labelStates: 'Loading states...',
    arrayStates: [],
    siteRepeated: false,
    existentSiteName: ''
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_SITE:
            return {
                ...initialState
            }
        case LOAD_SITES:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter
            }
        case SET_INPUT_SITE:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GETTING_SITE:
            return {
                ...state,
                updatingSite: true,
                gettingSite: true,
            }
        case GOT_SITE:
            return {
                ...state,
                siteFound: true,
                updatingSite: false,
                gettingSite: false,
                siteName: payload.siteName,
                address: payload.address,
                city: payload.city,
                notes: payload.notes,
                zipCode: payload.zipCode,
                latitude: payload.latitude,
                longitude: payload.longitude,
                groupNumber: payload.groupNumber,
                status: payload.status,
                stateSite: payload.stateSite,
            }
        case LOAD_DATA_SITE:
            return {
                ...state,
                labelStates: payload.labelStates,
                arrayStates: payload.arrayStates
            }
        case ERROR_GETTING_SITE:
            return {
                ...state,
                siteFound: false,
                updatingSite: false,
                gettingSite: false,
                siteName: '',
                address: '',
                city: '',
                notes: '',
                zipCode: '',
                latitude: null,
                longitude: null,
                groupNumber: null,
                stateSite: null,
            }
        case UPDATE_LIST_SITES:
            return {
                ...state,
                listItems: [...state.listItems.filter(e => e.id !== payload.id), payload]
            }
        case UPDATE_LISTFILTER_SITES:
            return {
                ...state,
                listItemsFilter: [...state.listItemsFilter.filter(e => e.id !== payload.id), payload]
            }
        default:
            return state
    }
}
