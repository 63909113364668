import React from 'react';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';

import { dropdownTheme } from '../../modules/resources/themes'

export default function ButtonCustom(props) {

    const useStyles = makeStyles(theme => ({
        button: {
            margin: theme.spacing(1),
            fontSize: props.fontSize,
            width: props.width,
            borderRadius: 30,
            padding: 10
        },
    }))

    const classes = useStyles();

    return (
        <div>
            <ThemeProvider theme={dropdownTheme}>
                <Button variant="contained" color="secondary" className={classes.button} onClick={props.onPress}>
                    {props.label}
                </Button>
            </ThemeProvider>
        </div>
    );
}