import React from "react";
import _ from 'lodash'

import {
    CLEAR_STATE_SUBCONTRACTOR_DRIVER,
    LOAD_SUBCONTRACTOR_DRIVERS,
    SET_INPUT_SUBCONTRACTOR_DRIVER,
    GETTING_SUBCONTRACTOR_DRIVER,
    GOT_SUBCONTRACTOR_DRIVER,
    LOAD_DATA_CREATE_SUBCONTRACTOR_DRIVER,
    LOADING_DATA_CREATE_SUBCONTRACTOR_DRIVER,
    ERROR_GETTING_SUBCONTRACTOR_DRIVER,
    UPDATE_LIST_TRUCK_EDIT_DRIVER
} from './types'

import {
    sw_get_subcontractor_drivers,
    sw_create_subcontractor_driver,
    sw_get_subcontractor_driver,
    sw_update_subcontractor_driver,
    sw_get_customers,
    sw_get_events,
    sw_get_trucks,

    sw_get_subcontractor_drivers_from_event,
    sw_get_subcontractor_companies,
    sw_get_customers_from_event,
    sw_get_driver_lastname,
    sw_get_status
} from '../../services/SubcontractorDriverApi'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { validateEmail, validatePassword, showAlert, isEmpty } from '../../resources/Util'

import firebase from '../../services/Firebase'

export const clearState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE_SUBCONTRACTOR_DRIVER,
        })
    }
}

const getRating = (rating) => {
    switch (rating) {
        case 1: return 'Gold';
        case 2: return 'Silver';
        case 3: return 'Bronze';
        default: return '-'
    }
}

export const createSubcontractorDriver = (info) => {

    const {
        token, company,
        firstName, lastName, email, phone, color, 
        // rating,
        idCustomer, idEvent, idTruck, idTruckUserType,
        isFromEvent, userType, history } = info

    return (dispatch) => {

        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(firstName) || isEmpty(lastName) || isEmpty(email) || isEmpty(cleanPhone) || cleanPhone.length !== 10) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (isEmpty(company) || isNaN(company) || company === null) {
            showAlert({ msj: 'Select a subcontractor', type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        }

        console.log("info driver: ", info)
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: 'creatingSubcontractorDriver', value: true }
        })

        sw_create_subcontractor_driver({
            token,
            firstName,
            lastName,
            email,
            phone: cleanPhone,
            color,
            // rating,
            idEvent: idEvent !== 0 ? idEvent : null,
            idCustomer: idCustomer !== 0 ? idCustomer : null,
            idTruck: idTruck !== 0 ? idTruck : null,
            idTruckUserType,
            "idCompany": company,
            "idType": 4
        }).then(data => {
            console.log("data driver: ", data)
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                    payload: { prop: 'creatingSubcontractorDriver', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                        payload: { prop: 'creatingSubcontractorDriver', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Subcontractor driver created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_DRIVER
                })
                if (isFromEvent === undefined) {
                    if (userType !== 3) {
                        history.push(`/subcontractor/${company}/drivers`)
                    } else {
                        history.push(`/subcontractor/drivers`)
                    }
                } else {
                    history.push(`/event/${idEvent}/drivers`)
                }

            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                    payload: { prop: 'creatingSubcontractorDriver', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })
    }
}

export const updateSubcontractorDriver = (info) => {

    const { token, id, company,
        firstName,
        lastName,
        email,
        status,
        password,
        confirmPassword,
        phone,
        color,
        rating,
        idCustomer,
        idTruck,
        idTruckUserType,
        idEvent,
        isFromEvent,
        userType,
        history } = info
    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(firstName) || isEmpty(lastName) ||
            isEmpty(email) || isEmpty(cleanPhone) || isEmpty(cleanPhone) || cleanPhone.length !== 10 ||
            isEmpty(status)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (!isEmpty(password) || !isEmpty(confirmPassword)) {
            if (password !== confirmPassword) {
                showAlert({ msj: string_general.errorMsj.differentPasswords, type: 2 })
                return false
            } else if (!validatePassword(password)) {
                showAlert({
                    msj: `${string_general.errorMsj.password} ${string_general.formatPassword_msj}`,
                    type: 2,
                    duration: 5000
                })
                return false
            }
        } else if (isEmpty(company) || isNaN(company)) {
            showAlert({ msj: 'Company not found', type: 2 })
            return false
        } else if (isEmpty(id) || isNaN(id)) {
            showAlert({ msj: 'Driver not found', type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: 'updatingSubcontractorDriver', value: true }
        })

        sw_update_subcontractor_driver({
            token,
            id,
            email,
            password,
            firstName,
            lastName,
            phone: cleanPhone,
            color,
            rating,
            idEvent: idEvent !== 0 ? idEvent : null,
            idCustomer: idCustomer !== 0 ? idCustomer : null,
            idTruck: idTruck !== 0 ? idTruck : null,
            idTruckUserType,
            idStatus: status
        }).then(data => {

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                payload: { prop: 'updatingSubcontractorDriver', value: false }
            })

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }

                if (status === 2) {
                    if (idEvent !== 0) {
                        firebase.database().ref(`tracking/${idEvent} - Event`).child(`${id}`).remove()
                    }
                }

                showAlert({ msj: 'Subcontractor driver updated successful', type: 1 })

                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_DRIVER
                })
                if (isFromEvent === undefined) {
                    if (userType !== 3) {
                        history.push(`/subcontractor/${company}/drivers`)
                    } else {
                        history.push(`/subcontractor/drivers`)
                    }
                } else {
                    history.push(`/event/${idEvent}/drivers`)
                }
            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                    payload: { prop: 'updatingSubcontractorDriver', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })
    }
}

export const getSubcontractorDriver = (info) => {
    const { token, id, gotDriver, userType } = info
    return (dispatch) => {

        dispatch({
            type: GETTING_SUBCONTRACTOR_DRIVER
        })

        sw_get_subcontractor_driver({ token, id }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_DRIVER
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_SUBCONTRACTOR_DRIVER
                    })
                    return false
                }

                const driver = data.data
                let companySelected = driver.sub_contractor_company[0].fk_idCompany

                const customer = driver.customers
                let customerSelected = 0
                if (customer) {
                    customerSelected = customer.fk_idCustomer
                }

                const event = driver.event
                let eventSelect = 0
                if (event) {
                    eventSelect = event.fk_idEvent
                }

                let radioDisabled = false
                let truckSelect = 0
                let typeDriverSelect = -1

                if (driver.truck !== null) {
                    radioDisabled = driver.truck.code === 4 ? true : false
                    truckSelect = driver.truck.id
                    typeDriverSelect = driver.truck.fk_idType

                    dispatch({
                        type: UPDATE_LIST_TRUCK_EDIT_DRIVER,
                        payload: {
                            value: driver.truck.id,
                            description: driver.truck.truckNumber,
                            code: driver.truck.code,
                            event: eventSelect
                        }
                    })
                }

                let columns = []

                if (userType !== 3) {
                    columns = [
                        {
                            Header: 'Events',
                            accessor: 'event',
                            headerStyle: tableStyles.header,
                            sortable: false,
                            Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                            getProps: (state, rowInfo, column) => {
                                return {
                                    style: {
                                        ...tableStyles.row,
                                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                    }
                                }
                            }
                        },
                        {
                            Header: 'Customers',
                            accessor: 'customer',
                            headerStyle: tableStyles.header,
                            sortable: false,
                            Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                            getProps: (state, rowInfo, column) => {
                                return {
                                    style: {
                                        ...tableStyles.row,
                                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                    }
                                }
                            }
                        },
                        {
                            Header: 'Trucks',
                            accessor: 'truck',
                            headerStyle: tableStyles.header,
                            sortable: false,
                            Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                            getProps: (state, rowInfo, column) => {
                                return {
                                    style: {
                                        ...tableStyles.row,
                                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                    }
                                }
                            }
                        }
                    ]
                } else {
                    columns = [
                        {
                            Header: 'Events',
                            accessor: 'event',
                            headerStyle: tableStyles.header,
                            sortable: false,
                            Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                            getProps: (state, rowInfo, column) => {
                                return {
                                    style: {
                                        ...tableStyles.row,
                                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                    }
                                }
                            }
                        },
                        {
                            Header: 'Trucks',
                            accessor: 'truck',
                            headerStyle: tableStyles.header,
                            sortable: false,
                            Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                            getProps: (state, rowInfo, column) => {
                                return {
                                    style: {
                                        ...tableStyles.row,
                                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                    }
                                }
                            }
                        }
                    ]
                }
                let items = []

                if (driver.historial !== undefined) {
                    driver.historial.forEach(element => items.push({
                        event: element.event === null ? '-' : element.event.eventName,
                        customer: element.customer === null ? '-' : element.customer.customerName,
                        truck: element.truck === null ? '-' : element.truck.truckNumber
                    }));
                }

                const color = driver.color === null ? '#000' : driver.color
                gotDriver(
                    customerSelected,
                    eventSelect,
                    radioDisabled,
                    truckSelect,
                    typeDriverSelect,
                    companySelected,
                    color,
                )

                dispatch({
                    type: GOT_SUBCONTRACTOR_DRIVER,
                    payload: {
                        firstName: driver.firstName,
                        lastName: driver.lastName,
                        email: driver.email,
                        status: driver.status.id,
                        phone: driver.phone.phone,
                        ratingSelected: driver.rating,
                        columns,
                        listItems: items,
                        event: eventSelect,
                    }
                })


            } else {
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_DRIVER
                })
                showAlert({ msj: 'Driver not found', type: 2 })
            }
        })
    }
}

export const getSubcontractoDrivers = (info) => {
    const { token, company, userType, success, showError, history } = info
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: "items", value: [] }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: "listItemsFilter", value: [] }
        })

        const requests = [
            sw_get_events({ token, company }),
            sw_get_subcontractor_drivers({ token, company }),
            sw_get_driver_lastname({ token }),
            sw_get_status({ token }),
        ]

        Promise.all(requests).then(function (values) {

            let events = [{
                value: 0,
                description: userType === 3 ? 'All' : 'Select an event'
            }]
            const eventsTemp = values[0].data
            if (eventsTemp !== undefined) {
                eventsTemp.forEach(e => events.push({
                    value: e.id,
                    description: e.eventName
                }))
            }

            const data = values[1]
            let columns = []
            if (userType !== 3) {
                columns = [
                    {
                        Header: 'Edit',
                        accessor: 'manage',
                        headerStyle: tableStyles.header,
                        width: 100,
                        sortable: false,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'First Name',
                        accessor: 'firstName',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'lastName',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phone',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Customer',
                        accessor: 'customer',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Event',
                        accessor: 'event',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Rating',
                        accessor: 'rating',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Date Modified',
                        accessor: 'updated_at',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]
            } else {
                columns = [
                    {
                        Header: 'Edit',
                        accessor: 'manage',
                        headerStyle: tableStyles.header,
                        width: 100,
                        sortable: false,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'First Name',
                        accessor: 'firstName',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'lastName',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phone',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Event',
                        accessor: 'event',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Date Modified',
                        accessor: 'updated_at',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]
            }


            let items = []

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
                return false
            }

            if (data.status === 200) {
                const tempDrivers = data.data

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    showError()
                } else {

                    tempDrivers.forEach(driver => {
                        items.push(
                            {
                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => {
                                    userType !== 3 ? history.push(`/subcontractor/${company}/edit_driver/${driver.id}`) : history.push(`/subcontractor/edit_driver/${driver.id}`)
                                }}>Edit</a>,
                                firstName: driver.firstName,
                                lastName: driver.lastName,
                                email: driver.email,
                                phone: driver.phone === null ? '-' : driver.phone.phone,
                                customer: driver.customerName === null ? '-' : driver.customerName,
                                event: driver.event === null ? '-' : driver.event.event.eventName,
                                eventId: driver.event === null ? -1 : driver.event.event.id,
                                status: driver.status.status,
                                rating: getRating(driver.rating),
                                updated_at: driver.updated_at
                            }
                        )
                    })
                    success()
                }
            } else {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            }

            items = _.orderBy(items, 'lastName', 'asc')


            let lastnames = [{
                value: 0,
                description: 'All'
            }]
            const lastnamesTemp = values[2].data

            for (let i = 0; i < lastnamesTemp.length; i++) {
                lastnames.push({
                    value: i + 1,
                    description: lastnamesTemp[i].lastName
                })
            }

            let listStatus = [{
                value: 0,
                description: 'All'
            }]
            const statusTemp = values[3].data

            statusTemp.forEach(e => {
                listStatus.push({
                    value: e.id,
                    description: e.status
                })
            })

            dispatch({
                type: LOAD_SUBCONTRACTOR_DRIVERS,
                payload: {
                    columns,
                    items,
                    listItemsFilter: items.filter(e => e.status === 'Active'),
                    events: _.orderBy(events, 'value', 'asc'),
                    lastnames,
                    listStatus
                }
            })
        })

    }
}

export const getOptionalDataDriver = (info) => {
    const { token, company, event, getDriver } = info
    return (dispatch) => {

        if (getDriver !== undefined) dispatch({
            type: GETTING_SUBCONTRACTOR_DRIVER
        })

        dispatch({
            type: LOADING_DATA_CREATE_SUBCONTRACTOR_DRIVER,
            payload: {
                labelCustomers: 'Loading customers...',
                labelEvents: 'Loading events...',
                labelTrucks: 'Loading trucks...',
                labelCompanies: 'Loading subcontractors...'
            }
        })

        const get_customers = event === undefined ? sw_get_customers({ token }) : sw_get_customers_from_event({ token, event })

        const requests = [
            get_customers,
            sw_get_events({ token, company }),
            sw_get_trucks({ token, company }),
            sw_get_subcontractor_companies({ token, event }),
            sw_get_status({ token }),
        ]

        Promise.all(requests).then(function (values) {

            const customerTemp = values[0].data

            let customers = []
            if (customerTemp !== undefined) {
                if (event === undefined) {
                    customerTemp.forEach(e => customers.push({
                        value: e.id,
                        description: e.customerName,
                        events: e.events
                    }))
                } else {
                    customerTemp.forEach(e => customers.push({
                        value: e.customer.id,
                        description: e.customer.customerName
                    }))
                }
            }

            customers.length === 0 ? customers.push({
                value: 0,
                description: 'Customers not found',
                events: []
            }) : customers.push({
                value: 0,
                description: 'Select a customer',
                events: []
            })

            let customersFilter = []
            if (event === undefined) {
                customersFilter = customers.filter(e => e.value === 0)
            } else {
                customers.forEach(e => customersFilter.push(e))
            }

            let events = []
            if (event === undefined) {
                const eventsTemp = values[1].data
                if (eventsTemp !== undefined) {
                    eventsTemp.forEach(e => events.push({
                        value: e.id,
                        description: e.eventName
                    }))
                }
            }

            events.length === 0 ? events.push({
                value: 0,
                description: 'Events not found'
            }) : events.push({
                value: 0,
                description: ' Select an event'
            })

            //code
            // 1 el truck tiene driver
            // 2 el truck tiene asisstant
            // 3 el truck no tiene nada
            const trucksTemp = values[2].data
            console.log("trucksTemp: ", values[2])
            let trucks = []
            if (trucksTemp !== undefined) {
                trucksTemp.forEach(e => {
                    trucks.push({
                        value: e.id,
                        description: e.truckNumber,
                        code: e.code,
                        event: e.idEvent
                    })
                })
            }

            trucks ? trucks.push({
                value: 0,
                description: 'Select a truck'
            }) : trucks.push({
                value: 0,
                description: 'Trucks not found'
            })



            const companiesTemp = values[3].data
            let companies = []
            companiesTemp.forEach(e => {
                companies.push({
                    value: e.subcontractor.id,
                    description: e.subcontractor.companyName
                })
            })

            companies.length === 0 ? companies.push({
                value: 0,
                description: 'Subcontractor company not found'
            }) : companies.push({
                value: 0,
                description: 'Select a subcontractor company'
            })

            let listStatus = []
            const statusTemp = values[4].data
            statusTemp.forEach(e => {
                listStatus.push({
                    value: e.id,
                    description: e.status
                })
            })
            dispatch({
                type: LOAD_DATA_CREATE_SUBCONTRACTOR_DRIVER,
                payload: {
                    events: _.orderBy(events, 'description', 'asc'),
                    customers: _.orderBy(customers, 'value', 'asc'),
                    customersFilter,
                    trucks: _.orderBy(trucks, 'value', 'asc'),
                    trucksFilter: trucks.filter(e => e.value === 0),

                    labelCustomers: customers.length !== 0 ? 'Select a customer' : 'Customers not found',
                    labelEvents: events.length !== 0 ? 'Select an event' : 'Events not found',
                    labelTrucks: trucks.length !== 0 ? 'Select a truck' : 'Trucks not found',
                    labelCompanies: companies.length !== 0 ? 'Select a subcontractor' : 'Subcontractors not found',
                    companies: _.orderBy(companies, 'value', 'asc'),
                    listStatus
                }
            })

            if (getDriver !== undefined) getDriver()
        })

    }
}

export const setInputSubcontractorDriver = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop, value }
        })
    }
}

export const filterByEvent = (info) => {
    const { event, customers, trucks } = info
    return (dispatch) => {

        let customersfilter = []
        customers.forEach(e => {
            const isEvent = e.events.find(f => f.idEvent === event)
            if (isEvent !== undefined || e.value === 0) {
                customersfilter.push(e)
            }
        })

        let trucksFilter = []
        trucks.forEach(e => {
            if (e.event === event || e.value === 0) {
                trucksFilter.push(e)
            }
        })
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: 'customersFilter', value: customersfilter }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: 'trucksFilter', value: trucksFilter }
        })
    }
}

//  WHEN SHOW THE DRIVERS FROM EVENT

export const filterSubcontractorDriver = (info) => {
    const { listItems, status, labelStatus, company, event, lastname, labelLastname } = info
    return (dispatch) => {
        //  WHEN SHOW THE DRIVERS FROM EVENT
        let filter = []
        if (status === 0 && company === 0 && event === 0 && lastname === 0) {
            filter = listItems
        } else if (status !== 0 && company === 0 && event === 0 && lastname === 0) {
            filter = listItems.filter(item => item.status === labelStatus)
        } else if (status !== 0 && company !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.company === company)
        } else if (status === 0 && company !== 0) {
            filter = listItems.filter(item => item.company === company)
        } else if (status === 0 && event !== 0 && lastname === 0) {
            filter = listItems.filter(item => item.eventId === event)
        } else if (status !== 0 && event !== 0 && lastname === 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.eventId === event)
        } else if (status !== 0 && event !== 0 && lastname !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.eventId === event && item.lastName === labelLastname)
        } else if (status === 0 && event !== 0 && lastname !== 0) {
            filter = listItems.filter(item => item.eventId === event && item.lastName === labelLastname)
        } else if (status === 0 && event === 0 && lastname !== 0) {
            filter = listItems.filter(item => item.lastName === labelLastname)
        } else if (status !== 0 && event === 0 && lastname !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.lastName === labelLastname)
        }
        //  WHEN SHOW THE DRIVERS FROM EVENT

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}

export const getSubcontractoDriversFromEvent = (info) => {
    const { token, event, customer, userType, company, success, showError, history } = info

    return (dispatch) => {

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: "items", value: [] }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_DRIVER,
            payload: { prop: "listItemsFilter", value: [] }
        })

        const requests = [
            sw_get_subcontractor_companies({ token, event }),
            sw_get_subcontractor_drivers_from_event({ token, idEvent: event }),
            sw_get_driver_lastname({ token }),
            sw_get_status({ token }),
        ]

        Promise.all(requests).then(function (values) {

            const companiesTemp = values[0].data

            let companies = [{ value: 0, description: 'All' }]
            companiesTemp.forEach(e => {
                companies.push({
                    value: e.subcontractor.id,
                    description: e.subcontractor.companyName
                })
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                payload: { prop: 'companies', value: companies }
            })
            let columns = []
            if (userType !== 3) {
                columns = [
                    {
                        Header: 'Edit',
                        accessor: 'manage',
                        headerStyle: tableStyles.header,
                        width: 100,
                        sortable: false,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'First Name',
                        accessor: 'firstName',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'lastName',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phone',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Customer',
                        accessor: 'customerName',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Subcontractor',
                        accessor: 'subcontractor',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Rating',
                        accessor: 'rating',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Date Modified',
                        accessor: 'updated_at',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]
            } else {
                columns = [
                    {
                        Header: 'Edit',
                        accessor: 'manage',
                        headerStyle: tableStyles.header,
                        width: 100,
                        sortable: false,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'First Name',
                        accessor: 'firstName',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'lastName',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phone',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Subcontractor',
                        accessor: 'subcontractor',
                        headerStyle: tableStyles.header,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Last Date Modified',
                        accessor: 'updated_at',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]
            }

            let items = []
            const data = values[1]

            console.log("DATA: ", data)
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
                return false
            }

            if (data.status === 200) {
                const tempDrivers = data.data
                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    showError()
                } else {
                    tempDrivers.forEach(driver => {
                        items.push(
                            {
                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/event/${event}/edit_driver/${driver.id}`)}>Edit</a>,
                                firstName: driver.firstName,
                                lastName: driver.lastName,
                                email: driver.email,
                                phone: driver.phone === null ? '-' : driver.phone.phone,
                                subcontractor: driver.companyName === undefined ? '-' : driver.companyName,
                                status: driver.status.status,
                                rating: getRating(driver.rating),
                                updated_at: driver.updated_at,
                                company: driver.idCompany,
                                customer: driver.idCustomer,
                                customerName: driver.customerName === null ? '-' : driver.customerName
                            }
                        )
                    })

                    items = customer === 0 ? items : items.filter(e => e.customer === customer)
                    success()
                }
            } else {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            }

            if (userType === 3) {
                items = items.filter(e => e.company === company)
            }

            let lastnames = [{
                value: 0,
                description: 'All'
            }]
            const lastnamesTemp = values[2].data

            for (let i = 0; i < lastnamesTemp.length; i++) {
                lastnames.push({
                    value: i + 1,
                    description: lastnamesTemp[i].lastName
                })
            }

            let listStatus = [{
                value: 0,
                description: 'All'
            }]
            const statusTemp = values[3].data

            statusTemp.forEach(e => {
                listStatus.push({
                    value: e.id,
                    description: e.status
                })
            })

            dispatch({
                type: LOAD_SUBCONTRACTOR_DRIVERS,
                payload: {
                    columns,
                    items,
                    listItemsFilter: items.filter(e => e.status === 'Active'),
                    events: [],
                    lastnames,
                    listStatus
                }
            })

        })
    }
}

export const getTrucksByCompany = (info) => {
    const { token, company, event, fullTrucks } = info
    return (dispatch) => {

        // fullTrucks : this is for know when the driver it's going to be edited from the event, 
        // so I need update the list "trucks" and "trucksFilter", because I need show all empty trucks + the the truck that is assigned to the driver 
        // for example: the trucks list is ["Truck A", "Truck B"] but the driver have assigned the "Truck C" 
        // so the end list will be ["Truck A", "Truck B", "Truck C"]. 
        // The logic is only update the driver from an event


        let trucks = []
        let trucksFilter = []

        if (fullTrucks === undefined) {
            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                payload: { prop: 'labelTrucks', value: 'Loading trucks...' }
            })

            trucks.push({
                value: 0,
                description: 'Loading trucks...'
            })
            trucksFilter.push({
                value: 0,
                description: 'Loading trucks...'
            })


            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                payload: { prop: 'trucks', value: trucks }
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                payload: { prop: 'trucksFilter', value: trucksFilter }
            })
        }

        const requests = [
            sw_get_trucks({ token, company })
        ]

        Promise.all(requests).then(function (values) {

            //code
            // 1 el truck tiene driver
            // 2 el truck tiene asisstant
            // 3 el truck no tiene nada
            const trucksTemp = values[0].data
            console.log("trucks: ", values[0])
            trucks = []
            if (trucksTemp !== undefined) {
                trucksTemp.forEach(e => {
                    trucks.push({
                        value: e.id,
                        description: e.truckNumber,
                        code: e.code,
                        event: `${e.idEvent}`
                    })
                })
            }

            if (fullTrucks !== undefined) {
                fullTrucks.forEach(e => {
                    const truckDriver = trucks.find(f => f.value === e.value)
                    if (truckDriver === undefined) {
                        trucks.push(e)
                    }
                })
            } else {
                trucks.push({
                    value: 0,
                    description: 'Select a truck'
                })
            }

            trucksFilter = []
            trucks.forEach(e => {
                if (e.event == event || e.value === 0) {
                    trucksFilter.push(e)
                }
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                payload: { prop: 'labelTrucks', value: trucks.length === 0 ? 'Trucks not found' : 'Select a truck' }
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                payload: { prop: 'trucks', value: trucks }
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_DRIVER,
                payload: { prop: 'trucksFilter', value: trucksFilter }
            })

        })

    }
}
//  WHEN SHOW THE DRIVERS FROM EVENT
