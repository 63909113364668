export const CLEAR_STATE_EVENT = 'CLEAR_STATE_EVENT'
export const LOAD_EVENTS = 'LOAD_EVENTS'
export const SET_INPUT_EVENT = 'SET_INPUT_EVENT'
export const LOAD_DATA_EVENT = 'LOAD_DATA_EVENT'
export const ADD_NEW_CITY_TO_EVENT = 'ADD_NEW_CITY_TO_EVENT'
export const GETTING_EVENT = 'GETTING_EVENT'
export const ERROR_GETTING_EVENT = 'ERROR_GETTING_EVENT'
export const GOT_EVENT = 'GOT_EVENT'

export const SET_STATES_EVENT = 'SET_STATES_EVENT'
export const SET_CITIES_EVENT = 'SET_CITIES_EVENT'
export const SET_CUSTOMERS_EVENT = 'SET_CUSTOMERS_EVENT'
export const SET_SUBCONTRACTORS_EVENT = 'SET_SUBCONTRACTORS_EVENT'

