import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import ReduxThunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import login from '../../modules/core/Login/reducer'
import user from '../../modules/core/User/reducer'
import superAdmin from '../../modules/core/SuperAdmin/reducer'
import admin from '../../modules/core/Admin/reducer'
import company from '../../modules/core/SubcontractorCompany/reducer'
import subcontractorAdmin from '../../modules/core/SubcontractorAdmin/reducer'
import subcontractorDriver from '../../modules/core/SubcontractorDriver/reducer'
import subcontractorHelper from '../../modules/core/SubcontractorHelper/reducer'
import subcontractorTruck from '../../modules/core/SubcontractorTruck/reducer'
import customer from '../../modules/core/Customer/reducer'
import site from '../../modules/core/Site/reducer'
import event from '../../modules/core/Event/reducer'
import product from '../../modules/core/Product/reducer'
import eventReport from '../../modules/core/EventReports/reducer'
import delivery from '../../modules/core/Delivery/reducer'
import dispatch from '../../modules/core/Dispatch/reducer'
import tracking from '../../modules/core/Tracking/reducer'
import transfer from '../../modules/core/Transfer/reducer'


const reducers = combineReducers({
    login,
    user,
    superAdmin,
    admin,
    company,
    subcontractorAdmin,
    subcontractorDriver,
    subcontractorHelper,
    subcontractorTruck,
    customer,
    site,
    event,
    product,
    eventReport,
    dispatch,
    delivery,
    tracking,
    transfer,
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default () => {
    let store = createStore(persistedReducer, applyMiddleware(ReduxThunk))
    let persistor = persistStore(store)
    return { store, persistor }
}
