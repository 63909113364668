import {
    LOAD_SUBCONTRACTOR_DRIVERS,
    SET_INPUT_SUBCONTRACTOR_DRIVER,
    CLEAR_STATE_SUBCONTRACTOR_DRIVER,
    GETTING_SUBCONTRACTOR_DRIVER,
    GOT_SUBCONTRACTOR_DRIVER,
    LOADING_DATA_CREATE_SUBCONTRACTOR_DRIVER,
    LOAD_DATA_CREATE_SUBCONTRACTOR_DRIVER,
    ERROR_GETTING_SUBCONTRACTOR_DRIVER,
    UPDATE_LIST_TRUCK_EDIT_DRIVER
} from './types'

const initialState = {
    creatingSubcontractorDriver: false,
    updatingSubcontractorDriver: false,
    gettingSubcontractorDriver: false,
    driverFound: true,
    labelCustomers: '',
    labelEvents: '',
    labelTrucks: '',

    events: [],
    eventsFilter: [],
    customers: [],
    customersFilter: [],
    trucks: [],
    trucksFilter: [],

    columns: [],
    listItems: [],
    listItemsFilter: [],
    columnsHistorial: [],
    listItemsHistorial: [],
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    phone: '',
    password: '',
    confirmPassword: '',

    labelCompanies: '',
    companies: [],

    rating: [{ value: 1, description: 'Gold'}, { value: 2, description: 'Silver'}, { value: 3, description: 'Bronze'}],
    ratingSelected: null,

    radioDisabled: false,
    radioDisabledOriginal: false,

    eventSelect: 0,
    truckSelect: 0,
    truckSelectOriginal: 0,
    customerSelected: 0,
    typeDriverSelect: 1,
    typeDriverSelectOriginal: -1,
    companySelected: 0,
    color: '#000000',

    lastnames: [],
    listStatus: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_SUBCONTRACTOR_DRIVER:
            return {
                ...initialState
            }
        case LOAD_SUBCONTRACTOR_DRIVERS:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter,
                events: payload.events,
                lastnames: payload.lastnames,
                listStatus: payload.listStatus
            }
        case SET_INPUT_SUBCONTRACTOR_DRIVER:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GETTING_SUBCONTRACTOR_DRIVER:
            return {
                ...state,
                updatingSubcontractorDriver: true,
                gettingSubcontractorDriver: true,
            }
        case UPDATE_LIST_TRUCK_EDIT_DRIVER:
            return {
                ...state,
                trucks: [...state.trucks.filter(e => e.value !== payload.value), payload]
            }
        case GOT_SUBCONTRACTOR_DRIVER:
            let customersFilter = []
            state.customers.forEach(e => {
                const isEvent = e.events.find(f => f.idEvent === payload.event)
                if (isEvent !== undefined || e.value === 0) {
                    customersFilter.push(e)
                }
            })

            let trucksFilter = []
            state.trucks.forEach(e => {
                if (e.event === payload.event || e.value === 0) {
                    trucksFilter.push(e)
                }
            })

            return {
                ...state,
                driverFound: true,
                updatingSubcontractorDriver: false,
                gettingSubcontractorDriver: false,
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                status: payload.status,
                ratingSelected: payload.ratingSelected,
                phone: payload.phone,
                password: '',
                confirmPassword: '',
                columnsHistorial: payload.columns,
                listItemsHistorial: payload.listItems,

                customersFilter: customersFilter,
                trucksFilter: trucksFilter
            }
        case ERROR_GETTING_SUBCONTRACTOR_DRIVER:
            return {
                ...state,
                driverFound: false,
                updatingSubcontractorDriver: false,
                gettingSubcontractorDriver: false,
                firstName: '',
                lastName: '',
                email: '',
                status: '',
                phone: '',
                password: '',
                confirmPassword: ''
            }
        case LOADING_DATA_CREATE_SUBCONTRACTOR_DRIVER:
            return {
                ...state,
                labelCustomers: payload.labelCustomers,
                labelEvents: payload.labelEvents,
                labelTrucks: payload.labelTrucks,
                labelCompanies: payload.labelCompanies
            }
        case LOAD_DATA_CREATE_SUBCONTRACTOR_DRIVER:
            return {
                ...state,
                events: payload.events,
                eventsFilter: payload.events,
                customers: payload.customers,
                customersFilter: payload.customersFilter,
                trucks: payload.trucks,
                trucksFilter: payload.trucksFilter,

                labelCustomers: payload.labelCustomers,
                labelEvents: payload.labelEvents,
                labelTrucks: payload.labelTrucks,
                labelCompanies: payload.labelCompanies,
                companies: payload.companies,
                listStatus: payload.listStatus
            }
        default:
            return state
    }
}
