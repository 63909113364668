
import { colors } from '../colors'
import { users } from "../images/index"

export const loginStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },
    containerCard: {
        border: `10px solid ${colors.blue_light}`,
        marginBottom: 30,
    },
    containerTitle: {
        display: 'flex',
        width: '100%',
        height: 90,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.blue_sfs
    },
    textField: {
        marginBottom: 20,
        width: "100%",
    },
    title: {
        color: '#fff',
        fontSize: 25
    },
};

export const loadingStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export const buttonStyles = {
    containerButtonLogin: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        backgroundColor: colors.blue_sfs,
        color: '#fff',
        borderRadius: 30,
        padding: 15,
        marginLeft: 20,
        marginRight: 20,
    },
    pdf: {
        backgroundColor: colors.blue_sfs,
        color: '#fff',
        borderRadius: 30,
        padding: 10,
        border: "none",
    },
    deleteButton: {
        backgroundColor: 'red',
        color: '#fff',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '10px',
        cursor: 'pointer',
        fontSize: '14px',
      },
    cancelButton: {
        color: '#fff',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '10px',
        cursor: 'pointer',
        fontSize: '14px',
        backgroundColor: colors.blue_sfs,
    }
};

export const inputStyles = {
    containerInput: {
        display: 'flex',
        flexDirection: 'row',
    },
    input: {
        width: "100%",
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
        width: 10,
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        width: 30,
    }
};

export const changePasswordStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflowX: 'auto',
        marginLeft: 15,
        marginRight: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '10%',
        // backgroundColor: 'green'
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        position: 'relative', right: 0,
        marginLeft: 20
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '60%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30
    }
};

export const homeStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        marginLeft: 15,
        marginRight: 5,

    },
    containerButtons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.blue_sfs,
        height: 100,
        width: 250,
        border: `5px solid ${colors.blue_light}`,
        borderRadius: 20,
        padding: 100,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
    },
    img: {
        width: 80,
        height: 80,
        textAlign: 'center',
        zIndex: 100000
    },
    label: {
        color: '#FFFFFF',
        marginTop: 20
    }
};

export const superAdminStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    }
};

export const subcontractorStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 20,
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20,
        marginTop: 40
    },
    title: {
        marginTop: 20,
        marginLeft: 10,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyItems: 'center',
    },
    containerDropdownStates: {
        display: 'flex',
        width: '95%',
        flexDirection: 'row',
        zIndex: 10,
        marginBottom: 10,
        marginTop: 20,
    }
};

export const subcontractorAdminStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    }
};


export const productsStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    }
};


export const eventReportStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,

    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '10%',
        // backgroundColor: 'green'
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',

    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
        width: '90%',

    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10

    },
    containerButtonCreate: {
        position: 'relative',
        right: 0,
        marginLeft: 20,
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30,
        alignSelf: 'center',
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyItems: 'center',
        padding: 20,

    },
    buttonExport: {
        backgroundColor: colors.blue_sfs,
        border: `5px solid ${colors.blue_sfs}`,
        borderRadius: 30,
        color: 'white',
        paddingBottom: 7,
        paddingTop: 7,
        marginTop: 5,
        marginLeft: 50,
        width: 250,
        fontSize: 15
    },
};


export const deliveryStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,

    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '10%',
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',

    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1


    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10

    },
    containerButtonCreate: {
        position: 'relative', right: 0,
        marginLeft: 20
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30,
        alignSelf: 'center',
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyItems: 'center',
        padding: 20,

    }
};

export const subcontractorDriverStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    }
};

export const subcontractorHelperStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    }
};

export const subcontractorTruckStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    }
};

export const customerStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    dropdownModal: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center'
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20,
        marginTop: 20
    },
    title: {
        marginTop: 20,
        marginLeft: 10,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyItems: 'center',
        padding: 20
    },
    containerDropdownStates: {
        display: 'flex',
        width: '95%',
        flexDirection: 'row',
        zIndex: 10
    }
};

export const sitesStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '10%',
        // backgroundColor: 'green'
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
        right: 0,
        marginRight: 20,
        width: '50%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20,
        marginTop: 20,
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    buttonImport: {
        backgroundColor: colors.blue_sfs,
        border: `5px solid ${colors.blue_sfs}`,
        borderRadius: 30,
        color: 'white',
        paddingBottom: 7,
        paddingTop: 7,
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 12,
        fontWeight: 'bold',
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    },
    containerDropdownStates: {
        display: 'flex',
        width: '93%',
        flexDirection: 'row',
        zIndex: 10
    },
};

export const eventStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        marginRight: 20,
        width: '10%',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20,
        marginTop: 50,
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    buttonImport: {
        backgroundColor: colors.blue_light,
        border: `5px solid ${colors.blue_light}`,
        borderRadius: 30,
        color: 'white',
        paddingBottom: 7,
        paddingTop: 7,
        paddingLeft: 10,
        paddingRight: 10
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    }
};

export const tableStyles = {
    table: {
        borderColor: 'transparent',
        backgroundColor: 'transparent'
    },
    header: {
        display: "flex",
        color: colors.header,
        fontSize: 17,
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        paddingLeft: 10,
        flexDirection: 'row',
    },
    row: {
        fontSize: 15,
        padding: 10

    }
};

export const tabsMenuStyles = {
    container: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 10,
        marginRight: 20
    }
};

export const dispatchStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 5,
    },
    containerFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDivfilters: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '90%',
        marginTop: 10,
    },
    containerDropdownFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    containerDropdown: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: "relative",
        zIndex: 10,
    },
    containerButtonCreate: {
        position: 'relative',
        right: 0,
        marginRight: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
    },
    containerButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30
    },
    containerDivResponsive: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyItems: 'center',
        padding: 20,
    },
    containerDivResponsive2: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyItems: 'center',
    },
    containerFloatButtons: {
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        right: 0,
        marginBottom: 20,
        marginRight: 20,
        flexDirection: 'row',
    },
    floatButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        margin: 5,
        backgroundColor: colors.blue_light,
        borderRadius: "50%",
        width: 50,
        height: 50,
    },
    buttonImport: {
        backgroundColor: colors.blue_sfs,
        border: `5px solid ${colors.blue_sfs}`,
        borderRadius: 30,
        color: 'white',
        paddingBottom: 7,
        paddingTop: 7,
        marginTop: 5,
        marginLeft: 5,
        marginBottom: 5,
        width: 110,
        fontSize: 11
    },
};

export const infoWindowStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 450,
        backgroundColor: 'white'
    },
    containerInfoCustomer: {
        display: "flex",
        paddingTop: 10,
        paddingBottom: 10,
        justifyContent: 'center',
        backgroundColor: colors.blue_light
    },
    labelCustomer: {
        fontSize: 15,
        fontWeight: 'bold',
        color: 'white',
        display: 'block'
    },
    containerInfo: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
        borderBottom: `2px solid white`,
        borderBottomColor: colors.bg_input
    },
    containerInfoDriverHelperTruck: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%'
    },
    titleInfo: {
        marginBottom: 10,
        fontSize: 15,
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: colors.blue_sfs,
        paddingLeft: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    labelInfo: {
        marginBottom: 10,
        fontSize: 12,
        paddingLeft: 10
    },
    containerTruckInfo: {
        display: 'flex',
        flexDirection: 'row',
    },
    titleTruck: {
        marginBottom: 10,
        fontSize: 15,
        fontWeight: 'bold',
        color: colors.blue_sfs
    },
    labelInfoTruck: {
        marginBottom: 10,
        fontSize: 15,
        fontWeight: 'bold',
        color: 'black'
    }
};




