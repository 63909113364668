import _ from 'lodash'
import {
    CLEAR_STATE_DISPATCHES,
    SET_INPUT_DISPATCH,
    LOADING_DISPATCHES,
    LOADING_GROUPS,
    LOADING_SITES,
    LOAD_DISPATCHES,
    LOAD_DISPATCH,
    LOAD_SITES_CREATE_DISPATCHES,
    LOAD_GROUPS_CREATE_DISPATCHES,
    LOAD_SITESLIST_CREATE_DISPATCHES,
    UPDATE_LIST_TO_CREATE_DISPATCHES,
    DELETE_LIST_TO_CREATE_DISPATCHES,
} from './types'

const initialState = {
    creatingDispatches: false,
    updatingDispatch: false,
    gettingGetting: false,
    importSitesFailed: false,
    hasToCheckCoodinates: false,
    adminFound: true,
    columnsSortable: [],
    columnsWithoutSortable: [],
    listItems: [],
    listItemsFilter: [],

    dispatches: [],
    dispatchesFilters: [],

    labelCustomers: '',
    customers: [],

    labelDispatchTypes: '',
    dispatchTypes: [],

    labelSites: '',
    listSites: [],
    sites: [],
    sitesFilter: [],
    sitesRepeated: [],
    sitesToCheckCoodinates: [],
    sitesImportedError: '',
    sitesCoodinatesError: '',
    sitesRepeatedCols: [],
    sitesToCheckCoodinatesCols: [],

    labelDrivers: '',
    drivers: [],

    labelGroups: '',
    groups: [],

    labelStatus: '',
    status: [],

    typeSelected: 0,
    driverSelected: 0,
    statusSelected: 0,
    siteSelected: 0,
    oldDriver: 0,
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_DISPATCHES:
            return {
                ...initialState
            }
        case SET_INPUT_DISPATCH:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case LOADING_DISPATCHES:
            return {
                ...state,
                labelCustomers: payload.labelCustomers,
                labelDispatchTypes: payload.labelDispatchTypes,
                labelSites: payload.labelSites,
                labelDrivers: payload.labelDrivers,
                labelGroups: payload.labelGroups,
            }

        case LOADING_GROUPS:
            return {
                ...state,
                labelGroups: payload.labelGroups,
            }
        case LOADING_SITES:
            return {
                ...state,
                labelGroups: payload.labelGroups,
            }
        case LOAD_DISPATCHES:
            return {
                ...state,
                labelCustomers: payload.labelCustomers,
                customers: payload.customers,
                labelDispatchTypes: payload.labelDispatchTypes,
                dispatchTypes: payload.dispatchTypes,
                labelSites: payload.labelSites,
                listSites: payload.sites,
                labelDrivers: payload.labelDrivers,
                drivers: payload.drivers,
                labelGroups: payload.labelGroups,
                groups: payload.groups,
                columnsSortable: payload.columnsSortable,
                columnsWithoutSortable: payload.columnsWithoutSortable,
                dispatches: payload.items,
                dispatchesFilters: payload.listItemsFilter,
            }
        case LOAD_DISPATCH:
            return {
                ...state,
                updatingDispatch: false,
                gettingGetting: false,
                labelDispatchTypes: payload.labelDispatchTypes,
                dispatchTypes: payload.dispatchTypes,
                labelSites: payload.labelSites,
                labelDrivers: payload.labelDrivers,
                drivers: payload.drivers,
                labelStatus: payload.labelStatus,
                status: payload.status,
                notes: payload.notes,
                sites: payload.sites,
                fullAddress: payload.fullAddress
            }
        case LOAD_SITES_CREATE_DISPATCHES:
            return {
                ...state,
                labelCustomers: payload.labelCustomers,
                customers: payload.customers,
                labelDispatchTypes: payload.labelDispatchTypes,
                dispatchTypes: payload.dispatchTypes,
                labelSites: payload.labelSites,
                sites: payload.sites,
                sitesFilter: payload.sitesFilter,
                labelDrivers: payload.labelDrivers,
                drivers: payload.drivers,
                labelGroups: payload.labelGroups,
                groups: payload.groups,
                columnsWithoutSortable: payload.columns,
                listItems: [],
                listItemsFilter: [],
                sitesf: payload.sitesf,
            }

        case LOAD_GROUPS_CREATE_DISPATCHES:
            return {
                ...state,
                labelGroups: payload.labelGroups,
                groups: payload.groups,
            }
        case LOAD_SITESLIST_CREATE_DISPATCHES:
            return {
                ...state,
                labelGroups: payload.labelGroups,
                sitesList: payload.sitesList,
            }
        case UPDATE_LIST_TO_CREATE_DISPATCHES:
            return {
                ...state,
                sites: payload.sites,
                sitesFilter: payload.sitesFilter,
                listItems: payload.listItems,
                listItemsFilter: payload.listItemsFilter
            }
        case DELETE_LIST_TO_CREATE_DISPATCHES:

            const driver = payload.driver
            const site = state.sites.find(e => e.id === payload.idSite)

            const drivers = [...site.drivers.filter(e => e.id !== driver.id), driver]
            const haveDispatch = drivers.find(e => e.isSelect) === undefined ? false : true
            const quantityDispatch = drivers.filter(e => e.isSelect).length
            const color = quantityDispatch !== 1 ? "#2e85d2" : drivers.find(e => e.isSelect).color

            const newSites = [...state.sites.filter(e => e.id !== site.id), {
                ...site,
                haveDispatch,
                color,
                drivers: _.orderBy(drivers, 'id', 'asc')
            }]

            let newSitesFilter = state.sitesFilter
            if (state.sitesFilter.find(e => e.id === payload.idSite) !== undefined) {
                newSitesFilter = [...state.sites.filter(e => e.id !== site.id), {
                    ...site,
                    haveDispatch,
                    color,
                    drivers: _.orderBy(drivers, 'id', 'asc')
                }]
            }

            const items = [...state.listItems.filter(e => e.id !== payload.idRow)]
            const itemsFilter = [...state.listItemsFilter.filter(e => e.id !== payload.idRow)]

            return {
                ...state,
                sites: newSites,
                sitesFilter: newSitesFilter,
                listItems: items,
                listItemsFilter: itemsFilter
            }
        default:
            return state
    }
}
