import axios from 'axios'
import { string_general } from '../resources/strings'


export const sw_get_customers_by_event = (params) => {
    const { token, idEvent } = params
    return axios.get(`${string_general.base_URL}auth/customers/list/events/${idEvent}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}


export const sw_get_active_products = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/list/active/products`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_inventory_report = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/inventory/report`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}


