import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash'


// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import Company from '@material-ui/icons/AccountBalanceOutlined';
import Email from '@material-ui/icons/EmailOutlined';
import Phone from '@material-ui/icons/Phone';
import Address from '@material-ui/icons/AddLocationOutlined';
import City from '@material-ui/icons/LocationCityOutlined';
import ZipCode from '@material-ui/icons/RoomOutlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import DropdownSearch from '../../components/DropdownSearch'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import ListSelect from '../../components/ListSelect'
import NotFound from '../../components/ResourceNotFound'
import Alert from '../../components/Alert'
import MultilineInput from '../../components/MultilineInput'

// ACTIONS
import {
  clearState,
  setInputSubcontractorCompany,
  selectDriversToCreate,
  getDataCompany,
  getSubcontractorCompany,
  updateSubcontractorCompany
} from '../../../modules/core/SubcontractorCompany/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { subcontractorStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_subcontractor } from '../../../modules/resources/strings'
import { validateZipCode } from '../../../modules/resources/Util'
import { isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class EditSubcontractorCompany extends React.Component {

  state = {
    showAlert: false
  }

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getDataCompany({
        token: this.props.user.token,
        getCompany: () => this.props.getSubcontractorCompany({
          token: this.props.user.token,
          id: this.props.match.params.id
        })
      })
    }
    this.configMenuItems()
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    this.props.setInputUser({ prop: 'itemsSubmenu', value: [] })
    this.props.setInputUser({ prop: 'value', value: 2 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [{
        link: `/subcontractor_companies`,
        label: 'Subcontractor'
      },
      {
        link: `/subcontractor_companies`,
        label: 'Companies'
      },
      {
        link: `/edit_subcontractor_company/${this.props.match.params.id}`,
        label: 'Update Company'
      }]
    })
  }

  componentWillUnmount() {
    const haveEvent = this.props.arrayEventsSelect.find(e => e.isSelect)
    if (!isEmpty(this.props.companyName) || !isEmpty(this.props.phone) ||
      !isEmpty(this.props.email) || !isEmpty(this.props.address) || !isEmpty(this.props.city)
      || !isEmpty(this.props.zipcode) || !isEmpty(this.props.stateCompany) || haveEvent !== undefined) {
      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push(`/edit_subcontractor_company/${this.props.match.params.id}`)
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      this.props.updatingSubcontractorCompany ?
        <Loading label={this.props.gettingSubcontractorCompany ? string_subcontractor.getting_subcontractor_company : string_subcontractor.updating_subcontractor_company} />
        : this.props.companyFound ?
          <div style={{ ...subcontractorStyles.container, alignItems: 'center' }}>
            <ThemeProvider theme={mainTheme}>
              <span style={subcontractorStyles.title}>{string_subcontractor.title_update_company}</span>

              {/* DESKTOP AND TABLE */}
              <div className={classes.sectionResponsive}>

                {/* SECTION #1 */}
                <div style={subcontractorStyles.containerDivResponsive}>

                  {/* COMPANY NAME */}
                  <Input
                    id="CompanyName"
                    label="Company Name"
                    placeholder="Enter company name"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.companyName}
                    onChange={value => this.props.setInputSubcontractorCompany({ prop: 'companyName', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Company className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* EMAIL */}
                  <Input
                    isRequired
                    id="Email"
                    label="Email"
                    placeholder="Enter company email"
                    type
                    className={classes.margin}
                    value={this.props.email}
                    onChange={value => this.props.setInputSubcontractorCompany({ prop: 'email', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* PHONE COMPANY */}
                  <Input
                    isRequired
                    id="Phone"
                    label="Phone"
                    placeholder="Enter company phone"
                    phone
                    className={classes.margin}
                    value={this.props.phone}
                    onChange={value => this.props.setInputSubcontractorCompany({ prop: 'phone', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* STATUS */}
                  <div style={subcontractorStyles.containerDropdown}>
                    <Dropdown
                      placeholder={string_general.label_dropdown_status}
                      selectItem={this.props.status}
                      options={this.props.arrayStatus}
                      onChange={(value, description) => this.props.setInputSubcontractorCompany({ prop: 'status', value })}
                    />

                    <div style={subcontractorStyles.required}> * </div>
                  </div>


                </div>

                {/* SECTIONS #2 */}
                <div style={subcontractorStyles.containerDivResponsive}>

                  {/* ADDRESS */}
                  <Input
                    id="Address"
                    label="Address"
                    placeholder="Enter company Address"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.address}
                    onChange={value => this.props.setInputSubcontractorCompany({ prop: 'address', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Address className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* CITY */}
                  <Input
                    isRequired
                    id="City"
                    label="City"
                    placeholder="Enter company city"
                    type
                    className={classes.margin}
                    value={this.props.city}
                    onChange={value => this.props.setInputSubcontractorCompany({ prop: 'city', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <City className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* STATE */}
                  <div style={subcontractorStyles.containerDropdownStates}>
                    <DropdownSearch
                      selectItem={this.props.stateCompany}
                      label='States'
                      placeholder={this.props.labelStates}
                      options={this.props.arrayStates}
                      onChange={(value, description) => this.props.setInputSubcontractorCompany({ prop: 'stateCompany', value })}
                    />
                    <div style={subcontractorStyles.required}> * </div>
                  </div>


                  {/* ZIP CODE */}
                  <Input
                    isRequired
                    id="ZipCode"
                    label="Zip Code"
                    placeholder="Enter company zip code"
                    type
                    className={classes.margin}
                    value={this.props.zipcode}
                    onChange={value => {
                      if (validateZipCode(value)) {
                        this.props.setInputSubcontractorCompany({ prop: 'zipcode', value })
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ZipCode className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                    {/* NOTES */}
                 <MultilineInput
                  id="note"
                  label="Notes"
                  type
                  className={classes.margin}
                  value={this.props.notes}
                  onChange={value => this.props.setInputSubcontractorCompany({ prop: 'notes', value: value })}
                  InputProps={{
                    className: classes.input
                  }}
                />


                </div>

                {/* SECTION #3 */}
                <div style={subcontractorStyles.containerDivResponsive}>
                  <ThemeProvider theme={mainTheme}>
                    <span style={{
                      marginBottom: 10,
                      marginTop: 20
                    }}>Events</span>
                    <ListSelect
                      maxHeight={250}
                      items={this.props.arrayEventsSelect}
                      titleLoading={string_subcontractor.getting_events}
                      icon={<i className="tim-icons icon-puzzle-10" />}
                      titleNotfound='Events not found'
                      titleButtonNotfound='Refresh'
                      goToNotFound={() => { }}
                      onChange={(item) => {
                        this.props.selectDriversToCreate({
                          array: this.props.arrayEventsSelect,
                          item
                        })
                      }}
                    />
                  </ThemeProvider>
                  {/* BUTTON */}
                  <div style={subcontractorStyles.containerButton}>
                    <span>{string_general.required_fields}</span>
                    <Button
                      label={string_general.button_update}
                      onPress={() => {
                        if (this.props.status === 2) {
                          this.setState({ showAlert: true })
                        } else {
                          this.props.updateSubcontractorCompany({
                            token: this.props.user.token,
                            id: this.props.match.params.id,
                            companyName: this.props.companyName,
                            phone: this.props.phone,
                            email: this.props.email,
                            status: this.props.status,
                            address: this.props.address,
                            zipcode: this.props.zipcode,
                            notes: this.props.notes,
                            city: this.props.city,
                            state: this.props.stateCompany,
                            events: this.props.arrayEventsSelect,
                            history: this.props.history
                          })
                        }
                      }
                      }
                      width={'100%'}
                      fontSize={15} />
                  </div>

                </div>
              </div>

              {/*  ALERT*/}
              <Alert
                open={this.state.showAlert}
                title={"Inactive subcontractor"}
                textContent={'Do you want inactive this subcontractor?'}
                onOk={() => {
                  this.setState({ showAlert: false })

                  this.props.updateSubcontractorCompany({
                    token: this.props.user.token,
                    id: this.props.match.params.id,
                    companyName: this.props.companyName,
                    phone: this.props.phone,
                    email: this.props.email,
                    status: this.props.status,
                    address: this.props.address,
                    zipcode: this.props.zipcode,
                    notes: this.props.notes,
                    city: this.props.city,
                    state: this.props.stateCompany,
                    events: this.props.arrayEventsSelect,
                    history: this.props.history
                  })
                }}
                onCancel={() => { this.setState({ showAlert: false }) }} />


            </ThemeProvider>

          </div>
          : <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <NotFound
              titleInfo='Company not found'
              titleButton="Go to list companies"
              goTo={() => this.props.history.push('/subcontractor_companies')}
            />
          </div>
    )
  }
}

EditSubcontractorCompany.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateProps = (state) => {
  const { isAuthenticated, user, isEdited } = state.user
  const {
    gettingSubcontractorCompany,
    updatingSubcontractorCompany,
    columns,
    listItems,
    listItemsFilter,
    companyName,
    phone,
    email,
    status,
    address,
    city,
    zipcode,
    notes,
    stateCompany,
    arrayStates,
    arrayEventsSelect,
    arrayStatus,
    companyFound
  } = state.company
  return {
    isAuthenticated, user, isEdited,
    gettingSubcontractorCompany,
    updatingSubcontractorCompany,
    columns,
    listItems,
    listItemsFilter,
    companyName,
    phone,
    email,
    status,
    address,
    city,
    zipcode,
    notes,
    stateCompany,
    arrayStates,
    arrayEventsSelect: _.orderBy(arrayEventsSelect, 'description', 'asc'),
    arrayStatus,
    companyFound
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  setInputSubcontractorCompany,
  selectDriversToCreate,
  getDataCompany,
  getSubcontractorCompany,
  updateSubcontractorCompany,
  setInputUser
})(EditSubcontractorCompany)))