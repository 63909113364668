import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

import { superAdminStyles } from '../../../modules/resources/styles'

import { clearState, getUserAdmins, filterByStatsAdmin } from '../../../modules/core/Admin/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Table from '../../components/Table'

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListAdmins extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    statusSelected: 1
  }

  componentDidMount() {
    this.props.getUserAdmins({
      token: this.props.user.token,
      success: () => this.setState({ loading: false }),
      showError: (error) => { this.setState({ loading: false }) },
      history: this.props.history
    })

    this.configMenuItems()
    window.scrollTo(0, 0);
  }

  configMenuItems() {

    if (this.props.userType === 2) {
      this.props.setInputUser({
        prop: 'itemsSubmenu', value: [
          {
            id: 1,
            label: 'SFS Admins',
            icon: 'tim-icons icon-book-bookmark',
            link: '/admins'
          }
        ]
      })
      this.props.setInputUser({ prop: 'itemSelect', value: 1 })
    } else {
      this.props.setInputUser({
        prop: 'itemsSubmenu', value: [
          {
            id: 1,
            label: 'Super SFS Admins',
            icon: 'tim-icons icon-book-bookmark',
            link: '/super_admins'
          },
          {
            id: 2,
            label: 'SFS Admins',
            icon: 'tim-icons icon-book-bookmark',
            link: '/admins'
          }
        ]
      })
      this.props.setInputUser({ prop: 'itemSelect', value: 2 })
    }

    this.props.setInputUser({ prop: 'value', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [{
        link: `/admins`,
        label: 'Users'
      },
      {
        link: `/admins`,
        label: 'SFS Admins'
      }]
    })
  }

  render() {
    return (
      <div style={superAdminStyles.container}>
        <div style={superAdminStyles.containerFilters}>
          <div style={superAdminStyles.containerDivfilters}>
            <div style={superAdminStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_status} </label>
              <Dropdown
                selectItem={this.state.statusSelected}
                placeholder={string_general.placeholder_select_status}
                options={this.props.listStatus}
                onChange={(value, description) => {
                  this.setState({ statusSelected: value })
                  this.props.filterByStatsAdmin({
                    listItems: this.props.listItems,
                    status: description
                  })
                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }
                } />
            </div>
          </div>

          <div style={superAdminStyles.containerButtonCreate}>
            <Button
              onPress={() => { this.props.history.push("/create_admin") }}
              width={'100%'}
              label={string_general.label_button_add}
              fontSize={12}
            />
          </div>

        </div>
        <Table
          numberPages={this.state.numberPages}
          loading={this.state.loading}
          columns={this.props.columns}
          items={this.props.listItemsFilter} />
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user } = state.user
  const { columns, listItems, listItemsFilter, listStatus } = state.admin
  return { isAuthenticated, user, userType: user.user.type.id, columns, listItems, listItemsFilter, listStatus }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getUserAdmins,
  filterByStatsAdmin,
  setInputUser
})(ListAdmins)))

