import {
    SET_AUTHENTICATION,
    SET_ITEM_USER,
    LOGOUT,
    CHANGE_PASSWORD_SUCCESS
} from './types'

const initialState = {
    isAuthenticated: false,
    user: {},
    itemsMenu: [],
    itemsSubmenu: [],
    siteNavigations: [],
    value: 0,
    itemSelect: 1,
    labelValue: 'Home',
    changing_password: false,
    changePassword: false,
    infoLabel: '',
    idCustomerSelected: 0,
    isEdited: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LOGOUT:
            return {
                ...initialState
            }
        case SET_AUTHENTICATION:
            return {
                ...state,
                isAuthenticated: true,
                user: payload.user,
                itemsMenu: payload.itemsMenu,
                itemsSubmenu: payload.itemsSubmenu,
                value: 0,
                labelValue: 'Home'
            }
        case SET_ITEM_USER:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassword: true,
                changing_password: false
            }
        default:
            return state
    }
}
