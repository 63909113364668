import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_create_subcontractor_company = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/subcontractor_company`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}



export const sw_update_subcontractor_company = (params) => {
    const { token , id} = params
    return axios.put(`${string_general.base_URL}auth/subcontractor_company/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_companies = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/subcontractor_company`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_company = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/subcontractor_company/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_events = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/events`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_states = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/states`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_status = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/status_subcontractor_companies`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}


export const sw_get_super_sfs_admin = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/users/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_super_sfs_admin = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/user/sfs/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}