import {
    LOAD_SUPER_SFS_ADMINS,
    SET_INPUT_SUPER_ADMIN,
    CLEAR_STATE_SUPER_ADMINS,
    GETTING_SUPER_SFS_ADMINS,
    GOT_SUPER_SFS_ADMIN,
    ERROR_GETTING_SUPER_SFS_ADMIN
} from './types'

const initialState = {
    creatingSuperAdmin: false,
    updatingSuperAdmin: false,
    gettingSuperAdmin: false,
    adminFound: true,
    columns: [],
    listItems: [],
    listItemsFilter: [],
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    listStatus: [],
    password: '',
    confirmPassword: ''
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_SUPER_ADMINS:
            return {
                ...initialState
            }
        case LOAD_SUPER_SFS_ADMINS:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter,
                listStatus: payload.listStatus
            }
        case SET_INPUT_SUPER_ADMIN:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GETTING_SUPER_SFS_ADMINS:
            return {
                ...state,
                updatingSuperAdmin: true,
                gettingSuperAdmin: true,
            }
        case GOT_SUPER_SFS_ADMIN:
            return {
                ...state,
                adminFound: true,
                updatingSuperAdmin: false,
                gettingSuperAdmin: false,
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                status: payload.status,
                password: payload.password,
                confirmPassword: payload.confirmPassword,
                listStatus: payload.listStatus
            }
        case ERROR_GETTING_SUPER_SFS_ADMIN:
            return {
                ...state,
                adminFound: false,
                updatingSuperAdmin: false,
                gettingSuperAdmin: false,
                firstName: '',
                lastName: '',
                email: '',
                status: '',
                password: '',
                confirmPassword: ''
            }
        default:
            return state
    }
}
