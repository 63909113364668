import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

import { deliveryStyles } from '../../../modules/resources/styles'
import { mainTheme, datePickerTheme } from '../../../modules/resources/themes'
import { getInitialData, setInputTransfer, addProduct, deleteProduct, clearState, createTransfer, setOtherSupplierDescription } from '../../../modules/core/Transfer/actions'
import { setInputUser } from '../../../modules/core/User/actions'
import { colors } from '../../../modules/resources/colors'
import { string_general, string_delivery, string_product, string_dispatch, string_transfer } from '../../../modules/resources/strings'
import Dropdown from '../../components/Dropdown'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import MultilineInput from '../../components/MultilineInput'
import DatePicker from '../../components/DatePicker'
import Button from '../../components/Button'
import ListManage from '../../components/ListManage';

//MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';


//COMPONENTS
import Modal from '../../components/Modal'

//ICONS
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import RoomIcon from '@material-ui/icons/Room';





const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  },
  fab: {
    width: 40,
    height: 20,
    margin: 10,
    background: colors.blue_sfs
  }

});



class CreateTransfer extends React.Component {

  state = {
    supplierModal: false

  }

  componentDidMount() {

    this.props.getInitialData({ token: this.props.user.token, idEvent: this.props.match.params.event })
    this.configMenuItems()
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    const idEvent = this.props.match.params.event
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [{
        id: 1,
        label: 'Trucks',
        icon: 'tim-icons icon-delivery-fast',
        link: `/event/${idEvent}/trucks`
      },
      {
        id: 2,
        label: 'Drivers',
        icon: 'tim-icons icon-badge',
        link: `/event/${idEvent}/drivers`
      },
      {
        id: 3,
        label: 'Helpers',
        icon: 'tim-icons icon-single-02',
        link: `/event/${idEvent}/helpers`
      },
      {
        id: 4,
        label: 'Dispatch',
        icon: 'tim-icons icon-vector',
        link: `/event/${idEvent}/dispatches`
      },
      {
        id: 5,
        label: 'Deliveries',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/deliveries`

      },
      {
        id: 6,
        label: 'Transfers',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/create_transfer`

      },

      {
        id: 7,
        label: 'Tracking',
        icon: 'tim-icons icon-world',
        link: `/event/${idEvent}/tracking`
      },
      {
        id: 8,
        label: 'Report',
        icon: 'tim-icons icon-notes',
        link: `/event/${idEvent}/reports`
      }]
    })
    this.props.setInputUser({ prop: 'value', value: 6 })
    this.props.setInputUser({ prop: 'itemSelect', value: 6 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/events`,
          label: 'Events'
        },
        {
          link: `/events`,
          label: this.props.infoLabel
        },
        {
          link: `/event/${this.props.match.params.event}/deliveries`,
          label: 'Deliveries'
        },
        {
          link: `/event/${this.props.match.params.event}/deliveries/edit_delivery/${this.props.match.params.id}/${this.props.match.params.isDelivery}`,
          label: 'Deliveries'
        }]
    })
  }

  componentWillUnmount() {
    if (this.props.deliveryProducts.length !== 0) {
      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.history.push(`/event/${this.props.match.params.event}/create_transfer`)
      } else {
        this.props.clearState()
      }
    }
  }



  render() {
    const { classes } = this.props;
    return (
      this.props.creatingTransfer ?
        <Loading label={string_transfer.creating_tranfer} />
        :
        <div style={{ ...deliveryStyles.container, alignItems: 'center' }}>

          {/* TITLE */}
          <span style={deliveryStyles.title}>{string_dispatch.create_transfer}</span>
          <ThemeProvider theme={mainTheme}>
            <div className={classes.sectionResponsive}>

              <div style={deliveryStyles.containerDivResponsive}>

                {/* TYPE */}

                <div style={deliveryStyles.containerDropdown}>

                  <Dropdown
                    selectItem={this.props.idType}
                    placeholder={string_transfer.label_type_transfer}
                    options={this.props.types}
                    onChange={(value, description) => {
                      this.props.setInputTransfer({ prop: 'idType', value })
                    }}
                  />

                </div>

                <div style={deliveryStyles.containerDropdown}>

                  <Dropdown
                    selectItem={this.props.idUser}
                    placeholder={string_transfer.label_driver}
                    options={this.props.userDrivers}
                    onChange={(value, description) => {
                      this.props.setInputTransfer({ prop: 'idUser', value })
                    }}
                  />
                  {console.log("DATA PROPS *************", this.props)}
                  {/* TODO: DELETE LOG */}


                </div>

                <div style={deliveryStyles.containerDropdown}>

                  <Dropdown
                    selectItem={this.props.idDriver}
                    placeholder={string_transfer.label_supplier}
                    options={this.props.drivers}
                    onChange={(value, description) => {
                      this.props.setInputTransfer({ prop: 'idDriver', value })
                      if (value == -1) {
                        this.setState({ supplierModal: true })
                      } else {
                        this.props.setOtherSupplierDescription({ value: 'Other supplier', suppliers: this.props.drivers })
                      }
                    }}
                  />

                </div>

                {/* SITE INPUT */}
                <Input
                  id="site"
                  label={string_delivery.label_site}
                  placeholder={string_delivery.label_site}
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.site}
                  onChange={value => this.props.setInputTransfer({ prop: 'site', value: value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RoomIcon className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* STATES DROPDOWN */}
                <div style={deliveryStyles.containerDropdown}>

                  <Dropdown
                    selectItem={this.props.idState}
                    placeholder={string_transfer.label_state}
                    options={this.props.states}
                    onChange={(value, description) => {
                      this.props.setInputTransfer({ prop: 'idState', value })
                    }}
                  />                  
                  <div style={deliveryStyles.required}> * </div>
                </div>

                {/* NOTES */}
                <MultilineInput
                  id="productName"
                  label={string_delivery.label_notes}
                  type
                  className={classes.margin}
                  value={this.props.note}
                  onChange={value => this.props.setInputTransfer({ prop: 'note', value: value })}
                  InputProps={{
                    className: classes.input
                  }}
                />

              </div>

              <div style={deliveryStyles.containerDivResponsive}>




                {/* PRODUCT */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 30
                }}>
                  <div style={{ flexDirection: 'column', width: '100%' }}>
                    <div style={deliveryStyles.containerDropdown}>
                      <Dropdown
                        selectItem={this.props.idProduct}
                        placeholder={string_delivery.label_product}
                        options={this.props.productsTransfer}
                        onChange={(value, description) => {
                          this.props.setInputTransfer({ prop: 'idProduct', value: value })
                          this.props.setInputTransfer({ prop: 'productName', value: description })
                        }} />
                      <div style={deliveryStyles.required}> * </div>
                    </div>
                    {/* GALLONS */}

                    <Input
                      id="gallons"
                      label={string_delivery.label_gallons}
                      placeholder={string_delivery.label_place_holder_gallons}
                      isRequired
                      type
                      className={classes.margin}
                      value={this.props.gallons}
                      onChange={value => this.props.setInputTransfer({ prop: 'gallons', value: value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalGasStationIcon className={classes.margin} color="secondary" />
                          </InputAdornment>
                        ),
                        className: classes.input
                      }}
                    />
                  </div>
                  <Fab color="primary" aria-label="add" className={classes.fab}
                    onClick={() => this.props.addProduct({ idProduct: this.props.idProduct, productName: this.props.productName, gallons: this.props.gallons, deliveryProducts: this.props.deliveryProducts })}>
                    <AddIcon />
                  </Fab>
                </div>

                <ListManage
                  items={this.props.deliveryProducts}
                  maxHeight={200}
                  noEdit
                  onPressDelete={(item) => this.props.deleteProduct({ deliveryProducts: this.props.deliveryProducts, item: item })}
                />
                <div style={deliveryStyles.containerButton}>
                  <span>{string_general.required_fields}</span>
                  <Button
                    label={string_general.button_create}
                    onPress={() =>
                      this.props.createTransfer({
                        token: this.props.user.token,
                        idEvent: this.props.match.params.event,
                        idType: this.props.idType,
                        site: this.props.site,
                        idUser: this.props.idUser,
                        idDriver: this.props.idDriver,
                        listProducts: this.props.deliveryProducts,
                        note: this.props.note,
                        other: this.props.other,
                        history: this.props.history,
                        idState: this.props.idState
                      })}

                    width={'100%'}
                    fontSize={15} />
                </div>

              </div>

            </div>


            <Modal
              open={this.state.supplierModal}
              maxWidth={'md'}
              close={() => {
                this.setState({ supplierModal: false })
                this.props.setOtherSupplierDescription({ value: 'Other supplier', suppliers: this.props.drivers })
              }}
              buttonTitle={'Save'}
              title={'Other Supplier'}
              onPressAction={() => {
                this.setState({ supplierModal: false })
                this.props.setOtherSupplierDescription({ value: this.props.other, suppliers: this.props.drivers })
              }}
            >
              <Input
                id="Other Supplier"
                label={string_transfer.label_other_supplier}
                placeholder={string_transfer.label_other_supplier}
                type
                className={classes.margin}
                value={this.props.other}
                onChange={value => this.props.setInputTransfer({ prop: 'other', value: value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RoomIcon className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input
                }}
              />
            </Modal>
          </ThemeProvider>
        </div>

    )
  }
}



const mapStateProps = (state) => {
  console.log("STATE PROPS ***********", state) //TODO DEL
  const { isAuthenticated, user, isEdited, infoLabel } = state.user
  const { types, idType, drivers, productsTransfer, site, idDriver, idProduct, deliveryProducts,
    note, gallons, productName, userDrivers, idUser, creatingTransfer, other, states, idState } = state.transfer
  return {
    isAuthenticated, user, isEdited, infoLabel,
    types, drivers, productsTransfer, idType, site,
    idDriver, idProduct, deliveryProducts, note, gallons,
    productName, userDrivers, idUser, creatingTransfer, other,
    states, idState
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  addProduct,
  deleteProduct,
  setInputUser,
  getInitialData,
  setInputTransfer,
  clearState,
  createTransfer,
  setOtherSupplierDescription,
})(CreateTransfer)))

