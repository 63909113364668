export const LOAD_SUBCONTRACTOR_TRUCKS = 'LOAD_SUBCONTRACTOR_TRUCKS'
export const SET_INPUT_SUBCONTRACTOR_TRUCK = 'SET_INPUT_SUBCONTRACTOR_TRUCK'
export const CLEAR_STATE_SUBCONTRACTOR_TRUCK = 'CLEAR_STATE_SUBCONTRACTOR_TRUCK'
export const GETTING_SUBCONTRACTOR_TRUCK = 'GETTING_SUBCONTRACTOR_TRUCK'
export const GOT_SUBCONTRACTOR_TRUCK = 'GOT_SUBCONTRACTOR_TRUCK'
export const LOADING_DATA_CREATE_SUBCONTRACTOR_TRUCK = 'LOADING_DATA_CREATE_SUBCONTRACTOR_TRUCK'
export const LOAD_DATA_CREATE_SUBCONTRACTOR_TRUCK = 'LOAD_DATA_CREATE_SUBCONTRACTOR_TRUCK'
export const ERROR_GETTING_SUBCONTRACTOR_HELPER = 'ERROR_GETTING_SUBCONTRACTOR_HELPER'
export const UPDATE_LIST_DRIVERS_EDIT_TRUCK = 'UPDATE_LIST_DRIVERS_EDIT_TRUCK'
export const UPDATE_LIST_ASSISTANT_EDIT_TRUCK = 'UPDATE_LIST_ASSISTANT_EDIT_TRUCK'
export const UPDATE_LIST_DRIVERS_HELPERS_CREATE_TRUCK = 'UPDATE_LIST_DRIVERS_HELPERS_CREATE_TRUCK'