import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import { FilePicker } from 'react-file-picker'
import LinearProgress from '@material-ui/core/LinearProgress';
import { ThemeProvider } from '@material-ui/styles';

import ReactExport from "react-data-export";

import { sitesStyles } from '../../../modules/resources/styles'
import { clearState, getSites, filterByStatusSite, importSites } from '../../../modules/core/Site/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Table from '../../components/Table'
import { mainTheme } from '../../../modules/resources/themes'
import { showAlert } from '../../../modules/resources/Util'
import Modal from '../../components/Modal'
import Alert from '../../components/Alert'

import {
  closeImportSitesErrorModal
} from '../../../modules/core/Dispatch/actions'


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Function to download the report bad import sites report
 * @param {*} errorReport Error message got it from the API Request
 */
const downloadFileReport = (errorReport) => {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(errorReport));
  element.setAttribute('download', "Report-Import-Sites-Failed.txt");

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListSites extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    statusSelected: 1,
    labelStatus: 'Active',
    importing: false,
    dataExport: [],
    exporting: false
  }

  componentDidMount() {
    this.props.getSites({
      token: this.props.user.token,
      id: this.props.match.params.customer,
      success: () => this.setState({ loading: false }),
      showError: (error) => { this.setState({ loading: false }) },
      history: this.props.history
    })

    this.configMenuItems()
    window.scrollTo(0, 0);
  }

  exportEmptys = (emptys) => {
    if (emptys.length > 0) {

      let rows = [
        [
          {
            value: "Name",
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: "2e85d2"
                }
              },
              font: {
                sz: "18",
                bold: true,
                color: { rgb: "FFFFFFFF" }
              }
            }
          },
          {
            value: "Address",
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: "2e85d2"
                }
              },
              font: {
                sz: "18",
                bold: true,
                color: { rgb: "FFFFFFFF" }
              }
            }
          },
        ]
      ]

      emptys.forEach(element => {
        rows.push([
          {
            value: element.site.siteName,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.site.address,
            style: {
              font: {
                sz: "15",
              }
            }
          },
        ])

      });

      const dataExport = [
        {
          ySteps: 1, //will put space of 5 rows,
          columns: [
            { title: "", width: { wpx: 200 } },
            { title: "", width: { wpx: 200 } },
          ],
          data: rows
        }
      ]


      this.setState({ dataExport }, () => {
        this.setState({ exporting: true }, () => {
          this.setState({ exporting: false })
        })
      })
    }
  }

  configMenuItems() {
    const idCustomer = this.props.match.params.customer
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [{
        id: 1,
        label: 'Sites',
        icon: 'tim-icons icon-square-pin',
        link: `/customer/${idCustomer}/sites`
      }]
    })
    this.props.setInputUser({ prop: 'value', value: 3 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/customers`,
          label: 'Customers'
        },
        {
          link: `/customers`,
          label: this.props.infoLabel
        },
        {
          link: `/customer/${this.props.match.params.customer}/sites`,
          label: 'Sites'
        }]
    })
  }

  render() {
    return (
      <div style={sitesStyles.container}>
        <div style={sitesStyles.containerFilters}>
          {(this.state.exporting) ?
            <ExcelFile hideElement={true} filename={'Sites Without Gps Data'} >
              <ExcelSheet dataSet={this.state.dataExport} name="Organization" />
            </ExcelFile>
            : null}


          <div style={sitesStyles.containerDivfilters}>
            <div style={sitesStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_status} </label>
              <Dropdown
                selectItem={this.state.statusSelected}
                placeholder={string_general.placeholder_select_status}
                options={[
                  { value: 0, description: 'All' },
                  { value: 1, description: 'Active' },
                  { value: 2, description: 'Inactive' }]}
                onChange={(value, description) => {
                  this.setState({ statusSelected: value })
                  this.props.filterByStatusSite({
                    listItems: this.props.listItems,
                    status: description
                  })
                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }
                } />
            </div>
          </div>

          <div style={sitesStyles.containerButtonCreate}>

            <FilePicker
              extensions={['xlsx']}
              onChange={FileObject => {
                this.props.importSites({
                  token: this.props.user.token,
                  fileTemp: FileObject,
                  idCustomer: this.props.match.params.customer,
                  status: this.state.labelStatus,
                  setImporting: (importing) => this.setState({ importing }),
                  history: this.props.history,
                  exporting: (emptys) => this.exportEmptys(emptys),
                })
              }}
              onError={errMsg => {
                showAlert({
                  msj: errMsg,
                  type: 3
                })
              }}
            >
              <button style={sitesStyles.buttonImport}>
                {string_general.button_import}
              </button>
            </FilePicker>

            <Button
              onPress={() => { this.props.history.push(`/customer/${this.props.match.params.customer}/create_site`) }}
              width={'100%'}
              label={string_general.label_button_add}
              fontSize={12}
            />
          </div>

        </div>
        {this.state.importing && <ThemeProvider theme={mainTheme}>
          <div style={{ zIndex: 100 }}><LinearProgress color="primary" /></div>
        </ThemeProvider>}

        <Table
          numberPages={this.state.numberPages}
          loading={this.state.loading}
          columns={this.props.columns}
          items={this.props.listItemsFilter} />

        {/* MODAL: IMPORTED SITES FAILED LIST*/}
        <ThemeProvider theme={mainTheme}>
          <Modal
            maxWidth='lg'
            open={this.props.importSitesFailed}
            close={() => this.props.closeImportSitesErrorModal()}
            title="Import sites failed"
            onPressAction={() => {
              downloadFileReport(this.props.sitesImportedError);
              this.props.closeImportSitesErrorModal();
            }}
            buttonTitle='Download report and close'
          >
            {/** HERE THE TABLE SCREEN */}

            <Table
              hidePaginationTop={true}
              numberPages={this.props.sitesRepeated.length}
              loading={false}
              columns={this.props.sitesRepeatedCols}
              items={this.props.sitesRepeated} />

          </Modal>

          <Alert
            open={this.state.showAlert}
            title={this.state.titleAlert}
            textContent={this.state.textContentAlert}
            onOk={() => this.state.onOkAlert()}
            onCancel={() => { this.setState({ showAlert: false }) }} />
        </ThemeProvider>
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel } = state.user
  const { columns, listItems, listItemsFilter } = state.site
  const { importSitesFailed, sitesImportedError,
    sitesRepeated, sitesRepeatedCols } = state.dispatch;
  return {
    isAuthenticated, user, infoLabel, columns, listItems, listItemsFilter,
    importSitesFailed, sitesImportedError,
    sitesRepeated, sitesRepeatedCols
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getSites,
  filterByStatusSite,
  importSites,
  setInputUser,
  closeImportSitesErrorModal
})(ListSites)))

