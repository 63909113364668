import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import ProductIcon from '@material-ui/icons/LocalGasStation';
import { colors } from '../../modules/resources/colors'


export default function ListManage(props) {


    const useStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            width: '100%',
            height: props.maxHeight,
            overflowY: 'scroll',
            border: `5px solid ${colors.bg_input}`,
            borderRadius: 10,
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center'
        },
        contentList: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
        },
    }));
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.contentList}>
                {
                    props.items.map((item, index) => {
                        return (
                            <ListItem
                                key={index}>
                                <ListItemAvatar>
                                    <ProductIcon />
                                </ListItemAvatar>
                                <ListItemText primary={`${item.description} - Gallons: ${item.gallons}`} />
                                {!props.noEdit &&
                                    <ListItemAvatar>
                                        <IconButton edge="end" aria-label="update" onClick={() => props.onPressUpdate(item)}>
                                            <UpdateIcon />
                                        </IconButton>
                                    </ListItemAvatar>
                                }
                                <ListItemAvatar>
                                    <IconButton edge="end" aria-label="delete" onClick={() => props.onPressDelete(item)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemAvatar>
                            </ListItem>
                        )
                    })
                }
            </div>
        </div>
    );
}
