import {
    LOAD_SUPER_ADMINS,
    SET_INPUT_ADMIN,
    CLEAR_STATE_ADMIN,
    GETTING_ADMIN,
    GOT_ADMIN,
    ERROR_GETTING_ADMIN
} from './types'

const initialState = {
    creatingAdmin: false,
    updatingAdmin: false,
    gettingAdmin: false,
    adminFound: true,
    columns: [],
    listItems: [],
    listItemsFilter: [],
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    password: '',
    confirmPassword: '',
    listStatus: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_ADMIN:
            return {
                ...initialState
            }
        case LOAD_SUPER_ADMINS:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter,
                listStatus: payload.listStatus
            }
        case SET_INPUT_ADMIN:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GETTING_ADMIN:
            return {
                ...state,
                updatingAdmin: true,
                gettingAdmin: true,
            }
        case GOT_ADMIN:
            return {
                ...state,
                adminFound: true,
                updatingAdmin: false,
                gettingAdmin: false,
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                status: payload.status,
                password: payload.password,
                confirmPassword: payload.confirmPassword,
                listStatus: payload.listStatus
            }
        case ERROR_GETTING_ADMIN:
            return {
                ...state,
                adminFound: false,
                updatingAdmin: false,
                gettingAdmin: false,
                firstName: '',
                lastName: '',
                email: '',
                status: '',
                password: '',
                confirmPassword: ''
            }
        default:
            return state
    }
}
