import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';

import { eventStyles } from '../../../modules/resources/styles'
import { getEvents, filterByStatus } from '../../../modules/core/Event/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Table from '../../components/Table'
import Modal from '../../components/Modal'

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListEvents extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    statusSelected: 1,
    showModal: false,
    titleModal: '',
    list: []
  }

  componentDidMount() {
    this.props.getEvents({
      token: this.props.user.token,
      success: () => this.setState({ loading: false }),
      showSubList: (title, list) => this.setState({ showModal: true, titleModal: title, list }),
      showError: (error) => { this.setState({ loading: false }) },
      company: this.props.user.user.subcontractor !== undefined ? this.props.user.user.subcontractor.company.id : null,
      userType: this.props.userType,
      history: this.props.history
    })

    this.configMenuItems()
    window.scrollTo(0, 0);
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'itemsSubmenu', value: [] })

    if (this.props.userType !== 3) {
      this.props.setInputUser({ prop: 'value', value: 4 })
    } else {
      this.props.setInputUser({ prop: 'value', value: 1 })
    }


    this.props.setInputUser({ prop: 'itemSelect', value: 1 })
    this.props.setInputUser({
      prop: 'infoLabel',
      value: ''
    })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [{
        link: `/events`,
        label: 'Events'
      }]
    })
  }

  render() {
    return (
      <div style={eventStyles.container}>
        <div style={eventStyles.containerFilters}>
          <div style={eventStyles.containerDivfilters}>
            <div style={eventStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_status} </label>
              <Dropdown
                selectItem={this.state.statusSelected}
                placeholder={string_general.placeholder_select_status}
                options={[
                  { value: 0, description: 'All' },
                  { value: 1, description: 'Active' },
                  { value: 2, description: 'Inactive' }]}
                onChange={(value, description) => {
                  this.setState({ statusSelected: value })
                  this.props.filterByStatus({
                    listItems: this.props.listItems,
                    status: description
                  })
                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }
                } />
            </div>
          </div>
          {this.props.userType !== 3 ?
            <div style={eventStyles.containerButtonCreate}>
              <Button
                onPress={() => { this.props.history.push("/create_event") }}
                width={'100%'}
                label={string_general.label_button_add}
                fontSize={12}
              />
            </div> : null}

        </div>

        <Table
          numberPages={this.state.numberPages}
          loading={this.state.loading}
          columns={this.props.columns}
          items={this.props.listItemsFilter} />


        {/* MODAL */}
        <ThemeProvider theme={mainTheme}>
          <Modal
            maxWidth='sm'
            open={this.state.showModal}
            close={() => this.setState({ showModal: false })}
            title={this.state.titleModal}
            onPressAction={() => this.setState({ showModal: false })}
            buttonTitle='Ok'
          >
            <div style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              {this.state.list.map(option => (
                <span>{option}</span>
              ))}
            </div>
          </Modal>
        </ThemeProvider>
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user } = state.user
  const { columns, listItems, listItemsFilter } = state.event
  return { isAuthenticated, user, userType: user.user.type.id, columns, listItems, listItemsFilter }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  getEvents,
  filterByStatus,
  setInputUser
})(ListEvents)))

