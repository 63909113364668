/*global google*/
import React from 'react';
import { compose, withProps, withStateHandlers } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

import User from '@material-ui/icons/PermIdentityOutlined';

import { marker_1, marker_2, marker_3 } from '../../../modules/resources/images'
import ListSelect from '../../components/ListSelect'
import { string_general } from '../../../modules/resources/strings';
import { getIconDispatch } from '../../../modules/resources/Util'

const MapContainer = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${string_general.api_key}&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `500px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)(props =>


    <GoogleMap
        options={{scrollwheel:true}}
        defaultZoom={string_general.zoom}
        onBoundsChanged={() => {}}
        defaultCenter={new google.maps.LatLng(string_general.latitude, string_general.longitude)}>
        {props.places.map(place =>  (
            <Marker
                key={`marker-${place.id}`}
                icon={{
                    url: (props.placesTaken.find(x => x.siteId === place.id) === undefined) ? getIconDispatch(`${place.color}`) : (!place.isNew ? getIconDispatch("#868181") : getIconDispatch("#BA2F30")),
                    scaledSize: new google.maps.Size(40, 40)
                }}
                title={`${place.name} / ${place.customerName}`}
                name={place.name}
                position={place.position}
                onClick={() => props.onChangeHoverKey(place.id)}
            // onMouseOver={() => props.onChangeHoverKey(place.id)}
            >
                {props.hoverKey === place.id &&
                    <InfoWindow
                        key={`infowindow-${place.name}`}
                        onCloseClick={() => props.onChangeHoverKey(0)}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 350,
                            backgroundColor: 'white',
                            alignItems: 'center'
                        }}>
                            <span style={{
                                marginBottom: 10
                            }}>{`${place.name} / ${place.customerName}`}</span>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                height: '80%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'white'
                            }}>
                                {place.drivers.length === 0 ? <span>There are no drivers to assign</span> :
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        width: '100%',
                                       
                                    }}>
                                        <ListSelect
                                            titleLoading='Searching drivers'
                                            maxHeight={250}
                                            icon={<User />}
                                            items={place.drivers}
                                            onChange={(item) => { props.onSelectDriver(place.id, item) }}
                                            goToNotFound={() => { }}
                                            titleNotfound='Drivers not found'
                                            titleButtonNotfound='Drivers not found'
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </InfoWindow>
                }
            </Marker>
        ))}
    </GoogleMap>
);



export default MapContainer;
