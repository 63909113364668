import React from 'react'
import { not_found } from '../../modules/resources/images'
import Button from './Button'

export default function ResourceNotFound(props) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            textAlign: 'center',
            marginTop: props.isList ? 0 : 60
        }}>
            <div>
                <img src={not_found} style={{
                    width: '50%',
                    height: '50',
                    textAlign: 'center'
                }} />
            </div>
            <span>{props.titleInfo}</span>
            <Button
                label={props.titleButton}
                onPress={props.goTo}
                width={'50%'}
                fontSize={12} />
        </div>)
}