import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_create_customer = (params) => {
    const { token } = params

    return axios.post(`${string_general.base_URL}auth/customers`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_customer = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/customers/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_customers = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/customers`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_customer = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/customers/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_drivers = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/user/empty/drivers`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractors = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/subcontractor_company`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_truck_type = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/truck_types`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_events = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/events`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_states = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/states`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_status = async (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/status_subcontractor_companies`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res
        }).catch((error) => {
            return error.response;
        })
}
