import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_login = (params) => {
    return axios.post(`${string_general.base_URL}auth/login`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error;
        })
}

export const sw_recovery_password = (params) => {
    return axios.post(`${string_general.base_URL}auth/reset`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error;
        })
}

export const sw_logout = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/logout?token=${token}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error;
        })
}

export const sw_change_password = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/changePassword`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error;
        })
}







