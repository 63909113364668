'use strict'

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker, SwatchesPicker } from 'react-color'
import IconButton from '@material-ui/core/IconButton';
import { colors } from '../../modules/resources/colors'

class ChooseColor extends React.Component {
    state = {
        displayColorPicker: false,
        color: this.props.color
    };

    componentWillUpdate() {
        if (this.props.color !== this.state.color) {
            this.setState({ color: this.props.color })
        }
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.hex })
        this.props.selectColor(color.hex)
    };

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px'
                },
            },
        });

        return (
            <div style={{ zIndex: 20 }}>
                <span>Select Color</span>

                <IconButton aria-label="Toggle password visibility" onClick={this.handleClick}
                >
                    <i className={this.props.icon} style={{
                        color: this.props.color,
                        fontSize: 40,
                        padding: 5,
                        border: `1px solid ${this.props.color}`,
                        borderRadius: 10,
                        backgroundColor: this.props.color
                    }} />
                </IconButton>

                <span>{this.props.isRequired ? '*' : ''}</span>
                {
                    this.state.displayColorPicker ? <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        {
                            this.props.isDriver ? <SwatchesPicker color={this.props.color} onChange={this.handleChange} />
                            : <SketchPicker color={this.props.color} onChange={this.handleChange} />
                        }
                        
                    </div> : null
                }
            </div>
        )
    }
}

export default ChooseColor