import React from "react";
import _ from 'lodash'

import {
    CLEAR_STATE_SUBCONTRACTOR_HELPER,
    LOAD_SUBCONTRACTOR_HELPERS,
    SET_INPUT_SUBCONTRACTOR_HELPER,
    GETTING_SUBCONTRACTOR_HELPER,
    GOT_SUBCONTRACTOR_HELPER,
    LOAD_DATA_CREATE_SUBCONTRACTOR_HELPER,
    LOADING_DATA_CREATE_SUBCONTRACTOR_HELPER,
    ERROR_GETTING_SUBCONTRACTOR_HELPER,
    UPDATE_LIST_TRUCK_EDIT_HELPER
} from './types'

import {
    sw_get_subcontractor_helpers,
    sw_create_subcontractor_helper,
    sw_get_subcontractor_helper,
    sw_update_subcontractor_helper,
    sw_get_events,
    sw_get_trucks,
    sw_get_subcontractor_companies,
    sw_get_subcontractor_helpers_from_event,
    sw_get_helper_lastname,
    sw_get_status
} from '../../services/SubcontractorHelperApi'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { validateEmail, showAlert, isEmpty, validatePassword } from '../../resources/Util'

export const clearState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE_SUBCONTRACTOR_HELPER,
        })
    }
}

export const createSubcontractorHelper = (info) => {

    const {
        token, company,
        firstName, lastName, email, phone,
        idEvent, idTruck, isFromEvent, userType, history } = info

    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(firstName) || isEmpty(lastName) || isEmpty(email) || isEmpty(cleanPhone) || cleanPhone.length !== 10) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (isEmpty(company) || isNaN(company)) {
            showAlert({ msj: 'Company not found', type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop: 'creatingSubcontractorHelper', value: true }
        })

        sw_create_subcontractor_helper({
            token,
            firstName,
            lastName,
            email,
            phone: cleanPhone,
            idEvent: idEvent !== 0 ? idEvent : null,
            idTruck: idTruck !== 0 ? idTruck : null,
            idTruckUserType: 2,
            idCompany: company,
            idType: 5
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_HELPER,
                    payload: { prop: 'creatingSubcontractorHelper', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_SUBCONTRACTOR_HELPER,
                        payload: { prop: 'creatingSubcontractorHelper', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Subcontractor helper created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_HELPER
                })

                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_HELPER,
                })
                if (isFromEvent === undefined) {

                    if (userType !== 3) {
                        history.push(`/subcontractor/${company}/helpers`)
                    } else {
                        history.push(`/subcontractor/helpers`)
                    }
                } else {
                    history.push(`/event/${idEvent}/helpers`)
                }
            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_HELPER,
                    payload: { prop: 'creatingSubcontractorHelper', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const updateSubcontractorHelper = (info) => {

    const { token, id, company,
        firstName,
        lastName,
        email,
        status,
        phone,
        password,
        confirmPassword,
        idTruck,
        idEvent,
        isFromEvent,
        userType,
        history } = info


    return (dispatch) => {
        let cleanPhone = phone.replace(/-/g, "")
        if (isEmpty(firstName) || isEmpty(lastName) ||
            isEmpty(email) || isEmpty(cleanPhone) || cleanPhone.length !== 10 || isEmpty(status)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (isEmpty(company) || isNaN(company)) {
            showAlert({ msj: 'Company not found', type: 2 })
            return false
        } else if (isEmpty(id) || isNaN(id)) {
            showAlert({ msj: 'Driver not found', type: 2 })
            return false
        } else if (isNaN(cleanPhone)) {
            showAlert({ msj: string_general.errorMsj.formatPhone, type: 2 })
            return false
        } else if (!isEmpty(password) || !isEmpty(confirmPassword)) {
            if (password !== confirmPassword) {
                showAlert({ msj: string_general.errorMsj.differentPasswords, type: 2 })
                return false
            } else if (!validatePassword(password)) {
                showAlert({
                    msj: `${string_general.errorMsj.password} ${string_general.formatPassword_msj}`,
                    type: 2,
                    duration: 5000
                })
                return false
            }
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop: 'updatingSubcontractorHelpers', value: true }
        })

        sw_update_subcontractor_helper({
            token,
            id,
            email,
            firstName,
            lastName,
            phone: cleanPhone,
            password,
            idEvent: idEvent !== 0 ? idEvent : null,
            idTruck: idTruck !== 0 ? idTruck : null,
            idStatus: status,
        }).then(data => {

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_HELPER,
                payload: { prop: 'updatingSubcontractorHelpers', value: false }
            })

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }

                showAlert({ msj: 'Subcontractor helper updated successful', type: 1 })

                dispatch({
                    type: CLEAR_STATE_SUBCONTRACTOR_HELPER,
                })
                if (isFromEvent === undefined) {
                    if (userType !== 3) {
                        history.push(`/subcontractor/${company}/helpers`)
                    } else {
                        history.push(`/subcontractor/helpers`)
                    }
                } else {
                    history.push(`/event/${idEvent}/helpers`)
                }

            } else {
                dispatch({
                    type: SET_INPUT_SUBCONTRACTOR_HELPER,
                    payload: { prop: 'updatingSubcontractorHelpers', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })
    }
}

export const getSubcontractorHelper = (info) => {
    const { token, id, gotHelper } = info
    return (dispatch) => {
        dispatch({
            type: GETTING_SUBCONTRACTOR_HELPER
        })

        sw_get_subcontractor_helper({ token, id }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_HELPER
                })
                return false
            }

            if (data.status === 200) {
                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_SUBCONTRACTOR_HELPER
                    })
                    return false
                }
                const helper = data.data
                console.log("helper: ", helper)
                let companySelected = helper.sub_contractor_company[0].fk_idCompany

                const event = helper.event
                let eventSelect = 0
                if (event) {
                    eventSelect = event.fk_idEvent
                }

                let truckSelect = 0
                if (helper.truck !== null) {
                    truckSelect = helper.truck.id
                    dispatch({
                        type: UPDATE_LIST_TRUCK_EDIT_HELPER,
                        payload: {
                            value: helper.truck.id,
                            description: helper.truck.truckNumber,
                            code: helper.truck.code,
                            event: eventSelect
                        }
                    })
                }

                const columns = [
                    {
                        Header: 'Events',
                        accessor: 'event',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: 'Trucks',
                        accessor: 'truck',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span style={{ fontSize: 11 }}>{props.value}</span>,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]
                let items = []

                if (helper.historial !== undefined) {
                    helper.historial.forEach(element => items.push({
                        event: element.event === null ? '-' : element.event.eventName,
                        truck: element.truck === null ? '-' : element.truck.truckNumber
                    }));
                }

                dispatch({
                    type: GOT_SUBCONTRACTOR_HELPER,
                    payload: {
                        firstName: helper.firstName,
                        lastName: helper.lastName,
                        email: helper.email,
                        status: helper.status.id,
                        phone: helper.phone.phone,
                        columns,
                        listItems: items,
                        event: eventSelect,
                    }
                })

                gotHelper(eventSelect, truckSelect, companySelected)

            } else {
                dispatch({
                    type: ERROR_GETTING_SUBCONTRACTOR_HELPER
                })
                showAlert({ msj: 'Driver not found', type: 2 })
            }
        })
    }
}

export const getSubcontractoHelpers = (info) => {
    const { token, company, userType, success, showError, history } = info
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop: "items", value: [] }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop: "listItemsFilter", value: [] }
        })

        const requests = [
            sw_get_events({ token, company }),
            sw_get_subcontractor_helpers({ token, company }),
            sw_get_helper_lastname({ token }),
            sw_get_status({ token }),
        ]

        Promise.all(requests).then(function (values) {

            let events = [{
                value: 0,
                description: userType === 3 ? 'All' : 'Select an event'
            }]
            const eventsTemp = values[0].data
            if (eventsTemp !== undefined) {
                eventsTemp.forEach(e => events.push({
                    value: e.id,
                    description: e.eventName
                }))
            }

            const data = values[1]
            const columns = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 100,
                    sortable: false,
                    filterable: false,
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'First Name',
                    accessor: 'firstName',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    filterable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Name',
                    accessor: 'lastName',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    filterable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Phone Number',
                    accessor: 'phone',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    filterable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    filterable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    filterable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            let items = []

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
                return false
            }

            if (data.status === 200) {
                const tempHelpers = data.data

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    showError()
                } else {
                    tempHelpers.forEach(helper => {
                        items.push(
                            {
                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => {
                                    userType !== 3 ? history.push(`/subcontractor/${company}/edit_helper/${helper.id}`) : history.push(`/subcontractor/edit_helper/${helper.id}`)
                                }}>Edit</a>,
                                firstName: helper.firstName,
                                lastName: helper.lastName,
                                email: helper.email,
                                phone: helper.phone === null ? '-' : helper.phone.phone,
                                status: helper.status.status,
                                eventId: helper.event === null ? -1 : helper.event.event.id,
                                updated_at: helper.updated_at
                            }
                        )
                    })
                    success()
                }
            } else {
                showAlert({ msj: 'Check your connection to internet', type: 3 })
                showError()
            }

            items = _.orderBy(items, 'lastName', 'asc')


            let lastnames = [{
                value: 0,
                description: 'All'
            }]
            const lastnamesTemp = values[2].data

            for (let i = 0; i < lastnamesTemp.length; i++) {
                lastnames.push({
                    value: i + 1,
                    description: lastnamesTemp[i].lastName
                })
            }

            let listStatus = [{
                value: 0,
                description: 'All'
            }]
            const statusTemp = values[3].data

            statusTemp.forEach(e => {
                listStatus.push({
                    value: e.id,
                    description: e.status
                })
            })

            dispatch({
                type: LOAD_SUBCONTRACTOR_HELPERS,
                payload: {
                    columns, items,
                    listItemsFilter: items.filter(e => e.status === 'Active'),
                    events: _.orderBy(events, 'value', 'asc'),
                    lastnames,
                    listStatus
                }
            })
        })
    }
}

export const getOptionalDataHelper = (info) => {
    const { token, company, event, getHelper } = info
    return (dispatch) => {

        if (getHelper !== undefined) dispatch({
            type: GETTING_SUBCONTRACTOR_HELPER
        })

        dispatch({
            type: LOADING_DATA_CREATE_SUBCONTRACTOR_HELPER,
            payload: {
                labelEvents: 'Loading events...',
                labelTrucks: 'Loading trucks...'
            }
        })

        const requests = [
            sw_get_events({ token, company }),
            sw_get_trucks({ token, company }),
            sw_get_subcontractor_companies({ token, event }),
            sw_get_status({ token })]

        Promise.all(requests).then(function (values) {

            let events = []
            if (event === undefined) {
                const eventsTemp = values[0].data
                if (eventsTemp !== undefined) {
                    eventsTemp.forEach(e => events.push({
                        value: e.id,
                        description: e.eventName
                    }))
                }
            }

            events.length === 0 ? events.push({
                value: 0,
                description: 'Events not found'
            }) : events.push({
                value: 0,
                description: 'Select an event'
            })

            //code
            // 1 el truck tiene driver
            // 2 el truck tiene asisstant
            // 3 el truck no tiene nada
            const trucksTemp = values[1].data
            let trucks = []
            if (trucksTemp !== undefined) {
                trucksTemp.forEach(e => {
                    if (e.code !== 2) {
                        trucks.push({
                            value: e.id,
                            description: e.truckNumber,
                            code: e.code,
                            event: e.idEvent
                        })
                    }
                })
            }

            trucks ? trucks.push({
                value: 0,
                description: 'Select a truck'
            }) : trucks.push({
                value: 0,
                description: 'Trucks not found'
            })

            const companiesTemp = values[2].data
            let companies = []
            companiesTemp.forEach(e => {
                companies.push({
                    value: e.subcontractor.id,
                    description: e.subcontractor.companyName
                })
            })

            companies.length === 0 ? companies.push({
                value: 0,
                description: 'Subcontractor company not found'
            }) : companies.push({
                value: 0,
                description: 'Select a subcontractor company'
            })

            let listStatus = []
            const statusTemp = values[3].data

            statusTemp.forEach(e => {
                listStatus.push({
                    value: e.id,
                    description: e.status
                })
            })

            dispatch({
                type: LOAD_DATA_CREATE_SUBCONTRACTOR_HELPER,
                payload: {
                    events: _.orderBy(events, 'value', 'asc'),
                    trucks: _.orderBy(trucks, 'value', 'asc'),
                    trucksFilter: trucks.filter(e => e.value === 0),
                    labelEvents: events.length !== 0 ? 'Select event' : 'Events not found',
                    labelTrucks: trucks.length !== 0 ? 'Select trucks' : 'Trucks not found',
                    labelCompanies: companies.length !== 0 ? 'Select a subcontractor' : 'Subcontractors not found',
                    companies: _.orderBy(companies, 'value', 'asc'),
                    listStatus
                }
            })

            if (getHelper !== undefined) getHelper()
        })

    }
}

export const setInputSubcontractorHelper = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop, value }
        })
    }
}

export const filterByEvent = (info) => {
    const { event, trucks } = info
    return (dispatch) => {

        let trucksFilter = []
        trucks.forEach(e => {
            if (e.event === event || e.value === 0) {
                trucksFilter.push(e)
            }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop: 'trucksFilter', value: trucksFilter }
        })
    }
}

//  WHEN SHOW THE HELPERS FROM EVENT
export const getSubcontractorHelpersFromEvent = (info) => {
    const { token, company, event, userType, success, showError, history } = info
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop: "items", value: [] }
        })

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop: "listItemsFilter", value: [] }
        })

        const requests = [
            sw_get_subcontractor_companies({ token, event }),
            sw_get_subcontractor_helpers_from_event({ token, idEvent: event }),
            sw_get_helper_lastname({ token }),
            sw_get_status({ token }),
        ]

        Promise.all(requests).then(function (values) {

            const companiesTemp = values[0].data
            let companies = [{ value: 0, description: 'All' }]
            companiesTemp.forEach(e => {
                companies.push({
                    value: e.subcontractor.id,
                    description: e.subcontractor.companyName
                })
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_HELPER,
                payload: { prop: 'companies', value: companies }
            })

            const columns = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 100,
                    sortable: false,
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'First Name',
                    accessor: 'firstName',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Name',
                    accessor: 'lastName',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Phone Number',
                    accessor: 'phone',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Subcontractor',
                    accessor: 'companyName',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            let items = []
            const data = values[1]
            if (data === undefined) {
                showAlert({ msj: 'Check your connection to internet', type: 3 })
                showError()
                return false
            }

            if (data.status === 200) {
                const tempHelpers = data.data
                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    showError()
                } else {
                    tempHelpers.forEach(helper => {
                        items.push(
                            {
                                company: helper.idCompany,
                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/event/${event}/edit_helper/${helper.id}`)}>Edit</a>,
                                firstName: helper.firstName,
                                lastName: helper.lastName,
                                email: helper.email,
                                phone: helper.phone === null ? '-' : helper.phone.phone,
                                companyName: helper.companyName,
                                status: helper.status.status,
                                updated_at: helper.updated_at
                            }
                        )
                    })
                    success()
                }
            } else {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            }

            if (userType === 3) {
                items = items.filter(e => e.company === company)
            }


            let lastnames = [{
                value: 0,
                description: 'All'
            }]
            const lastnamesTemp = values[2].data

            for (let i = 0; i < lastnamesTemp.length; i++) {
                lastnames.push({
                    value: i + 1,
                    description: lastnamesTemp[i].lastName
                })
            }


            let listStatus = [{
                value: 0,
                description: 'All'
            }]
            const statusTemp = values[3].data

            statusTemp.forEach(e => {
                listStatus.push({
                    value: e.id,
                    description: e.status
                })
            })

            dispatch({
                type: LOAD_SUBCONTRACTOR_HELPERS,
                payload: {
                    columns,
                    items,
                    listItemsFilter: items.filter(e => e.status === 'Active'),
                    events: [],
                    lastnames,
                    listStatus
                }
            })
        })
    }
}

export const filterSubcontractorHelper = (info) => {
    const { listItems, status, labelStatus, company, event, lastname, labelLastname } = info
    return (dispatch) => {

        let filter = []
        if (status === 0 && company === 0 && event === 0 && lastname === 0) {
            filter = listItems
        } else if (status !== 0 && company === 0 && event === 0 && lastname === 0) {
            filter = listItems.filter(item => item.status === labelStatus)
        } else if (status !== 0 && company !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.company === company)
        } else if (status === 0 && company !== 0) {
            filter = listItems.filter(item => item.company === company)
        } else if (status === 0 && event !== 0 && lastname === 0) {
            filter = listItems.filter(item => item.eventId === event)
        } else if (status !== 0 && event !== 0 && lastname === 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.eventId === event)
        } else if (status !== 0 && event !== 0 && lastname !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.eventId === event && item.lastName === labelLastname)
        } else if (status === 0 && event !== 0 && lastname !== 0) {
            filter = listItems.filter(item => item.eventId === event && item.lastName === labelLastname)
        } else if (status === 0 && event === 0 && lastname !== 0) {
            filter = listItems.filter(item => item.lastName === labelLastname)
        } else if (status !== 0 && event === 0 && lastname !== 0) {
            filter = listItems.filter(item => item.status === labelStatus && item.lastName === labelLastname)
        }

        dispatch({
            type: SET_INPUT_SUBCONTRACTOR_HELPER,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}

export const getTrucksByCompany = (info) => {
    const { token, company, event, fullTrucks } = info
    return (dispatch) => {

        // fullTrucks : this is for know when the helper it's going to be edited from the event, 
        // so I need update the list "trucks" and "trucksFilter", because I need show all empty trucks + the the truck that is assigned to the driver 
        // for example: the trucks list is ["Truck A", "Truck B"] but the driver have assigned the "Truck C" 
        // so the end list will be ["Truck A", "Truck B", "Truck C"]. 
        // The logic is only update the driver from an event


        let trucks = []
        let trucksFilter = []

        if (fullTrucks === undefined) {
            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_HELPER,
                payload: { prop: 'labelTrucks', value: 'Loading trucks...' }
            })

            trucks.push({
                value: 0,
                description: 'Loading trucks...'
            })
            trucksFilter.push({
                value: 0,
                description: 'Loading trucks...'
            })


            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_HELPER,
                payload: { prop: 'trucks', value: trucks }
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_HELPER,
                payload: { prop: 'trucksFilter', value: trucksFilter }
            })
        }

        const requests = [
            sw_get_trucks({ token, company })
        ]

        Promise.all(requests).then(function (values) {

            //code
            // 1 el truck tiene driver
            // 2 el truck tiene asisstant
            // 3 el truck no tiene nada
            const trucksTemp = values[0].data
            trucks = []
            if (trucksTemp !== undefined) {
                trucksTemp.forEach(e => {
                    if (e.code !== 2) {
                        trucks.push({
                            value: e.id,
                            description: e.truckNumber,
                            code: e.code,
                            event: `${e.idEvent}`
                        })
                    }
                })
            }

            if (fullTrucks !== undefined) {
                fullTrucks.forEach(e => {
                    const truckDriver = trucks.find(f => f.value === e.value)
                    if (truckDriver === undefined) {
                        trucks.push(e)
                    }
                })
            } else {
                trucks.push({
                    value: 0,
                    description: 'Select a truck'
                })
            }

            trucksFilter = []
            trucks.forEach(e => {
                if (e.event == event || e.value === 0) {
                    trucksFilter.push(e)
                }
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_HELPER,
                payload: { prop: 'labelTrucks', value: trucks.length === 0 ? 'Trucks not found' : 'Select a truck' }
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_HELPER,
                payload: { prop: 'trucks', value: trucks }
            })

            dispatch({
                type: SET_INPUT_SUBCONTRACTOR_HELPER,
                payload: { prop: 'trucksFilter', value: trucksFilter }
            })

        })

    }
}

//  WHEN SHOW THE HELPERS FROM EVENT