import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash"

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import Car from '@material-ui/icons/DirectionsCarOutlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import ChooseColor from '../../components/ChooseColor'
import RadioButton from '../../components/RadioButton'

// ACTIONS
import {
  clearState,
  setInputSubcontractorTruck,
  getOptionalDataTruck,
  createSubcontractorTruck,
  filterDrivers_Assistant,
  updateListDriversAndHelpers,
  filterByEvent
} from '../../../modules/core/SubcontractorTruck/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { subcontractorTruckStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_subcontractor } from '../../../modules/resources/strings'
import { validateLicense, isEmpty } from '../../../modules/resources/Util';

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class CreateSubcontractorTruck extends React.Component {

  componentDidMount() {
    if (!this.props.isEdited) {
      const company = this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id
      this.props.getOptionalDataTruck({
        token: this.props.user.token,
        company,
        event: this.props.match.params.event, //WHEN SHOW THE TRUCKS FROM EVENT
        getTruck: () => {
          if (this.props.userType === 3 && this.props.match.params.event !== undefined) {

            this.props.updateListDriversAndHelpers({
              token: this.props.user.token,
              company,
              event: this.props.match.params.event,
            })
          }
        }
      })
    }

    this.confiSiteNavigations()
    this.configMenuItems()
  }

  confiSiteNavigations() {
    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor_companies`,
              label: 'Subcontractor'
            },
            {
              link: `/subcontractor_companies`,
              label: this.props.infoLabel
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/trucks`,
              label: 'Trucks'
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/create_truck`,
              label: 'Create Truck'
            }]
        })
      } else {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor/trucks`,
              label: 'Trucks'
            },
            {
              link: `/subcontractor/create_truck`,
              label: 'Create Truck'
            }]
        })
      }
    } else {
      this.props.setInputUser({
        prop: 'siteNavigations',
        value: [
          {
            link: `/events`,
            label: 'Events'
          },
          {
            link: `/events`,
            label: this.props.infoLabel
          },
          {
            link: `/event/${this.props.match.params.event}/trucks`,
            label: 'Trucks'
          },
          {
            link: `/event/${this.props.match.params.event}/create_truck`,
            label: 'Create Truck'
          }]
      })
    }
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })

    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        const idCompany = this.props.match.params.company
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [
            {
              id: 1,
              label: 'Subcontractor admins',
              icon: 'tim-icons icon-book-bookmark',
              link: `/subcontractor/${idCompany}/admins`
            },
            {
              id: 2,
              label: 'Drivers',
              icon: 'tim-icons icon-badge',
              link: `/subcontractor/${idCompany}/drivers`
            },
            {
              id: 3,
              label: 'Helpers',
              icon: 'tim-icons icon-single-02',
              link: `/subcontractor/${idCompany}/helpers`
            },
            {
              id: 4,
              label: 'Trucks',
              icon: 'tim-icons icon-delivery-fast',
              link: `/subcontractor/${idCompany}/trucks`
            },
          ]
        })
        this.props.setInputUser({ prop: 'value', value: 2 })
        this.props.setInputUser({ prop: 'itemSelect', value: 4 })
      } else {
        this.props.setInputUser({ prop: 'itemsSubmenu', value: [] })
        this.props.setInputUser({ prop: 'value', value: 4 })
        this.props.setInputUser({ prop: 'itemSelect', value: 1 })
      }
    } else {
      const idEvent = this.props.match.params.event
      this.props.setInputSubcontractorTruck({ prop: 'eventSelect', value: idEvent })
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          },
          {
            id: 4,
            label: 'Dispatch',
            icon: 'tim-icons icon-vector',
            link: `/event/${idEvent}/dispatches`
          },
          {
            id: 5,
            label: 'Deliveries',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/deliveries`

          },
          {
            id: 6,
            label: 'Transfers',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/create_transfer`
    
          },
        
          {
            id: 7,
            label: 'Tracking',
            icon: 'tim-icons icon-world',
            link: `/event/${idEvent}/tracking`
          },
          {
            id: 8,
            label: 'Report',
            icon: 'tim-icons icon-notes',
            link: `/event/${idEvent}/reports`
          }]
        })
        this.props.setInputUser({ prop: 'value', value: 4 })
      } else {
        this.props.setInputSubcontractorTruck({ prop: 'subcontractorSelect', value: this.props.user.user.subcontractor.company.id })

        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          },
          ]
        })
        this.props.setInputUser({ prop: 'value', value: 1 })
      }
      this.props.setInputUser({ prop: 'itemSelect', value: 1 })
    }
  }

  componentWillUnmount() {
    if (!isEmpty(this.props.truckNumber) || !isEmpty(this.props.comparmentsNumber) ||
      !isEmpty(this.props.pumpsNumber) || !isEmpty(this.props.totalCapacity) ||
      !isEmpty(this.props.make) || !isEmpty(this.props.licenseNumber) ||
      (this.props.eventSelect !== 0 && this.props.match.params.event === undefined) || this.props.driverSelect !== 0
      || this.props.assistantSelect !== 0 || this.props.subcontractorSelect !== 0) {

      if (!window.confirm(string_general.leaveMsjCreate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        if (this.props.match.params.event === undefined) {
          if (this.props.userType !== 3) {
            this.props.history.push(`/subcontractor/${this.props.match.params.company}/create_truck`)
          } else {
            this.props.history.push(`/subcontractor/create_truck`)
          }
        } else {
          this.props.history.push(`/event/${this.props.match.params.event}/create_truck`)
        }
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      this.props.creatingSubcontractorTruck ?
        <Loading label={string_subcontractor.creating_truck} />
        :
        <div style={{ ...subcontractorTruckStyles.container, alignItems: 'center' }}>

          {/* TITLE */}
          <span style={subcontractorTruckStyles.title}>{string_subcontractor.title_create_truck}</span>
          <ThemeProvider theme={mainTheme}>

            <div className={classes.sectionResponsive}>

              <div style={subcontractorTruckStyles.containerDivResponsive}>

                {/* TRUCK TYPE */}
                <RadioButton
                  title='Select truck type *'
                  radios={this.props.typesTruck}
                  itemSelect={this.props.typeTruckSelect}
                  onChange={(value) => this.props.setInputSubcontractorTruck({ prop: 'typeTruckSelect', value })}
                />

                {/* TRUCK NUMBER */}
                <Input
                  id="TruckNumber"
                  label="Truck Number"
                  placeholder="Enter truck number"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.truckNumber}
                  onChange={value => this.props.setInputSubcontractorTruck({ prop: 'truckNumber', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Car className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* COMPARTMENTS */}
                <Input
                  id="Comparments"
                  label="Number of Compartments"
                  placeholder="Enter truck number compartments"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.comparmentsNumber}
                  onChange={value => this.props.setInputSubcontractorTruck({ prop: 'comparmentsNumber', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Car className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* PUMPS */}
                <Input
                  id="Pumps"
                  label="Number of Pumps"
                  placeholder="Enter truck number pumps"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.pumpsNumber}
                  onChange={value => this.props.setInputSubcontractorTruck({ prop: 'pumpsNumber', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Car className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* TOTAL CAPACITY */}
                <Input
                  id="TotalCapacity"
                  label="Total Capacity"
                  placeholder="Enter truck total capacity"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.totalCapacity}
                  onChange={value => this.props.setInputSubcontractorTruck({ prop: 'totalCapacity', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Car className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* MAKE */}
                <Input
                  id="Make"
                  label="Make"
                  placeholder="Enter truck make"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.make}
                  onChange={value => this.props.setInputSubcontractorTruck({ prop: 'make', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Car className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

              </div>

              <div style={subcontractorTruckStyles.containerDivResponsive}>

                {/* LICENSE */}
                <Input
                  id="License"
                  label="Number License"
                  placeholder="Enter truck license"
                  type
                  className={classes.margin}
                  value={this.props.licenseNumber}
                  onChange={value => {
                    if (validateLicense(value))
                      this.props.setInputSubcontractorTruck({ prop: 'licenseNumber', value })
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Car className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* COLOR */}
                <ChooseColor
                  icon='tim-icons icon-delivery-fast'
                  color={this.props.color}
                  selectColor={(color) => this.props.setInputSubcontractorTruck({ prop: 'color', value: color })}
                />

                {/* WHEN SHOW THE TRUCKS FROM EVENT */}

                {
                  (this.props.match.params.event !== undefined && this.props.userType !== 3) ?
                    <div style={{ ...subcontractorTruckStyles.containerDropdown, zIndex: 2 }}>
                      <Dropdown
                        selectItem={this.props.subcontractorSelect}
                        placeholder={this.props.labelCompanies}
                        options={this.props.companies}
                        onChange={(value) => {
                          this.props.setInputSubcontractorTruck({ prop: 'subcontractorSelect', value })
                          this.props.setInputSubcontractorTruck({ prop: 'driverSelect', value: 0 })
                          this.props.setInputSubcontractorTruck({ prop: 'assistantSelect', value: 0 })
                          this.props.updateListDriversAndHelpers({
                            token: this.props.user.token,
                            company: value,
                            event: this.props.match.params.event,
                          })
                        }
                        } />
                      <div style={subcontractorTruckStyles.required}> * </div>
                    </div>
                    : null}

                {this.props.match.params.event === undefined ?
                  <div style={{ ...subcontractorTruckStyles.containerDropdown, zIndex: 2 }}>
                    <Dropdown
                      selectItem={this.props.eventSelect}
                      placeholder={this.props.labelEvents}
                      options={this.props.events}
                      onChange={(value) => {
                        this.props.setInputSubcontractorTruck({ prop: 'eventSelect', value })
                        this.props.setInputSubcontractorTruck({ prop: 'driverSelect', value: 0 })
                        this.props.setInputSubcontractorTruck({ prop: 'assistantSelect', value: 0 })
                        this.props.filterByEvent({
                          event: value,
                          drivers: this.props.drivers,
                          assistants: this.props.assistants
                        })
                      }}
                    />
                  </div>
                  : null}

                {/* WHEN SHOW THE TRUCKS FROM EVENT */}

                {/* DRIVERS */}
                <div style={{ ...subcontractorTruckStyles.containerDropdown, zIndex: 1, }}>
                  <Dropdown
                    selectItem={this.props.driverSelect}
                    placeholder={this.props.labelDrivers}
                    options={this.props.driversFilter}
                    onChange={(value) => {
                      this.props.setInputSubcontractorTruck({ prop: 'driverSelect', value })
                      this.props.filterDrivers_Assistant({
                        listItems: this.props.assistants,
                        id: value,
                        event: this.props.eventSelect,
                        prop: 'assistantsFilter'
                      })
                    }}
                  />
                </div>

                {/* ASSISTANTS */}
                <div style={{ ...subcontractorTruckStyles.containerDropdown, zIndex: 1, }}>
                  <Dropdown
                    selectItem={this.props.assistantSelect}
                    placeholder={this.props.labelAssistants}
                    options={this.props.assistantsFilter}
                    onChange={(value) => {
                      this.props.setInputSubcontractorTruck({ prop: 'assistantSelect', value })
                      this.props.filterDrivers_Assistant({
                        listItems: this.props.drivers,
                        id: value,
                        event: this.props.eventSelect,
                        prop: 'driversFilter'
                      })
                    }}
                  />
                </div>

                {/* BUTTON CREATE */}
                <div style={{ ...subcontractorTruckStyles.containerButton, marginTop: 50 }} >
                  <span>{string_general.required_fields}</span>
                  <Button
                    label={string_general.button_create}
                    onPress={() =>
                      this.props.createSubcontractorTruck({
                        token: this.props.user.token,
                        company: this.props.match.params.event !== undefined ? this.props.subcontractorSelect : (this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id),
                        truckNumber: this.props.truckNumber,
                        comparmentsNumber: this.props.comparmentsNumber,
                        pumpsNumber: this.props.pumpsNumber,
                        totalCapacity: this.props.totalCapacity,
                        licenseNumber: this.props.licenseNumber,
                        make: this.props.make,
                        fk_idTruckType: this.props.typeTruckSelect,
                        color: this.props.color,
                        idEvent: this.props.match.params.event !== undefined ? this.props.match.params.event : this.props.eventSelect,
                        idDriver: this.props.driverSelect,
                        idAssistant: this.props.assistantSelect,
                        isFromEvent: this.props.match.params.event,
                        userType: this.props.userType,
                        history: this.props.history
                      })}
                    width={'100%'}
                    fontSize={15} />
                </div>

              </div>
            </div>
          </ThemeProvider>
        </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel, isEdited } = state.user
  const { creatingSubcontractorTruck,
    truckNumber, comparmentsNumber, pumpsNumber, totalCapacity, make, licenseNumber,
    typesTruck,
    labelEvents, events,
    labelDrivers, drivers, driversFilter,
    labelAssistants, assistants, assistantsFilter,
    labelCompanies, companies,
    eventSelect,
    typeTruckSelect,
    driverSelect,
    assistantSelect,
    subcontractorSelect,
    color, } = state.subcontractorTruck
  console.log("drivers view: ", drivers)
  console.log("assistants view: ", assistants)
  return {
    isAuthenticated, user, infoLabel, isEdited,
    creatingSubcontractorTruck,
    truckNumber, comparmentsNumber, pumpsNumber, totalCapacity, make, licenseNumber,
    typesTruck: typesTruck.filter(e => e.value !== 0),
    labelEvents, events,
    labelDrivers, drivers, driversFilter: _.orderBy(driversFilter, 'value', 'asc'),
    labelAssistants, assistants, assistantsFilter: _.orderBy(assistantsFilter, 'value', 'asc'),
    labelCompanies, companies: _.orderBy(companies, 'value', 'asc'),
    eventSelect,
    typeTruckSelect,
    driverSelect,
    assistantSelect,
    subcontractorSelect,
    color,
    userType: user.user.type.id,
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps,
  {
    clearState,
    setInputSubcontractorTruck,
    getOptionalDataTruck,
    createSubcontractorTruck,
    filterDrivers_Assistant,
    updateListDriversAndHelpers,
    filterByEvent,
    setInputUser
  })(CreateSubcontractorTruck)))

