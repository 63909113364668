export const logo = require('../images/logo.png')
export const img_truck = require('../images/truck.png')
export const img_footer = require('../images/footer.png')
export const img_footer_page = require('../images/imgFooterPage.png')
export const not_found = require('../images/notFound.gif')
export const users = require('../images/users.svg')
export const companies = require('../images/companies.svg')
export const customers = require('../images/customers.png')
export const events = require('../images/events.png')
export const products = require('../images/products.png')
export const ico_logout = require('../images/logout.png')
export const marker_1 = require('../images/marker1.svg')
export const marker_2 = require('../images/marker2.svg')
export const marker_3 = require('../images/marker3.svg')
export const up_arrow = require('../images/up_arrow.svg')
export const down_arrow = require('../images/down_arrow.svg')
export const hide_table = require('../images/hide_table.svg')
export const show_table = require('../images/show_table.svg')
export const hide_map = require('../images/hide_map.svg')
export const show_map = require('../images/show_map.svg')
export const format_password = require('../images/format_password.svg')
export const check = require('../images/check.png')
export const trucks = require('../images/trucks.png')
