import {
    SET_INPUT_EVENT_REPORT,
    LOAD_REPORT_EVENT,
    UPDATE_START_DATE,
    UPDATE_END_DATE,
    LOADING_FILTER_DATA_REPORT,
    LOAD_FILTER_DATA_REPORT,
    CLEAR_STATE_REPORT
} from './types'
import Moment from "moment";
const initialState = {
    customerList: [],
    productList: [],
    idCustomer: 0,
    idProduct: 0,
    startDate: new Date(),
    endDate: new Date(),
    startDateFormat: Moment(new Date()).format('YYYY-MM-DD'),
    endDateFormat: Moment(new Date()).format('YYYY-MM-DD'),
    columns: [],
    reportList: [],
    gallonColumns: [],
    gallonReport: [],
    labelCustomers: '',
    labelProducts: ''

}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_INPUT_EVENT_REPORT:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case LOAD_REPORT_EVENT:
            return {
                ...state,
                reportList: payload.reportList,
                columns: payload.columns,
                gallonReport: payload.gallonReport,
                gallonColumns: payload.gallonColumns

            }

        case UPDATE_START_DATE:
            return {
                ...state,
                startDate: payload.startDate,
                startDateFormat: payload.startDateFormat
            }

        case UPDATE_END_DATE:
            return {
                ...state,
                endDate: payload.endDate,
                endDateFormat: payload.endDateFormat
            }

        case LOAD_FILTER_DATA_REPORT:
            return {
                ...state,
                customerList: payload.customers,
                idCustomer: payload.idCustomer,
                productList: payload.products,
                idProduct: payload.products[0].value,
                labelCustomers: payload.labelCustomers,
                labelProducts: payload.labelProducts,

            }

        case LOADING_FILTER_DATA_REPORT:
            return {
                ...state,
                labelCustomers: payload.labelCustomers,
                labelProducts: payload.labelProducts,
            }

        case CLEAR_STATE_REPORT:
            return {
                ...initialState
            }

        default:
            return state
    }
}
