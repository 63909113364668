import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ThemeProvider } from '@material-ui/styles';

import { mainTheme } from '../../modules/resources/themes'

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 100,
        width: '100%',
        height:40
    },
}));

export default function Dropdown(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(props.selectItem);

    if (props.selectItem !== value) {
        setValue(props.selectItem)
    }

    function handleChange(event) {
        const item = props.options.filter(e => e.value === event.target.value)[0]
        if (props.onChange !== undefined) {
            props.onChange(item.value, item.description, item)
        }
        setValue(item.value);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    return (
        <div style={{ width: '90%' }}>
            <ThemeProvider theme={mainTheme}>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="demo-controlled-open-select">{props.placeholder}</InputLabel>
                    <Select
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={value}
                        onChange={handleChange}
                        disabled={props.disabled}
                        inputProps={{ name: 'value', id: 'demo-controlled-open-select' }}
                    >
                        {props.options.map((option, index) => (
                            <MenuItem key={index} value={option.value}>{option.description}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ThemeProvider>
        </div>
    );
}
