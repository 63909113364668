import React from 'react'

// MATERIAL
import TextField from '@material-ui/core/TextField';
import 'react-notifications/lib/notifications.css';

// STYLES
import { inputStyles } from '../../modules/resources/styles'

export default function MultilineInput(props) {
    return (
        <div style={inputStyles.containerInput}>
            <TextField
                id={props.id}
                label={props.label}
                placeholder={props.placeholder}
                margin="normal"
                variant="filled"
                multiline
                rows="4"
                style={inputStyles.input}
                className={props.className}
                value={props.value}
                onChange={event => props.onChange(event.target.value)}
                InputProps={props.InputProps}

            />
            {props.isRequired ? <div style={inputStyles.required}> * </div> : null}
        </div>
    )
}