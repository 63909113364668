import {
    LOAD_SUBCONTRACTOR_TRUCKS,
    SET_INPUT_SUBCONTRACTOR_TRUCK,
    CLEAR_STATE_SUBCONTRACTOR_TRUCK,
    GETTING_SUBCONTRACTOR_TRUCK,
    GOT_SUBCONTRACTOR_TRUCK,
    LOADING_DATA_CREATE_SUBCONTRACTOR_TRUCK,
    LOAD_DATA_CREATE_SUBCONTRACTOR_TRUCK,
    ERROR_GETTING_SUBCONTRACTOR_HELPER,
    UPDATE_LIST_DRIVERS_EDIT_TRUCK,
    UPDATE_LIST_ASSISTANT_EDIT_TRUCK,
    UPDATE_LIST_DRIVERS_HELPERS_CREATE_TRUCK
} from './types'

const initialState = {
    creatingSubcontractorTruck: false,
    updatingSubcontractorTruck: false,
    gettingSubcontractorTruck: false,
    truckFound: true,
    columns: [],
    listItems: [],
    listItemsFilter: [],
    columnsHistorial:[],
    listItemsHistorial: [],
    truckNumber: '',
    comparmentsNumber: '',
    pumpsNumber: '',
    totalCapacity: '',
    make: '',
    licenseNumber: '',
    status: null,
    typesTruck: [],
    labelEvents: '',
    events: [],
    labelDrivers: '',
    drivers: [],
    driversFilter: [],
    labelAssistants: '',
    assistants: [],
    assistantsFilter: [],

    labelCompanies: '',
    companies: [],

    eventSelect: 0,
    typeTruckSelect: 1,
    driverSelect: 0,
    assistantSelect: 0,
    subcontractorSelect: 0,
    color: '#000000',
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_SUBCONTRACTOR_TRUCK:
            return {
                ...initialState
            }
        case LOAD_SUBCONTRACTOR_TRUCKS:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter,
                events: payload.events,
                typesTruck: payload.typesTruck
            }
        case SET_INPUT_SUBCONTRACTOR_TRUCK:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GETTING_SUBCONTRACTOR_TRUCK:
            return {
                ...state,
                updatingSubcontractorTruck: true,
                gettingSubcontractorTruck: true,
            }
        case UPDATE_LIST_DRIVERS_EDIT_TRUCK:
            return {
                ...state,
                drivers: [...state.drivers.filter(e => e.value !== payload.value), payload],
                driversFilter: [...state.driversFilter.filter(e => e.value !== payload.value), payload]
            }
        case UPDATE_LIST_ASSISTANT_EDIT_TRUCK:
            return {
                ...state,
                assistants: [...state.assistants.filter(e => e.value !== payload.value), payload],
                assistantsFilter: [...state.assistantsFilter.filter(e => e.value !== payload.value), payload]
            }
        case GOT_SUBCONTRACTOR_TRUCK:

            let driversFilter = []
            state.drivers.forEach(e => {
                if ((e.event === payload.event && e.value !== payload.idAssitant) || e.value === 0) {
                    driversFilter.push(e)
                }
            })

            let assistantsFilter = []
            state.assistants.forEach(e => {
                if ((e.event === payload.event && e.value !== payload.idDriver) || e.value === 0) {
                    assistantsFilter.push(e)
                }
            })

            return {
                ...state,
                truckFound: true,
                updatingSubcontractorTruck: false,
                gettingSubcontractorTruck: false,
                truckNumber: payload.truckNumber,
                comparmentsNumber: payload.comparmentsNumber,
                pumpsNumber: payload.pumpsNumber,
                totalCapacity: payload.totalCapacity,
                make: payload.make,
                licenseNumber: payload.licenseNumber,
                status: payload.status,
                columnsHistorial: payload.columns,
                listItemsHistorial: payload.listItems,
                driversFilter,
                assistantsFilter
            }
        case ERROR_GETTING_SUBCONTRACTOR_HELPER:
            return {
                ...state,
                truckFound: false,
                updatingSubcontractorTruck: false,
                gettingSubcontractorTruck: false,
                firstName: '',
                lastName: '',
                email: '',
                status: '',
                phone: '',
                password: '',
                confirmPassword: ''
            }
        case LOADING_DATA_CREATE_SUBCONTRACTOR_TRUCK:
            return {
                ...state,
                labelEvents: payload.labelEvents,
                labelDrivers: payload.labelDrivers,
                labelAssistants: payload.labelAssistants,
                labelCompanies: payload.labelCompanies
            }
        case LOAD_DATA_CREATE_SUBCONTRACTOR_TRUCK:
            return {
                ...state,
                typesTruck: payload.typesTruck,
                events: payload.events,
                labelEvents: payload.labelEvents,
                labelDrivers: payload.labelDrivers,
                drivers: payload.drivers,
                driversFilter: payload.driversFilter,
                labelAssistants: payload.labelAssistants,
                assistants: payload.assistants,
                assistantsFilter: payload.assistantsFilter,
                labelCompanies: payload.labelCompanies,
                companies: payload.companies
            }
        case UPDATE_LIST_DRIVERS_HELPERS_CREATE_TRUCK:
            return {
                ...state,
                labelDrivers: payload.labelDrivers,
                drivers: payload.drivers,
                driversFilter: payload.drivers,
                labelAssistants: payload.labelAssistants,
                assistants: payload.assistants,
                assistantsFilter: payload.assistants,
            }
        default:
            return state
    }
}
