import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

import { subcontractorHelperStyles } from '../../../modules/resources/styles'
import {
  clearState,
  getSubcontractoHelpers,
  getSubcontractorHelpersFromEvent,
  filterSubcontractorHelper
} from '../../../modules/core/SubcontractorHelper/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Table from '../../components/Table'

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListSubcontractorHelpers extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    statusSelected: 1,
    labelStatus: 'Active',
    subcontractorSelect: 0,
    eventSelected: 0,
    lastnameSelect: 0,
    labelLastname: 'All'
  }

  componentDidMount() {
    const company = this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id
    if (this.props.match.params.event === undefined) {
      this.props.getSubcontractoHelpers({
        token: this.props.user.token,
        company,
        userType: this.props.userType,
        success: () => this.setState({ loading: false }),
        showError: (error) => { this.setState({ loading: false }) },
        history: this.props.history
      })
    } else {
      this.props.getSubcontractorHelpersFromEvent({
        token: this.props.user.token,
        company,
        userType: this.props.userType,
        event: this.props.match.params.event,
        success: () => this.setState({ loading: false }),
        showError: (error) => { this.setState({ loading: false }) },
        history: this.props.history
      })
    }
    this.confiSiteNavigations()
    this.configMenuItems()
    window.scrollTo(0, 0);
  }

  confiSiteNavigations() {
    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor_companies`,
              label: 'Subcontractor'
            },
            {
              link: `/subcontractor_companies`,
              label: this.props.infoLabel
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/helpers`,
              label: 'Helpers'
            }]
        })
      } else {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor/helpers`,
              label: 'Helpers'
            }]
        })
      }

    } else {
      this.props.setInputUser({
        prop: 'siteNavigations',
        value: [
          {
            link: `/events`,
            label: 'Events'
          },
          {
            link: `/events`,
            label: this.props.infoLabel
          },
          {
            link: `/event/${this.props.match.params.event}/helpers`,
            label: 'Helpers'
          }]
      })
    }
  }

  configMenuItems() {
    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        const idCompany = this.props.match.params.company
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [
            {
              id: 1,
              label: 'Subcontractor admins',
              icon: 'tim-icons icon-book-bookmark',
              link: `/subcontractor/${idCompany}/admins`
            },
            {
              id: 2,
              label: 'Drivers',
              icon: 'tim-icons icon-badge',
              link: `/subcontractor/${idCompany}/drivers`
            },
            {
              id: 3,
              label: 'Helpers',
              icon: 'tim-icons icon-single-02',
              link: `/subcontractor/${idCompany}/helpers`
            },
            {
              id: 4,
              label: 'Trucks',
              icon: 'tim-icons icon-delivery-fast',
              link: `/subcontractor/${idCompany}/trucks`
            },
          ]
        })
        this.props.setInputUser({ prop: 'value', value: 2 })
        this.props.setInputUser({ prop: 'itemSelect', value: 3 })
      } else {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: []
        })
        this.props.setInputUser({ prop: 'value', value: 3 })
        this.props.setInputUser({ prop: 'itemSelect', value: 1 })
      }
    } else {
      const idEvent = this.props.match.params.event

      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          },
          {
            id: 4,
            label: 'Dispatch',
            icon: 'tim-icons icon-vector',
            link: `/event/${idEvent}/dispatches`
          },
          {
            id: 5,
            label: 'Deliveries',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/deliveries`

          },
          {
            id: 6,
            label: 'Transfers',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/create_transfer`
    
          },
        
          {
            id: 7,
            label: 'Tracking',
            icon: 'tim-icons icon-world',
            link: `/event/${idEvent}/tracking`
          },
          {
            id: 8,
            label: 'Report',
            icon: 'tim-icons icon-notes',
            link: `/event/${idEvent}/reports`
          }]
        })
        this.props.setInputUser({ prop: 'value', value: 4 })
      } else {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          }]
        })
        this.props.setInputUser({ prop: 'value', value: 1 })
      }
      this.props.setInputUser({ prop: 'itemSelect', value: 3 })
    }
  }

  render() {
    return (
      <div style={subcontractorHelperStyles.container}>
        <div style={subcontractorHelperStyles.containerFilters}>
          <div style={subcontractorHelperStyles.containerDivfilters}>
            <div style={subcontractorHelperStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_status} </label>
              <Dropdown
                selectItem={this.state.statusSelected}
                placeholder={string_general.placeholder_select_status}
                options={this.props.listStatus}
                onChange={(value, description) => {
                  this.setState({ statusSelected: value })
                  this.props.filterSubcontractorHelper({
                    listItems: this.props.listItems,
                    labelStatus: description,
                    status: value,
                    company: this.state.subcontractorSelect,
                    event: this.state.eventSelected,
                    lastname: this.state.lastnameSelect,
                    labelLastname: this.state.labelLastname
                  })
                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }
                } />

              {/* WHEN SHOW THE TRUCKS FROM EVENT */}
              {
                (this.props.match.params.event !== undefined && this.props.userType !== 3) ?
                  <div style={subcontractorHelperStyles.containerDropdownFilters}>
                    <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_subcontractors} </label>
                    <Dropdown
                      selectItem={this.state.subcontractorSelect}
                      placeholder={string_general.placeholder_select_subcontractor}
                      options={this.props.companies}
                      onChange={(value, description) => {
                        this.setState({ subcontractorSelect: value })
                        this.props.filterSubcontractorHelper({
                          listItems: this.props.listItems,
                          status: this.state.statusSelected,
                          labelStatus: this.state.labelStatus,
                          company: value,
                          event: this.state.eventSelected,
                          lastname: this.state.lastnameSelect,
                          labelLastname: this.state.labelLastname
                        })
                      }} />
                  </div>
                  : null
              }
              {/* WHEN SHOW THE TRUCKS FROM EVENT */}

              {/* WHEN SHOW THE DRIVER WITH LOGING AS SUBCONTRACTOR ADMIN */}
              {
                (this.props.userType === 3 && this.props.match.params.event === undefined) ?
                  <div style={subcontractorHelperStyles.containerDropdownFilters}>
                    <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_events} </label>
                    <Dropdown
                      selectItem={this.state.eventSelected}
                      placeholder={string_general.placeholder_select_subcontractor}
                      options={this.props.events}
                      onChange={(value, description) => {
                        this.setState({ eventSelected: value })
                        this.props.filterSubcontractorHelper({
                          listItems: this.props.listItems,
                          status: this.state.statusSelected,
                          labelStatus: this.state.labelStatus,
                          company: this.state.subcontractorSelect,
                          event: value,
                          lastname: this.state.lastnameSelect,
                          labelLastname: this.state.labelLastname
                        })
                      }} />
                  </div>
                  : null
              }
              {/* WHEN SHOW THE DRIVER WITH LOGING AS SUBCONTRACTOR ADMIN */}

              {/* WHEN SHOW THE DRIVER WITH LOGING AS SUBCONTRACTOR ADMIN */}
              {
                this.props.userType === 3 ?
                  <div style={subcontractorHelperStyles.containerDropdownFilters}>
                    <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_lastaname} </label>
                    <Dropdown
                      selectItem={this.state.lastnameSelect}
                      placeholder={string_general.placeholder_select}
                      options={this.props.lastnames}
                      onChange={(value, description) => {
                        this.setState({ lastnameSelect: value, labelLastname: description })
                        this.props.filterSubcontractorHelper({
                          listItems: this.props.listItems,
                          status: this.state.statusSelected,
                          labelStatus: this.state.labelStatus,
                          company: this.state.subcontractorSelect,
                          event: this.state.eventSelected,
                          lastname: value,
                          labelLastname: description
                        })
                      }} />
                  </div>
                  : null
              }
              {/* WHEN SHOW THE DRIVER WITH LOGING AS SUBCONTRACTOR ADMIN */}
            </div>
          </div>

          <div style={subcontractorHelperStyles.containerButtonCreate}>
            <Button
              onPress={() => {
                if (this.props.match.params.event === undefined) {
                  if (this.props.userType !== 3) {
                    this.props.history.push(`/subcontractor/${this.props.match.params.company}/create_helper`)
                  } else {
                    this.props.history.push(`/subcontractor/create_helper`)
                  }
                } else {
                  this.props.history.push(`/event/${this.props.match.params.event}/create_helper`)
                }
              }}
              width={'100%'}
              label={string_general.label_button_add}
              fontSize={12}
            />
          </div>

        </div>
        <Table
          numberPages={this.state.numberPages}
          loading={this.state.loading}
          columns={this.props.columns}
          filterable={this.props.userType === 3}
          items={this.props.listItemsFilter} />
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { user, infoLabel } = state.user
  const { columns, listItems, listItemsFilter, companies, events, lastnames, listStatus } = state.subcontractorHelper
  return {
    user, infoLabel, userType: user.user.type.id,
    columns, listItems, listItemsFilter, companies, events, lastnames, listStatus
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps,
  {
    clearState,
    getSubcontractoHelpers,
    filterSubcontractorHelper,
    getSubcontractorHelpersFromEvent,
    setInputUser
  })(ListSubcontractorHelpers)))

