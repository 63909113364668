import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FolderIcon from '@material-ui/icons/PermIdentityOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import City from '@material-ui/icons/LocationCityOutlined';

import Button from './Button'
import { colors } from '../../modules/resources/colors'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
    },
    contentLabelButton: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 20
    },
    contentButton: {
        display: 'flex',
        width: '30%',
        justifyContent: 'flex-end'
    },
    contentLabel: {
        display: 'flex',
        width: '70%',
    },
    contentList: {
        border: `5px solid ${colors.bg_input}`,
        borderRadius: 10
    }
}));

function Row(props) {
    const { index, style, isCity } = props;
    const item = props.items[index]
    return (
        <ListItem style={style} key={index}>
            <ListItemAvatar>
                {isCity ? <City /> : <FolderIcon />}
            </ListItemAvatar>
            <ListItemText primary={item.description} />
            <ListItemAvatar>
                <IconButton edge="end" aria-label="delete" onClick={() => props.onPressDelete(item)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemAvatar>
        </ListItem>
    );
}

Row.propTypes = {
    index: PropTypes.number.isRequired
};

export default function ListElements(props) {
    const classes = useStyles();

    Row.defaultProps = {
        onPressDelete: props.onPressDelete,
        items: props.items,
        isCity: props.isCity
    }

    return (
        <div className={classes.root}>
            {/* BUTTON */}
            <div className={classes.contentLabelButton}>
                <div className={classes.contentLabel}>
                    <span>{props.title}</span>
                </div>
                <div className={classes.contentButton}>
                    {props.withoutButton ? null :
                        <Button
                            label={props.titleButton}
                            onPress={() => props.onPressAdd()}
                            width={'100%'}
                            fontSize={10} />
                    }
                </div>
            </div>

            <div className={classes.contentList}>
                <FixedSizeList
                    height={200}
                    width={'100%'}
                    itemSize={45}
                    itemCount={props.items.length}>
                    {Row}
                </FixedSizeList>
            </div>
        </div>
    );
}
