import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import { mainTheme } from '../../../modules/resources/themes'

import { productsStyles } from '../../../modules/resources/styles'
import { clearState, getProducts, getProductsStatus, filterByProductStatus, setInputProduct, createProduct, updateProduct } from '../../../modules/core/Product/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general, string_product } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Table from '../../components/Table'
import ProductModal from '../../components/ProductModal'
import Input from '../../components/Input'
import Loading from '../../components/Loading'
// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

//ICONS
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListProducts extends React.Component {

  state = {
    numberPages: 100,
    loading: true,

  }

  componentDidMount() {
    this.props.clearState()
    this.loadData()

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/products`,
          label: 'Products'
        },]
    })

    window.scrollTo(0, 0);
  }

  loadData() {
    this.props.getProductsStatus({
      token: this.props.user.token

    })
    this.props.getProducts({
      token: this.props.user.token,
      status: 0,
      success: () => this.setState({ loading: false }),
      showError: () => { this.setState({ loading: false }) },
      modal: (product) => {
        this.props.setInputProduct({ prop: 'showEditProductModal', value: true })
        this.props.setInputProduct({ prop: 'productName', value: product.productName })
        this.props.setInputProduct({ prop: 'idProduct', value: product.id })
        this.props.setInputProduct({ prop: 'idStatusUpdate', value: product.status.id })

      }
    })
  }

  createProduct() {

    this.props.createProduct({
      token: this.props.user.token,
      name: this.props.productName,
      getProducts: () => { this.loadData() }
    })
  }

  updateProduct() {
    this.props.updateProduct({
      token: this.props.user.token,
      id: this.props.idProduct,
      name: this.props.productName,
      status: this.props.idStatusUpdate,
      getProducts: () => { this.loadData() },
    })
  }

  render() {
    const { classes } = this.props;
    return (

      this.props.loadingProduct ?
        <Loading label={this.props.loadingProductText} />
        :
        <div style={productsStyles.container}>
          <div style={productsStyles.containerFilters}>
            <div style={productsStyles.containerDivfilters}>
              <div style={productsStyles.containerDropdownFilters}>
                <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_status} </label>
                <Dropdown
                  selectItem={this.props.idStatus}
                  placeholder={string_general.placeholder_select_status}
                  options={this.props.listStatus}
                  onChange={(value, description) => {
                    this.props.filterByProductStatus({
                      listItems: this.props.listItems,
                      status: description
                    })
                    this.props.setInputProduct({ prop: 'idStatus', value: value })
                  }} />
                <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
                <Dropdown
                  selectItem={this.state.numberPages}
                  placeholder={string_general.placeholder_select_rows}
                  options={string_general.numberPages}
                  onChange={(value, description) => {
                    this.setState({ numberPages: value })
                  }} />

              </div>
            </div>

            <div style={productsStyles.containerButtonCreate}>
              <Button
                onPress={() => {
                  this.props.setInputProduct({ prop: 'productName', value: "" })
                  this.props.setInputProduct({ prop: 'showAddProductModal', value: true })
                }}
                width={'100%'}
                label={string_general.label_button_add}
                fontSize={12}
              />
            </div>

          </div>
          <Table
            numberPages={this.state.numberPages}
            loading={this.state.loading}
            columns={this.props.columns}
            items={this.props.listItemsFilter} />
          <ThemeProvider theme={mainTheme}>
            <ProductModal
              maxWidth='sm'
              open={this.props.showAddProductModal}
              close={() => this.props.setInputProduct({ prop: 'showAddProductModal', value: false })}
              title={string_product.title_create_product}
              titleButton={string_general.button_create}
              onPress={() => { this.createProduct() }}

            >
              {/* PRODUCTNAME */}
              
              <Input
                id="productName"
                label={string_product.product_name_label}
                placeholder={string_product.product_name_label_placeholder}
                isRequired
                type
                className={classes.margin}
                value={this.props.productName}
                onChange={value => this.props.setInputProduct({ prop: 'productName', value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalGasStationIcon className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input
                }}
              />

              {/* STATUS */}
              {/* <div style={productsStyles.containerDropdown}>
                <Dropdown
                  placeholder={this.props.status.description}
                  options={[{ value: this.props.status.value, description: this.props.status.description }]}
                  disabled
                />
                <div style={productsStyles.required}> * </div>
              </div> */}
              <div style={productsStyles.containerButton}>
                <span>{string_general.required_fields}</span>
              </div>
            </ProductModal>

            {/*EDIT PRODUCT */}
            <ProductModal
              maxWidth='sm'
              open={this.props.showEditProductModal}
              close={() => this.props.setInputProduct({ prop: 'showEditProductModal', value: false })}
              title={string_product.title_update_product}
              titleButton={string_general.button_update}
              onPress={() => { this.updateProduct() }}
            >
              {/* PRODUCTNAME */}
              <Input
                id="productName"
                label={string_product.product_name_label}
                placeholder={string_product.product_name_label_placeholder}
                isRequired
                type
                className={classes.margin}
                value={this.props.productName}
                onChange={value => this.props.setInputProduct({ prop: 'productName', value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalGasStationIcon className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input
                }}
              />
              {/* STATUS */}
              <div style={productsStyles.containerDropdown}>
                <Dropdown
                  selectItem={this.props.idStatusUpdate}
                  placeholder={string_general.label_dropdown_status}
                  options={this.props.listStatusUpdate}
                  onChange={(value, description) => this.props.setInputProduct({ prop: 'idStatusUpdate', value: value })}

                />
                <div style={productsStyles.required}> * </div>
              </div>
              <div style={productsStyles.containerButton}>
                <span>{string_general.required_fields}</span>
              </div>
            </ProductModal>
          </ThemeProvider>
        </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user } = state.user
  const { columns, listItems, listItemsFilter, listStatus, productName, creatingProduct, showAddProductModal, showEditProductModal,
    listStatusUpdate, idStatus, status, idProduct, loadingProduct, loadingProductText, idStatusUpdate } = state.product



  return {
    isAuthenticated, user, columns, listItems, listItemsFilter, listStatus, productName, creatingProduct,
    showAddProductModal, showEditProductModal, listStatusUpdate, idStatus, status, idProduct, loadingProduct, loadingProductText, idStatusUpdate
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getProducts,
  filterByProductStatus,
  getProductsStatus,
  setInputProduct,
  createProduct,
  updateProduct,
  setInputUser
})(ListProducts)))

