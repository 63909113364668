import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_create_subcontractor_admin = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/general/users`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_admins = (params) => {
    const { token, company } = params
    return axios.get(`${string_general.base_URL}auth/subcontractor_admins?idCompany=${company}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_admin = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/users/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_subcontractor_admin = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/general/users/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}