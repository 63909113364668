export const string_general = {
    base_URL: process.env.REACT_APP_BASE_URL,
    
    api_key: 'AIzaSyCQqEQ4eYVunWgjnXQ0hp7DJE6tgFSx1lg', //Geocode API Key
    // api_key: 'AIzaSyDf-yIqxErTkbWzKhLox7nAANnrfDIY190', //MY
    geocoding_URL: 'https://maps.googleapis.com/maps/api/geocode/json?',//GEOCODING GOOGLE
    appName: 'SFSFuelPro',
    required_fields: '* Fields Required',
    button_create: 'Create',
    button_update: 'Update',
    button_import: 'IMPORT SITES',
    button_create_sites: 'CREATE SITES',
    button_submit_dispatch: 'Submit Dispatches',
    button_edit: 'Edit',
    label_button_export: 'Report Export',
    label_button_export_detailed: 'Detailed Report Export',
    label_dropdown_status: 'Status',
    label_dropdown_customer: 'Customer',
    label_dropdown_product: 'Product',
    label_dropdown_start_date: 'Start date',
    label_dropdown_end_date: 'End date',
    label_dropdown_show: "Show",
    label_dropdown_subcontractors: "Subcontractors",
    label_dropdown_customers: 'Customers',
    label_dropdown_types: 'Types',
    label_dropdown_sites: 'Sites',
    label_dropdown_drivers: 'Drivers',
    label_dropdown_group: 'Group',
    label_dropdown_events: "Events",
    label_dropdown_types_truck: "Types",
    label_dropdown_lastaname: "Last Name",
    label_button_add: 'Add New',
    label_last_date_modified: 'Last Date Modified',
    placeholder_select: 'Select',
    placeholder_select_status: 'Select',
    placeholder_select_rows: 'Select',
    placeholder_select_subcontractor: 'Select',
    placeholder_select_loading_customers: 'Loading customers...',
    placeholder_select_loading_types: 'Loading types...',
    placeholder_select_loading_status: 'Loading status...',
    placeholder_select_loading_products: 'Loading products...',
    numberPages: [
        { value: 10, description: '10' },
        { value: 20, description: '20' },
        { value: 25, description: '25' },
        { value: 50, description: '50' },
        { value: 100, description: '100' },
    ],
    internet_connection_error_message: ' Check your connection to internet',
    formatPassword: 'The password must contain six characters<br />with at least one capital letter,<br />a number and a special character',
    formatPassword_msj: 'The password must contain six characters with at least one capital letter, a number and a special character',
    errorMsj: {
        internet: 'Check your connection to internet',
        allFields: 'All fields are required',
        formatEmail: 'Email format invalid',
        formatZipCode: 'Format zip code invalid',
        formatPhone: 'Format phone invalid',
        differentPasswords: 'Passwords are different',
        password: 'Format password invalid: ',
        emptyDispatches: 'Empty dispatches',
        credencials: 'Credentials invalid',
        latitude: 'Invalid latitude',
        longitude: 'Invalid longitude',
        compartments: 'Compartments is not a number',
        totalCapacity: 'Total capacity is not a number',
        pumps: 'Pumps is not a number',
        truckNumber: 'Truck number is not a number',
        gpsData: 'Geo Position not found'

    },
    latitude: 37.905741,
    longitude: -89.472572,
    zoom: 4.4,
    leaveMsjCreate: 'Warning, you have unsaved changes, are you sure to exit?',
    leaveMsjUpdate: 'Warning, If you leave before saving, any changes made will be lost',
    recalculateGpsData: 'Recalculate Gps Data'

}

export const string_login = {
    title: 'My Account',
    button: "Login",
    reset_password: 'Reset Password'
}

export const string_change_password = {
    changing_password: 'Changing password...',
    title: 'Change password'
}

export const string_admins = {

    // SUPER ADMINS
    title_create: 'Create super admin',
    title_update: 'Update super admin',
    creating_super_admin: 'Creating super admin...',
    updating_super_admin: 'Updating super admin...',
    getting_super_admin: 'Getting super admin...',

    // ADMINS
    title_create_admin: 'Create admin',
    title_update_admin: 'Update admin',
    creating_admin: 'Creating admin...',
    updating_admin: 'Updating admin...',
    getting_admin: 'Getting admin...'
}

export const string_subcontractor = {
    // COMAPNY
    title_create_company: 'Create Subcontractor Company',
    title_update_company: 'Update Subcontractor Company',
    getting_subcontractor_company: 'Getting Subcontractor Company...',
    creating_subcontractor_company: 'Creating Subcontractor Company...',
    updating_subcontractor_company: 'Updating Subcontractor Company...',
    getting_events: 'Getting Events...',

    // ADMINS
    title_create_admin: 'Create Subcontractor Admin',
    title_update_admin: 'Update Subcontractor Admin',
    creating_admin: 'Creating Subcontractor Admin...',
    updating_subcontractor_admin: 'Updating Subcontractor Admin...',
    getting_subcontractor_admin: 'Getting Subcontractor Admin...',

    //DRIVERS
    title_create_driver: 'Create Driver',
    title_update_driver: 'Update Driver',
    creating_driver: 'Creating Driver...',
    updating_subcontractor_driver: 'Updating Driver...',
    getting_subcontractor_driver: 'Getting Driver...',

    //HELPERS
    title_create_helper: 'Create Helper',
    title_update_helper: 'Update Helper',
    creating_helper: 'Creating Helper...',
    updating_subcontractor_helper: 'Updating Helper...',
    getting_subcontractor_helper: 'Getting Helper...',

    //TRUCKS
    title_create_truck: 'Create Truck',
    title_update_truck: 'Update Truck',
    creating_truck: 'Creating Truck...',
    updating_subcontractor_truck: 'Updating Truck...',
    getting_subcontractor_truck: 'Getting Truck...'
}

export const string_customer = {
    // CUSTOMER
    title_create_customer: 'Create Customer',
    title_update_customer: 'Update Customer',
    getting_customer: 'Getting Customer...',
    creating_customer: 'Creating Customer...',
    updating_customer: 'Updating Customer...',

    // SITES
    title_create_site: 'Create Site',
    title_update_site: 'Update Site',
    creating_site: 'Creating Site...',
    updating_site: 'Updating Site...',
    getting_site: 'Getting Site...',
}

export const string_event = {
    // CUSTOMER
    title_create_event: 'Create Event',
    title_update_event: 'Update Event',
    getting_event: 'Getting Event...',
    creating_event: 'Creating Event...',
    updating_event: 'Updating Event...',
    getting_states: 'Getting States...',
    getting_customers: 'Getting Customers...',
    getting_subcontractors: 'Getting Subcontractors...',
}

export const string_product = {

    title_create_product: 'Create Product',
    title_update_product: 'Update Product',
    product_name_label: 'Product Name',
    product_name_label_placeholder: 'Enter a product name',
    product_name_colum: 'Product Name',
    product_status_colum: 'Status',
    product_empty_error_message: 'Product name is required',
    create_product_success_message: 'Product created successful',
    get_product_error_message: 'Product not found',
    update_product_success_message: 'Product updated successful',
    creating_product: 'Creating Product...',
    updating_product: 'Updating Product...',

}

export const string_event_report = {

    title_reports: 'Reports',
    site_label: 'Site',
    product_label: 'Product',
    gallons_label: 'Gallons',
    delivery_date_label: 'Delivery Date and Time',
    total_gallons_label: 'Total Gallons',
    start_date_label: 'Start Date and Time',
    end_date_label: 'End Date and Time',
    invalid_start_date_message: 'The start date cannot be greater than the end date.',
    invalid_end_date_message: 'The end date cannot be less than the start date.',
    button_label_inventory_report: 'Inventory Report'
}

export const string_delivery = {

    label_dropdown_type: 'Type',
    label_site: 'Site',
    label_driver: 'Driver',
    label_driver_in: 'Driver-In',
    label_driver_out: 'Driver-Out',
    label_product_information: 'Product Information',
    label_product: 'Product',
    label_gallons: 'Gallons',
    label_notes: 'Notes',
    label_status: 'Status',
    label_delivered_date_time: 'Delivered Date Time',
    label_delivered_date: 'Delivered Date',
    updating_delivery: 'Updating Delivery...',
    getting_delivery: 'Getting Delivery...',
    title_update_delivery: 'Update Delivery',
    title_update_transfer: 'Update Transfer',
    label_place_holder_gallons: 'Enter gallons',
    label_dropdown_product: 'Select a product',
    product_empty_error_message: 'Product information is required',
    gallons_error_message: 'Gallons must be a numerical data',
    product_error_message: 'The product already exists',
    title_update_gallons: 'Update Product Gallons',
    update_delivery_success_message: 'Delivery updated successful',
    update_transfer_success_message: 'Transfer updated successful',
    empty_product_list_error_message: 'You must add a product'

}

export const string_dispatch = {
    // CUSTOMER
    title_update_dispatch: 'Update Dispatch',
    getting_dispatch: 'Getting Dispatch...',
    creating_dispatch: 'Creating Dispatch...',
    updating_dispatch: 'Updating Dispatch...',
    create_transfer: 'Create Transfer',
    // getting_customers: 'Getting Dispatches...',
    // getting_subcontractors: 'Getting Subcontractors...',
}

export const string_transfer = {
    creating_tranfer: 'Creating Transfer...',
    label_supplier: 'Select a Supplier',
    label_driver: 'Select a Driver',
    label_type_transfer: 'Select a type',
    label_supplier_transfer: 'Select a supplier',
    label_driver_transfer: 'Select a driver',
    label_other_supplier: 'Other supplier',
    create_transfer_success_message: 'Transfer created successful',
    label_state: "Select a State"

}
