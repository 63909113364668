import {

    CLEAR_STATE_EVENT,
    LOAD_EVENTS,
    SET_INPUT_EVENT,
    LOAD_DATA_EVENT,
    ADD_NEW_CITY_TO_EVENT,
    GETTING_EVENT,
    ERROR_GETTING_EVENT,
    GOT_EVENT,


    SET_STATES_EVENT,
    SET_CITIES_EVENT,
    SET_CUSTOMERS_EVENT,
    SET_SUBCONTRACTORS_EVENT

} from './types'

const initialState = {
    creatingEvent: false,
    updatingEvent: false,
    gettingEvent: false,
    columns: [],
    listItems: [],
    listItemsFilter: [],
    eventFound: true,


    eventName: '',
    status: 0,
    typeEvent: 0,
    city: '',

    labelTypes: 'Loading types...',
    eventTypes: [{ value: -1, description: 'Loading types...' }],

    cities: null,
    citiesSelect: [],
    states: [],
    customers: [],
    subcontractors: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_EVENT:
            return {
                ...initialState
            }
        case LOAD_EVENTS:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter
            }
        case SET_INPUT_EVENT:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case LOAD_DATA_EVENT:
            return {
                ...state,
                labelTypes: payload.labelTypes,
                eventTypes: payload.types,
                states: payload.states,
                customers: payload.customers,
                subcontractors: payload.subcontractors,
                cities: payload.cities
            }
        case ADD_NEW_CITY_TO_EVENT:
            return {
                ...state,
                citiesSelect: [...state.citiesSelect.filter(e => e !== payload), payload],
                city: ''
            }
        case GETTING_EVENT:
            return {
                ...state,
                updatingEvent: true,
                gettingEvent: true
            }
        case ERROR_GETTING_EVENT:
            return {
                ...state,
                eventFound: false,
                creatingEvent: false,
                updatingEvent: false,
                gettingEvent: false,
            }
        case GOT_EVENT:
            return {
                ...state,
                eventFound: true,
                updatingEvent: false,
                gettingEvent: false,
                eventName: payload.eventName,
                typeEvent: payload.typeEvent,
                status: payload.status

            }
        case SET_STATES_EVENT:
            return {
                ...state,
                states: [...state.states.filter(e => e.id !== payload.id), payload]
            }
        case SET_CITIES_EVENT:
            return {
                ...state,
                citiesSelect: [...state.citiesSelect.filter(e => e.id !== payload.id), payload]
            }
        case SET_CUSTOMERS_EVENT:
            return {
                ...state,
                customers: [...state.customers.filter(e => e.id !== payload.id), payload]
            }
        case SET_SUBCONTRACTORS_EVENT:
            return {
                ...state,
                subcontractors: [...state.subcontractors.filter(e => e.id !== payload.id), payload]
            }
        default:
            return state
    }
}
