import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import { eventReportStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { string_event_report } from '../../../modules/resources/strings'

import { setInputUser } from '../../../modules/core/User/actions'


import Button from '../../components/Button'

class ReportHome extends React.Component {

    componentDidMount() {
        this.props.setInputUser({
            prop: 'siteNavigations',
            value: [
                {
                    link: `/events`,
                    label: 'Events'
                },
                {
                    link: `/events`,
                    label: this.props.infoLabel
                },
                {
                    link: `/event/${this.props.match.params.event}/reports`,
                    label: 'Report'
                }]
        })
    }

    render() {

        return (
            <div style={eventReportStyles.container}>
                <span style={eventReportStyles.title}>{string_event_report.title_reports}</span>
                <div style={eventReportStyles.containerFilters}>
                    <div style={eventReportStyles.containerDropdownFilters}>
                        <div style={eventReportStyles.containerButtonCreate}>
                            <Button
                                onPress={() => {
                                    this.props.history.push(`/event/${this.props.match.params.event}/inventoryReport`)
                                }}
                                width={'100%'}
                                label={string_event_report.button_label_inventory_report}
                                fontSize={12}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateProps = (state) => {
    const { infoLabel } = state.user
    return { infoLabel }
}

export default (withRouter(connect(mapStateProps, {
    setInputUser
})(ReportHome)))