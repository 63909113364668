import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash'
import { DownloadExcel } from "react-excel-export";

import { dispatchStyles } from '../../../modules/resources/styles'
import { clearState, getDispatches, filterByCustomer, filterDispatches, filterByDriver, reorder, updatePriority } from '../../../modules/core/Dispatch/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import '../../../modules/resources/css/export-btn.css'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import AutocompleteMui from '../../components/AutocompleteMui'
import Button from '../../components/Button'
import Table from '../../components/Table'
import TablePriority from '../../components/TablePriority'



const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListDispatches extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    customerSelect: this.props.idCustomerSelected,
    typeSelected: 0,
    siteSelected: 0,
    driverSeleted: 0,
    groupSelect: 0,
    groupLabel: '',
    updatePriority: false
  }

  componentDidMount() {
    this.props.getDispatches({
      token: this.props.user.token,
      event: this.props.match.params.event,
      customer: this.props.idCustomerSelected,
      success: () => this.setState({ loading: false }),
      showError: (error) => { this.setState({ loading: false }) },
      history: this.props.history
    })

    this.configMenuItems()
    window.scrollTo(0, 0);
  }

  configMenuItems() {

    const idEvent = this.props.match.params.event
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [{
        id: 1,
        label: 'Trucks',
        icon: 'tim-icons icon-delivery-fast',
        link: `/event/${idEvent}/trucks`
      },
      {
        id: 2,
        label: 'Drivers',
        icon: 'tim-icons icon-badge',
        link: `/event/${idEvent}/drivers`
      },
      {
        id: 3,
        label: 'Helpers',
        icon: 'tim-icons icon-single-02',
        link: `/event/${idEvent}/helpers`
      },
      {
        id: 4,
        label: 'Dispatch',
        icon: 'tim-icons icon-vector',
        link: `/event/${idEvent}/dispatches`
      },
      {
        id: 5,
        label: 'Deliveries',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/deliveries`

      },

      {
        id: 6,
        label: 'Transfers',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/create_transfer`

      },

      {
        id: 7,
        label: 'Tracking',
        icon: 'tim-icons icon-world',
        link: `/event/${idEvent}/tracking`
      },
      {
        id: 8,
        label: 'Report',
        icon: 'tim-icons icon-notes',
        link: `/event/${idEvent}/reports`
      }]
    })
    this.props.setInputUser({ prop: 'value', value: 4 })
    this.props.setInputUser({ prop: 'itemSelect', value: 4 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/events`,
          label: 'Events'
        },
        {
          link: `/events`,
          label: this.props.infoLabel
        },
        {
          link: `/event/${this.props.match.params.event}/dispatches`,
          label: 'Dispatches'
        }]
    })
  }
  getFileName = () => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `dispatch-list-${month}-${day}-${year}`;
  }

  renderTable = () => ( 
    this.state.updatePriority ?
      <TablePriority
        numberPages={this.state.numberPages}
        columns={this.props.columnsWithoutSortable}
        items={this.props.dispatchesFilters}
        reorder={data => this.props.reorder({ data, list: this.props.dispatches })}
        updatePriority={data => this.props.updatePriority({ token: this.props.user.token, data })}
      />
      :
      <Table
        numberPages={this.state.numberPages}
        loading={this.state.loading}
        columns={this.props.columnsSortable}
        items={this.props.dispatchesFilters} />
  )

  render() {
    return (
      <div style={dispatchStyles.container}>
        <div style={dispatchStyles.containerFilters}>

          <div style={dispatchStyles.containerDivfilters}>

            <div style={dispatchStyles.containerDropdownFilters}>
              <div style={{
                display: 'flex',
                width: '52%',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                {/* SHOW ROWS */}
                <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
                <Dropdown
                  selectItem={this.state.numberPages}
                  placeholder={string_general.placeholder_select_rows}
                  options={string_general.numberPages}
                  onChange={(value, description) => {
                    this.setState({ numberPages: value })
                  }
                  } />


                {/* DRIVERS */}
                <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_drivers} </label>
                <Dropdown
                  selectItem={this.state.driverSeleted}
                  placeholder={this.props.labelDrivers}
                  options={this.props.drivers}
                  onChange={(value, description) => {
                    this.setState({
                      updatePriority: false,
                      driverSeleted: value,
                      typeSelected: 0,
                      siteSelected: 0,
                      groupSelect: 0,
                      customerSelect: this.props.idCustomerSelected !== 0 ? this.props.idCustomerSelected : 0
                    })
                    if (value !== 0) {
                      this.setState({ updatePriority: true })
                    } else {
                      this.setState({ updatePriority: false })
                    }
                    this.props.filterByDriver({
                      listItems: this.props.dispatches,
                      driver: value,
                      customer: this.state.customerSelect
                    })
                  }
                  } />
              </div>
            </div>

            <div style={dispatchStyles.containerDropdownFilters}>


              {/* CUSTOMERS */}
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_customers} </label>
              <Dropdown
                selectItem={this.state.customerSelect}
                placeholder={this.props.labelCustomers}
                disabled={this.props.idCustomerSelected !== 0}
                options={this.props.customers}
                onChange={(value, description) => {
                  this.setState({
                    updatePriority: false,
                    customerSelect: value,
                    typeSelected: 0,
                    siteSelected: 0,
                    driverSeleted: 0,
                    groupSelect: 0,
                    groupLabel: this.state.groupLabel
                  })
                  this.props.filterDispatches({
                    listItems: this.props.dispatches,
                    customer: value,
                    type: this.state.typeSelected,
                    site: this.state.siteSelected,
                    group: this.state.groupSelect,
                    groupLabel: this.state.groupLabel
                  })
                }} />

              {/* DISPATCH TYPE */}
              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_types} </label>
              <Dropdown
                selectItem={this.state.typeSelected}
                placeholder={this.props.labelDispatchTypes}
                options={this.props.dispatchTypes}
                onChange={(value, description) => {
                  if (this.state.driverSeleted !== 0) {
                    this.setState({ updatePriority: true })
                  } else {
                    this.setState({ updatePriority: false, typeSelected: value })
                    this.props.filterDispatches({
                      listItems: this.props.dispatches,
                      customer: this.state.customerSelect,
                      type: value,
                      site: this.state.siteSelected,
                      group: this.state.groupSelect,
                      groupLabel: this.state.groupLabel
                    })
                  }
                }} />

              {/* SITES */}
              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_sites} </label>

              <AutocompleteMui
                id="sites-dispatch"
                selectItem={this.props.listSites[0]}
                data={this.props.listSites}
                dataSelector="description"
                label={this.props.labelSites}
                onChange={(siteIdSelected) => {
                  if (this.state.driverSeleted !== 0) {
                    this.setState({ updatePriority: true })
                  } else {
                    this.setState({ updatePriority: false, siteSelected: siteIdSelected })
                    this.props.filterDispatches({
                      listItems: this.props.dispatches,
                      customer: this.state.customerSelect,
                      type: this.state.typeSelected,
                      site: siteIdSelected,
                      group: this.state.groupSelect,
                      groupLabel: this.state.groupLabel
                    })
                  }
                }}
              />

              {/* GROUP NUMBER */}
              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_group} </label>
              <Dropdown
                selectItem={this.state.groupSelect}
                placeholder={this.props.labelGroups}
                options={this.props.groups}
                onChange={(value, description) => {
                  if (this.state.driverSeleted !== 0) {
                    this.setState({ updatePriority: true })
                  } else {
                    this.setState({ updatePriority: false, groupSelect: value, groupLabel: description })
                    this.props.filterDispatches({
                      listItems: this.props.dispatches,
                      customer: this.state.customerSelect,
                      type: this.state.typeSelected,
                      site: this.state.siteSelected,
                      group: value,
                      groupLabel: description
                    })
                  }
                }} />
            </div>
          </div>

          <div style={dispatchStyles.containerButtonCreate}>
           <div className="export-button">
              <DownloadExcel
                data={this.props.dispatchesFilters}
                buttonLabel="Export To Excel"
                fileName={this.getFileName()}
                style={dispatchStyles.exportButton}
              />
            </div>
            <Button
              onPress={() => { this.props.history.push(`/event/${this.props.match.params.event}/create_dispatch`) }}
              width={'100%'}
              label={'Add Dispatch'}
              fontSize={12}
            />
       
            {/* <Button
              onPress={() => { this.props.history.push(`/event/${this.props.match.params.event}/create_transfer`) }}
              width={'100%'}
              label={'Create Transfer'}
              fontSize={12}
            /> */}
          </div>

        </div>

        {this.renderTable()}
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, idCustomerSelected, infoLabel } = state.user
  const {
    columnsSortable, columnsWithoutSortable, dispatches, dispatchesFilters,


    labelCustomers, customers,
    labelDispatchTypes, dispatchTypes,
    labelSites, listSites,
    labelDrivers, drivers,
    labelGroups, groups


  } = state.dispatch

  return {
    isAuthenticated, user, idCustomerSelected, infoLabel,

    columnsSortable, columnsWithoutSortable, dispatches: _.orderBy(dispatches, o => +o.priority, 'asc'), dispatchesFilters,

    labelCustomers, customers,
    labelDispatchTypes, dispatchTypes,
    labelSites, listSites,
    labelDrivers, drivers,
    labelGroups, groups

  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getDispatches,
  filterByCustomer,
  filterDispatches,
  filterByDriver,
  reorder,
  updatePriority,
  setInputUser
})(ListDispatches)))

