import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

import { buttonStyles, deliveryStyles } from '../../../modules/resources/styles'
import { mainTheme, datePickerTheme } from '../../../modules/resources/themes'
import {
  clearState, setInputDelivery, getDelivery, addProduct, deleteProduct, updateProductGallons,
  updateDelivery, handleChangeImages, handleRemoveImages as handleRemoveImagesAction
} from '../../../modules/core/Delivery/actions'
import { setInputUser } from '../../../modules/core/User/actions'
import { colors } from '../../../modules/resources/colors'
import { string_general, string_delivery, string_product } from '../../../modules/resources/strings'
import Dropdown from '../../components/Dropdown'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import MultilineInput from '../../components/MultilineInput'
import DatePicker from '../../components/DatePicker'
import Button from '../../components/Button'
import ProductModal from '../../components/ProductModal'
import ListManage from '../../components/ListManage';

//MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';


//ICONS
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import { isEmpty } from '../../../modules/resources/Util';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  },
  fab: {
    width: 40,
    height: 20,
    margin: 10,
    background: colors.blue_sfs
  }

});



class EditDelivery extends React.Component {
  state = {
    isModalOpen: false,
    imageId: null,
  }

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getDelivery({
        token: this.props.user.token,
        idDelivery: this.props.match.params.id,
        isDelivery: this.props.match.params.isDelivery,
      })
    }

    this.configMenuItems()
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    const idEvent = this.props.match.params.event
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [{
        id: 1,
        label: 'Trucks',
        icon: 'tim-icons icon-delivery-fast',
        link: `/event/${idEvent}/trucks`
      },
      {
        id: 2,
        label: 'Drivers',
        icon: 'tim-icons icon-badge',
        link: `/event/${idEvent}/drivers`
      },
      {
        id: 3,
        label: 'Helpers',
        icon: 'tim-icons icon-single-02',
        link: `/event/${idEvent}/helpers`
      },
      {
        id: 4,
        label: 'Dispatch',
        icon: 'tim-icons icon-vector',
        link: `/event/${idEvent}/dispatches`
      },
      {
        id: 5,
        label: 'Deliveries',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/deliveries`

      },

      {
        id: 6,
        label: 'Transfers',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/create_transfer`

      },

      {
        id: 7,
        label: 'Tracking',
        icon: 'tim-icons icon-world',
        link: `/event/${idEvent}/tracking`
      },
      {
        id: 8,
        label: 'Report',
        icon: 'tim-icons icon-notes',
        link: `/event/${idEvent}/reports`
      }]
    })
    this.props.setInputUser({ prop: 'value', value: 4 })
    this.props.setInputUser({ prop: 'itemSelect', value: 5 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/events`,
          label: 'Events'
        },
        {
          link: `/events`,
          label: this.props.infoLabel
        },
        {
          link: `/event/${this.props.match.params.event}/deliveries`,
          label: 'Deliveries'
        },
        {
          link: `/event/${this.props.match.params.event}/deliveries/edit_delivery/${this.props.match.params.id}/${this.props.match.params.isDelivery}`,
          label: 'Deliveries'
        }]
    })
  }

  componentWillUnmount() {
    if (this.props.deliveryProducts.length !== 0 || !isEmpty(this.props.deliveryDate) || this.props.idStatus !== 0 || !isEmpty(this.props.notes)) {
      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push(`/event/${this.props.match.params.event}/deliveries/edit_delivery/${this.props.match.params.id}/${this.props.match.params.isDelivery}`)
      } else {
        this.props.clearState()
      }
    }
  }

  handleChangeCheck = (event, id) => {
    const isChecked = event.target.checked;
    const { images, handleChangeImages } = this.props;
    const imagesUpdated = images.map(img => (img.id === id ? { ...img, isPublic: isChecked } : img));
    handleChangeImages(imagesUpdated);
  };
  removeImage = () => {
    const { images } = this.props;
    const { imageId } = this.state;
    const filteredImages = images.filter(image => image.id !== imageId);
    this.handleRemoveImages(filteredImages);
    this.toggleModal();
  };

  handleRemoveImages = (newImages) => {
    const { handleRemoveImagesAction } = this.props;
    handleRemoveImagesAction(newImages)
  };

  toggleModal = () => {
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen });
  }

  renderDriver() {
    if (this.props.match.params.isDelivery == 1) {
      return <div style={deliveryStyles.containerDropdown}>
        <Dropdown
          selectItem={0}
          placeholder={string_delivery.label_driver}
          disabled
          options={[{ value: 0, description: this.props.driverName }]}
        />

      </div>
    } else {
      return <div>
        <div style={deliveryStyles.containerDropdown}>

          <Dropdown
            selectItem={0}
            placeholder={string_delivery.label_driver_in}
            disabled
            options={[{ value: 0, description: this.props.driverNameIn }]}
          />

        </div>
        <div style={deliveryStyles.containerDropdown}>

          <Dropdown
            selectItem={0}
            placeholder={string_delivery.label_driver_out}
            disabled
            options={[{ value: 0, description: this.props.driverNameOut }]}
          />

        </div>
      </div>

    }
  }
  renderImages() {
    const backendUrl = string_general.base_URL.replace("/api/", "");
    const { images } = this.props;
    return (
      <>
        <h5 style={{ fontWeight: "400" }}>Images Uploaded</h5>
        <div style={{ display: 'flex', gap: 5, flexWrap: "wrap" }}>
          {
            images && images.map(({ id, url, isPublic }) => (
              <div key={id} style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                <img src={`${backendUrl}${url}`} style={{ width: 140 }} />
                <FormControlLabel
                  style={{ marginRight: 8 }}
                  control={
                    <Checkbox
                      checked={isPublic}
                      onChange={(e) => this.handleChangeCheck(e, id)}
                      aria-label='Customer Image'
                      value='customerImage'
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontSize: '14px' }}>
                      Customer Image
                    </Typography>
                  }
                />
                <IconButton edge="end" aria-label="delete" style={{
                  width: 30, height: 30, display: 'flex', justifyContent: 'center',
                  position: "absolute", top: -8, right: 12, backgroundColor: "red",
                  borderRadius: "50%", cursor: "pointer", alignItems: "center"
                }} onClick={() => {
                  this.toggleModal();
                  this.setState({ imageId: id })
                }
                }>
                  <DeleteIcon style={{ color: "white", width: 20 }} />
                </IconButton>

              </div>
            ))
          }
        </div>
      </>
    )
  }

  renderStatus() {

    if (this.props.match.params.isDelivery == 1) {

      return <div style={deliveryStyles.containerDropdown}>
        <Dropdown
          selectItem={this.props.idStatus}
          placeholder={string_delivery.label_status}
          options={this.props.statusList}
          onChange={(value, description) => {
            this.props.setInputDelivery({ prop: 'idStatus', value: value })
          }}

        />
        <div style={deliveryStyles.required}> * </div>
      </div>
    }

  }


  render() {
    const { classes } = this.props;
    return (
      this.props.updatingDelivery ?
        <Loading label={this.props.gettingDelivery ? string_delivery.getting_delivery : string_delivery.updating_delivery} />
        :
        <div style={{ ...deliveryStyles.container, alignItems: 'center' }}>

          {/* TITLE */}
          <span style={deliveryStyles.title}>{(this.props.match.params.isDelivery == 1) ? string_delivery.title_update_delivery : string_delivery.title_update_transfer}</span>
          <ThemeProvider theme={mainTheme}>
            <div className={classes.sectionResponsive}>

              <div style={deliveryStyles.containerDivResponsive}>


                {/* DRIVER */}

                {this.renderDriver()}

                {/* PRODUCT */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 30
                }}>


                  <div style={{ flexDirection: 'column', width: '100%' }}>
                    <div style={deliveryStyles.containerDropdown}>
                      <Dropdown
                        selectItem={this.props.idProduct}
                        placeholder={string_delivery.label_product}
                        options={this.props.productList}
                        onChange={(value, description) => {
                          this.props.setInputDelivery({ prop: 'idProduct', value: value })
                          this.props.setInputDelivery({ prop: 'productName', value: description })
                        }} />
                      <div style={deliveryStyles.required}> * </div>
                    </div>
                    {/* GALLONS */}

                    <Input
                      id="gallons"
                      label={string_delivery.label_gallons}
                      placeholder={string_delivery.label_place_holder_gallons}
                      isRequired
                      type
                      className={classes.margin}
                      value={this.props.gallons}
                      onChange={value => this.props.setInputDelivery({ prop: 'gallons', value: value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalGasStationIcon className={classes.margin} color="secondary" />
                          </InputAdornment>
                        ),
                        className: classes.input
                      }}
                    />
                  </div>
                  <Fab color="primary" aria-label="add" className={classes.fab}
                    onClick={() => this.props.addProduct({ idProduct: this.props.idProduct, productName: this.props.productName, gallons: this.props.gallons, deliveryProducts: this.props.deliveryProducts })}>
                    <AddIcon />
                  </Fab>


                </div>

                <ListManage
                  items={this.props.deliveryProducts}
                  maxHeight={200}
                  onPressUpdate={(item) => {
                    this.props.setInputDelivery({ prop: 'showModalUpdateProduct', value: true })
                    this.props.setInputDelivery({ prop: 'gallonsUpdate', value: item.gallons })
                    this.props.setInputDelivery({ prop: 'productNameUpdate', value: item.description })
                    this.props.setInputDelivery({ prop: 'idProductUpdate', value: item.id })
                  }}
                  onPressDelete={(item) => this.props.deleteProduct({ deliveryProducts: this.props.deliveryProducts, item: item })}
                />

              </div>


              <div style={{ ...deliveryStyles.containerDivResponsive, marginRight: 5, marginLeft: 5 }}>

                {/* DELIVERED DATE */}
                <ThemeProvider theme={datePickerTheme}>
                  <div style={deliveryStyles.containerDropdown}>
                    <DatePicker
                      selectedDate={this.props.deliveryDate}
                      validateDates
                      onChange={(date) => this.props.setInputDelivery({ prop: 'deliveryDate', value: date })}
                      label={string_delivery.label_delivered_date}
                      hasTime
                    ></DatePicker>
                    <div style={deliveryStyles.required}> * </div>
                  </div>
                </ThemeProvider>
                {/* STATUS */}
                {this.renderStatus()}

                {/* NOTES */}
                <MultilineInput
                  id="productName"
                  label={string_delivery.label_notes}
                  type
                  className={classes.margin}
                  value={this.props.notes}
                  onChange={value => this.props.setInputDelivery({ prop: 'notes', value: value })}
                  InputProps={{
                    className: classes.input
                  }}
                />
                {this.renderImages()}
                <Dialog
                  open={this.state.isModalOpen}
                  onClose={this.toggleModal}
                  aria-labelledby="alert-dialog-title"
                >
                  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this image?"}</DialogTitle>
                  <DialogActions>
                    <button onClick={this.toggleModal} style={buttonStyles.cancelButton}>Cancel</button>
                    <button onClick={this.removeImage} style={buttonStyles.deleteButton}>Delete</button>
                  </DialogActions>
                </Dialog>
                <div style={deliveryStyles.containerButton}>
                  <span>{string_general.required_fields}</span>
                  <Button
                    label={string_general.button_update}
                    onPress={() =>
                      this.props.updateDelivery({
                        token: this.props.user.token,
                        idEvent: this.props.match.params.event,
                        idDelivery: this.props.match.params.id,
                        isDelivery: this.props.match.params.isDelivery,
                        date: this.props.deliveryDate,
                        status: this.props.idStatus,
                        listProducts: this.props.deliveryProducts,
                        notes: this.props.notes,
                        history: this.props.history,
                        arrImages: this.props.images
                      })}

                    width={'100%'}
                    fontSize={15} />
                </div>

              </div>


            </div>
            <ProductModal
              maxWidth='sm'
              open={this.props.showModalUpdateProduct}
              close={() => this.props.setInputDelivery({ prop: 'showModalUpdateProduct', value: false })}
              title={string_delivery.title_update_gallons}
              titleButton={string_general.button_update}
              onPress={() => { this.props.updateProductGallons({ deliveryProducts: this.props.deliveryProducts, idProduct: this.props.idProductUpdate, productName: this.props.productNameUpdate, gallons: this.props.gallonsUpdate }) }}
            >
              {/* PRODUCTNAME */}
              <Input
                id="productNameUpdate"
                label={string_product.product_name_label}
                type
                className={classes.margin}
                value={this.props.productNameUpdate}
                onChange={value => this.props.setInputDelivery({ prop: 'productNameUpdate', value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalGasStationIcon className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  disabled: true
                }}
              />
              {/* GALLONS */}
              <Input
                id="gallonsUpdate"
                label={string_delivery.label_gallons}
                placeholder={string_delivery.label_place_holder_gallons}
                isRequired
                type
                className={classes.margin}
                value={this.props.gallonsUpdate}
                onChange={value => this.props.setInputDelivery({ prop: 'gallonsUpdate', value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalGasStationIcon className={classes.margin} color="secondary" />
                    </InputAdornment>
                  ),
                  className: classes.input
                }}
              />
              <div style={deliveryStyles.containerButton}>
                <span>{string_general.required_fields}</span>
              </div>
            </ProductModal>
          </ThemeProvider>
        </div>

    )
  }
}



const mapStateProps = (state) => {
  const { isAuthenticated, user, isEdited, infoLabel } = state.user
  const { updatingDelivery, gettingDelivery, productList, statusList, driverName, gallons, deliveryDate, notes, idProduct, idStatus,
    deliveryProducts, productName, showModalUpdateProduct, productNameUpdate, gallonsUpdate, idProductUpdate, driverNameIn, driverNameOut, images } = state.delivery
  return {
    isAuthenticated, user, isEdited, updatingDelivery, gettingDelivery, productList, statusList, driverName, gallons, deliveryDate, notes, infoLabel,
    idProduct, idStatus, deliveryProducts, productName, showModalUpdateProduct, productNameUpdate, gallonsUpdate, idProductUpdate, driverNameIn, driverNameOut, images
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getDelivery,
  setInputDelivery,
  addProduct,
  deleteProduct,
  handleChangeImages,
  handleRemoveImagesAction,
  updateProductGallons,
  updateDelivery,
  setInputUser
})(EditDelivery)))
