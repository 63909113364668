import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 100,
    width: '100%',
    height: 40
  },
}));

export default function AutocompleteMui(props) {

  const [value, setValue] = React.useState(props.selectItem);
  const styles = {
    fullWidth: true,
  }
  if (props.selectItem !== value) {
    setValue(props.selectItem)
  }

  /**
   * Pass an onChange method prop and receives the data on your onChange example:
   * onChange=(value, description, item) => {do here some actions} //This in your props
   */
  function handleChange(event, item) {
    if (!!item) {
      if (props.onChange !== undefined) {
        props.onChange(item.value, item.description, item)
      }
      setValue(item.value);
    } else {
      setValue(props.selectItem);
      if (props.onChange !== undefined) {
        props.onChange(props.selectItem.value, props.selectItem.description, props.selectItem)
      }
    }
  }

  return (
    <div style={{ width: '90%', 'z-index': 10 }}>
      <Autocomplete
        style={{ width: 300 }}
        disablePortal
        autoComplete={true}
        id="autocompletemui"
        options={props.data}
        getOptionLabel={(option) => option[props.dataSelector] || ""}
        onChange={handleChange}
        value={value}
        getOptionSelected={(option, value) => {
          return option.value === value.value || option.description === value.description
        }}
        renderInput={(params) => <TextField {...params} label={props.label} />}
      />
    </div>
  );
}
