import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

import { subcontractorAdminStyles } from '../../../modules/resources/styles'
import { clearState, getSubcontractoAdmins, filterByStatusSubcontractorAdmin } from '../../../modules/core/SubcontractorAdmin/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Table from '../../components/Table'

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListSubcontractorAdmins extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    statusSelected: 1
  }



  componentDidMount() {

    this.props.getSubcontractoAdmins({
      token: this.props.user.token,
      company: this.props.match.params.company,
      success: () => this.setState({ loading: false }),
      showError: (error) => { this.setState({ loading: false }) },
      history: this.props.history
    })

    this.configMenuItems()
    window.scrollTo(0, 0);
    
  }

  configMenuItems() {
    const idCompany = this.props.match.params.company
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [
        {
          id: 1,
          label: 'Subcontractor admins',
          icon: 'tim-icons icon-book-bookmark',
          link: `/subcontractor/${idCompany}/admins`
        },
        {
          id: 2,
          label: 'Drivers',
          icon: 'tim-icons icon-badge',
          link: `/subcontractor/${idCompany}/drivers`
        },
        {
          id: 3,
          label: 'Helpers',
          icon: 'tim-icons icon-single-02',
          link: `/subcontractor/${idCompany}/helpers`
        },
        {
          id: 4,
          label: 'Trucks',
          icon: 'tim-icons icon-delivery-fast',
          link: `/subcontractor/${idCompany}/trucks`
        },
      ]
    })
    this.props.setInputUser({ prop: 'value', value: 2 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/subcontractor_companies`,
          label: 'Subcontractor'
        },
        {
          link: `/subcontractor_companies`,
          label: this.props.infoLabel
        },
        {
          link: `subcontractor/${idCompany}/admins`,
          label: 'Admins'
        }]
    })
  }

  render() {
    return (
      <div style={subcontractorAdminStyles.container}>
        <div style={subcontractorAdminStyles.containerFilters}>
          <div style={subcontractorAdminStyles.containerDivfilters}>
            <div style={subcontractorAdminStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_status} </label>
              <Dropdown
                selectItem={this.state.statusSelected}
                placeholder={string_general.placeholder_select_status}
                options={[
                  { value: 0, description: 'All' },
                  { value: 1, description: 'Active' },
                  { value: 2, description: 'Inactive' }]}
                onChange={(value, description) => {
                  this.setState({ statusSelected: value })
                  this.props.filterByStatusSubcontractorAdmin({
                    listItems: this.props.listItems,
                    status: description
                  })
                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }
                } />
            </div>
          </div>

          <div style={subcontractorAdminStyles.containerButtonCreate}>
            <Button
              onPress={() => { this.props.history.push(`/subcontractor/${this.props.match.params.company}/create_admin`) }}
              width={'100%'}
              label={string_general.label_button_add}
              fontSize={12}
            />
          </div>

        </div>
        <Table
          numberPages={this.state.numberPages}
          loading={this.state.loading}
          columns={this.props.columns}
          items={this.props.listItemsFilter} />
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel } = state.user
  const { columns, listItems, listItemsFilter } = state.subcontractorAdmin
  return { isAuthenticated, user, infoLabel, columns, listItems, listItemsFilter }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getSubcontractoAdmins,
  filterByStatusSubcontractorAdmin,
  setInputUser
})(ListSubcontractorAdmins)))

