import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

import { customerStyles } from '../../../modules/resources/styles'
import {
  clearState,
  setInputCustomer,
  getCustomers,
  filterByStatsCustomer
} from '../../../modules/core/Customer/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Table from '../../components/Table'

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListCustomers extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    statusSelected: 1
  }

  componentDidMount() {
    this.props.getCustomers({
      token: this.props.user.token,
      success: () => this.setState({ loading: false }),
      showError: (error) => { this.setState({ loading: false }) },
      history: this.props.history
    })

    this.configMenuItems()
    window.scrollTo(0, 0);
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'itemsSubmenu', value: [] })
    this.props.setInputUser({ prop: 'value', value: 3 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })
    this.props.setInputUser({ prop: 'infoLabel', value: '' })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/customers`,
          label: 'Customers'
        }]
    })
  }

  render() {
    return (
      <div style={customerStyles.container}>
        <div style={customerStyles.containerFilters}>
          <div style={customerStyles.containerDivfilters}>
            <div style={customerStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_status} </label>
              <Dropdown
                selectItem={this.state.statusSelected}
                placeholder={string_general.placeholder_select_status}
                options={[
                  { value: 0, description: 'All' },
                  { value: 1, description: 'Active' },
                  { value: 2, description: 'Inactive' }]}
                onChange={(value, description) => {
                  this.setState({ statusSelected: value })
                  this.props.filterByStatsCustomer({
                    listItems: this.props.listItems,
                    status: description
                  })
                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }} />
            </div>
          </div>

          <div style={customerStyles.containerButtonCreate}>
            <Button
              onPress={() => { this.props.history.push("/create_customer") }}
              width={'100%'}
              label={string_general.label_button_add}
              fontSize={12}
            />
          </div>

        </div>

        <Table
          numberPages={this.state.numberPages}
          loading={this.state.loading}
          columns={this.props.columns}
          items={this.props.listItemsFilter} />
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel } = state.user
  const { columns, listItems, listItemsFilter } = state.customer
  return { isAuthenticated, user, infoLabel, columns, listItems, listItemsFilter }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getCustomers,
  setInputCustomer,
  filterByStatsCustomer,
  setInputUser
})
  (ListCustomers)))

