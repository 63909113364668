import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_create_subcontractor_truck = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/trucks`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_trucks = (params) => {
    const { token, company } = params
    return axios.get(`${string_general.base_URL}auth/trucks?idCompany=${company}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_truck = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/trucks/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_subcontractor_truck = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/trucks/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_truck_types = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/truck_types`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_events = (params) => {
    const { token, company } = params
    return axios.get(`${string_general.base_URL}auth/event/subcontractor/${company}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_free_drivers = (params) => {
    const { token, company } = params
    return axios.get(`${string_general.base_URL}auth/free/drivers?idCompany=${company}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_free_assistants = (params) => {
    const { token, company } = params
    return axios.get(`${string_general.base_URL}auth/free/assistants?idCompany=${company}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

//  WHEN SHOW THE TRUCKS FROM EVENT
export const sw_get_subcontractor_trucks_from_event = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/event/trucks`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_companies = (params) => {
    const { token, event } = params
    return axios.get(`${string_general.base_URL}auth/subcontractor/list/events/${event}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

//  WHEN SHOW THE TRUCKS FROM EVENT