import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Alert(props) {
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {props.textContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onCancel()} color="primary">
                    Cancel
                     </Button>
                <Button onClick={() => props.onOk()} color="primary">
                    Ok
                    </Button>
            </DialogActions>
        </Dialog>
    );
}
