import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

// MATERIAL
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';


//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Loading from '../../components/Loading'

// ACTIONS
import { clearState, setInputDispatch, getDispatch, updateDispatch } from '../../../modules/core/Dispatch/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { dispatchStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_dispatch } from '../../../modules/resources/strings'
import MultilineInput from '../../components/MultilineInput'

const styles = theme => ({
    root: {
        display: 'flex',
        background: "black"
    },
    input: {
        color: "black",
        background: colors.bg_input
    },
    sectionResponsive: {
        display: 'flex',
        width: '90%',
        height: '100vh',
        flexDirection: 'column',
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
            width: '40%',
            marginLeft: 10,
            marginRight: 10,
            marginTop: 50,
        },
    }
});

class EditDispatch extends React.Component {

    componentDidMount() {
        if (!this.props.isEdited) {
            this.props.getDispatch({
                token: this.props.user.token,
                event: this.props.match.params.event,
                id: this.props.match.params.id,
                gotDispatch: (typeSelected, driverSelected, statusSelected, notes,  siteId, fullAddress) => {

                    this.props.setInputDispatch({ prop: 'typeSelected', value: typeSelected })
                    this.props.setInputDispatch({ prop: 'driverSelected', value: driverSelected })
                    this.props.setInputDispatch({ prop: 'oldDriver', value: driverSelected })
                    this.props.setInputDispatch({ prop: 'statusSelected', value: statusSelected })
                    this.props.setInputDispatch({ prop: 'notes', value: notes })
                    this.props.setInputDispatch({ prop: 'siteSelected', value: siteId })
                    this.props.setInputDispatch({ prop: 'fullAddress', value: fullAddress })

                }
            })
        }

        this.configMenuItems()
    }

    configMenuItems() {
        this.props.setInputUser({ prop: 'isEdited', value: false })
        const idEvent = this.props.match.params.event
        this.props.setInputUser({
            prop: 'itemsSubmenu', value: [{
                id: 1,
                label: 'Trucks',
                icon: 'tim-icons icon-delivery-fast',
                link: `/event/${idEvent}/trucks`
            },
            {
                id: 2,
                label: 'Drivers',
                icon: 'tim-icons icon-badge',
                link: `/event/${idEvent}/drivers`
            },
            {
                id: 3,
                label: 'Helpers',
                icon: 'tim-icons icon-single-02',
                link: `/event/${idEvent}/helpers`
            },
            {
                id: 4,
                label: 'Dispatch',
                icon: 'tim-icons icon-vector',
                link: `/event/${idEvent}/dispatches`
            },
            {
                id: 5,
                label: 'Deliveries',
                icon: 'tim-icons icon-tag',
                link: `/event/${idEvent}/deliveries`

            },
            {
                id: 6,
                label: 'Transfers',
                icon: 'tim-icons icon-tag',
                link: `/event/${idEvent}/create_transfer`
        
              },
            
              {
                id: 7,
                label: 'Tracking',
                icon: 'tim-icons icon-world',
                link: `/event/${idEvent}/tracking`
              },
              {
                id: 8,
                label: 'Report',
                icon: 'tim-icons icon-notes',
                link: `/event/${idEvent}/reports`
              }]
        })
        this.props.setInputUser({ prop: 'value', value: 4 })
        this.props.setInputUser({ prop: 'itemSelect', value: 4 })

        this.props.setInputUser({
            prop: 'siteNavigations',
            value: [
                {
                    link: `/events`,
                    label: 'Events'
                },
                {
                    link: `/events`,
                    label: this.props.infoLabel
                },
                {
                    link: `/event/${this.props.match.params.event}/dispatches`,
                    label: 'Dispatches'
                },
                {
                    link: `/event/${this.props.match.params.event}/edit_dispatch/${this.props.match.params.id}`,
                    label: 'Update Dispatch'
                }]
        })
    }

    componentWillUnmount() {
        if (this.props.typeSelected !== 0 || this.props.driverSelected !== 0 || this.props.statusSelected !== 0) {
            // if (!window.confirm(string_general.leaveMsjCreate)) {
            //     this.props.setInputUser({ prop: 'isEdited', value: true })
            //     this.props.history.push(`/event/${this.props.match.params.event}/edit_dispatch/${this.props.match.params.id}`)
            // } else {
            //     this.props.clearState()
            // }
        }
    }

    render() {
        const { classes } = this.props;
        const gOptions = [
            {"value": "Positive", "description": "Positive"},
            {"value": "0", "description": "0"}
          ];
          const defaultOption = localStorage.getItem('gallonInformation' + this.props.match.params.id);

        return (
            this.props.updatingDispatch ?
                <Loading label={string_dispatch.updating_dispatch} />
                :
                <div style={{ ...dispatchStyles.container, alignItems: 'center' }}>

                    {/* TITLE */}
                    <span style={dispatchStyles.title}>{string_dispatch.title_update_dispatch}</span>

                    <div className={classes.sectionResponsive}>
                        <ThemeProvider theme={mainTheme}>

                            {/* TYPE */}
                            <div style={dispatchStyles.containerDropdown}>
                                <Dropdown
                                    selectItem={this.props.typeSelected}
                                    placeholder={this.props.labelDispatchTypes}
                                    options={this.props.dispatchTypes}
                                    onChange={(value, description) => {
                                        this.props.setInputDispatch({ prop: 'typeSelected', value })
                                    }} />
                                <div style={dispatchStyles.required}> * </div>
                            </div>

                            {/* SITE */}

                            <div style={dispatchStyles.containerDropdown}>
                                <Dropdown
                                    selectItem={this.props.siteSelected}
                                    label="Site3"
                                    placeholder={this.props.labelSites}
                                    options={this.props.sites}
                                    disabled
                                />
                                <div style={dispatchStyles.required}> * </div>
                            </div>

                            <div style={dispatchStyles.containerDropdown}>
                                <span><strong>Site Address: </strong> {this.props.fullAddress}</span>
                            </div>

                            {/* DRIVER */}
                            <div style={dispatchStyles.containerDropdown}>
                                <Dropdown
                                    selectItem={this.props.driverSelected}
                                    placeholder={this.props.labelDrivers}
                                    options={this.props.drivers}
                                    onChange={(value, description) => {
                                        this.props.setInputDispatch({ prop: 'driverSelected', value })
                                    }} />
                                <div style={dispatchStyles.required}> * </div>
                            </div>

                            {/* STATUS */}
                            <div style={dispatchStyles.containerDropdown}>
                                <Dropdown
                                    selectItem={this.props.statusSelected}
                                    placeholder={this.props.labelStatus}
                                    options={this.props.status}
                                    onChange={(value, description) => {
                                        this.props.setInputDispatch({ prop: 'statusSelected', value })
                                    }} />
                                <div style={dispatchStyles.required}> * </div>

                            </div>

                                
                                {/* NOTES */}
                                <MultilineInput
                                id="productName"
                                isRequired
                                label="Add Dispatch Notes"
                                type
                                className={classes.margin}
                                value={this.props.notes}
                                onChange={value => this.props.setInputDispatch({ prop: 'notes', value: value })}
                                InputProps={{
                                    className: classes.input
                                }}
                                />


                        

                        </ThemeProvider>
                        <div style={dispatchStyles.containerButton}>
                            
                            <span style={{ marginTop: 50 }}>{string_general.required_fields}</span>
                            <Button
                            label="Modify Product Delivery"
                            width={'100%'}
                            fontSize={15}
                            onPress={() =>
                                this.props.history.push(`/event/${this.props.match.params.event}/modify_dispatch/${this.props.match.params.id}/${this.props.driverSelected}`) 

                            }
                            >

                            </Button>
                            <Button
                                label={string_general.button_update}
                                onPress={() =>
                                    this.props.updateDispatch({
                                        token: this.props.user.token,
                                        event: this.props.match.params.event,
                                        id: this.props.match.params.id,
                                        oldDriver: this.props.oldDriver,
                                        newDriver: this.props.driverSelected,
                                        type: this.props.typeSelected,
                                        status: this.props.statusSelected,
                                        notes: this.props.notes,
                                        history: this.props.history,
                                        gallonInformation: this.props.gallonInformationSelected
                                    })}
                                width={'100%'}
                                fontSize={15} />
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateProps = (state) => {
    const { isAuthenticated, user, infoLabel, isEdited } = state.user
    const {
        updatingDispatch,
        labelDispatchTypes, dispatchTypes,
        labelSites, sites,
        labelDrivers, drivers,
        labelStatus, status,

        typeSelected,
        driverSelected,
        statusSelected,
        notes,
        siteSelected,
        oldDriver,
        gallonInformationSelected,
        fullAddress
    } = state.dispatch
    console.log("drivers: ", drivers)
    return {
        isAuthenticated, user, infoLabel, isEdited,
        updatingDispatch,
        labelDispatchTypes, dispatchTypes,
        labelSites, sites,
        labelDrivers, drivers,
        labelStatus, status,

        typeSelected,
        driverSelected,
        statusSelected,
        notes,
        siteSelected,
        oldDriver,
        gallonInformationSelected,
        fullAddress
    }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
    clearState,
    getDispatch,
    updateDispatch,
    setInputUser,
    setInputDispatch
})(EditDispatch)))

