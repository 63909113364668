import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash'

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import Company from '@material-ui/icons/AccountBalanceOutlined';
import User from '@material-ui/icons/PermIdentityOutlined';
import Email from '@material-ui/icons/EmailOutlined';
import Phone from '@material-ui/icons/Phone';
import Address from '@material-ui/icons/AddLocationOutlined';
import City from '@material-ui/icons/LocationCityOutlined';
import ZipCode from '@material-ui/icons/RoomOutlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import DropdownSearch from '../../components/DropdownSearch'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import ListElements from '../../components/ListElements'
import ListSelect from '../../components/ListSelect'
import ChooseColor from '../../components/ChooseColor'
import Modal from '../../components/Modal'
import NotFound from '../../components/ResourceNotFound'
import Table from '../../components/Table'

// ACTIONS
import {
  clearState,
  setInputCustomer,
  filterDrivers,
  selectDriversToCreate,
  selectDrivers,
  deleteDrivers,
  updateCustomer,
  getInfo,
  getCustomer
} from '../../../modules/core/Customer/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { customerStyles } from '../../../modules/resources/styles'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_customer } from '../../../modules/resources/strings'
import { validateZipCode, isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  },
  sectionResponsiveModal: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
    },
  }
});

class EditCustomer extends React.Component {

  state = {
    showModal: false
  }

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getInfo({
        token: this.props.user.token,
        getCustomer: () => this.props.getCustomer({
          token: this.props.user.token,
          id: this.props.match.params.id
        })
      })
    }

    this.configMenuItems()
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    this.props.setInputUser({ prop: 'itemsSubmenu', value: [] })
    this.props.setInputUser({ prop: 'value', value: 3 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/customers`,
          label: 'Customers'
        },
        {
          link: `/edit_customer/${this.props.match.params.id}`,
          label: 'Update Customer'
        }
      ]
    })
  }

  componentWillUnmount() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    const haveDriver = this.props.driversSelect.find(e => e.isSelect)
    if (!isEmpty(this.props.customerName) || !isEmpty(this.props.contactName) ||
      !isEmpty(this.props.email) || !isEmpty(this.props.phone) || !isEmpty(this.props.address)
      || !isEmpty(this.props.city) || !isEmpty(this.props.zipCode) || !isEmpty(this.props.stateCustomer) || haveDriver !== undefined) {

      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push(`/edit_customer/${this.props.match.params.id}`)
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      this.props.updatingCustomer ?
        <Loading label={this.props.gettingCustomer ? string_customer.getting_customer : string_customer.updating_customer} />
        : this.props.customerFound ?
          <div style={{ ...customerStyles.container, alignItems: 'center' }}>
            <span style={customerStyles.title}>{string_customer.title_update_customer}</span>

            {/* DESKTOP AND TABLE */}
            <div className={classes.sectionResponsive}>

              {/* SECTION #1 */}
              <div style={customerStyles.containerDivResponsive}>
                <ThemeProvider theme={mainTheme}>

                  {/* CUSTOMER NAME */}
                  <Input
                    id="CustomerName"
                    label="Customer Name"
                    placeholder="Enter customer name"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.customerName}
                    onChange={value => this.props.setInputCustomer({ prop: 'customerName', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Company className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* CONTACT NAME */}
                  <Input
                    isRequired
                    id="ContactName"
                    label="Contact Name"
                    placeholder="Enter customer contact name"
                    type
                    className={classes.margin}
                    value={this.props.contactName}
                    onChange={value => this.props.setInputCustomer({ prop: 'contactName', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <User className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* EMAIL */}
                  <Input
                    isRequired
                    id="Email"
                    label="Email"
                    placeholder="Enter customer email"
                    type
                    className={classes.margin}
                    value={this.props.email}
                    onChange={value => this.props.setInputCustomer({ prop: 'email', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* PHONE */}
                  <Input
                    isRequired
                    id="Phone"
                    label="Phone"
                    placeholder="Enter customer phone"
                    phone
                    className={classes.margin}
                    value={this.props.phone}
                    onChange={value => this.props.setInputCustomer({ prop: 'phone', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* STATUS */}
                  <div style={customerStyles.containerDropdown}>
                    <Dropdown
                      placeholder={string_general.label_dropdown_status}
                      selectItem={this.props.status}
                      options={this.props.arrayStatus}
                      onChange={(value, description) => this.props.setInputCustomer({ prop: 'status', value })}
                    />

                    <div style={customerStyles.required}> * </div>
                  </div>

                  {/* COLOR */}
                  <ChooseColor
                    icon='tim-icons icon-palette'
                    color={this.props.color}
                    selectColor={(color) => this.props.setInputCustomer({ prop: 'color', value: color })}
                  />

                </ThemeProvider>
              </div>

              {/* SECTIONS #2 */}
              <div style={{ ...customerStyles.containerDivResponsive, marginRight: 5, marginLeft: 5 }}>
                <ThemeProvider theme={mainTheme}>

                  {/* ADDRESS */}
                  <Input
                    id="Address"
                    label="Address"
                    placeholder="Enter customer address"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.address}
                    onChange={value => this.props.setInputCustomer({ prop: 'address', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Address className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* CITY */}
                  <Input
                    isRequired
                    id="City"
                    label="City"
                    placeholder="Enter customer city"
                    type
                    className={classes.margin}
                    value={this.props.city}
                    onChange={value => this.props.setInputCustomer({ prop: 'city', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <City className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* STATE */}
                  <div style={customerStyles.containerDropdownStates}>
                    <DropdownSearch
                      selectItem={this.props.stateCustomer}
                      label='States'
                      placeholder={this.props.labelStates}
                      options={this.props.arrayStates}
                      onChange={(value, description) => this.props.setInputCustomer({ prop: 'stateCustomer', value })}
                    />
                    <div style={customerStyles.required}> * </div>

                  </div>


                  {/* ZIP CODE */}
                  <Input
                    isRequired
                    id="ZipCode"
                    label="Zip Code"
                    placeholder="Enter customer zip code"
                    type
                    className={classes.margin}
                    value={this.props.zipCode}
                    onChange={value => {
                      if (validateZipCode(value)) {
                        this.props.setInputCustomer({ prop: 'zipCode', value })
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ZipCode className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  <ListElements
                    title='Drivers'
                    titleButton='Add'
                    items={this.props.driversSelect}
                    onPressAdd={() => this.setState({ showModal: true })}
                    onPressDelete={(item) => this.props.deleteDrivers({
                      drivers: this.props.drivers,
                      driversFilter: this.props.driversFilter,
                      driversSelect: this.props.driversSelect,
                      item
                    })}
                  />

                </ThemeProvider>
              </div>

              {/* SECTION #3 */}
              <div style={customerStyles.containerDivResponsive}>

                <Table
                  hidePaginationTop={true}
                  numberPages={5}
                  loading={false}
                  columns={this.props.columnsHistorial}
                  items={this.props.listItemsHistorial} />

                {/* BUTTON */}
                <div style={{ ...customerStyles.containerButton, marginTop: 80 }}>
                  <span>{string_general.required_fields}</span>
                  <Button
                    label={string_general.button_update}
                    onPress={() =>
                      this.props.updateCustomer({
                        token: this.props.user.token,
                        id: this.props.match.params.id,
                        customerName: this.props.customerName,
                        contactName: this.props.contactName,
                        email: this.props.email,
                        phone: this.props.phone,
                        idStatus: this.props.status,
                        color: this.props.color,
                        address: this.props.address,
                        zipCode: this.props.zipCode,
                        city: this.props.city,
                        fk_idState: this.props.stateCustomer,
                        drivers: this.props.driversSelect,
                        history: this.props.history
                      })}
                    width={'100%'}
                    fontSize={15} />
                </div>

              </div>

            </div>

            {/* ADD DRIVERS */}
            <ThemeProvider theme={mainTheme}>
              <Modal
                maxWidth='lg'
                open={this.state.showModal}
                close={() => this.setState({ showModal: false })}
                title='Add drivers'
                onPressAction={() => {
                  this.setState({ showModal: false })
                  this.props.selectDrivers({ drivers: this.props.drivers })
                }}
                buttonTitle='Apply'
              >

                <div style={{ ...customerStyles.containerFilters, marginBottom: 20 }}>

                  <div className={classes.sectionResponsiveModal}>

                    <div style={customerStyles.dropdownModal}>
                      <label style={{ marginRight: 10 }}>Subcontractor</label>
                      <Dropdown
                        selectItem={this.props.filterByCompany}
                        placeholder='Select Subcontractor'
                        options={this.props.arrayCompanies}
                        onChange={(value, description) => {
                          this.props.setInputCustomer({ prop: 'filterByCompany', value })
                          this.props.filterDrivers({
                            list: this.props.drivers,
                            filterByCompany: value,
                            filterByStatus: this.props.filterByStatus,
                            filterByTruck: this.props.filterByTruck,
                            filterByEvent: this.props.filterByEvent
                          })
                        }}
                      />
                    </div>

                    <div style={customerStyles.dropdownModal}>
                      <label style={{ marginRight: 10, marginLeft: 10 }}>Status</label>
                      <Dropdown
                        selectItem={this.props.filterByStatus}
                        placeholder='Select Status'
                        options={[
                          { value: 0, description: 'All' },
                          { value: 1, description: 'Active' },
                          { value: 2, description: 'Inactive' }]}
                        onChange={(value, description) => {
                          this.props.setInputCustomer({ prop: 'filterByStatus', value })
                          this.props.filterDrivers({
                            list: this.props.drivers,
                            filterByCompany: this.props.filterByCompany,
                            filterByStatus: value,
                            filterByTruck: this.props.filterByTruck,
                            filterByEvent: this.props.filterByEvent
                          })
                        }}
                      />
                    </div>

                    <div style={customerStyles.dropdownModal}>
                      <label style={{ marginRight: 10, marginLeft: 10 }}>Truck type</label>
                      <Dropdown
                        selectItem={this.props.filterByTruck}
                        placeholder='Select Truck Type'
                        options={this.props.arrayTypes}
                        onChange={(value, description) => {
                          this.props.setInputCustomer({ prop: 'filterByTruck', value })
                          this.props.filterDrivers({
                            list: this.props.drivers,
                            filterByCompany: this.props.filterByCompany,
                            filterByStatus: this.props.filterByStatus,
                            filterByTruck: value,
                            filterByEvent: this.props.filterByEvent
                          })
                        }}
                      />
                    </div>

                    <div style={customerStyles.dropdownModal}>
                      <label style={{ marginRight: 10, marginLeft: 10 }}>Event </label>
                      <Dropdown
                        selectItem={this.props.filterByEvent}
                        placeholder='Select Event'
                        options={this.props.arrayEvents}
                        onChange={(value, description) => {
                          this.props.setInputCustomer({ prop: 'filterByEvent', value })
                          this.props.filterDrivers({
                            list: this.props.drivers,
                            filterByCompany: this.props.filterByCompany,
                            filterByStatus: this.props.filterByStatus,
                            filterByTruck: this.props.filterByTruck,
                            filterByEvent: value
                          })
                        }}
                      />
                    </div>
                  </div>

                </div>

                <ListSelect
                  titleLoading='Searching drivers'
                  maxHeight={400}
                  icon={<User />}
                  items={this.props.driversFilter}
                  onChange={(item) => {
                    this.props.selectDriversToCreate({
                      drivers: this.props.drivers,
                      array: this.props.driversFilter,
                      item
                    })
                  }}
                  goToNotFound={() => { }}
                  titleNotfound='Drivers not found'
                  titleButtonNotfound='Drivers not found'
                />
              </Modal>
            </ThemeProvider>

          </div>
          : <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <NotFound
              titleInfo='Company not found'
              titleButton="Go to list companies"
              goTo={() => this.props.history.push('/subcontractor_companies')}
            />
          </div>
    )
  }
}

EditCustomer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateProps = (state) => {
  const { isAuthenticated, user, isEdited } = state.user
  const {
    updatingCustomer, gettingCustomer, customerFound,

    customerName,
    contactName,
    email,
    phone,
    status,
    color,
    address,
    city,
    zipCode,
    stateCustomer,
    arrayStates,

    columnsHistorial,
    listItemsHistorial,

    labelStates,
    drivers,
    driversFilter,
    driversSelect,
    arrayCompanies,
    arrayStatus,
    arrayTypes,
    arrayEvents,

    filterByCompany,
    filterByStatus,
    filterByTruck,
    filterByEvent,
  } = state.customer

  return {
    isAuthenticated, user, isEdited,

    updatingCustomer, gettingCustomer, customerFound,

    customerName,
    contactName,
    email,
    phone,
    status,
    color,
    address,
    city,
    zipCode,
    stateCustomer,
    arrayStates,
    arrayEvents,

    columnsHistorial,
    listItemsHistorial,

    labelStates,
    drivers: _.orderBy(drivers, 'id', 'asc'),
    driversFilter: _.orderBy(driversFilter, 'id', 'asc'),
    driversSelect: _.orderBy(driversSelect, 'id', 'asc'),
    arrayCompanies,
    arrayStatus,
    arrayTypes,

    filterByCompany,
    filterByStatus,
    filterByTruck,
    filterByEvent,
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  setInputCustomer,
  filterDrivers,
  selectDriversToCreate,
  selectDrivers,
  deleteDrivers,
  updateCustomer,
  getInfo,
  getCustomer,
  setInputUser
})(EditCustomer)))