import React from "react";
import jsPDF from 'jspdf';
import _ from 'lodash'
import {
    SET_INPUT_DELIVERY,
    LOAD_DELIVERIES,
    LOADING_FILTER_DATA_DELIVERY,
    CLEAR_STATE_DELIVERY,
    GETTING_DELIVERY,
    SET_DELIVERY,
    SET_PRODUCT,
    SET_PRODUCT_UPDATE,
    UPDATING_DELIVERY
} from './types'

import { sw_get_customers_by_event, sw_get_active_products } from '../../services/EventReportApi'
import { sw_get_deliveries, sw_get_delivery_types, sw_get_delivery_status, sw_get_delivery, sw_update_delivery, sw_report_gallons } from '../../services/DeliveryApi'
import { string_general, string_delivery } from '../../../modules/resources/strings'
import { colors } from '../../resources/colors'
import { buttonStyles, dispatchStyles, tableStyles } from '../../resources/styles'
import { showAlert } from '../../resources/Util'
import Moment from "moment";


export const clearState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_STATE_DELIVERY })
    }
}

export const validateTransfer = (info) => {
    const { description, loadFilter } = info
    return (dispatch) => {
        var isTransfer = false
        if (description.includes("Transfer")) {
            isTransfer = true

        }
        dispatch({
            type: SET_INPUT_DELIVERY,
            payload: { prop: 'isTransfer', value: isTransfer }
        })

        loadFilter(isTransfer)
    }
}
const generatePDF = (images, pdfName) => {
    const pdf = new jsPDF();
    const backendUrl = string_general.base_URL.replace("/api/", "");
    let x = 60;
    let y = 10;

    images.forEach((image, index) => {
        const imgElement = new Image();
        imgElement.src = backendUrl + image.url;
        try {
            if (index > 0) pdf.addPage();
            pdf.addImage(imgElement, 'JPEG', x, y, 100, 240);
        } catch (err) {
            console.log(err);
        }
    });
    pdf.save(pdfName);
};

const renderPdfButton = (images, pdfName) => {
    const publicImages = images.filter(image => image.isPublic);
    const areImageToDownload = publicImages.length > 0;
    return (areImageToDownload && <button style={buttonStyles.pdf} onClick={() => generatePDF(publicImages, pdfName)}>Download PDF</button>)
}

export const getDeliveries = (info) => {
    const { token, idEvent, history, hideLoading, filterData, idCustomer } = info
    return (dispatch) => {
        dispatch({
            type: LOADING_FILTER_DATA_DELIVERY,
            payload: {
                labelCustomers: string_general.placeholder_select_loading_customers,
                labelTypes: string_general.placeholder_select_loading_types,
                labelStatus: string_general.placeholder_select_loading_status
            }
        })
        const requests = [
            sw_get_customers_by_event({ token, idEvent }),
            sw_get_delivery_types({ token }),
            sw_get_delivery_status({ token }),
            sw_get_deliveries({ token, idEvent })

        ]

        Promise.all(requests).then(function (values) {
            //console.log(values)
            // CUSTOMERS
            let customers = [{ value: 0, description: 'All' }]
            let allCustomers = [];

            console.log("values[0]: ", values[0])
            if (values[0].status == 200) {
                const tempCustomers = values[0].data
                allCustomers = tempCustomers
                if (tempCustomers !== undefined) {
                    tempCustomers.forEach(customer => {
                        customers.push(
                            {
                                value: customer.idCustomer,
                                description: customer.customer.customerName
                            }
                        )
                    });
                }
            }



            //TYPES
            let types = []
            console.log("values: ", values)
            if (values[1].status == 200) {
                const tempTypes = values[1].data
                if (tempTypes !== undefined) {
                    tempTypes.forEach(type => {
                        types.push(
                            {
                                value: type.id,
                                description: type.label

                            }
                        )
                    });
                }

            }



            //STATUS
            let status = [{ value: 0, description: 'All' }]
            if (values[2].status == 200) {
                const tempStatus = values[2].data
                if (tempStatus !== undefined) {
                    tempStatus.forEach(object => {
                        status.push(
                            {
                                value: object.id,
                                description: object.status


                            }
                        )
                    });
                }
            }


            //DELIVERIES
            let deliveries = []
            let transfers = []
            if (values[3].status == 200) {

                console.log("DELIVERIES: ", values[3])
                const tempList = values[3].data

                if (tempList !== undefined) {
                    const tempDelivery = tempList.deliveries;
                    tempDelivery.forEach(({ products, images, id, site, firstName, lastName, note, date, dispatchStatus, idCustomer, dispatch }) => {
                        let tempProducts = products
                        var name = ""
                        if (tempProducts.length !== 0) {
                            tempProducts.forEach(product => {
                                name += `${product.product.productName} - Gallons ${product.gallons}\n`

                            });
                        }
                        const customerName = allCustomers.map(customer => {
                            if (customer.idCustomer === idCustomer) {
                                return customer.customer.customerName;
                            }
                        });
                        console.log(date);
                        const pdfName = `${customerName[0]}_${site}_${date}`;
                        const imagePdf = images ? JSON.parse(images) : []
                        deliveries.push(
                            {

                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/event/${idEvent}/deliveries/edit_delivery/${id}/${1}`)}>{string_general.button_edit}</a>,
                                pdf: renderPdfButton(imagePdf, pdfName),
                                id,
                                site,
                                driver: `${firstName} ${lastName}`,
                                notes: note,
                                deliveredDate: date,
                                status: dispatchStatus,
                                product: name,
                                idCustomer,
                                idType: dispatch.fk_idDispatchType,
                                idStatus: dispatch.fk_idStatus


                            }
                        )
                    });
                    const tempTransfer = tempList.transfers

                    tempTransfer.forEach(transfer => {
                        let tempProducts = transfer.products
                        var name = ""
                        if (tempProducts.length !== 0) {
                            tempProducts.forEach(product => {
                                name += `${product.product.productName} - Gallons ${product.gallons}\n`

                            });
                        }

                        transfers.push(
                            {
                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/event/${idEvent}/deliveries/edit_delivery/${transfer.id}/${0}`)}>{string_general.button_edit}</a>,
                                id: transfer.id,
                                site: transfer.siteName,
                                driverIn: transfer.fullNameDriverIn,
                                driverOut: transfer.fullNameDriverOut,
                                notes: transfer.note,
                                deliveredDate: transfer.date,
                                product: name,
                                idCustomer: transfer.idCustomer,
                                idType: transfer.typeTransfer


                            }
                        )
                    });
                }
            }


            var deliveryColumns = getDeliveryColums()
            let transferColumns = getTransferColums()



            dispatch({
                type: LOAD_DELIVERIES,
                payload: {
                    customers,
                    idCustomer,
                    types,
                    status,
                    labelCustomers: string_general.placeholder_select,
                    labelTypes: string_general.placeholder_select,
                    labelStatus: string_general.placeholder_select,
                    deliveries,
                    deliveryColumns,
                    transfers,
                    transferColumns
                }
            })


            hideLoading()
            filterData(deliveries, types[0].value)


        })
    }
}

export const getDeliveryColums = () => {
    const columns = [
        {
            Header: string_general.button_edit,
            accessor: 'manage',
            headerStyle: tableStyles.header,
            sortable: false,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: "PDF images",
            accessor: 'pdf',
            headerStyle: tableStyles.header,
            sortable: false,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_site,
            accessor: 'site',
            headerStyle: tableStyles.header,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },

        {
            Header: string_delivery.label_driver,
            accessor: 'driver',
            headerStyle: tableStyles.header,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_product_information,
            accessor: 'product',
            headerStyle: tableStyles.header,
            sortable: false,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_notes,
            accessor: 'notes',
            headerStyle: tableStyles.header,
            sortable: false,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_status,
            accessor: 'status',
            headerStyle: tableStyles.header,
            sortable: false,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_delivered_date_time,
            accessor: 'deliveredDate',
            headerStyle: tableStyles.header,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        }
    ]

    return columns
}

export const getTransferColums = () => {
    const columns = [
        {
            Header: string_general.button_edit,
            accessor: 'manage',
            headerStyle: tableStyles.header,
            sortable: false,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_site,
            accessor: 'site',
            headerStyle: tableStyles.header,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },

        {
            Header: string_delivery.label_driver_in,
            accessor: 'driverIn',
            headerStyle: tableStyles.header,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },

        {
            Header: string_delivery.label_driver_out,
            accessor: 'driverOut',
            headerStyle: tableStyles.header,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_product_information,
            accessor: 'product',
            headerStyle: tableStyles.header,
            sortable: false,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_notes,
            accessor: 'notes',
            headerStyle: tableStyles.header,
            sortable: false,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        },
        {
            Header: string_delivery.label_delivered_date_time,
            accessor: 'deliveredDate',
            headerStyle: tableStyles.header,
            Cell: props => <span>{props.value}</span>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        ...tableStyles.row,
                        background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                    }
                }
            }
        }
    ]

    return columns
}




export const filterData = (info) => {
    const { listItems, idCustomer, idType, idStatus, isTransfer } = info
    return (dispatch) => {
        var filterList = listItems.filter(function (item) {
            var validationCustomer = true
            var validationType = true
            var validationStatus = true
            if (idCustomer != 0) {
                validationCustomer = item.idCustomer == idCustomer
            }

            if (idType != 0) {
                validationType = item.idType == idType
            }
            if (!isTransfer) {
                if (idStatus != 0) {

                    validationStatus = item.idStatus == idStatus

                }
            }

            return validationCustomer && validationType && validationStatus

        });
        dispatch({
            type: SET_INPUT_DELIVERY,
            payload: { prop: 'itemsFilter', value: filterList }
        })

    }
}


export const setInputDelivery = ({ prop, value }) => {

    return (dispatch) => {
        dispatch({
            type: SET_INPUT_DELIVERY,
            payload: { prop, value }
        })
    }

}

// UPDATE DELIVERY



export const addProduct = (info) => {
    const { idProduct, productName, gallons, deliveryProducts } = info
    return (dispatch) => {
        if (idProduct === 0 || gallons === '') {
            showAlert({ msj: string_delivery.product_empty_error_message, type: 2 })
            return false
        } else {

            let filter = deliveryProducts.filter(item => item.id === idProduct)
            if (filter.length !== 0) {
                showAlert({ msj: string_delivery.product_error_message, type: 2 })
                return false
            } else {
                if (isNaN(gallons)) {
                    showAlert({ msj: string_delivery.gallons_error_message, type: 2 })
                    return false
                } else {
                    let newProduct = {
                        id: idProduct,
                        description: productName,
                        gallons: gallons
                    }

                    let newDeliveryProducts = [...deliveryProducts, newProduct]
                    dispatch({
                        type: SET_PRODUCT,
                        payload: { deliveryProducts: newDeliveryProducts }
                    })
                }
            }
        }
    }
}


export const deleteProduct = (info) => {
    const { deliveryProducts, item } = info
    return (dispatch) => {
        let filter = deliveryProducts.filter(e => e !== item)
        dispatch({
            type: SET_INPUT_DELIVERY,
            payload: { prop: 'deliveryProducts', value: filter }
        })
    }
}

export const handleChangeImages = (images) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_DELIVERY,
            payload: { prop: 'images', value: images }
        })
    }
};
export const handleRemoveImages = (images) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_DELIVERY,
            payload: { prop: 'images', value: images }
        })
    }
};

export const updateProductGallons = (info) => {
    const { deliveryProducts, idProduct, productName, gallons } = info
    return (dispatch) => {

        if (gallons === '' || isNaN(gallons)) {
            showAlert({ msj: string_delivery.gallons_error_message, type: 2 })
        } else {
            let updateProduct = {
                id: idProduct,
                description: productName,
                gallons: gallons
            }
            let filter = [...deliveryProducts.filter(e => e.id !== idProduct), updateProduct]

            dispatch({
                type: SET_PRODUCT_UPDATE,
                payload: { deliveryProducts: filter }

            })
        }
    }
}
export const updateDelivery = (info) => {
    const { token, idEvent, idDelivery, isDelivery, date, status, listProducts, notes, history, arrImages } = info

    let deliveryDate = Moment(date).format('YYYY-MM-DD HH:mm:ss');
    const gallery = JSON.stringify(arrImages)
    return (dispatch) => {



        if (listProducts.length == 0) {
            showAlert({ msj: string_delivery.empty_product_list_error_message, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_DELIVERY,
            payload: { prop: 'updatingDelivery', value: true }
        })


        sw_update_delivery({ token, idDelivery, isDelivery, deliveryDate, status, listProducts, notes, gallery }).then(data => {
            dispatch({
                type: SET_INPUT_DELIVERY,
                payload: { prop: 'updatingDelivery', value: false }
            })

            if (data === undefined) {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })
                return false
            }

            if (data.status === 200) {
                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }
                var message = string_delivery.update_transfer_success_message
                if (isDelivery) {
                    message = string_delivery.update_delivery_success_message
                }
                dispatch({
                    type: CLEAR_STATE_DELIVERY
                })
                showAlert({ msj: message, type: 1 })
                history.push(`/event/${idEvent}/deliveries`)
            } else {
                console.log("DATA ERROR: ", data)
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}




export const getDelivery = (info) => {
    const { token, idDelivery, isDelivery } = info

    return (dispatch) => {
        dispatch({
            type: GETTING_DELIVERY
        })

        const requests = [
            sw_get_active_products({ token }),
            sw_get_delivery_status({ token }),
            sw_get_delivery({ token, idDelivery, isDelivery })

        ]

        Promise.all(requests).then(function (values) {
            //PRODUCTS
            let products = [{ value: 0, description: string_delivery.label_dropdown_product }]
            if (values[0].status == 200) {
                const tempProducts = values[0].data
                if (tempProducts !== undefined) {
                    tempProducts.forEach(product => {
                        products.push(
                            {
                                value: product.id,
                                description: product.productName
                            }
                        )
                    });
                }
            }


            //STATUS
            let status = []
            if (values[1].status == 200) {
                const tempStatus = values[1].data
                if (tempStatus !== undefined) {
                    tempStatus.forEach(object => {
                        status.push(
                            {
                                value: object.id,
                                description: object.status
                            }
                        )
                    });
                }
            }
            let delivery = {}
            console.log(values[2])
            if (values[2].status == 200) {
                const tempDelivery = values[2].data

                console.log(tempDelivery)

                if (tempDelivery !== undefined) {
                    console.log(tempDelivery)
                    let products = []
                    if (tempDelivery.products.length !== 0) {
                        tempDelivery.products.forEach(product => {
                            products.push(
                                {
                                    id: product.product.id,
                                    description: product.product.productName,
                                    gallons: product.gallons
                                }
                            )
                        });
                    }


                    if (isDelivery == 1) {
                        delivery = {
                            driver: tempDelivery.driverName,
                            driverIn: '',
                            driverOut: '',
                            idStatus: tempDelivery.idStatus,
                            notes: tempDelivery.Notes,
                            deliveryDate: tempDelivery.deliveryDate,
                            products: products,
                            images: JSON.parse(tempDelivery.images ?? '[]')

                        }
                    } else {
                        delivery = {
                            driver: '',
                            driverIn: tempDelivery.driverNameIn,
                            driverOut: tempDelivery.driverNameOut,
                            idStatus: 0,
                            notes: tempDelivery.Notes,
                            deliveryDate: tempDelivery.deliveryDate,
                            products: products,
                            images: JSON.parse(tempDelivery.images ?? '[]')
                        }

                    }




                }
            }
            dispatch({
                type: SET_DELIVERY,
                payload: {
                    status,
                    products,
                    delivery
                }
            })


        })
    }
}

export const getProducts = (info) => {
    const { token } = info

    return (dispatch) => {
        dispatch({
            type: GETTING_DELIVERY
        })


        sw_get_active_products({ token }).then(data => {

            let products = [{ value: 0, description: string_delivery.label_dropdown_product }]
            if (data.status == 200) {
                const tempProducts = data.data
                if (tempProducts !== undefined) {
                    tempProducts.forEach(product => {
                        products.push(
                            {
                                value: product.id,
                                description: product.productName
                            }
                        )
                    });
                }
            }

            dispatch({
                type: SET_INPUT_DELIVERY,
                payload: { prop: 'productList', value: products }
            })

        })

    }
}

export const reportGallons = (info) => {
    const { token, listProducts, idDriver, idDispatch, note, history, idEvent } = info
    console.log('info', info)
    return (dispatch) => {
        dispatch({
            type: GETTING_DELIVERY
        })


        sw_report_gallons({
            listProducts,
            idUser: idDriver,
            idDispatch,
            note,
            idMyUser: idDriver,
            token,
            history,
        }).then(data => {

            if (data.status == 200) {

                var message = string_delivery.update_delivery_success_message

                dispatch({
                    type: CLEAR_STATE_DELIVERY
                })
                showAlert({ msj: message, type: 1 })
                history.push(`/event/${idEvent}/dispatches`)

            } else {
                console.log("DATA ERROR: ", data)
                showAlert({ msj: data.data.error, type: 3 })
            }



        })

    }
}
