import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

// ICONS
import User from '@material-ui/icons/PermIdentityOutlined';
import Address from '@material-ui/icons/AddLocationOutlined';
import City from '@material-ui/icons/LocationCityOutlined';
import ZipCode from '@material-ui/icons/RoomOutlined';
import Location from '@material-ui/icons/EditLocationOutlined';
import GroupNumber from '@material-ui/icons/Filter1Outlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import DropdownSearch from '../../components/DropdownSearch'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import './CreateSite.css';

// ACTIONS
import { clearState, getDataSite, setInputSite, createSite, setSiteRepeated } from '../../../modules/core/Site/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { sitesStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_customer } from '../../../modules/resources/strings'
import { validateZipCode, isEmpty } from '../../../modules/resources/Util'

import Swal from 'sweetalert2';

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class CreateSite extends React.Component {

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getDataSite({
        token: this.props.user.token
      })
    }

    if (this.props.customer === undefined) {
      this.configMenuItems()
    }


  }

  configMenuItems() {
    
    this.props.setInputUser({ prop: 'isEdited', value: false })
    const idCustomer = this.props.match.params.customer
    
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [{
        id: 1,
        label: 'Sites',
        icon: 'tim-icons icon-square-pin',
        link: `/customer/${idCustomer}/sites`
      }]
    })
    this.props.setInputUser({ prop: 'value', value: 3 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/customers`,
          label: 'Customers'
        },
        {
          link: `/customers`,
          label: this.props.infoLabel
        },
        {
          link: `/customer/${this.props.match.params.customer}/sites`,
          label: 'Sites'
        },
        {
          link: `/customer/${this.props.match.params.customer}/create_site`,
          label: 'Create Site'
        }]
    })
  }

  componentWillUnmount() {
    if (!isEmpty(this.props.siteName) || !isEmpty(this.props.address) ||
      !isEmpty(this.props.city) || !isEmpty(this.props.notes) ||
      !isEmpty(this.props.zipCode) || !isEmpty(this.props.latitude) ||
      !isEmpty(this.props.longitude) || !isEmpty(this.props.groupNumber) ||
      !isEmpty(this.props.stateSite)) {
      if (!window.confirm(string_general.leaveMsjCreate)) {
        const customerId = this.props.match.params.customer === undefined ? this.props.customer : this.props.match.params.customer;
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push(`/customer/${customerId}/create_site`)
      } else {
        this.props.clearState()
       
      }
    }
  }

  render() {
    const { classes, siteRepeated, existentSiteName, setSiteRepeated } = this.props;

    const continueOnSiteRepeated = (existentSiteName) => {
      Swal.fire({
        title: "Site coordinates already exist",
        text: `Existing Site Name: ${existentSiteName}`,
        confirmButtonText: "Insert anyway",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel"
      }).then(res => {
        if (res.isConfirmed) {
          setSiteRepeated(false);
          //Insert the site
          this.props.createSite({
            token: this.props.user.token,
            customer: this.props.match.params.customer === undefined ? this.props.customer : this.props.match.params.customer,
            siteName: this.props.siteName,
            address: this.props.address,
            city: this.props.city,
            fk_idState: this.props.stateSite,
            zipCode: this.props.zipCode,
            latitude: this.props.latitude,
            longitude: this.props.longitude,
            groupNumber: this.props.groupNumber,
            notes: this.props.notes,
            isFromEvent: this.props.customer,
            createSiteFromEvent: this.props.createSiteFromEvent,
            history: this.props.history,
            continueOnSiteRepeated: continueOnSiteRepeated,
            createStatusError: 1
          });
        }
        setSiteRepeated(false);
      })
    }

    if(siteRepeated){
      continueOnSiteRepeated(existentSiteName);
    }
    /**
     * To determine if want to proceed create a site even if already exists. 
     * 0 = No asked before (First attempt)
     * 1 = Want to create 
     * -1 = Don't want to create the site
     */

    return (
      this.props.creatingSite ?
        <Loading label={string_customer.creating_site} />
        :
        <div style={{ ...sitesStyles.container, alignItems: 'center' }}>

          {/* TITLE */}
          <span style={sitesStyles.title}>{string_customer.title_create_site}</span>
          <ThemeProvider theme={mainTheme}>
            <div className={classes.sectionResponsive}>

              {/* SECTION #1 */}
              <div style={sitesStyles.containerDivResponsive}>

                {/* SITE NAME */}
                <Input
                  id="SiteName"
                  label="Site Name"
                  placeholder="Enter site name - "
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.siteName}
                  onChange={value => this.props.setInputSite({ prop: 'siteName', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* ADDRESS */}
                <Input
                  id="Address"
                  label="Address"
                  placeholder="Enter site address"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.address}
                  onChange={value => this.props.setInputSite({ prop: 'address', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Address className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* CITY */}
                <Input
                  isRequired
                  id="City"
                  label="City"
                  placeholder="Enter site city"
                  type
                  className={classes.margin}
                  value={this.props.city}
                  onChange={value => this.props.setInputSite({ prop: 'city', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <City className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* STATE */}
                <div style={sitesStyles.containerDropdownStates}>
                  <DropdownSearch
                    selectItem={this.props.stateSite}
                    label='States'
                    placeholder={this.props.labelStates}
                    options={this.props.arrayStates}
                    onChange={(value, description) => {
                      this.props.setInputSite({ prop: 'stateSite', value })
                    }}
                  />
                  <div style={sitesStyles.required}> * </div>
                </div>

                {/* ZIP CODE */}
                <Input
                  isRequired
                  id="ZipCode"
                  label="Zip Code"
                  placeholder="Enter site zip code"
                  type
                  className={classes.margin}
                  value={this.props.zipCode}
                  onChange={value => {
                    if (validateZipCode(value)) {
                      this.props.setInputSite({ prop: 'zipCode', value })
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ZipCode className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

              </div>

              {/* SECTION #2 */}
              <div style={sitesStyles.containerDivResponsive}>

                <span>GPS DATA</span>

                {/* LATITUDE */}
                <Input
                  id="Latitude"
                  label="Latitude"
                  placeholder="Enter site latitude"
                  type
                  className={classes.margin}
                  value={this.props.latitude}
                  onChange={value => this.props.setInputSite({ prop: 'latitude', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Location className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* Longitude */}
                <Input
                  id="Longitude"
                  label="Longitude"
                  placeholder="Enter site longitude"
                  type
                  className={classes.margin}
                  value={this.props.longitude}
                  onChange={value => this.props.setInputSite({ prop: 'longitude', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Location className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* GROUP NUMBER */}
                <Input
                  id="GroupNumber"
                  label="Group Number"
                  placeholder="Enter site group number"
                  type
                  className={classes.margin}
                  value={this.props.groupNumber}
                  onChange={value => this.props.setInputSite({ prop: 'groupNumber', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GroupNumber className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* SITE NOTES */}
                <TextareaAutosize
                  style={{ height: 150, width: '90%' }}
                  aria-label="maximum height"
                  placeholder="Site notes"
                  value={this.props.notes}
                  onChange={event => this.props.setInputSite({ prop: 'notes', value: event.target.value })}
                />

                {/* BUTTON */}
                <div style={sitesStyles.containerButton}>
                  <span>{string_general.required_fields}</span>
                  <Button
                    label={string_general.button_create}
                    onPress={() =>
                      this.props.createSite({
                        token: this.props.user.token,
                        customer: this.props.match.params.customer === undefined ? this.props.customer : this.props.match.params.customer,
                        siteName: this.props.siteName,
                        address: this.props.address,
                        city: this.props.city,
                        fk_idState: this.props.stateSite,
                        zipCode: this.props.zipCode,
                        latitude: this.props.latitude,
                        longitude: this.props.longitude,
                        groupNumber: this.props.groupNumber,
                        notes: this.props.notes,
                        isFromEvent: this.props.customer,
                        createSiteFromEvent: this.props.createSiteFromEvent,
                        history: this.props.history,
                        continueOnSiteRepeated: continueOnSiteRepeated,
                        createStatusError: 0
                      })

                    }
                    width={'100%'}
                    fontSize={15} />

                </div>

              </div>

            </div>
          </ThemeProvider>
        </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel, isEdited } = state.user
  const {
    creatingSite,

    siteName,
    address,
    city,
    notes,
    zipCode,
    latitude,
    longitude,
    groupNumber,
    stateSite,

    labelStates,
    arrayStates,

    siteRepeated,
    existentSiteName,
  } = state.site

  return {
    isAuthenticated, user, infoLabel, isEdited,

    creatingSite,

    siteName,
    address,
    city,
    notes,
    zipCode,
    latitude,
    longitude,
    groupNumber,
    stateSite,

    labelStates,
    arrayStates,
    
    siteRepeated,
    existentSiteName
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getDataSite,
  setInputSite,
  createSite,
  setInputUser,
  setSiteRepeated
})(CreateSite)))

