import {
    SET_AUTHENTICATION,
    SET_ITEM_USER
} from './types'


export const setAuthentication = (isAuthenticated) => {
    return (dispatch) => {
        dispatch({
            type: SET_AUTHENTICATION,
            payload: isAuthenticated
        })
    }
}

export const setInputUser = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_ITEM_USER,
            payload: { prop, value }
        })
    }
}