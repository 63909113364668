import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash'

import { subcontractorStyles } from '../../../modules/resources/styles'
import { getSubcontractorCompanies, filterByStatsSubcontractorCompany } from '../../../modules/core/SubcontractorCompany/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Table from '../../components/Table'

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListSubcontractorCompany extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    statusSelected: 1
  }

  componentDidMount() {
      

      this.props.getSubcontractorCompanies({
        token: this.props.user.token,
        success: () => this.setState({ loading: false }),
        showError: (error) => { this.setState({ loading: false }) },
        history: this.props.history
      })

      this.configMenuItems()
      window.scrollTo(0, 0);

  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'itemsSubmenu', value: [] })
    this.props.setInputUser({ prop: 'value', value: 2 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })
    this.props.setInputUser({
      prop: 'infoLabel',
      value: ''
    })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [{
        link: `/subcontractor_companies`,
        label: 'Subcontractor'
      },
      {
        link: `/subcontractor_companies`,
        label: 'Companies'
      }]
    })
  }

  render() {
    return (
      <div style={subcontractorStyles.container}>
        <div style={subcontractorStyles.containerFilters}>
          <div style={subcontractorStyles.containerDivfilters}>
            <div style={subcontractorStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_status} </label>
              <Dropdown
                selectItem={this.state.statusSelected}
                placeholder={string_general.placeholder_select_status}
                options={[
                  { value: 0, description: 'All' },
                  { value: 1, description: 'Active' },
                  { value: 2, description: 'Inactive' }]}
                onChange={(value, description) => {
                  this.setState({ statusSelected: value })
                  this.props.filterByStatsSubcontractorCompany({
                    listItems: this.props.listItems,
                    status: description
                  })
                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }
                } />
            </div>
          </div>

          <div style={subcontractorStyles.containerButtonCreate}>
            <Button
              onPress={() => { this.props.history.push("/create_subcontractor_company") }}
              width={'100%'}
              label={string_general.label_button_add}
              fontSize={12}
            />
          </div>

        </div>

        <Table
          numberPages={this.state.numberPages}
          loading={this.state.loading}
          columns={this.props.columns}
          items={this.props.listItemsFilter} />
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user } = state.user
  const { columns, listItems, listItemsFilter } = state.company
  console.log("listItemsFilter: ", listItemsFilter)
  return { isAuthenticated, user, columns, listItems, listItemsFilter: _.orderBy(listItemsFilter, ["name"], ['asc']) }
}

export default withStyles(styles)(withRouter(connect(mapStateProps,
  {
    getSubcontractorCompanies,
    filterByStatsSubcontractorCompany,
    setInputUser
  })(ListSubcontractorCompany)))

