import React from "react";
import _ from 'lodash'

import {
    CLEAR_STATE_ADMIN,
    LOAD_SUPER_ADMINS,
    SET_INPUT_ADMIN,
    GETTING_ADMIN,
    GOT_ADMIN,
    ERROR_GETTING_ADMIN
} from './types'

import { sw_get_admins, sw_create_admin, sw_get_admin, sw_update_admin, sw_get_status } from '../../services/AdminApi'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { validateEmail, validatePassword, showAlert, isEmpty } from '../../resources/Util'

export const clearState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_STATE_ADMIN })
    }
}

export const createUserAdmin = (info) => {
    const { token, firstname, lastname, email, password, confirmPassword, history } = info
    return (dispatch) => {
        if (isEmpty(firstname) || isEmpty(lastname) || isEmpty(email) || isEmpty(password) ||
            isEmpty(confirmPassword)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (password !== confirmPassword) {
            showAlert({ msj: string_general.errorMsj.differentPasswords, type: 2 })
            return false
        } else if (!validatePassword(password)) {
            showAlert({
                msj: `${string_general.errorMsj.password} ${string_general.formatPassword_msj}`,
                type: 2,
                duration: 5000
            })
            return false
        }


        dispatch({
            type: SET_INPUT_ADMIN,
            payload: { prop: 'creatingAdmin', value: true }
        })
        sw_create_admin({
            token,
            email,
            password,
            "firstName": firstname,
            "lastName": lastname,
            "fk_idUserType": 2
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_ADMIN,
                    payload: { prop: 'creatingAdmin', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_ADMIN,
                        payload: { prop: 'creatingAdmin', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Admin created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_ADMIN
                })
                history.push("/admins")
            } else {
                dispatch({
                    type: SET_INPUT_ADMIN,
                    payload: { prop: 'creatingAdmin', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const updateUserAdmin = (info) => {
    let { token, id, firstname, lastname, email, status, password, confirmPassword, history } = info
    return (dispatch) => {

        if (isEmpty(id) || isEmpty(firstname) || isEmpty(lastname) ||
            isEmpty(email) || isEmpty(status)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (!validateEmail(email)) {
            showAlert({ msj: string_general.errorMsj.formatEmail, type: 2 })
            return false
        } else if (!isEmpty(password) || !isEmpty(confirmPassword)) {
            if (password !== confirmPassword) {
                showAlert({ msj: string_general.errorMsj.differentPasswords, type: 2 })
                return false
            } else if (!validatePassword(password)) {
                showAlert({
                    msj: `${string_general.errorMsj.password} ${string_general.formatPassword_msj}`,
                    type: 2,
                    duration: 5000
                })
                return false
            }
        }

        if(isEmpty(password)){
            password = undefined
        }
        dispatch({
            type: SET_INPUT_ADMIN,
            payload: { prop: 'updatingAdmin', value: true }
        })

        sw_update_admin({
            token,
            id,
            email,
            password,
            "firstName": firstname,
            "lastName": lastname,
            "idStatus": status
        }).then(data => {

            dispatch({
                type: SET_INPUT_ADMIN,
                payload: { prop: 'updatingAdmin', value: false }
            })

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }

                showAlert({ msj: 'Admin updated successful', type: 1 })
                dispatch({
                    type: SET_INPUT_ADMIN,
                    payload: { prop: 'updatingAdmin', value: false }
                })
                dispatch({
                    type: CLEAR_STATE_ADMIN
                })
                history.push("/admins")
            } else {
                dispatch({
                    type: SET_INPUT_ADMIN,
                    payload: { prop: 'updatingAdmin', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })
    }
}

export const getUserAdmin = (info) => {
    const { token, id } = info
    return (dispatch) => {
        dispatch({
            type: GETTING_ADMIN
        })

        const requests = [
            sw_get_admin({ token, id }),
            sw_get_status({ token }),
        ]

        Promise.all(requests).then(function (values) {

            const data = values[0]
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_ADMIN
                })
                return false
            }

            if (data.status === 200) {
                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_ADMIN
                    })
                    return false
                }

                let listStatus = []
                const statusTemp = values[1].data

                statusTemp.forEach(e => {
                    if (e.id !== 3) {
                        listStatus.push({
                            value: e.id,
                            description: e.status
                        })
                    }
                })

                const admin = data.data
                dispatch({
                    type: GOT_ADMIN,
                    payload: {
                        firstName: admin.firstName,
                        lastName: admin.lastName,
                        email: admin.email,
                        status: admin.status.id,
                        listStatus
                    }
                })
            } else {
                dispatch({
                    type: ERROR_GETTING_ADMIN
                })
                showAlert({ msj: 'Admin not found', type: 2 })
            }
        })

        sw_get_admin({ token, id }).then(data => {


        })
    }
}

export const getUserAdmins = (info) => {
    const { token, success, showError, history } = info
    return (dispatch) => {

        const requests = [
            sw_get_admins({ token }),
            sw_get_status({ token }),
        ]

        Promise.all(requests).then(function (values) {

            const columns = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 100,
                    sortable: false,
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'First Name',
                    accessor: 'firstName',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }, {
                    Header: 'Last Name',
                    accessor: 'lastName',
                    headerStyle: tableStyles.header,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }, {
                    Header: 'Status',
                    accessor: 'status',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            let items = []
            const data = values[0]
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            } else {
                if (data.status === 200) {
                    const tempAdmins = data.data

                    if (!data.data.success && data.data.success !== undefined) {
                        showAlert({ msj: data.data.message, type: 3 })
                        showError()
                    } else {
                        tempAdmins.forEach(admin => {
                            items.push(
                                {
                                    manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/edit_admin/${admin.id}`)}>Edit</a>,
                                    firstName: admin.firstName,
                                    lastName: admin.lastName,
                                    status: admin.status.status,
                                    updated_at: admin.updated_at
                                }
                            )
                        });
                        success()
                    }
                } else {
                    showError()
                }
            }
            items = _.orderBy(items, 'lastName', 'asc')

            let listStatus = [{
                value: 0,
                description: 'All'
            }]
            const statusTemp = values[1].data

            statusTemp.forEach(e => {
                if (e.id !== 3) {
                    listStatus.push({
                        value: e.id,
                        description: e.status
                    })
                }
            })

            dispatch({
                type: LOAD_SUPER_ADMINS,
                payload: {
                    columns,
                    items,
                    listItemsFilter: items.filter(e => e.status === 'Active'),
                    listStatus
                }
            })
        })



        sw_get_admins({ token }).then(data => {


        })
    }
}

export const setInputAdmin = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_ADMIN,
            payload: { prop, value }
        })
    }
}

export const filterByStatsAdmin = (info) => {
    const { listItems, status } = info
    return (dispatch) => {
        let filter = (status !== 'All') ? listItems.filter(item => item.status === status) : listItems
        dispatch({
            type: SET_INPUT_ADMIN,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}