import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash"

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import User from '@material-ui/icons/PermIdentityOutlined';
import Car from '@material-ui/icons/DirectionsCarOutlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import ChooseColor from '../../components/ChooseColor'
import RadioButton from '../../components/RadioButton'
import NotFound from '../../components/ResourceNotFound'
import Table from '../../components/Table'

// ACTIONS
import {
  clearState,
  setInputSubcontractorTruck,
  getOptionalDataTruck,
  updateSubcontractorTruck,
  filterDrivers_Assistant,
  getSubcontractorTruck,
  filterByEvent
} from '../../../modules/core/SubcontractorTruck/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { subcontractorTruckStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_subcontractor } from '../../../modules/resources/strings'
import { validateLicense, isEmpty } from '../../../modules/resources/Util';

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class EditSubcontractorTruck extends React.Component {

  componentDidMount() {

    if (!this.props.isEdited) {
      const company = this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id
      this.props.getOptionalDataTruck({
        token: this.props.user.token,
        company,
        event: this.props.match.params.event, //WHEN SHOW THE TRUCKS FROM EVENT
        getTruck: () => this.props.getSubcontractorTruck({
          token: this.props.user.token,
          id: this.props.match.params.id,
          gotTruck: (
            eventSelect = null,
            typeTruckSelect = 1,
            driverSelect = null,
            assistantSelect = null,
            color = '') => {
            this.props.setInputSubcontractorTruck({ prop: 'eventSelect', value: eventSelect })
            this.props.setInputSubcontractorTruck({ prop: 'typeTruckSelect', value: typeTruckSelect })
            this.props.setInputSubcontractorTruck({ prop: 'driverSelect', value: driverSelect })
            this.props.setInputSubcontractorTruck({ prop: 'assistantSelect', value: assistantSelect })
            this.props.setInputSubcontractorTruck({ prop: 'color', value: color })
          }
        })
      })
    }

    this.confiSiteNavigations()
    this.configMenuItems()
  }

  confiSiteNavigations() {
    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor_companies`,
              label: 'Subcontractor'
            },
            {
              link: `/subcontractor_companies`,
              label: this.props.infoLabel
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/trucks`,
              label: 'Trucks'
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/edit_truck/${this.props.match.params.id}`,
              label: 'Update Truck'
            }]
        })
      } else {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor/trucks`,
              label: 'Trucks'
            },
            {
              link: `/subcontractor/edit_truck/${this.props.match.params.id}`,
              label: 'Create Truck'
            }]
        })
      }
    } else {
      this.props.setInputUser({
        prop: 'siteNavigations',
        value: [
          {
            link: `/events`,
            label: 'Events'
          },
          {
            link: `/events`,
            label: this.props.infoLabel
          },
          {
            link: `/event/${this.props.match.params.event}/trucks`,
            label: 'Trucks'
          },
          {
            link: `/event/${this.props.match.params.event}/company/${this.props.match.params.company}/edit_truck/${this.props.match.params.id}`,
            label: 'Update Truck'
          }]
      })
    }
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        const idCompany = this.props.match.params.company
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [
            {
              id: 1,
              label: 'Subcontractor admins',
              icon: 'tim-icons icon-book-bookmark',
              link: `/subcontractor/${idCompany}/admins`
            },
            {
              id: 2,
              label: 'Drivers',
              icon: 'tim-icons icon-badge',
              link: `/subcontractor/${idCompany}/drivers`
            },
            {
              id: 3,
              label: 'Helpers',
              icon: 'tim-icons icon-single-02',
              link: `/subcontractor/${idCompany}/helpers`
            },
            {
              id: 4,
              label: 'Trucks',
              icon: 'tim-icons icon-delivery-fast',
              link: `/subcontractor/${idCompany}/trucks`
            },
          ]
        })
        this.props.setInputUser({ prop: 'value', value: 2 })
        this.props.setInputUser({ prop: 'itemSelect', value: 4 })
      } else {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: []
        })
        this.props.setInputUser({ prop: 'value', value: 4 })
        this.props.setInputUser({ prop: 'itemSelect', value: 1 })
      }
    } else {
      const idEvent = this.props.match.params.event
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          },
          {
            id: 4,
            label: 'Dispatch',
            icon: 'tim-icons icon-vector',
            link: `/event/${idEvent}/dispatches`
          },
          {
            id: 5,
            label: 'Deliveries',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/deliveries`

          },
          {
            id: 6,
            label: 'Transfers',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/create_transfer`
    
          },
        
          {
            id: 7,
            label: 'Tracking',
            icon: 'tim-icons icon-world',
            link: `/event/${idEvent}/tracking`
          },
          {
            id: 8,
            label: 'Report',
            icon: 'tim-icons icon-notes',
            link: `/event/${idEvent}/reports`
          }]
        })
        this.props.setInputUser({ prop: 'value', value: 4 })
      } else {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          }]
        })
        this.props.setInputUser({ prop: 'value', value: 1 })
      }
      this.props.setInputUser({ prop: 'itemSelect', value: 1 })
    }
  }

  componentWillUnmount() {

    if (!isEmpty(this.props.truckNumber) || !isEmpty(this.props.comparmentsNumber) ||
      !isEmpty(this.props.pumpsNumber) || !isEmpty(this.props.totalCapacity) ||
      !isEmpty(this.props.make) || !isEmpty(this.props.licenseNumber) ||
      (this.props.eventSelect !== 0 && this.props.match.params.event === undefined) || this.props.driverSelect !== 0
      || this.props.assistantSelect !== 0) {
      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        if (this.props.match.params.event === undefined) {
          if (this.props.userType !== 3) {
            this.props.history.push(`/subcontractor/${this.props.match.params.company}/edit_truck/${this.props.match.params.id}`)
          } else {
            this.props.history.push(`/subcontractor/edit_truck/${this.props.match.params.id}`)
          }
        } else {
          this.props.history.push(`/event/${this.props.match.params.event}/company/${this.props.match.params.company}/edit_truck/${this.props.match.params.id}`)
        }
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      this.props.updatingSubcontractorTruck ?
        <Loading label={this.props.gettingSubcontractorTruck ? string_subcontractor.getting_subcontractor_truck : string_subcontractor.updating_subcontractor_truck} />
        : this.props.truckFound ?
          <div style={{ ...subcontractorTruckStyles.container, alignItems: 'center' }}>

            {/* TITLE */}
            <span style={subcontractorTruckStyles.title}>{string_subcontractor.title_update_truck}</span>
            <ThemeProvider theme={mainTheme}>

              <div className={classes.sectionResponsive}>

                <div style={subcontractorTruckStyles.containerDivResponsive}>

                  {/* TRUCK TYPE */}
                  <RadioButton
                    title='Select truck type *'
                    radios={this.props.typesTruck}
                    itemSelect={this.props.typeTruckSelect}
                    onChange={(value) => this.props.setInputSubcontractorTruck({ prop: 'typeTruckSelect', value })}
                  />

                  {/* TRUCK NUMBER */}
                  <Input
                    id="TruckNumber"
                    label="Truck Number"
                    placeholder="Enter truck number"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.truckNumber}
                    onChange={value => this.props.setInputSubcontractorTruck({ prop: 'truckNumber', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Car className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* COMPARTMENTS */}
                  <Input
                    id="Comparments"
                    label="Number of Compartments"
                    placeholder="Enter truck number compartments"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.comparmentsNumber}
                    onChange={value => this.props.setInputSubcontractorTruck({ prop: 'comparmentsNumber', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Car className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* PUMPS */}
                  <Input
                    id="Pumps"
                    label="Number of Pumps"
                    placeholder="Enter truck number pumps"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.pumpsNumber}
                    onChange={value => this.props.setInputSubcontractorTruck({ prop: 'pumpsNumber', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Car className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* TOTAL CAPACITY */}
                  <Input
                    id="TotalCapacity"
                    label="Total Capacity"
                    placeholder="Enter truck total capacity"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.totalCapacity}
                    onChange={value => this.props.setInputSubcontractorTruck({ prop: 'totalCapacity', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Car className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* Make */}
                  <Input
                    id="Make"
                    label="Make"
                    placeholder="Enter truck make"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.make}
                    onChange={value => this.props.setInputSubcontractorTruck({ prop: 'make', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Car className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                </div>

                <div style={subcontractorTruckStyles.containerDivResponsive}>

                  {/* LICENSE */}
                  <Input
                    id="License"
                    label="Number License"
                    placeholder="Enter truck license"
                    type
                    className={classes.margin}
                    value={this.props.licenseNumber}
                    onChange={value => {
                      if (validateLicense(value))
                        this.props.setInputSubcontractorTruck({ prop: 'licenseNumber', value })
                    }}

                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Car className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* COLOR */}
                  <ChooseColor
                    icon='tim-icons icon-delivery-fast'
                    color={this.props.color}
                    selectColor={(color) => this.props.setInputSubcontractorTruck({ prop: 'color', value: color })}
                  />

                  {/* STATUS */}
                  <div style={subcontractorTruckStyles.containerDropdown}>
                    <Dropdown
                      selectItem={this.props.status}
                      placeholder={string_general.label_dropdown_status}
                      options={[{ value: 1, description: 'Active' }, { value: 2, description: 'Inactive' }]}
                      onChange={(value) => this.props.setInputSubcontractorTruck({ prop: 'status', value })}
                    />
                    <div style={subcontractorTruckStyles.required}> * </div>
                  </div>

                  {/* EVENT */}
                  {
                    this.props.match.params.event === undefined ?
                      <div style={{ ...subcontractorTruckStyles.containerDropdown, zIndex: 2, }}>
                        <Dropdown
                          selectItem={this.props.eventSelect}
                          placeholder={this.props.labelEvents}
                          options={this.props.events}
                          onChange={(value) => {
                            this.props.setInputSubcontractorTruck({ prop: 'eventSelect', value })
                            this.props.setInputSubcontractorTruck({ prop: 'driverSelect', value: 0 })
                            this.props.setInputSubcontractorTruck({ prop: 'assistantSelect', value: 0 })
                            this.props.filterByEvent({
                              event: value,
                              drivers: this.props.drivers,
                              assistants: this.props.assistants
                            })
                          }}
                        />
                      </div>
                      : null}

                  {/* DRIVERS */}
                  <div style={{ ...subcontractorTruckStyles.containerDropdown, zIndex: 1, }}>
                    <Dropdown
                      selectItem={this.props.driverSelect}
                      placeholder={this.props.labelDrivers}
                      options={this.props.driversFilter}
                      onChange={(value) => {
                        this.props.setInputSubcontractorTruck({ prop: 'driverSelect', value })
                        this.props.filterDrivers_Assistant({
                          listItems: this.props.assistants,
                          id: value,
                          event: this.props.eventSelect,
                          prop: 'assistantsFilter'
                        })
                        if (value === this.props.assistantSelect) this.props.setInputSubcontractorTruck({ prop: 'assistantSelect', value: 0 })
                      }}
                    />
                  </div>

                  {/* ASSISTANTS */}
                  <div style={{ ...subcontractorTruckStyles.containerDropdown, zIndex: 1, }}>
                    <Dropdown
                      selectItem={this.props.assistantSelect}
                      placeholder={this.props.labelAssistants}
                      options={this.props.assistantsFilter}
                      onChange={(value) => {
                        this.props.setInputSubcontractorTruck({ prop: 'assistantSelect', value })
                        this.props.filterDrivers_Assistant({
                          listItems: this.props.drivers,
                          id: value,
                          event: this.props.eventSelect,
                          prop: 'driversFilter'
                        })
                        if (value === this.props.driverSelect) this.props.setInputSubcontractorTruck({ prop: 'driverSelect', value: 0 })
                      }}
                    />
                  </div>

                </div>

                <div style={subcontractorTruckStyles.containerDivResponsive}>

                  <Table
                    hidePaginationTop={true}
                    numberPages={5}
                    loading={false}
                    columns={this.props.columnsHistorial}
                    items={this.props.listItemsHistorial} />

                  {/* BUTTON CREATE */}
                  <div style={{ ...subcontractorTruckStyles.containerButton, marginTop: 50 }} >
                    <span>{string_general.required_fields}</span>
                    <Button
                      label={string_general.button_update}
                      onPress={() =>
                        this.props.updateSubcontractorTruck({
                          token: this.props.user.token,
                          company: this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id,
                          id: this.props.match.params.id,
                          truckNumber: this.props.truckNumber,
                          comparmentsNumber: this.props.comparmentsNumber,
                          pumpsNumber: this.props.pumpsNumber,
                          totalCapacity: this.props.totalCapacity,
                          licenseNumber: this.props.licenseNumber,
                          make: this.props.make,
                          fk_idTruckType: this.props.typeTruckSelect,
                          color: this.props.color,
                          idEvent: this.props.match.params.event !== undefined ? this.props.match.params.event : this.props.eventSelect,
                          idDriver: this.props.driverSelect,
                          idAssistant: this.props.assistantSelect,
                          fk_idStatus: this.props.status,
                          isFromEvent: this.props.match.params.event,
                          userType: this.props.userType,
                          history: this.props.history
                        })}
                      width={'100%'}
                      fontSize={15} />
                  </div>

                </div>
              </div>
            </ThemeProvider>
          </div>
          : <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <NotFound
              titleInfo='Driver not found'
              titleButton="Go to list drivers"
              goTo={() => this.props.history.push(`/subcontractor/${this.props.match.params.company}/drivers`)}
            />
          </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel, isEdited } = state.user
  const { updatingSubcontractorTruck, gettingSubcontractorTruck, truckFound,
    truckNumber, comparmentsNumber, pumpsNumber, totalCapacity, make, licenseNumber, status,
    typesTruck,
    labelEvents, events,
    labelDrivers, drivers, driversFilter,
    labelAssistants, assistants, assistantsFilter,
    columnsHistorial, listItemsHistorial,
    eventSelect,
    typeTruckSelect,
    driverSelect,
    assistantSelect,
    color } = state.subcontractorTruck
  return {
    isAuthenticated, user, infoLabel, userType: user.user.type.id, isEdited,
    updatingSubcontractorTruck, gettingSubcontractorTruck, truckFound,
    truckNumber, comparmentsNumber, pumpsNumber, totalCapacity, make, licenseNumber, status,
    typesTruck: typesTruck.filter(e => e.value !== 0 ),
    labelEvents, events,
    labelDrivers, drivers, driversFilter: _.orderBy(driversFilter, 'value', 'asc'),
    labelAssistants, assistants, assistantsFilter: _.orderBy(assistantsFilter, 'value', 'asc'),
    columnsHistorial, listItemsHistorial,
    eventSelect,
    typeTruckSelect,
    driverSelect,
    assistantSelect,
    color
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps,
  {
    clearState,
    setInputSubcontractorTruck,
    getOptionalDataTruck,
    updateSubcontractorTruck,
    filterDrivers_Assistant,
    getSubcontractorTruck,
    filterByEvent,
    setInputUser
  })(EditSubcontractorTruck)))

