import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash'

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';

// ICONS
import IconButton from '@material-ui/core/IconButton';
import Email from '@material-ui/icons/EmailOutlined';
import User from '@material-ui/icons/PermIdentityOutlined';
import Phone from '@material-ui/icons/Phone';
import Lock from '@material-ui/icons/LockOutlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import NotFound from '../../components/ResourceNotFound'
import Table from '../../components/Table'

// ACTIONS
import {
  clearState,
  setInputSubcontractorHelper,
  getOptionalDataHelper,
  getSubcontractorHelper, //CAMBIAR EL NOMBRE POR HELPER
  updateSubcontractorHelper,
  filterByEvent,
  getTrucksByCompany
} from '../../../modules/core/SubcontractorHelper/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { subcontractorHelperStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_subcontractor } from '../../../modules/resources/strings'
import { isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  }
});

class EditSubcontractorHelper extends React.Component {

  state = {
    showPassword: false,
    showConfirmPassword: false,
  }

  componentDidMount() {

    if (this.props.idCustomerSelected !== 0) {
      this.props.setInputSubcontractorHelper({ prop: 'customerSelected', value: this.props.idCustomerSelected })
    }

    if (!this.props.isEdited) {
      const company = this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id
      this.props.getOptionalDataHelper({
        token: this.props.user.token,
        company,
        getHelper: () => this.props.getSubcontractorHelper({
          token: this.props.user.token,
          id: this.props.match.params.id,
          gotHelper: (eventSelect = 0, truckSelect = 0, companySelected = 0) => {

            this.props.setInputSubcontractorHelper({ prop: 'eventSelect', value: eventSelect })
            this.props.setInputSubcontractorHelper({ prop: 'truckSelect', value: truckSelect })
            this.props.setInputSubcontractorHelper({ prop: 'companySelected', value: companySelected })

            if (this.props.match.params.event !== undefined) {
              this.props.getTrucksByCompany({
                token: this.props.user.token,
                company: companySelected,
                event: this.props.match.params.event,
                fullTrucks: this.props.trucks
              })
            }
          }
        })
      })
    }

    this.confiSiteNavigations()
    this.configMenuItems()
  }

  confiSiteNavigations() {
    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor_companies`,
              label: 'Subcontractor'
            },
            {
              link: `/subcontractor_companies`,
              label: this.props.infoLabel
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/helpers`,
              label: 'Helpers'
            },
            {
              link: `/subcontractor/${this.props.match.params.company}/edit_helper/${this.props.match.params.id}`,
              label: 'Update Helper'
            }]
        })
      } else {
        this.props.setInputUser({
          prop: 'siteNavigations',
          value: [
            {
              link: `/subcontractor/helpers`,
              label: 'Helpers'
            },
            {
              link: `/subcontractor/edit_helper/${this.props.match.params.id}`,
              label: 'Edit Helper'
            },
          ]
        })
      }

    } else {
      this.props.setInputUser({
        prop: 'siteNavigations',
        value: [
          {
            link: `/events`,
            label: 'Events'
          },
          {
            link: `/events`,
            label: this.props.infoLabel
          },
          {
            link: `/event/${this.props.match.params.event}/helpers`,
            label: 'Helpers'
          },
          {
            link: `/event/${this.props.match.params.event}/edit_helper/${this.props.match.params.id}`,
            label: 'Update Helper'
          }]
      })
    }
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    if (this.props.match.params.event === undefined) {
      if (this.props.userType !== 3) {
        const idCompany = this.props.match.params.company
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [
            {
              id: 1,
              label: 'Subcontractor admins',
              icon: 'tim-icons icon-book-bookmark',
              link: `/subcontractor/${idCompany}/admins`
            },
            {
              id: 2,
              label: 'Drivers',
              icon: 'tim-icons icon-badge',
              link: `/subcontractor/${idCompany}/drivers`
            },
            {
              id: 3,
              label: 'Helpers',
              icon: 'tim-icons icon-single-02',
              link: `/subcontractor/${idCompany}/helpers`
            },
            {
              id: 4,
              label: 'Trucks',
              icon: 'tim-icons icon-delivery-fast',
              link: `/subcontractor/${idCompany}/trucks`
            },
          ]
        })
        this.props.setInputUser({ prop: 'value', value: 2 })
        this.props.setInputUser({ prop: 'itemSelect', value: 3 })
      } else {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: []
        })
        this.props.setInputUser({ prop: 'value', value: 3 })
        this.props.setInputUser({ prop: 'itemSelect', value: 1 })
      }

    } else {
      const idEvent = this.props.match.params.event
      this.props.setInputSubcontractorHelper({ prop: 'eventSelect', value: idEvent })
      if (this.props.userType !== 3) {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          },
          {
            id: 4,
            label: 'Dispatch',
            icon: 'tim-icons icon-vector',
            link: `/event/${idEvent}/dispatches`
          },
          {
            id: 5,
            label: 'Deliveries',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/deliveries`

          },
          {
            id: 6,
            label: 'Transfers',
            icon: 'tim-icons icon-tag',
            link: `/event/${idEvent}/create_transfer`
    
          },
        
          {
            id: 7,
            label: 'Tracking',
            icon: 'tim-icons icon-world',
            link: `/event/${idEvent}/tracking`
          },
          {
            id: 8,
            label: 'Report',
            icon: 'tim-icons icon-notes',
            link: `/event/${idEvent}/reports`
          }]
        })
        this.props.setInputUser({ prop: 'value', value: 4 })
      } else {
        this.props.setInputUser({
          prop: 'itemsSubmenu', value: [{
            id: 1,
            label: 'Trucks',
            icon: 'tim-icons icon-delivery-fast',
            link: `/event/${idEvent}/trucks`
          },
          {
            id: 2,
            label: 'Drivers',
            icon: 'tim-icons icon-badge',
            link: `/event/${idEvent}/drivers`
          },
          {
            id: 3,
            label: 'Helpers',
            icon: 'tim-icons icon-single-02',
            link: `/event/${idEvent}/helpers`
          }]
        })
        this.props.setInputUser({ prop: 'value', value: 1 })
      }

      this.props.setInputUser({ prop: 'itemSelect', value: 3 })
    }
  }

  componentWillUnmount() {
    if (!isEmpty(this.props.firstName) || !isEmpty(this.props.lastName) ||
      !isEmpty(this.props.email) || !isEmpty(this.props.phone) ||
      (this.props.eventSelect !== 0 && this.props.match.params.event === undefined) || this.props.companySelected !== 0 ||
      this.props.truckSelect !== 0 || (this.props.match.params.event !== undefined && this.props.companySelected !== 0)) {
      if (!window.confirm(string_general.leaveMsjUpdate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        if (this.props.match.params.event === undefined) {
          if (this.props.userType !== 3) {
            this.props.history.push(`/subcontractor/${this.props.match.params.company}/edit_helper/${this.props.match.params.id}`)
          } else {
            this.props.history.push(`/subcontractor/edit_helper/${this.props.match.params.id}`)
          }
        } else {
          this.props.history.push(`/event/${this.props.match.params.event}/edit_helper/${this.props.match.params.id}`)
        }
      } else {
        this.props.clearState()
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      this.props.updatingSubcontractorHelpers ?
        <Loading label={this.props.gettingSubcontractorHelper ? string_subcontractor.getting_subcontractor_helper : string_subcontractor.updating_subcontractor_helper} />
        : this.props.helperFound ?
          <div style={{ ...subcontractorHelperStyles.container, alignItems: 'center' }}>

            {/* TITLE */}
            <span style={subcontractorHelperStyles.title}>{string_subcontractor.title_update_helper}</span>
            <ThemeProvider theme={mainTheme}>
              <div className={classes.sectionResponsive}>

                <div style={subcontractorHelperStyles.containerDivResponsive}>

                  {/* FIRSTNAME */}
                  <Input
                    id="Firstname"
                    label="First Name"
                    placeholder="Enter helper first name"
                    isRequired
                    type
                    className={classes.margin}
                    value={this.props.firstName}
                    onChange={value => this.props.setInputSubcontractorHelper({ prop: 'firstName', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <User className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* LASTNAME */}
                  <Input
                    isRequired
                    id="Lastname"
                    label="Last Name"
                    placeholder="Enter helper last name"
                    type
                    className={classes.margin}
                    value={this.props.lastName}
                    onChange={value => this.props.setInputSubcontractorHelper({ prop: 'lastName', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <User className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* EMAIL */}
                  <Input
                    isRequired
                    id="Email"
                    label="Email"
                    placeholder="Enter helper email"
                    type
                    className={classes.margin}
                    value={this.props.email}
                    onChange={value => this.props.setInputSubcontractorHelper({ prop: 'email', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* PHONE */}
                  <Input
                    isRequired
                    id="Phone"
                    label="Number Phone"
                    placeholder="Enter helper phone"
                    phone
                    className={classes.margin}
                    value={this.props.phone}
                    onChange={value => this.props.setInputSubcontractorHelper({ prop: 'phone', value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />
                </div>

                <div style={subcontractorHelperStyles.containerDivResponsive}>

                  {/* STATUS */}
                  <div style={subcontractorHelperStyles.containerDropdown}>
                    <Dropdown
                      placeholder={string_general.label_dropdown_status}
                      selectItem={this.props.status}
                      onChange={(value, description) => this.props.setInputSubcontractorHelper({ prop: 'status', value })}
                      options={this.props.listStatus}
                    />
                    <div style={subcontractorHelperStyles.required}> * </div>
                  </div>

                  {/* PASSWORD */}
                  <Input
                    id="password"
                    label="Password"
                    placeholder="Enter helper password"
                    info
                    type={this.state.showPassword}
                    className={classes.margin}
                    value={this.props.password}
                    onChange={value => this.props.setInputSubcontractorHelper({ prop: 'password', value })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />
                  {/* CONFIRM PASSWORD */}
                  <Input
                    id="ConfirmPassword"
                    label="Confirm password"
                    placeholder="Enter helper confirm password"
                    info
                    type={this.state.showConfirmPassword}
                    className={classes.margin}
                    value={this.props.confirmPassword}
                    onChange={value => this.props.setInputSubcontractorHelper({ prop: 'confirmPassword', value })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="Toggle password visibility" onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })}>
                            {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock className={classes.margin} color="secondary" />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                  />

                  {/* EVENT */}
                  {
                    this.props.match.params.event === undefined ?
                      <div style={{ ...subcontractorHelperStyles.containerDropdown, zIndex: 2, }}>
                        <Dropdown
                          selectItem={this.props.eventSelect}
                          placeholder={this.props.labelEvents}
                          options={this.props.events}
                          onChange={(value) => {
                            this.props.setInputSubcontractorHelper({ prop: 'eventSelect', value })
                            this.props.setInputSubcontractorHelper({ prop: 'truckSelect', value: 0 })
                            this.props.filterByEvent({
                              event: value,
                              trucks: this.props.trucks
                            })
                          }
                          }
                        />
                      </div> : null
                  }

                  {/* TRUCKS */}
                  <div style={{ ...subcontractorHelperStyles.containerDropdown, zIndex: 1, }}>
                    <Dropdown
                      selectItem={this.props.truckSelect}
                      placeholder={this.props.labelTrucks}
                      options={this.props.trucksFilter}
                      onChange={(value) => this.props.setInputSubcontractorHelper({ prop: 'truckSelect', value })}
                    />
                  </div>

                </div>

                <div style={subcontractorHelperStyles.containerDivResponsive}>

                  <Table
                    hidePaginationTop={true}
                    numberPages={5}
                    loading={false}
                    columns={this.props.columnsHistorical}
                    items={this.props.listItemsHistorical} />

                  <div style={{ ...subcontractorHelperStyles.containerButton, marginTop: 80 }}>
                    <span>{string_general.required_fields}</span>
                    <Button
                      label={string_general.button_update}
                      onPress={() =>
                        this.props.updateSubcontractorHelper({
                          token: this.props.user.token,
                          company: this.props.match.params.event === undefined ? (this.props.userType !== 3 ? this.props.match.params.company : this.props.user.user.subcontractor.company.id) : this.props.companySelected,
                          id: this.props.match.params.id,
                          firstName: this.props.firstName,
                          lastName: this.props.lastName,
                          email: this.props.email,
                          phone: this.props.phone,
                          password: this.props.password,
                          confirmPassword: this.props.confirmPassword,
                          idCustomer: this.props.customerSelected,
                          idEvent: this.props.eventSelect,
                          idTruck: this.props.truckSelect,
                          status: this.props.status,
                          isFromEvent: this.props.match.params.event,
                          userType: this.props.userType,
                          history: this.props.history
                        })}
                      width={'100%'}
                      fontSize={15} />
                  </div>


                </div>
              </div>
            </ThemeProvider>
          </div>
          : <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <NotFound
              titleInfo='Driver not found'
              titleButton="Go to list drivers"
              goTo={() => this.props.history.push(`/subcontractor/${this.props.match.params.company}/drivers`)}
            />
          </div>
    )
  }
}

const mapStateProps = (state) => {
  const { user, infoLabel, isEdited } = state.user
  const { updatingSubcontractorHelpers, gettingSubcontractorHelper, helperFound,
    labelEvents, events,
    labelTrucks, trucks, trucksFilter,
    firstName, lastName, email, phone, status, password, confirmPassword,
    columnsHistorical, listItemsHistorical,
    companySelected,
    eventSelect,
    truckSelect,
    listStatus } = state.subcontractorHelper
  return {
    user, infoLabel, userType: user.user.type.id, isEdited,
    updatingSubcontractorHelpers, gettingSubcontractorHelper, helperFound,
    labelEvents, events,
    labelTrucks, trucks, trucksFilter: _.orderBy(trucksFilter, 'value', 'asc'),
    firstName, lastName, email, phone, status, password, confirmPassword,
    columnsHistorical, listItemsHistorical,
    companySelected,
    eventSelect,
    truckSelect,
    listStatus
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps,
  {
    clearState,
    setInputSubcontractorHelper,
    getOptionalDataHelper,
    getSubcontractorHelper,
    updateSubcontractorHelper,
    filterByEvent,
    getTrucksByCompany,
    setInputUser
  })(EditSubcontractorHelper)))

