import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_create_subcontractor_helper = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/general/users`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_helpers = (params) => {
    const { token, company } = params
    return axios.get(`${string_general.base_URL}auth/list/helpers?idCompany=${company}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_helper = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/users/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_subcontractor_helper = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/general/users/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_events = (params) => {
    const { token, company } = params
    return axios.get(`${string_general.base_URL}auth/event/subcontractor/${company}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_trucks = (params) => {
    const { token, company } = params
    return axios.get(`${string_general.base_URL}auth/trucks/empty/drivers?idCompany=${company}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_subcontractor_companies = (params) => {
    const { token, event } = params
    return axios.get(`${string_general.base_URL}auth/subcontractor/list/events/${event}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

//  WHEN SHOW THE TRUCKS FROM EVENT
export const sw_get_subcontractor_helpers_from_event = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/list/event/helpers`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}
//  WHEN SHOW THE TRUCKS FROM EVENT


export const sw_get_helper_lastname = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/helpers/list/lastnames`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_status = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/user_statuses`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}