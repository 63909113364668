import React from "react";

import {
    LOAD_PRODUCTS,
    CLEAR_STATE_PRODUCT,
    SET_INPUT_PRODUCT,
    LOAD_PRODUCTS_STATUS,
    LOAD_PRODUCTS_STATUS_UPDATE
} from './types'

import { sw_get_products, sw_get_products_status, sw_create_product, sw_update_product } from '../../services/ProductApi'
import { string_product, string_general } from '../../../modules/resources/strings'
import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { showAlert, isEmpty } from '../../resources/Util'
export const clearState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE_PRODUCT
        })
    }
}

export const createProduct = (info) => {
    const { token, name, getProducts } = info
    return (dispatch) => {
        if (isEmpty(name)) {
            showAlert({ msj: string_product.product_empty_error_message, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_PRODUCT,
            payload: { prop: 'loadingProduct', value: true }
        })

        dispatch({
            type: SET_INPUT_PRODUCT,
            payload: { prop: 'loadingProductText', value: string_product.creating_product }
        })

        sw_create_product({
            token,
            name
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })
                dispatch({
                    type: SET_INPUT_PRODUCT,
                    payload: { prop: 'loadingProduct', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_PRODUCT,
                        payload: { prop: 'loadingProduct', value: false }
                    })
                    return false
                }


                showAlert({ msj: string_product.create_product_success_message, type: 1 })
                dispatch({
                    type: CLEAR_STATE_PRODUCT
                })

                dispatch({
                    type: SET_INPUT_PRODUCT,
                    payload: { prop: 'showAddProductModal', value: false }
                })


                getProducts()
            } else {
                dispatch({
                    type: SET_INPUT_PRODUCT,
                    payload: { prop: 'loadingProduct', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}


export const updateProduct = (info) => {
    const { token, name, status, id, getProducts } = info
    return (dispatch) => {


        if (isEmpty(name)) {
            showAlert({ msj: string_product.product_empty_error_message, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_PRODUCT,
            payload: { prop: 'loadingProduct', value: true }
        })

        dispatch({
            type: SET_INPUT_PRODUCT,
            payload: { prop: 'loadingProductText', value: string_product.updating_product }
        })

        sw_update_product({
            token,
            name,
            status,
            id
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })
                dispatch({
                    type: SET_INPUT_PRODUCT,
                    payload: { prop: 'loadingProduct', value: false }
                })
                return false
            }

            if (data.status === 200) {



                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_PRODUCT,
                        payload: { prop: 'loadingProduct', value: false }
                    })
                    return false
                }


                showAlert({ msj: string_product.update_product_success_message, type: 1 })
                dispatch({
                    type: CLEAR_STATE_PRODUCT
                })

                dispatch({
                    type: SET_INPUT_PRODUCT,
                    payload: { prop: 'showEditProductModal', value: false }
                })
                getProducts()
            } else {
                dispatch({
                    type: SET_INPUT_PRODUCT,
                    payload: { prop: 'loadingProduct', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}


export const getProducts = (info) => {

    const { token, status, success, showError, modal } = info
    return (dispatch) => {

        sw_get_products({ token, status }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })
                showError()
                return false
            }

            if (data.status === 200) {
                const tempProducts = data.data
                const columns = [
                    {
                        Header: string_general.button_edit,
                        accessor: 'manage',
                        headerStyle: tableStyles.header,
                        width: 100,
                        sortable: false,
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: string_product.product_name_colum,
                        accessor: 'productName',
                        headerStyle: tableStyles.header,

                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },

                    {
                        Header: string_product.product_status_colum,
                        accessor: 'status',
                        headerStyle: tableStyles.header,
                        sortable: false,
                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    },
                    {
                        Header: string_general.label_last_date_modified,
                        accessor: 'updated_at',
                        headerStyle: tableStyles.header,

                        Cell: props => <span>{props.value}</span>, // Custom cell components!
                        getProps: (state, rowInfo, column) => {
                            return {
                                style: {
                                    ...tableStyles.row,
                                    background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                                }
                            }
                        }
                    }
                ]

                let items = []
                tempProducts.forEach(product => {
                    items.push(
                        {
                            manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => { modal(product) }}>{string_general.button_edit}</a>,
                            productName: product.productName,
                            status: product.status.status,
                            updated_at: product.last_modified
                        }
                    )
                });
                success()
                dispatch({
                    type: LOAD_PRODUCTS,
                    payload: { columns, items, listItemsFilter: items.filter(e => e.status === 'Active') }
                })
            } else {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })
                showError()
                return false
            }
        })
    }
}

export const getProductsStatus = (info) => {

    const { token } = info
    return (dispatch) => {
        sw_get_products_status({ token }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })

                return false
            }
            if (data.status === 200) {
                const tempStatus = data.data
                let items = []
                let itemsUpdate = []
                items.push({ value: 0, description: 'All' })
                tempStatus.forEach(status => {
                    items.push(
                        {
                            value: status.id,
                            description: status.status,

                        }
                    )

                    itemsUpdate.push(
                        {
                            value: status.id,
                            description: status.status,

                        }
                    )


                });

                dispatch({
                    type: LOAD_PRODUCTS_STATUS_UPDATE,
                    payload: { itemsUpdate }
                })

                dispatch({
                    type: SET_INPUT_PRODUCT,
                    payload: { prop: 'status', value: itemsUpdate[0] }
                })
                dispatch({
                    type: LOAD_PRODUCTS_STATUS,
                    payload: { items }
                })
            } else {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })

                return false
            }
        })
    }
}

export const setInputProduct = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_PRODUCT,
            payload: { prop, value }
        })
    }
}

export const filterByProductStatus = (info) => {
    const { listItems, status } = info
    return (dispatch) => {
        let filter = (status !== 'All') ? listItems.filter(item => item.status === status) : listItems
        dispatch({
            type: SET_INPUT_PRODUCT,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}