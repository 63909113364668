export const LOAD_CUSTOMERS_DELIVERY = 'LOAD_CUSTOMERS_DELIVERY'
export const LOAD_TYPES = 'LOAD_TYPES'
export const LOAD_STATUS = 'LOAD_STATUS'
export const SET_INPUT_DELIVERY = 'SET_INPUT_DELIVERY'
export const LOAD_DELIVERIES = 'LOAD_DELIVERIES'
export const LOADING_FILTER_DATA_DELIVERY = 'LOADING_FILTER_DATA_DELIVERY'
export const CLEAR_STATE_DELIVERY = 'CLEAR_STATE_DELIVERY'
export const GETTING_DELIVERY = 'GETTING_DELIVERY'
export const SET_DELIVERY = 'SET_DELIVERY'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_PRODUCT_UPDATE = 'SET_PRODUCT_UPDATE'

