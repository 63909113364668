import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_initial_transfer = (params) => {
    const { token, idEvent } = params

    return axios.get(`${string_general.base_URL}auth/initial/webtransfer?idEvent=${idEvent}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        })
        .catch((error) => {
            return error.response;
        })

}

export const sw_send_transfer = async (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/transfers`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        })
        .catch((error) => {
            return error.response;
        })


}

export const sw_get_states = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/states`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}