import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { mainTheme } from '../../modules/resources/themes';
import Table from './TableCreateDispatches';
import Modal from './Modal';

export const DispatchModal = ({
    isOpen,
    onModalClose,
    title,
    onPressAction,
    buttonTitle,
    numberPages,
    columns,
    items,
    children }) => {
    return (
        <ThemeProvider theme={mainTheme}>
            <Modal
                maxWidth='lg'
                open={isOpen}
                close={onModalClose}
                title={title}
                onPressAction={onPressAction}
                buttonTitle={buttonTitle}
            >

                <Table
                    hidePaginationTop={true}
                    numberPages={numberPages}
                    loading={false}
                    columns={columns}
                    items={items} />
            </Modal>

            {children}

        </ThemeProvider>
    )
}
