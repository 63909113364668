import {
    GOT_CUSTOMER,
    LOAD_CUSTOMER,
    SET_INPUT_CUSTOMER,
    CLEAR_STATE_CUSTOMER,
    GETTING_CUSTOMER,
    LOADING_OPTIONAL_DATA_CUSTOMER,
    LOAD_OPTIONAL_DATA_CUSTOMER,
    SET_CUSTOMER_DRIVERS_SELECTED,
    ERROR_GETTING_CUSTOMER
} from './types'

const initialState = {
    creatingCustomer: false,
    updatingCustomer: false,
    gettingCustomer: false,
    columns: [],
    listItems: [],
    listItemsFilter: [],
    columnsHistorial: [],
    listItemsHistorial: [],
    labelStates: '',
    customerName: '',
    contactName: '',
    phone: '',
    email: '',
    color: '#00000',
    status: 1,
    address: '',
    city: '',
    zipCode: '',
    stateCompany: '',
    arrayStates: [],

    arrayStatus: [{ value: -1, description: 'Loading status...' }],
    drivers: [],
    driversFilter: [],
    driversSelect: [],
    arrayCompanies: [],
    arrayStatus: [],
    arrayTypes: [],
    arrayEvents: [],

    filterByCompany: 0,
    filterByStatus: 0,
    filterByTruck: 0,
    filterByEvent: 0,


    customerFound: true
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_STATE_CUSTOMER:
            return {
                ...initialState
            }
        case LOAD_CUSTOMER:
            return {
                ...state,
                columns: payload.columns,
                listItems: payload.items,
                listItemsFilter: payload.listItemsFilter
            }
        case SET_INPUT_CUSTOMER:
            const { prop, value } = payload
            return {
                ...state,
                [prop]: value
            }
        case GETTING_CUSTOMER:
            return {
                ...state,
                updatingCustomer: true,
                gettingCustomer: true,
            }
        case GOT_CUSTOMER:
            return {
                ...state,
                customerFound: true,
                updatingCustomer: false,
                gettingCustomer: false,
                customerName: payload.customerName,
                contactName: payload.contactName,
                email: payload.email,
                phone: payload.phone,
                color: payload.color,
                address: payload.address,
                status: payload.status,
                city: payload.city,
                zipCode: payload.zipCode,
                stateCustomer: payload.stateCustomer,
                columnsHistorial: payload.columns,
                listItemsHistorial: payload.items,
            }
        case LOADING_OPTIONAL_DATA_CUSTOMER:
            return {
                ...state,
                labelStates: "Loading states...",
                arrayStates: [],
                drivers: [],
                driversFilter: [],
                driversSelect: [],
                arrayCompanies: [],
                arrayStatus: [],
                arrayTypes: [],
                arrayEvents: [],

                filterByCompany: 0,
                filterByStatus: 0,
                filterByTruck: 0,
                filterByEvent: 0,
            }
        case LOAD_OPTIONAL_DATA_CUSTOMER:
            return {
                ...state,
                labelStates: payload.labelStates,
                arrayStates: payload.arrayStates,
                drivers: payload.drivers,
                driversFilter: payload.drivers,
                driversSelect: payload.driversSelect,
                arrayCompanies: payload.arrayCompanies,
                arrayStatus: payload.arrayStatus,
                arrayTypes: payload.arrayTypes,
                arrayEvents: payload.arrayEvents
            }
        case SET_CUSTOMER_DRIVERS_SELECTED:
            return {
                ...state,
                drivers: [...state.drivers.filter(e => e.id !== payload.id), payload],
                driversFilter: [...state.driversFilter.filter(e => e.id !== payload.id), payload],
                driversSelect: [...state.driversSelect.filter(e => e.id !== payload.id), payload]
            }
        case ERROR_GETTING_CUSTOMER:
            return {
                ...state,
                customerFound: false,
                creatingCustomer: false,
                updatingCustomer: false,
                gettingCustomer: false,
            }
        default:
            return state
    }
}
