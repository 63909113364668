import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

const settings = { timestampsInSnapshots: true };

// PROD 
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_APY_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_BD_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESS_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

if (process.env.REACT_APP_ENV === "production") {
    config["measurementId"] = "G-7XM7JX02WZ";
    config["headers"] = [{
        source: "build.js",
        headers: [{
            key: "Cache-Control",
            value: "max-age=0"
        }]
    }]
}

firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;
