export const CLEAR_STATE_DISPATCHES = 'CLEAR_STATE_DISPATCHES'
export const SET_INPUT_DISPATCH = 'SET_INPUT_DISPATCH'
export const LOADING_DISPATCHES = 'LOADING_DISPATCHES'
export const LOAD_DISPATCHES = 'LOAD_DISPATCHES'
export const LOAD_DISPATCH = 'LOAD_DISPATCH'
export const LOAD_SITES_CREATE_DISPATCHES = 'LOAD_SITES_CREATE_DISPATCHES'
export const UPDATE_LIST_TO_CREATE_DISPATCHES = 'UPDATE_LIST_TO_CREATE_DISPATCHES'
export const DELETE_LIST_TO_CREATE_DISPATCHES = 'DELETE_LIST_TO_CREATE_DISPATCHES'
export const SET_PRODUCT = 'SET_PRODUCT'
export const LOAD_GROUPS_CREATE_DISPATCHES = 'LOAD_GROUPS_CREATE_DISPATCHES'
export const LOADING_GROUPS = 'LOADING_GROUPS'
export const LOAD_SITESLIST_CREATE_DISPATCHES = 'LOAD_SITESLIST_CREATE_DISPATCHES'
export const LOADING_SITES = 'LOADING_SITES'
