import React from "react";
import _ from 'lodash'

import {
    CLEAR_STATE_SITE,
    LOAD_SITES,
    SET_INPUT_SITE,
    LOAD_DATA_SITE,
    GETTING_SITE,
    GOT_SITE,
    ERROR_GETTING_SITE,
    UPDATE_LIST_SITES,
    UPDATE_LISTFILTER_SITES
} from './types'

import {
    SET_INPUT_DISPATCH
} from '../Dispatch/types'

import {
    sw_get_sites,
    sw_get_states,
    sw_create_site,
    sw_get_site,
    sw_update_site,
    sw_import_sites,
    sw_get_location_by_address
} from '../../services/SiteApi'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { showAlert, isEmpty, siteFailedCols, sitesToCheckCoordinatesCols } from '../../resources/Util'
import { string_general } from '../../resources/strings'
import Swal from 'sweetalert2';

export const clearState = () => {
    return (dispatch) => {

        dispatch({
            type: CLEAR_STATE_SITE
        })
    }
}


const getCoodinates = (locations, zipCode) => {

    const location = locations.find(loc => loc.formatted_address.includes(zipCode));

    let latitude,
        longitude;

    latitude = locations[0].geometry.location.lat;
    longitude = locations[0].geometry.location.lng;

    if (location) {
        latitude = location.geometry.location.lat;
        longitude = location.geometry.location.lng;
    }

    return {
        latitude,
        longitude,
        location
    }
};

const showSweetAlert = () => {
    Swal.fire({
        title: "Site coordinates",
        text: 'Some location coordinates, could no be accurate, please review the following address',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: false,
    })
};

export const createSite = (info) => {
    const {
        token,
        customer,
        siteName,
        address,
        city,
        fk_idState,
        zipCode,
        latitude,
        longitude,
        groupNumber,
        notes,
        isFromEvent,
        createSiteFromEvent,
        history,
        createStatusError
    } = info

    return (dispatch) => {
        //Validators
        if (isEmpty(customer) || isEmpty(siteName) || isEmpty(address) ||
            isEmpty(city) || isEmpty(fk_idState) || isEmpty(zipCode)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (zipCode === "00000" || zipCode === "00000-" || (zipCode.length < 5 || (zipCode.length > 5 && zipCode.length < 10))) {
            showAlert({ msj: string_general.errorMsj.formatZipCode, type: 2 })
            return false
        } else if (isNaN(latitude)) {
            showAlert({ msj: string_general.errorMsj.latitude, type: 2 })
            return false
        } else if (isNaN(longitude)) {
            showAlert({ msj: string_general.errorMsj.longitude, type: 2 })
            return false
        }


        if (isEmpty(latitude) || isEmpty(longitude)) {
            //Lat and long null, so request to the service to get the lat and log
            // from the address, city and zip code inserted. 
            const shortZipCode = zipCode.substring(0, 5);

            const geo_address = address + ',' + city + ',' + shortZipCode;
            sw_get_location_by_address({ address: geo_address.replace(/ /g, "%20").replace(/,/g, "%2C").replace(/ /g, "%20").replace(/#/g, "%23") }).then(({ data }) => {

                // console.log('data google ', data.data)
                //latitude:data.data.results[0].geometry.location.lat,
                //longitude:data.data.results[0].geometry.location.lng,
                const { latitude, longitude, location } = getCoodinates(data.results, shortZipCode);

                if (!location){
                    showSweetAlert(); 
                    return false;
                }

                dispatch({
                    type: SET_INPUT_SITE,
                    payload: { prop: 'creatingSite', value: true }
                })

                if (data.status === "OK") {
                    creatingSite({
                        token,
                        customer,
                        siteName,
                        address,
                        city,
                        fk_idState,
                        zipCode,
                        latitude,
                        longitude,
                        groupNumber,
                        notes,
                        isFromEvent,
                        createSiteFromEvent,
                        history,
                        createStatusError
                    }, dispatch)

                } else {
                    creatingSite(info, dispatch)
                }

            })



        } else {

            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'creatingSite', value: true }
            })

            creatingSite(info, dispatch)
        }

    }
}


function creatingSite(info, dispatch) {
    const {
        token,
        customer,
        siteName,
        address,
        city,
        fk_idState,
        zipCode,
        latitude,
        longitude,
        groupNumber,
        notes,
        isFromEvent,
        createSiteFromEvent,
        history,
        createStatusError
    } = info

    //Make the request to create the site
    sw_create_site({
        token,
        fk_idCustomer: customer,
        siteName,
        address,
        city,
        fk_idState,
        zipCode,
        latitude,
        longitude,
        groupNumber,
        notes,
        createStatusError
    }).then(data => {

        if (data === undefined) {
            showAlert({ msj: string_general.errorMsj.internet, type: 3 })
            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'creatingSite', value: false }
            })
            return false
        }

        if (data.status === 200) {

            if (!data.data.success && data.data.success !== undefined) {
                showAlert({ msj: data.data.message, type: 3 })
                dispatch({
                    type: SET_INPUT_SITE,
                    payload: { prop: 'creatingSite', value: false }
                })
                return false
            }

            showAlert({ msj: 'Site created successful', type: 1 })
            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'siteRepeated', value: false }
            })
            dispatch({
                type: CLEAR_STATE_SITE
            })
            if (isFromEvent === undefined) {
                history.push(`/customer/${customer}/sites`)
            } else {
                const tempSite = data.data
                const newSite = {
                    id: tempSite.id,
                    customer: tempSite.fk_idCustomer, // FILTER BY CUSTOMER
                    group: tempSite.groupNumber, // FILTER BY GROUP
                    name: tempSite.siteName, // SHOW NAME SITE
                    customerName: tempSite.customerName, // SHOW NAME CUSTOMER
                    position: { lat: tempSite.latitude, lng: tempSite.longitude }, // POSITION
                    haveDispatch: false, // I NEED KNOW IF A SITE WAS ADDED A DISPATCH
                    isNew: true, // I NEED KNOW IF A DISPATCH IS NEW OR NOT BECAUSE I NEED CHANGE THE COLOR ICON IN THE MAP
                    drivers: [] // THE LIST DRIVERS FOR ADD DRIVER TO SITE AND CREATE A DISPATCH
                }
                const requests = [sw_get_states({ token })]

                Promise.all(requests).then(function (values) {

                    const tempStates = values[0].data
                    let states = []
                    tempStates.forEach(e => states.push({ value: e.id, label: e.description }))
                    dispatch({
                        type: LOAD_DATA_SITE,
                        payload: {
                            labelStates: 'Select state',
                            arrayStates: _.orderBy(states, 'id', 'asc')
                        }
                    })

                    createSiteFromEvent(newSite)
                })
            }
        } else if (data.status === 400) {
            //Problems inserting the information. There is a site with same locations.
            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'creatingSite', value: false }
            })

            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'existentSiteName', value: data.data.siteRepeated.siteName }
            })
            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'siteRepeated', value: true }
            })
            showAlert({ msj: data.data.error, type: 3 })
            // continueOnSiteRepeated(data.data.siteRepeated.siteName)
        } else {
            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'creatingSite', value: false }
            })
            showAlert({ msj: "Site Note should contain 20 words as maximum", type: 3 })
        }
    })

}

export const setSiteRepeated = (value) => {
    return (dispatch => {
        dispatch({
            type: SET_INPUT_SITE,
            payload: { prop: 'siteRepeated', value }
        })
    })
}


export const recalculateGpsData = (info) => {

    const {
        id,
        customer,
        siteName,
        address,
        city,
        fk_idState,
        zipCode,
        latitude,
        longitude,
        fk_idStatus,
    } = info


    return (dispatch) => {

        if (isEmpty(customer) || isEmpty(id) || isEmpty(siteName) || isEmpty(address) ||
            isEmpty(city) || isEmpty(fk_idState) || isEmpty(zipCode) || isEmpty(fk_idStatus)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (zipCode === "00000" || zipCode === "00000-" || (zipCode.length < 5 || (zipCode.length > 5 && zipCode.length < 10))) {
            showAlert({ msj: string_general.errorMsj.formatZipCode, type: 2 })
            return false
        } else if (isNaN(latitude)) {
            showAlert({ msj: string_general.errorMsj.latitude, type: 2 })
            return false
        } else if (isNaN(longitude)) {
            showAlert({ msj: string_general.errorMsj.longitude, type: 2 })
            return false
        }





        const geo_address = address + ',' + city + ',' + zipCode.substring(0, 5)
        sw_get_location_by_address({ address: geo_address.replace(/ /g, "%20").replace(/,/g, "%2C").replace(/ /g, "%20").replace(/#/g, "%23") }).then(({ data }) => {

            const { latitude, longitude, location } = getCoodinates(data.results, zipCode.substring(0, 5));
            if (!location) showSweetAlert();

            if (data.status === "OK") {

                dispatch({
                    type: SET_INPUT_SITE,
                    payload: { prop: 'latitude', value: latitude }
                })

                dispatch({
                    type: SET_INPUT_SITE,
                    payload: { prop: 'longitude', value: longitude }
                })

            } else {

                showAlert({ msj: string_general.errorMsj.gpsData, type: 3 })
            }



        })








    }




}

export const updateSite = (info) => {
    const {
        token,
        id,
        customer,
        siteName,
        address,
        city,
        fk_idState,
        zipCode,
        latitude,
        longitude,
        groupNumber,
        fk_idStatus,
        notes,
        history
    } = info

    return (dispatch) => {

        if (isEmpty(customer) || isEmpty(id) || isEmpty(siteName) || isEmpty(address) ||
            isEmpty(city) || isEmpty(fk_idState) || isEmpty(zipCode) || isEmpty(fk_idStatus)) {
            showAlert({ msj: string_general.errorMsj.allFields, type: 3 })
            return false
        } else if (zipCode === "00000" || zipCode === "00000-" || (zipCode.length < 5 || (zipCode.length > 5 && zipCode.length < 10))) {
            showAlert({ msj: string_general.errorMsj.formatZipCode, type: 2 })
            return false
        } else if (isNaN(latitude)) {
            showAlert({ msj: string_general.errorMsj.latitude, type: 2 })
            return false
        } else if (isNaN(longitude)) {
            showAlert({ msj: string_general.errorMsj.longitude, type: 2 })
            return false
        }

        dispatch({
            type: SET_INPUT_SITE,
            payload: { prop: 'updatingSite', value: true }
        })

        if (isEmpty(latitude) || isEmpty(longitude)) {

            const geo_address = address + ',' + city + ',' + zipCode.substring(0, 5)
            sw_get_location_by_address({ address: geo_address.replace(/ /g, "%20").replace(/,/g, "%2C").replace(/ /g, "%20").replace(/#/g, "%23") }).then(({ data }) => {

                //latitude:data.data.results[0].geometry.location.lat,
                //longitude:data.data.results[0].geometry.location.lng,
                const { latitude, longitude, location } = getCoodinates(data.results, '1213');
                if (!location) showSweetAlert();

                if (data.status === "OK") {
                    updatingSite({
                        token,
                        id,
                        customer,
                        siteName,
                        address,
                        city,
                        fk_idState,
                        zipCode,
                        latitude,
                        longitude,
                        groupNumber,
                        fk_idStatus,
                        notes,
                        history
                    }, dispatch)
                } else {
                    updatingSite(info, dispatch)
                }

            })


        } else {

            updatingSite(info, dispatch)
        }





    }
}

function updatingSite(info, dispatch) {

    const {
        token,
        id,
        customer,
        siteName,
        address,
        city,
        fk_idState,
        zipCode,
        latitude,
        longitude,
        groupNumber,
        fk_idStatus,
        notes,
        history
    } = info

    sw_update_site({
        token,
        id,
        fk_idCustomer: customer,
        siteName,
        address,
        city,
        fk_idState,
        zipCode,
        latitude,
        longitude,
        fk_idStatus,
        groupNumber,
        notes
    }).then(data => {

        if (data === undefined) {
            showAlert({ msj: string_general.errorMsj.internet, type: 3 })
            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'updatingSite', value: false }
            })
            return false
        }

        if (data.status === 200) {

            if (!data.data.success && data.data.success !== undefined) {
                showAlert({ msj: data.data.message, type: 3 })
                dispatch({
                    type: SET_INPUT_SITE,
                    payload: { prop: 'updatingSite', value: false }
                })
                return false
            }

            showAlert({ msj: 'Site updated successful', type: 1 })
            dispatch({
                type: CLEAR_STATE_SITE
            })
            history.push(`/customer/${customer}/sites`)
        } else {
            dispatch({
                type: SET_INPUT_SITE,
                payload: { prop: 'updatingSite', value: false }
            })
            showAlert({ msj: data.data.error, type: 3 })
        }
    })

}

export const getSite = (info) => {
    const { token, id } = info
    return (dispatch) => {

        sw_get_site({ token, id }).then(data => {
            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: ERROR_GETTING_SITE
                })
                return false
            }

            if (data.status === 200) {
                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: ERROR_GETTING_SITE
                    })
                    return false
                }

                const site = data.data

                dispatch({
                    type: GOT_SITE,
                    payload: {
                        siteName: site.siteName,
                        address: site.address,
                        city: site.city,
                        notes: site.notes,
                        zipCode: site.zipCode,
                        latitude: site.latitude,
                        longitude: site.longitude,
                        groupNumber: site.groupNumber,
                        status: site.fk_idStatus,
                        stateSite: site.fk_idState,
                    }
                })
            } else {
                dispatch({
                    type: ERROR_GETTING_SITE
                })
                showAlert({ msj: 'Site not found', type: 2 })
            }
        })
    }
}

export const getDataSite = (info) => {
    const { token, getSite } = info
    return (dispatch) => {

        if (getSite !== undefined) dispatch({
            type: GETTING_SITE
        })

        const requests = [sw_get_states({ token })]

        Promise.all(requests).then(function (values) {

            const tempStates = values[0].data
            let states = []
            tempStates.forEach(e => states.push({ value: e.id, label: e.description }))

            dispatch({
                type: LOAD_DATA_SITE,
                payload: {
                    labelStates: 'Select state',
                    arrayStates: _.orderBy(states, 'id', 'asc')
                }
            })

            if (getSite !== undefined) getSite()
        })

    }
}

export const getSites = (info) => {
    const { token, id, success, showError, history } = info
    return (dispatch) => {
        const columns = [
            {
                Header: 'Edit',
                accessor: 'manage',
                headerStyle: tableStyles.header,
                width: 100,
                sortable: false,
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            ...tableStyles.row,
                            background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                        }
                    }
                }
            },
            {
                Header: 'Site Name',
                accessor: 'siteName',
                headerStyle: tableStyles.header,
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            ...tableStyles.row,
                            background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                        }
                    }
                }
            },
            {
                Header: 'Address',
                accessor: 'address',
                headerStyle: tableStyles.header,
                sortable: false,
                Cell: props => <span>{props.value}</span>, // Custom cell components!
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            ...tableStyles.row,
                            background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                        }
                    }
                }
            },
            {
                Header: 'City',
                accessor: 'city',
                headerStyle: tableStyles.header,
                Cell: props => <span>{props.value}</span>, // Custom cell components!
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            ...tableStyles.row,
                            background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                        }
                    }
                }
            },
            {
                Header: 'State',
                accessor: 'state',
                headerStyle: tableStyles.header,
                Cell: props => <span>{props.value}</span>, // Custom cell components!
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            ...tableStyles.row,
                            background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                        }
                    }
                }
            },
            {
                Header: 'Group Number',
                accessor: 'groupNumber',
                headerStyle: tableStyles.header,
                sortable: false,
                Cell: props => <span>{props.value}</span>, // Custom cell components!
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            ...tableStyles.row,
                            background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                        }
                    }
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                headerStyle: tableStyles.header,
                Cell: props => <span>{props.value}</span>, // Custom cell components!
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            ...tableStyles.row,
                            background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                        }
                    }
                }
            },
            {
                Header: 'Last Date Modified',
                accessor: 'updated_at',
                headerStyle: tableStyles.header,
                Cell: props => <span>{props.value}</span>, // Custom cell components!
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            ...tableStyles.row,
                            background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                        }
                    }
                }
            }
        ]
        let items = []

        sw_get_sites({ token, id }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                showError()
            } else {
                if (data.status === 200) {
                    const tempSites = data.data

                    if (!data.data.success && data.data.success !== undefined) {
                        showAlert({ msj: data.data.message, type: 3 })
                        showError()
                    } else {
                        tempSites.forEach(site => {
                            items.push(
                                {
                                    id: site.id,
                                    manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/customer/${id}/edit_site/${site.id}`)}>Edit</a>,
                                    siteName: site.siteName,
                                    address: site.address,
                                    city: site.city,
                                    state: site.state.description,
                                    groupNumber: site.groupNumber,
                                    status: site.status.status,
                                    updated_at: site.updated_at
                                }
                            )
                        });
                        success()
                    }
                } else {
                    showError()
                }
            }

            dispatch({
                type: LOAD_SITES,
                payload: { columns, items, listItemsFilter: items.filter(e => e.status === 'Active') }
            })
        })

    }
}

export const setInputSite = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_SITE,
            payload: { prop, value }
        })
    }
}

export const filterByStatusSite = (info) => {
    const { listItems, status } = info
    return (dispatch) => {
        let filter = (status !== 'All') ? listItems.filter(item => item.status === status) : listItems
        dispatch({
            type: SET_INPUT_SITE,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}

/**
 * Determine why this function is not used to import the sites or where is used it. 
 * Currently the function that is working it is on Dispatch Action.
 * @param {*} info 
 * @returns 
 */
export const importSites = (info) => {
    const { token, idCustomer, fileTemp, status, setImporting, history, exporting } = info
    return (dispatch) => {

        setImporting(true)
        let data = new FormData();
        data.append('file', fileTemp);
        data.append('idCustomer', idCustomer);

        sw_import_sites({ token, data }).then(data => {
            setImporting(false)

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }

            const { listSites: sitesTemp, success, message } = data.data;

            if (data.status === 200) {

                if (!success && success !== undefined) {
                    showAlert({ msj: message, type: 3 })
                    return false
                }

                if (sitesTemp !== undefined) {
                    sitesTemp.forEach(site => {
                        dispatch({
                            type: UPDATE_LIST_SITES,
                            payload: {
                                id: site.id,
                                manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/customer/${idCustomer}/edit_site/${site.id}`)}>Edit</a>,
                                siteName: site.siteName,
                                address: site.address,
                                city: site.city,
                                state: site.state.description,
                                groupNumber: site.groupNumber,
                                status: site.status.status,
                                updated_at: site.updated_at
                            }
                        })

                        if (status === site.status.status || status === 'All') {
                            dispatch({
                                type: UPDATE_LISTFILTER_SITES,
                                payload: {
                                    id: site.id,
                                    manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/customer/${idCustomer}/edit_site/${site.id}`)}>Edit</a>,
                                    siteName: site.siteName,
                                    address: site.address,
                                    city: site.city,
                                    state: site.state.description,
                                    groupNumber: site.groupNumber,
                                    status: site.status.status,
                                    updated_at: site.updated_at
                                }
                            })
                        }
                    });

                    if (sitesTemp !== undefined) {

                        const emptys = sitesTemp.filter(e => e.isEmpty === true)
                        exporting(emptys)
                        if (emptys.length > 0) {
                            showAlert({ msj: 'Can not get Gps Data for ' + emptys.length + ' site(s) ', type: 2 })
                        } else {
                            showAlert({ msj: 'Sites import successful', type: 1 })
                        }


                    }
                }

            } else if (data.status == 400) {
                //Manage the imports replicated sites
                // const textErrorForFile = data.data.messageTxt; //String to implement on a text file.
                // console.log("ENTRO")
                // setImporting(false)
                // showAlert({ msj: data.data.message, type: 3, duration: 10000 })

                //Manage the imports replicated sites

                const { messageTxt, sitesRepeated, error } = data.data;

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'importSitesFailed', value: true }
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesImportedError', value: messageTxt }
                })

                let dataToTable = [];

                sitesRepeated.forEach(siteRepeated => {
                    dataToTable.push({
                        colSiteName: siteRepeated[0].name,
                        colSiteAddress: `${siteRepeated[0].address}, ${siteRepeated[0].city}, ${siteRepeated[0].state}`,
                        colCurrentSiteName: siteRepeated[1].siteName,
                        colCurrentSiteAddress: `${siteRepeated[1].address}, ${siteRepeated[1].city}, ${siteRepeated[0].state}`
                    });
                });

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesRepeated', value: dataToTable } //String to implement on a text file.
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesRepeatedCols', value: siteFailedCols } //String to implement on a text file.
                })

                setImporting(false)
                showAlert({ msj: error, type: 3, duration: 10000 })
            } else {
                setImporting(false)
                showAlert({ msj: data.data.message, type: 3, duration: 10000 })
            }

            const { sitesCheckLocation, messageSiteCheckLocation } = data.data;

            if (sitesCheckLocation.length > 0) {

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'hasToCheckCoodinates', value: true }
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesCoodinatesError', value: messageSiteCheckLocation }
                })

                let dataToTable = [];

                sitesCheckLocation.forEach(site => {
                    dataToTable.push({
                        colSiteName: site.name,
                        colSiteAddress: `${site.address}, ${site.city}, ${site.state}`,
                    });
                });

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesToCheckCoodinates', value: dataToTable } //String to implement on a text file.
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesToCheckCoodinatesCols', value: sitesToCheckCoordinatesCols } //String to implement on a text file.
                })

            }

        })

    }
}
