import axios from 'axios'
import { string_general } from '../resources/strings'

export const sw_create_super_sfs_admins = (params) => {
    const { token } = params
    return axios.post(`${string_general.base_URL}auth/users`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_super_sfs_admins = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/super/users`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_super_sfs_admin = (params) => {
    const { token, id } = params
    return axios.get(`${string_general.base_URL}auth/users/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_update_super_sfs_admin = (params) => {
    const { token, id } = params
    return axios.put(`${string_general.base_URL}auth/user/sfs/${id}`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}

export const sw_get_status = (params) => {
    const { token } = params
    return axios.get(`${string_general.base_URL}auth/user_statuses`, {
        headers: {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }, { params })
        .then(res => {
            return res;
        }).catch((error) => {
            return error.response;
        })
}