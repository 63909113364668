import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash'

// MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// ICONS
import Event from '@material-ui/icons/EventOutlined';

//MY COMPONENTS
import Dropdown from '../../components/Dropdown'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import ListElements from '../../components/ListElements'
import ListSelect from '../../components/ListSelect'
import Autocomplete from '../../components/Autocomplete'

// ACTIONS
import {
  clearState,
  setInputEvent,
  getDataEvent,
  deleteCities,
  addCities,
  selectStatesToEvent,
  selectCustomersToEvent,
  selectSubcontractorToEvent,
  createEvent
} from '../../../modules/core/Event/actions'
import { setInputUser } from '../../../modules/core/User/actions'

// STYLES
import { eventStyles } from '../../../modules/resources/styles'
import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_event } from '../../../modules/resources/strings'

import { showAlert, isEmpty } from '../../../modules/resources/Util'

const styles = theme => ({
  root: {
    display: 'flex',
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  },
  sectionResponsive: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 50,
    },
  },
  sectionResponsiveModal: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 10,
      marginRight: 10,
    },
  },
  fab: {
    width: 45,
    height: 40,
    background: colors.blue_sfs
  }
});

class CreateEvent extends React.Component {

  state = {
    showModal: false,
    customerSelected: undefined
  }

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getDataEvent({ token: this.props.user.token })
    }
    this.configMenuItems()
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    this.props.setInputUser({ prop: 'itemsSubmenu', value: [] })
    this.props.setInputUser({ prop: 'value', value: 4 })
    this.props.setInputUser({ prop: 'itemSelect', value: 1 })


    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [{
        link: `/events`,
        label: 'Events'
      },
      {
        link: `/create_event`,
        label: 'Create Event'
      }]
    })
  }

  componentWillUnmount() {

    console.log("this.props.customers: ", this.props.customers)
    const haveCities = this.props.citiesSelect.find(e => e.isSelect)
    const haveStates = this.props.states.find(e => e.isSelect)
    const haveCustomer = this.props.customers === null ? undefined : this.props.customers.find(e => e.isSelect)
    const haveCompanies = this.props.subcontractors === null ? undefined : this.props.subcontractors.find(e => e.isSelect)

    if (!isEmpty(this.props.eventName) || this.props.typeEvent !== 0 ||
      haveCities !== undefined || haveStates !== undefined || haveCustomer !== undefined ||
      haveCompanies !== undefined) {

      if (!window.confirm(string_general.leaveMsjCreate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push('/create_event')
      } else {
        this.props.clearState()
      }
    }

  }


  render() {
    const { classes } = this.props;
    return (
      this.props.creatingEvent ?
        <Loading label={string_event.creating_event} />
        :
        <div style={{ ...eventStyles.container, alignItems: 'center' }}>
          <span style={eventStyles.title}>{string_event.title_create_event}</span>

          {/* DESKTOP AND TABLE */}
          <ThemeProvider theme={mainTheme}>
            <div className={classes.sectionResponsive}>

              {/* SECTION #1 */}
              <div style={eventStyles.containerDivResponsive}>

                {/* EVENT NAME */}
                <Input
                  id="EventName"
                  label="Event Name"
                  placeholder="Enter event name"
                  isRequired
                  type
                  className={classes.margin}
                  value={this.props.eventName}
                  onChange={value => this.props.setInputEvent({ prop: 'eventName', value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Event className={classes.margin} color="secondary" />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                />

                {/* TYPES */}
                <div style={eventStyles.containerDropdown}>
                  <Dropdown
                    selectItem={this.props.typeEvent}
                    placeholder={this.props.labelTypes}
                    options={this.props.eventTypes}
                    onChange={(value, description) => this.props.setInputEvent({ prop: 'typeEvent', value })}
                  />
                  <div style={eventStyles.required}>*</div>
                </div>


                {/* CITY */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 30
                }}>

                  <Autocomplete
                    suggestions={this.props.cities}
                    label='City'
                    placeholder='Enter event city'
                    value={this.props.city}
                    onChangeValue={value => this.props.setInputEvent({ prop: 'city', value })}
                    onKeyPress={() => {
                      isEmpty(this.props.city) ?
                        showAlert({ msj: 'City empty', type: 2 })
                        : this.props.addCities({ city: this.props.city, cities: this.props.cities })
                    }}
                  />

                  <Fab color="primary" aria-label="add" className={classes.fab}
                    onClick={() => {
                      isEmpty(this.props.city) ?
                        showAlert({ msj: 'City empty', type: 2 })
                        : this.props.addCities({ city: this.props.city, cities: this.props.cities })
                    }}>
                    <AddIcon />
                  </Fab>
                </div>

                <span style={{
                  marginBottom: 10
                }}>Cities </span>
                <ListElements
                  isCity
                  withoutButton
                  title=''
                  items={this.props.citiesSelect}
                  onPressDelete={(item) => this.props.deleteCities({
                    item,
                    cities: this.props.citiesSelect
                  })}
                />
              </div>


              {/* SECTIONS #2 */}
              <div style={{ ...eventStyles.containerDivResponsive, marginRight: 5, marginLeft: 5 }}>

                <span style={{
                  marginBottom: 10
                }}>States *</span>
                <ListSelect
                  maxHeight={200}
                  items={this.props.states}
                  titleLoading={string_event.getting_states}
                  icon={<i className="tim-icons icon-square-pin" />}
                  titleNotfound='States not found'
                  titleButtonNotfound='Refresh'
                  goToNotFound={() => { }}
                  onChange={(item) => {
                    this.props.selectStatesToEvent({
                      array: this.props.states,
                      item
                    })
                  }}
                />

                <span style={{
                  marginBottom: 10,
                  marginTop: 10,
                }}>Customers</span>
                <ListSelect
                  customerSelected={(item) => this.setState({ customerSelected: item })}
                  maxHeight={200}
                  items={this.props.customers}
                  titleLoading={string_event.getting_customers}
                  icon={<i className="tim-icons icon-user-run" />}
                  titleNotfound='Customer not found'
                  titleButtonNotfound='Refresh'
                  goToNotFound={() => { }}
                  selectItem
                  onChange={(item) => {
                    this.props.selectCustomersToEvent({
                      array: this.props.customers,
                      item
                    })
                  }}
                />
              </div>

              {/* SECTION #3 */}
              <div style={eventStyles.containerDivResponsive}>

                <span style={{
                  marginBottom: 10
                }}>Subcontractors</span>
                <ListSelect
                  maxHeight={200}
                  items={this.props.subcontractors}
                  titleLoading={string_event.getting_subcontractors}
                  icon={<i className="tim-icons icon-bank" />}
                  titleNotfound='Subcontractor not found'
                  titleButtonNotfound='Refresh'
                  goToNotFound={() => { }}
                  onChange={(item) => {
                    this.props.selectSubcontractorToEvent({
                      array: this.props.subcontractors,
                      item
                    })
                  }}
                />

                {/* BUTTON */}
                <div style={eventStyles.containerButton}>
                  <span>{string_general.required_fields}</span>
                  <Button
                    label={string_general.button_create}
                    onPress={() =>
                      this.props.createEvent({
                        token: this.props.user.token,
                        eventName: this.props.eventName,
                        type: this.props.typeEvent,
                        cities: this.props.citiesSelect,
                        states: this.props.states,
                        customers: this.props.customers,
                        subcontractors: this.props.subcontractors,
                        customerSelect: this.state.customerSelected,
                        history: this.props.history
                      })}
                    width={'100%'}
                    fontSize={15} />
                </div>

              </div>

            </div>
          </ThemeProvider>
        </div>
    )
  }
}

CreateEvent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateProps = (state) => {
  const { isAuthenticated, user, isEdited } = state.user
  const {
    creatingEvent,

    eventName,
    typeEvent,
    city,


    labelTypes,
    eventTypes,

    cities,
    citiesSelect,
    states,
    customers,
    subcontractors

  } = state.event
  return {
    isAuthenticated, user, isEdited,

    creatingEvent,

    eventName,
    typeEvent,
    city,

    labelTypes,
    eventTypes,

    cities: _.orderBy(cities, 'id', 'asc'),
    citiesSelect: _.orderBy(citiesSelect, 'id', 'asc'),
    states: _.orderBy(states, 'id', 'asc'),
    customers,
    subcontractors
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  setInputEvent,
  getDataEvent,
  deleteCities,
  addCities,
  selectStatesToEvent,
  selectCustomersToEvent,
  selectSubcontractorToEvent,
  createEvent,
  setInputUser
})(CreateEvent)))